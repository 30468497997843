import {
  ApiCampaignReport,
  formatCampaignReportDescriptor,
  formatCampaignReportStatus,
} from './EngagementSurvey';
import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import { EditButton } from './EngagementSurveyReportsTableEdit';
import EmptyState from 'views/Widgets/EmptyState';
import EngagementSurveyReportNewModalEditorButton from './EngagementSurveyReportNewModalEditorButton';
import EngagementSurveyReportsTableDeleteButton from './EngagementSurveyReportsTableDeleteButton';
import FilterablePeopleTable from 'views/Widgets/People/FilterablePeopleTable';
import Loading from 'views/Widgets/Loading';
import { renderValidationError } from 'utils/util/formatter';
import { useCampaignReports } from '../CampaignReportsContext';
import { useConfirmApi } from 'utils/api/ApiHooks';
import { useIntl } from 'react-intl';

type CampaignReportsDataCampaignReportsData = {
  campaign_id: number;
  values: ApiCampaignReport[];
};

const EngagementSurveyReportsTable: FC = () => {
  const { campaignId, engagementSurveyListVersion } = useCampaignReports();
  const { formatMessage } = useIntl();

  const {
    data: reportsForCampaignData,
    error: reportsForCampaignError,
    status: reportsForCampaignStatus,
  } = useConfirmApi<CampaignReportsDataCampaignReportsData>({
    url: `/campaigns/${campaignId}/reports`,
    method: 'GET',
    params: {
      type: 'E',
      // Marker, just to refresh when changed
      version: engagementSurveyListVersion,
    },
  });

  const columns = useMemo(
    () => [
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.table.column.type.name',
          defaultMessage: 'Type',
        }),
        field: 'type',
        className: 'col-2',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.table.column.description.name',
          defaultMessage: 'Description',
        }),
        field: 'description',
        sortable: false,
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.table.column.status.name',
          defaultMessage: 'Status',
        }),
        field: 'status',
        className: 'text-nowrap',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.table.column.actions.name',
          defaultMessage: 'Actions',
        }),
        field: 'actions',
        sortable: false,
        className: 'col-auto',
      },
    ],
    [formatMessage]
  );

  const rows = useMemo(
    () =>
      reportsForCampaignData?.values.map((report) => {
        const { title, description } = formatCampaignReportDescriptor(
          report,
          formatMessage
        );
        return {
          id: report.id,
          type: title,
          description,
          status: formatCampaignReportStatus(report, formatMessage),
          actions: report.editable ? (
            <Row className="ps-0 mb-n3 pe-0 flex-nowrap">
              <Col className="ps-0 col-auto pe-0 pb-3">
                <EditButton
                  reportId={report.id}
                  sliceType={report.config.slice_type}
                />
              </Col>
              <Col className="col-auto ps-0 pe-4 pb-3">
                <EngagementSurveyReportsTableDeleteButton
                  reportId={report.id}
                />
              </Col>
            </Row>
          ) : null,
        };
      }),
    [formatMessage, reportsForCampaignData?.values]
  );

  if (reportsForCampaignError) {
    return (
      <Card>
        <CardBody>{renderValidationError(reportsForCampaignError)}</CardBody>
      </Card>
    );
  }

  if (reportsForCampaignStatus != 'SUCCESS' || !rows) {
    return (
      <Loading
        message={formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.message.loading_reports',
          defaultMessage: 'Loading reports...',
        })}
      />
    );
  }

  return (
    <>
      {!rows.length && (
        <Card>
          <CardBody>
            <EmptyState
              title={formatMessage({
                id: 'app.views.administration.campaign_reports.engagement_survey_reports.empty_state.title',
                defaultMessage: 'No reports',
              })}
              subtitle={formatMessage({
                id: 'app.views.administration.campaign_reports.engagement_survey_reports.empty_state.subtitle',
                defaultMessage:
                  'There are no reports available for this campaign.',
              })}
            >
              <EngagementSurveyReportNewModalEditorButton />
            </EmptyState>
          </CardBody>
        </Card>
      )}
      {rows.length > 0 && (
        // @ts-expect-error
        <FilterablePeopleTable
          rows={rows}
          columns={columns}
          hideFilters={true}
          hideExportButton={true}
        />
      )}
    </>
  );
};

export default React.memo(EngagementSurveyReportsTable);
