import * as consts from '../../consts/consts';

import { Button, Col, PopoverBody, Row } from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getCampaignCoverageDurationMonthString,
  getPhaseByType,
  toPersonWithSurvey,
} from '../../utils/models/Campaign';
import { Campaign, Features, Me, Organization, Person } from 'types';
import {
  DEFAULT_OBJECTIVES_HEADER,
  DEFAULT_OBJECTIVES_MESSAGE_HTML,
  DEFAULT_SELF_REFLECTION_INTRO_MESSAGE_HTML,
  PERFORMANCE_ACCOMPLISHMENTS_DEFAULT_VISIBILITY_PRIVATE,
  PERFORMANCE_ACCOMPLISHMENTS_INSTRUCTIONS,
  PERFORMANCE_ACCOMPLISHMENTS_MAXIMUM,
  PERFORMANCE_ACCOMPLISHMENTS_MINIMUM,
  PERFORMANCE_FEATURE_ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS,
  PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP,
  PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_STEP,
  PERFORMANCE_FEATURE_COMPLETE_RESUME_STEP,
  PERFORMANCE_FEATURE_CONTRIBUTION_SELF_REFLECTIONS,
  PERFORMANCE_FEATURE_OBJECTIVES_HEADER,
  PERFORMANCE_FEATURE_OBJECTIVES_MESSAGE_HTML,
  PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_SELF_REFLECTION_INTRO_MESSAGE_HTML,
  PHASE_TYPE_SELF,
  getAccomplishmentQuestionText,
  getAutoFocusIndex,
  getCampaignFeature,
  getCampaignHasFeatureEnabled,
  getCurrentPerformancePreviewPathPrefix,
  getPerformanceFeatureEnabled,
  getPerformanceFeatureEnabledAndNonEmtpy,
  getPerformanceFeatureEnabledWithFallback,
  getPerformanceFeatureValue,
  getPhaseOpenResponseQuestions,
  getSelectedTimeFrameForObjectives,
  getStepNumber,
  perfCampaignCallback,
  prepareOpenResponseQuestion,
} from '../../utils/models/Performance';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getFriendlyUserFacingErrorObjectAndMessage,
  prepTagsForSubmit,
  yyyymmddToLocalDate,
} from '../../utils/util/util';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import AutoSaveInfoBox from 'views/Widgets/Forms/AutoSaveInfoBox';
import BackButtonLink from 'views/Widgets/Buttons/BackButtonLink';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { DEFAULT_ACCOMPLISHMENTS_MINIMUM } from '../../consts/consts';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import Loading from '../Widgets/Loading';
import LogRocket from 'logrocket';
import PerformancePage from './PerformancePage';
import PersonProfileFeedback from '../Person/PersonProfileFeedback';
import PersonalObjectives from '../Person/PersonalObjectives';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import SelfReflectionImg from '../../assets/img/illustrations/self-reflection.png';
import SimpleActivityEditorCard from '../Widgets/Cards/SimpleActivityEditorCard';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { activitiesInHomepageFeedIsEnabled } from 'utils/util/features';
import { applyCustomFiltersToQuestions } from '../Widgets/People/Filters/common';
import { connect } from 'react-redux';
import { formatTimeFrame } from '../Objectives/ObjectivesTimeFrameSelector/utils';
import { isEqual } from 'lodash';
import { isObjectiveWithinTimeframe } from 'utils/models/Objective';
import { peopleObjectsAreEqual } from '../../utils/models/Person';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useAutosave } from 'views/Widgets/Forms/hooks';

// query strings for each possible "substep" / "page" of the assess self flow
const ASSESS_SELF_PAGE_RESUME = 'resume';
const ASSESS_SELF_PAGE_REFLECTIONS = 'reflection';
const ASSESS_SELF_PAGE_OBJECTIVES = 'objectives';
const ASSESS_SELF_PAGE_RESPONSES = 'responses';

// minimums to proceed to next step
const MIN_REFLECTIONS_REQUIRED = 3;
const MIN_OBJECTIVES_REQUIRED = 1;

const RESUME_HIGHLIGHTS_LOCALSTORAGE_KEY =
  'resume-highlights-local-storage-key';

const continueDisabledReflectionText = (duration, formatMessage) =>
  formatMessage(
    {
      id: 'app.views.performance.performance_step_assess_self.continue_disabled_reflection_text',
      defaultMessage:
        'Add 3 reflections from the past {duration} before continuing.',
    },
    { duration }
  );

const reachedAccomplishmentsLimit = (
  numAccomplishments,
  maximumAccomplishments
) => maximumAccomplishments && numAccomplishments >= maximumAccomplishments;

const PerformanceStepAssessSelf: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();

  const [feedbackWithinPeriodCount, setfeedbackWithinPeriodCount] = useState(0);
  const [objectiveWithinPeriodCount, setObjectiveWithinPeriodCount] =
    useState(0);
  const [highlights, setHighlights] = useState();
  const [lastestSubmittedHighlights, setLastestSubmittedHighlights] =
    useState();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  const campaign = props.campaign;

  const defaultVisibility = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_ACCOMPLISHMENTS_DEFAULT_VISIBILITY_PRIVATE
      )
        ? 'M'
        : 'E',
    [campaign]
  );

  const minimumAccomplishments = useMemo(
    () =>
      getCampaignFeature(campaign, PERFORMANCE_ACCOMPLISHMENTS_MINIMUM) ||
      DEFAULT_ACCOMPLISHMENTS_MINIMUM,
    [campaign]
  );

  const maximumAccomplishments = useMemo(
    () => getCampaignFeature(campaign, PERFORMANCE_ACCOMPLISHMENTS_MAXIMUM),
    [campaign]
  );

  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth0();

  const campaignDuration = useMemo(
    () => getCampaignCoverageDurationMonthString(campaign, formatMessage),
    [campaign, formatMessage]
  );

  const objectivesStepHeaderText = useMemo(
    () =>
      getPerformanceFeatureValue(
        campaign,
        PERFORMANCE_FEATURE_OBJECTIVES_HEADER
      ) ?? DEFAULT_OBJECTIVES_HEADER,
    [campaign]
  );

  const objectivesMessageHtml = useMemo(
    () =>
      getPerformanceFeatureValue(
        campaign,
        PERFORMANCE_FEATURE_OBJECTIVES_MESSAGE_HTML
      ) ?? DEFAULT_OBJECTIVES_MESSAGE_HTML,
    [campaign]
  );

  const selfReflectionIntroText = useMemo(
    () =>
      getPerformanceFeatureValue(
        campaign,
        PERFORMANCE_FEATURE_SELF_REFLECTION_INTRO_MESSAGE_HTML
      ) ?? DEFAULT_SELF_REFLECTION_INTRO_MESSAGE_HTML(formatMessage),
    [campaign, formatMessage]
  );

  const completeResumeStepIsEnabled = useMemo(
    () =>
      getPerformanceFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_COMPLETE_RESUME_STEP
      ),
    [campaign]
  );

  const completeObjectivesStepIsEnabled = useMemo(
    () =>
      getPerformanceFeatureEnabledWithFallback(campaign, [
        PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP,
        PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_STEP,
      ]),
    [campaign]
  );

  const contributionSelfReflectionsEnabled = getPerformanceFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_CONTRIBUTION_SELF_REFLECTIONS
  );

  const openResponseQuestionsEnabled = getPerformanceFeatureEnabledAndNonEmtpy(
    campaign,
    PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS
  );

  const hideFeedbackRequestPromptsWhenClaimingContributions =
    !getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS
    );

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const isDemoOrPreviewMode = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [campaign?.status]
  );

  const schema = useMemo(
    () => [{ name: 'highlights', type: INPUT_TYPES.CUSTOM_INPUT }],
    []
  );

  const currentPageName = useMemo(
    () =>
      new URLSearchParams(location.search).get('page')
        ? new URLSearchParams(location.search).get('page')
        : null,
    [location.search]
  );

  const isInCompleteResumeStep = useMemo(
    () => currentPageName === ASSESS_SELF_PAGE_RESUME,
    [currentPageName]
  );

  const {
    stash: storeUnsavedChanges,
    discard: clearUnsavedChanges,
    state: resumeDraftFetchState,
    latestObjectFetched: latestDraftObjectFetched,
    deleteRemote: deleteResumeDraftFromServer,
    // hasPendingChanges: resumeDraftHasPendingChangesToSync,
    isChangedFromSubmitted: resumeIsInDraftState,
  } = useAutosave({
    key: `campaign-${campaign?.id}-highlights`,
    enabled: !isDemoOrPreviewMode && isInCompleteResumeStep,
    inputs: schema,
    submittedObject: { highlights: lastestSubmittedHighlights },
  });

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.currentPerfSurveyResponse,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.currentPerfSurveyResponse,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  useEffect(() => {
    if (isInitialLoad) {
      return;
    }
    // @ts-expect-error
    if (latestDraftObjectFetched?.highlights) {
      // @ts-expect-error
      setHighlights([...latestDraftObjectFetched.highlights]);
    } else if (!lastestSubmittedHighlights) {
      // @ts-expect-error
      setHighlights([
        {
          visibility: defaultVisibility,
          organization: props.currentOrganization?.id,
        },
      ]);
    }
  }, [
    isInitialLoad,
    latestDraftObjectFetched,
    lastestSubmittedHighlights,
    props.currentOrganization?.id,
    defaultVisibility,
  ]);

  const resumeLocalStorageKey = useMemo(
    () => RESUME_HIGHLIGHTS_LOCALSTORAGE_KEY + '-' + campaign?.id,
    [campaign?.id]
  );

  const discardUnsavedChanges = useCallback(() => {
    clearUnsavedChanges();
    // @ts-expect-error
    setHighlights([
      ...(lastestSubmittedHighlights ?? [
        {
          visibility: defaultVisibility,
          organization: props.currentOrganization?.id,
        },
      ]),
    ]);
  }, [
    clearUnsavedChanges,
    defaultVisibility,
    lastestSubmittedHighlights,
    props.currentOrganization?.id,
  ]);

  const saveHighlights = useCallback(
    (highlights, onSuccess) => {
      if (isDemoOrPreviewMode) {
        return onSuccess();
      }

      LogRocket.info('saveHighlights', {
        highlights,
        isInCompleteResumeStep,
      });
      if (
        !props.currentPerfSurveyResponse?.id ||
        !props.currentOrganization?.id ||
        !highlights
      ) {
        return;
      }
      const highlightsToSave = highlights.filter(
        (h) => !!h.title?.trim()?.length
      );
      const numHighlights =
        highlightsToSave.filter((x) => x.title?.trim().length)?.length || 0;
      if (numHighlights < minimumAccomplishments) {
        toast.error(
          formatMessage({
            id: 'app.views.performance.performance_step_assess_self.save_highlights.error.unable_to_save',
            defaultMessage:
              'We were unable to save at this time. Please try again later or inform Customer Support.',
          })
        );
        return;
      }
      setIsSaveInProgress(true);
      ConfirmAPI.sendRequestToConfirm(
        'PATCH',
        '/performance/save-highlights/' + props.currentPerfSurveyResponse?.id,
        {
          organization_id: props.currentOrganization?.id,
          highlights: highlightsToSave,
        },
        (data, error, hardErrorMessage) => {
          if (error || hardErrorMessage) {
            const [errorObject] = getFriendlyUserFacingErrorObjectAndMessage(
              error,
              hardErrorMessage
            );
            console.error(
              'Error updating highlight: ' + JSON.stringify(errorObject)
            );
            toast.error(
              formatMessage({
                id: 'app.views.performance.performance_step_assess_self.save_highlights.error.unable_to_validate',
                defaultMessage:
                  'We were unable to validate these accomplishments and move on to the next step for an unexpected reason. Please contact customer support to resolve this issue, or try again later in case you are having network difficulties.',
              })
            );
            setIsSaveInProgress(false);
          } else {
            // successfully saved, don't need to use local storage anymore and then call onSuccess
            // Note: we call setIsSaveInProgress(false) in onSuccess
            // as late as possible to avoid flashing an enabled
            // Save and continue button
            deleteResumeDraftFromServer(() => onSuccess());
          }
        }
      );
    },
    [
      isDemoOrPreviewMode,
      deleteResumeDraftFromServer,
      isInCompleteResumeStep,
      minimumAccomplishments,
      props.currentOrganization?.id,
      props.currentPerfSurveyResponse?.id,
      formatMessage,
    ]
  );

  const callback = useCallback(
    (data) => {
      if (data) {
        if (isInCompleteResumeStep) {
          saveHighlights(highlights, () => {
            propsSetCurrentPerfSurveyResponse(data);
            perfCampaignCallback(
              props.me,
              props.currentOrganization,
              campaign,
              props.currentPerfSurveyResponse,
              history,
              props.demoPeople,
              data,
              formatMessage
            );
          });
        } else {
          propsSetCurrentPerfSurveyResponse(data);
          perfCampaignCallback(
            props.me,
            props.currentOrganization,
            campaign,
            props.currentPerfSurveyResponse,
            history,
            props.demoPeople,
            data,
            formatMessage
          );
        }
      }
    },
    [
      highlights,
      history,
      isInCompleteResumeStep,
      props.currentOrganization,
      campaign,
      props.currentPerfSurveyResponse,
      props.demoPeople,
      props.me,
      propsSetCurrentPerfSurveyResponse,
      saveHighlights,
      formatMessage,
    ]
  );

  const pagesList = useMemo(() => {
    const l = [
      null, // page index 0 is the "null" page, i.e. no query parameter
    ];

    if (completeObjectivesStepIsEnabled) {
      // @ts-expect-error
      l.push(ASSESS_SELF_PAGE_OBJECTIVES);
    }
    if (completeResumeStepIsEnabled) {
      // @ts-expect-error
      l.push(ASSESS_SELF_PAGE_RESUME);
    }
    if (contributionSelfReflectionsEnabled) {
      // @ts-expect-error
      l.push(ASSESS_SELF_PAGE_REFLECTIONS);
    }
    if (openResponseQuestionsEnabled) {
      // @ts-expect-error
      l.push(ASSESS_SELF_PAGE_RESPONSES);
    }

    return l;
  }, [
    completeObjectivesStepIsEnabled,
    completeResumeStepIsEnabled,
    contributionSelfReflectionsEnabled,
    openResponseQuestionsEnabled,
  ]);

  // ignore the initial 0 "welcome" page
  const maxPages = useMemo(() => pagesList.length - 1, [pagesList.length]);

  const currentPageIndex = useMemo(
    // @ts-expect-error
    () => pagesList.indexOf(currentPageName),
    [currentPageName, pagesList]
  );

  const isInSelfReflectionEditingStep = useMemo(
    () => currentPageName === ASSESS_SELF_PAGE_REFLECTIONS,
    [currentPageName]
  );

  const isInCompleteObjectivesStep = useMemo(
    () => currentPageName === ASSESS_SELF_PAGE_OBJECTIVES,
    [currentPageName]
  );

  const isInOpenResponsesStep = useMemo(
    () => currentPageName === ASSESS_SELF_PAGE_RESPONSES,
    [currentPageName]
  );

  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();

  const navigatePage = useCallback(
    (goForward) => {
      if (goForward) {
        // go forward
        history.push(
          previewPathPrefix +
            consts.PERFORMANCE_STEP_ASSESS_SELF(formatMessage).path +
            '?page=' +
            pagesList[currentPageIndex + 1]
        );
      } else {
        // go back
        if (currentPageIndex === 1) {
          // go to beginning
          history.push(
            previewPathPrefix +
              consts.PERFORMANCE_STEP_ASSESS_SELF(formatMessage).path
          );
        } else {
          history.push(
            previewPathPrefix +
              consts.PERFORMANCE_STEP_ASSESS_SELF(formatMessage).path +
              '?page=' +
              pagesList[currentPageIndex - 1]
          );
        }
      }
    },
    [currentPageIndex, history, pagesList, previewPathPrefix, formatMessage]
  );

  const person = props.me;

  // structure any skills as structured data and pass in any other free text
  // responses as is
  const object = useMemo(() => {
    if (!props.currentPerfSurveyResponse) {
      return {};
    }

    const skillKeys = ['positive_skills', 'negative_skills'];

    const responses = props.currentPerfSurveyResponse?.responses || {};
    const filtered = Object.keys(responses)
      .filter((key) => !skillKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = responses[key];
        return obj;
      }, {});

    return {
      positive_skills: props.currentPerfSurveyResponse.positive_skills,
      negative_skills: props.currentPerfSurveyResponse.negative_skills,
      ...filtered,
    };
  }, [props.currentPerfSurveyResponse]);

  const contributionFeedbackIsWithinPeriod = useCallback(
    (cf) => {
      const perfCoverageStartDate = campaign?.coverage_start_date
        ? new Date(campaign?.coverage_start_date)
        : null;

      if (!perfCoverageStartDate) {
        return true;
      }

      return !cf?.created_at || new Date(cf.created_at) > perfCoverageStartDate;
    },
    [campaign?.coverage_start_date]
  );

  const getFeedback = useCallback(
    (contributionFeedback) => {
      setfeedbackWithinPeriodCount(
        contributionFeedback?.filter(
          (cf) =>
            peopleObjectsAreEqual(cf.author_person, person) &&
            contributionFeedbackIsWithinPeriod(cf)
        )?.length
      );
    },
    [person, contributionFeedbackIsWithinPeriod]
  );

  const selectedTimeFrameForObjectives =
    getSelectedTimeFrameForObjectives(campaign);

  const getObjectives = useCallback(
    (objectives, timeframeText) => {
      const filteredObjectives = objectives?.filter((o) =>
        isObjectiveWithinTimeframe(o, selectedTimeFrameForObjectives)
      );

      // Update objectiveWithinPeriodCount (which controls the Save & Continue button)
      // only if viewing the quarter the campaign wants objectives for
      if (selectedTimeFrameForObjectives?.start && timeframeText) {
        const originalTimeFrameText = formatTimeFrame(
          selectedTimeFrameForObjectives,
          locale
        );
        if (originalTimeFrameText === timeframeText) {
          setObjectiveWithinPeriodCount(filteredObjectives?.length);
        }
      }
    },
    [selectedTimeFrameForObjectives, locale]
  );

  const notEnoughActivities = useMemo(
    () =>
      // @ts-expect-error
      (highlights?.filter((h) => h.title?.trim())?.length || 0) <
      minimumAccomplishments,
    [highlights, minimumAccomplishments]
  );

  const continueDisabledActivityText = useMemo(() => {
    if (notEnoughActivities) {
      return formatMessage(
        {
          id: 'app.views.performance.performance_step_assess_self.continue_disabled_activity_text',
          defaultMessage:
            'Add at least {minimumAccomplishments} activities from the past {campaignDuration} before continuing.',
        },
        { campaignDuration, minimumAccomplishments }
      );
    } else {
      return formatMessage({
        id: 'app.views.performance.performance_step_assess_self.title_required',
        defaultMessage:
          'Title (non-blank) is required for all accomplishments.',
      });
    }
  }, [
    campaignDuration,
    minimumAccomplishments,
    notEnoughActivities,
    formatMessage,
  ]);

  const notEnoughReflections = useMemo(
    () => feedbackWithinPeriodCount < MIN_REFLECTIONS_REQUIRED,
    [feedbackWithinPeriodCount]
  );

  const notEnoughObjectives = useMemo(
    () =>
      !objectiveWithinPeriodCount ||
      objectiveWithinPeriodCount < MIN_OBJECTIVES_REQUIRED,
    [objectiveWithinPeriodCount]
  );

  const phaseSelf = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_SELF),
    [campaign]
  );

  // @ts-expect-error
  const phaseOpenResponseQuestions = getPhaseOpenResponseQuestions(phaseSelf);

  const openResponseQuestions = useMemo(
    () =>
      applyCustomFiltersToQuestions({
        questions: phaseOpenResponseQuestions,
        campaign,
        targetPerson: toPersonWithSurvey({}, props.currentPerfSurveyResponse),
      }),
    [phaseOpenResponseQuestions, campaign, props.currentPerfSurveyResponse]
  );

  if (
    openResponseQuestionsEnabled &&
    phaseOpenResponseQuestions &&
    phaseOpenResponseQuestions.length > 0 &&
    (!openResponseQuestions || openResponseQuestions.length === 0)
  ) {
    console.error('No openResponseQuestions found');
  }

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        id: props.currentPerfSurveyResponse?.id,
        campaign: campaign.id,
        step: currentStepNumber,
        positive_skills: prepTagsForSubmit(
          object['positive_skills'],
          props.currentOrganization?.id
        ),
        negative_skills: prepTagsForSubmit(
          object['negative_skills'],
          props.currentOrganization?.id
        ),
        responses: openResponseQuestionsEnabled
          ? openResponseQuestions
              // don't include section headers or other non-questions or
              // either of the skill-related questions
              .filter(
                (q) =>
                  q.name &&
                  q.name !== 'positive_skills' &&
                  q.name !== 'negative_skills'
              )
              // get the responses provided by the user
              .reduce((acc, q) => ({ ...acc, [q.name]: object[q.name] }), {})
          : undefined,
      };
    },
    [
      currentStepNumber,
      openResponseQuestions,
      openResponseQuestionsEnabled,
      props.currentOrganization?.id,
      campaign.id,
      props.currentPerfSurveyResponse?.id,
    ]
  );

  const reflectionInputs = useMemo(() => {
    if (!openResponseQuestionsEnabled) {
      return [];
    }

    const autoFocusIndex = getAutoFocusIndex(openResponseQuestions);
    return openResponseQuestions?.map((q, index) =>
      prepareOpenResponseQuestion(
        formatMessage,
        q,
        [],
        false,
        person?.given_name,
        [],
        false,
        campaign,
        props.currentOrganization,
        autoFocusIndex === index,
        index,
        isDemoOrPreviewMode
      )
    );
  }, [
    openResponseQuestions,
    openResponseQuestionsEnabled,
    person?.given_name,
    campaign,
    props.currentOrganization,
    isDemoOrPreviewMode,
    formatMessage,
  ]);

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (!openResponseQuestionsEnabled) {
        return errors;
      }

      // tag clouds aren't explicitly blocked in the frontend, so we
      // have to check positive and negative skills manually
      const positiveSkills = openResponseQuestions?.find(
        (q) => q.name === 'positive_skills'
      );
      if (positiveSkills?.required) {
        if (
          positiveSkills.minLength &&
          positiveSkills.minLength > 1 &&
          obj.positive_skills?.length < positiveSkills.minLength
        ) {
          errors['positive_skills'] = formatMessage(
            {
              id: 'app.views.performance.performance_step_assess_self.on_validate.min_positive_skills_required',
              defaultMessage:
                'At least {min_positive_skills} skills or behaviors are required.',
            },
            { min_positive_skills: positiveSkills.minLength }
          );
        } else if (!(obj.positive_skills?.length > 0)) {
          errors['positive_skills'] = formatMessage({
            id: 'app.views.performance.performance_step_assess_self.on_validate.positive_skills_required',
            defaultMessage: 'At least one skill or behavior is required.',
          });
        }
      }

      const negativeSkills = openResponseQuestions?.find(
        (q) => q.name === 'negative_skills'
      );
      if (negativeSkills?.required) {
        if (
          negativeSkills.minLength &&
          negativeSkills.minLength > 1 &&
          obj.negative_skills?.length < negativeSkills.minLength
        ) {
          errors['negative_skills'] = formatMessage(
            {
              id: 'app.views.performance.performance_step_assess_self.on_validate.min_negative_skills_required',
              defaultMessage:
                'At least {min_negative_skills} skills or behaviors are required.',
            },
            { min_negative_skills: negativeSkills.minLength }
          );
        } else if (!(obj.negative_skills?.length > 0)) {
          errors['negative_skills'] = formatMessage({
            id: 'app.views.performance.performance_step_assess_self.on_validate.negative_skills_required',
            defaultMessage: 'At least one skill or behavior is required.',
          });
        }
      }

      return errors;
    },
    [openResponseQuestions, openResponseQuestionsEnabled, formatMessage]
  );

  const pageTitle = useMemo(() => {
    if (isInCompleteResumeStep) {
      return formatMessage({
        id: 'app.views.performance.performance_step_assess_self.page_title.resume',
        defaultMessage: 'Tell us about your impact.',
      });
    }

    if (isInSelfReflectionEditingStep) {
      return formatMessage({
        id: 'app.views.performance.performance_step_assess_self.page_title.self_reflection',
        defaultMessage: 'Add your self-reflections.',
      });
    }

    if (isInCompleteObjectivesStep) {
      return objectivesStepHeaderText;
    }

    if (isInOpenResponsesStep) {
      if (completeResumeStepIsEnabled) {
        return formatMessage({
          id: 'app.views.performance.performance_step_assess_self.page_title.open_responses',
          defaultMessage: 'A few final questions',
        });
      } else {
        return formatMessage({
          id: 'app.views.performance.performance_step_assess_self.page_title.open_responses_self_reflection',
          defaultMessage: 'Self-reflection',
        });
      }
    }

    // default is the initial page ("page 0")
    return formatMessage({
      id: 'app.views.performance.performance_step_assess_self.page_title.welcome',
      defaultMessage: "Let's switch gears.",
    });
  }, [
    completeResumeStepIsEnabled,
    isInCompleteObjectivesStep,
    isInCompleteResumeStep,
    isInOpenResponsesStep,
    isInSelfReflectionEditingStep,
    objectivesStepHeaderText,
    formatMessage,
  ]);

  const shouldDisableContinue = useMemo(() => {
    if (isInCompleteResumeStep) {
      return !!notEnoughActivities;
    }

    if (isInSelfReflectionEditingStep) {
      return notEnoughReflections;
    }

    if (isInCompleteObjectivesStep) {
      return notEnoughObjectives;
    }

    if (isInOpenResponsesStep) {
      return false;
    }

    // default is the initial page ("page 0")
    return false;
  }, [
    isInCompleteObjectivesStep,
    isInCompleteResumeStep,
    isInOpenResponsesStep,
    isInSelfReflectionEditingStep,
    notEnoughActivities,
    notEnoughObjectives,
    notEnoughReflections,
  ]);

  const isOnLastPage = useMemo(() => {
    return currentPageIndex === pagesList.length - 1;
  }, [currentPageIndex, pagesList.length]);

  const disableHoverText = useMemo(() => {
    if (isSaveInProgress) {
      return formatMessage({
        id: 'app.views.performance.performance_step_assess_self.continue_disabled_save_in_progress_text',
        defaultMessage: 'A save is already in progress.',
      });
    }

    if (isInCompleteResumeStep) {
      return continueDisabledActivityText;
    }

    if (isInSelfReflectionEditingStep) {
      return continueDisabledReflectionText(
        getCampaignCoverageDurationMonthString(campaign, formatMessage),
        formatMessage
      );
    }

    if (isInCompleteObjectivesStep) {
      return formatMessage({
        id: 'app.views.performance.performance_step_assess_self.continue_disabled_objectives_text',
        defaultMessage: 'Add at least one objective before continuing.',
      });
    }

    return null;
  }, [
    isSaveInProgress,
    continueDisabledActivityText,
    isInCompleteObjectivesStep,
    isInCompleteResumeStep,
    isInSelfReflectionEditingStep,
    campaign,
    formatMessage,
  ]);

  const finalStepSubmitForm = useMemo(
    () => (
      <ValidatedForm
        draftAutosaveEnabled={!isDemoOrPreviewMode}
        uniqueFormKey={`campaign-${campaign.id}-self-reflection-questions`}
        className={openResponseQuestionsEnabled ? '' : 'd-flex'}
        method={'PATCH'}
        url={isDemoOrPreviewMode ? undefined : 'survey-responses'}
        autoFocus={false}
        callback={callback}
        disabled={shouldDisableContinue || isSaveInProgress}
        disabledHoverText={disableHoverText}
        buttonIsBlock={false}
        buttonClassName={openResponseQuestionsEnabled ? 'mt-3' : 'mt-n2'}
        submitText={formatMessage({
          id: 'app.views.performance.performance_step_assess_self.submit_text.save_and_continue',
          defaultMessage: 'Save and continue',
        })}
        object={object}
        onValidate={onValidate}
        transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        inputs={reflectionInputs}
        // if isSaveInProgress is not explicitly provided, fall back to the
        // default behavior to ensure no flashing of an enabled Save and
        // continue button at the very last second after submitting
        isSubmitting={isSaveInProgress ? true : undefined}
      />
    ),
    [
      callback,
      disableHoverText,
      object,
      onValidate,
      openResponseQuestionsEnabled,
      isDemoOrPreviewMode,
      reflectionInputs,
      shouldDisableContinue,
      transformObjectBeforeSubmit,
      isSaveInProgress,
      formatMessage,
      campaign.id,
    ]
  );

  const nextStep = useCallback(() => {
    // save highlights even if it's not the final step
    if (isInCompleteResumeStep) {
      saveHighlights(highlights, () => {
        navigatePage(true);
      });
    } else {
      navigatePage(true);
    }
  }, [highlights, isInCompleteResumeStep, navigatePage, saveHighlights]);

  const getSaveAndContinueButton = useCallback(
    (uniqueLocationString) => {
      return (
        <>
          {currentPageIndex !== 0 && (
            <>
              <span id={uniqueLocationString + '-save-button'}>
                {!isOnLastPage && (
                  <Button
                    color="primary"
                    disabled={shouldDisableContinue || isSaveInProgress}
                    style={
                      shouldDisableContinue
                        ? { pointerEvents: 'none' }
                        : undefined
                    }
                    onClick={nextStep}
                  >
                    {isSaveInProgress
                      ? formatMessage({
                          id: 'app.views.performance.performance_step_assess_self.get_save_and_continue_button.saving',
                          defaultMessage: 'Saving...',
                        })
                      : formatMessage({
                          id: 'app.views.performance.performance_step_assess_self.get_save_and_continue_button.save_and_continue',
                          defaultMessage: 'Save and continue',
                        })}
                  </Button>
                )}
                {isOnLastPage && !isInOpenResponsesStep && finalStepSubmitForm}
              </span>
              {shouldDisableContinue && (
                <UncontrolledPopover
                  placement="bottom"
                  trigger="hover"
                  target={uniqueLocationString + '-save-button'}
                >
                  <PopoverBody>{disableHoverText}</PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )}
        </>
      );
    },
    [
      isSaveInProgress,
      currentPageIndex,
      disableHoverText,
      finalStepSubmitForm,
      isInOpenResponsesStep,
      isOnLastPage,
      nextStep,
      shouldDisableContinue,
      formatMessage,
    ]
  );

  const defaultResumeInstructions = useMemo(
    () =>
      getAccomplishmentQuestionText({
        formatMessage,
        minimumAccomplishments,
        maximumAccomplishments,
        campaignDuration: getCampaignCoverageDurationMonthString(
          campaign,
          formatMessage
        ),
      }),
    [maximumAccomplishments, minimumAccomplishments, campaign, formatMessage]
  );

  const topButton = useMemo(() => {
    return getSaveAndContinueButton('top');
  }, [getSaveAndContinueButton]);

  // don't even show bottom button if disabled
  const bottomButton = useMemo(() => {
    return getSaveAndContinueButton('bottom');
  }, [getSaveAndContinueButton]);

  useEffect(() => {
    if (isDemoOrPreviewMode && isInitialLoad) {
      // fetch/save nothing from the server
      setIsInitialLoad(false);
      return;
    }

    if (
      !isInCompleteResumeStep ||
      !isInitialLoad ||
      highlights !== undefined ||
      !props.currentPerfSurveyResponse?.id ||
      !props.currentOrganization?.id
    ) {
      return;
    }

    const params = {
      organization_id: props.currentOrganization?.id,
      proxy: props.currentProxyPerson?.email,
    };

    ConfirmAPI.getUrlWithCache(
      `/performance/get-highlights/${props.currentPerfSurveyResponse?.id}`,
      'performance_get_highlights',
      null, // this is too much data, don't cache it
      null,
      params,
      (data) => {
        if (!data) {
          toast.error(
            formatMessage({
              id: 'app.views.performance.performance_step_assess_self.get_highlights.error',
              defaultMessage:
                'Unable to retrieve your saved highlights. Please wait and try again if you are having network issues, or contact Customer Support if not.',
            })
          );
          console.error(`Unable to retrieve highlights.`);
          return;
        }

        setIsInitialLoad(false);

        const haveBackendData =
          data.results &&
          data.results?.length &&
          !!data.results?.filter((x) => x.title?.trim()?.length)?.length;
        if (haveBackendData) {
          // @ts-expect-error
          setLastestSubmittedHighlights([...data.results]);
          // @ts-expect-error
          setHighlights([...data.results]);
        }

        // load cached work-in-progress data from localStorage
        // @ts-expect-error
        const cached = latestDraftObjectFetched?.highlights;
        if (cached) {
          // @ts-expect-error
          setHighlights([...cached]);
        }
      },
      (message) => {
        toast.error(
          formatMessage({
            id: 'app.views.performance.performance_step_assess_self.get_highlights.error',
            defaultMessage:
              'Unable to retrieve your saved highlights. Please wait and try again if you are having network issues, or contact Customer Support if not.',
          })
        );
        console.error(`Unable to retrieve highlights: ${message}`);
      }
    );
  }, [
    isDemoOrPreviewMode,
    discardUnsavedChanges,
    highlights,
    isInCompleteResumeStep,
    isInitialLoad,
    props.currentOrganization.id,
    props.currentPerfSurveyResponse?.id,
    props.currentProxyPerson,
    resumeLocalStorageKey,
    // @ts-expect-error
    user.sub,
    formatMessage,
    latestDraftObjectFetched,
  ]);

  const addHighlight = useCallback(() => {
    const newHighlights = [
      // @ts-expect-error
      ...highlights,
      {
        visibility: defaultVisibility,
        organization: props.currentOrganization?.id,
      },
    ];
    // @ts-expect-error
    setHighlights([...newHighlights]);
  }, [defaultVisibility, highlights, props.currentOrganization?.id]);

  const updateHighlight = useCallback(
    (index, newHighlight) => {
      // @ts-expect-error
      if (!isEqual(highlights[index], newHighlight)) {
        // NOTE: undo import just revert to a blank accomplishment
        // @ts-expect-error
        const newHiglights = highlights.map((it, idx) =>
          idx === index ? newHighlight ?? {} : it
        );
        setHighlights(newHiglights);
        storeUnsavedChanges({
          highlights: newHiglights,
        });
      }
    },
    [highlights, storeUnsavedChanges]
  );

  const getDeleteHandler = useCallback(
    (index) => () => {
      // @ts-expect-error
      const newHighlights = highlights.filter((_, idx) => idx !== index);
      setHighlights(newHighlights);
      storeUnsavedChanges({
        highlights: newHighlights,
      });
    },
    [highlights, storeUnsavedChanges]
  );

  const perfCoverageStartDate = useMemo(() => {
    if (campaign && campaign?.coverage_start_date) {
      return yyyymmddToLocalDate(campaign.coverage_start_date);
    }
    return null;
  }, [campaign]);

  const backButtonLinkCallback = useCallback(
    () => navigatePage(false),
    [navigatePage]
  );

  // visually, we want to wrap all screens in a white card for accessibility
  // and color contrasts except for ones that already have the main content
  // within cards
  const shouldWrapBodyInCard =
    !isInCompleteResumeStep && !isInCompleteObjectivesStep;

  return (
    <PerformancePage
      campaign={campaign}
      title={pageTitle}
      wrapBodyInCard={shouldWrapBodyInCard}
    >
      <div className="d-flex align-items-center">
        {currentPageIndex > 1 && (
          <>
            <BackButtonLink onClick={backButtonLinkCallback} />
            <span className="text-muted mx-3">{'∙'}</span>
          </>
        )}
        {currentPageIndex !== 0 &&
          (isInCompleteResumeStep && resumeIsInDraftState ? (
            <>
              <AutoSaveInfoBox
                className="me-3"
                onDiscardDraft={discardUnsavedChanges}
              />
            </>
          ) : maxPages > 1 ? (
            <span
              className={
                'text-muted' +
                (!openResponseQuestionsEnabled || currentPageIndex !== maxPages
                  ? ' me-4'
                  : '')
              }
            >
              <FormattedMessage
                id="app.views.performance.performance_step_assess_self.step_n_of_n"
                defaultMessage="Step {current} of {max}"
                values={{
                  current: currentPageIndex,
                  max: maxPages,
                }}
              />
            </span>
          ) : (
            <></>
          ))}
        {topButton}
      </div>
      {currentPageIndex === 0 && (
        <>
          <Row className="mb-4">
            <Col className="col-12 pb-4 pb-md-0 col-md-4">
              <img src={SelfReflectionImg} className="w-100 pe-5" />
            </Col>
            <Col>
              {phaseSelf?.self_review_instructions ? (
                <RichTextViewer
                  model={phaseSelf.self_review_instructions}
                  expanded={true}
                />
              ) : (
                <div className="mb-4">
                  <RichTextViewer
                    model={selfReflectionIntroText}
                    expanded={true}
                  />
                </div>
              )}
              <button
                autoFocus={true}
                type="button"
                className="btn btn-primary"
                onClick={() => navigatePage(true)}
              >
                <FormattedMessage
                  id="app.views.performance.performance_step_assess_self.continue"
                  defaultMessage="Continue"
                />
              </button>
            </Col>
          </Row>
        </>
      )}
      {isInCompleteResumeStep &&
        (!isInitialLoad &&
        ['DISABLED', 'SUCCESS'].includes(resumeDraftFetchState) ? (
          <>
            <div className="mb-4">
              <>
                <div className="mb-4">
                  <div>
                    <RichTextViewer
                      model={
                        phaseSelf[PERFORMANCE_ACCOMPLISHMENTS_INSTRUCTIONS] ||
                        defaultResumeInstructions
                      }
                      expanded={true}
                    />
                  </div>
                </div>
              </>
            </div>
            <Row>
              {highlights &&
                // @ts-expect-error
                highlights.map((highlight, index) => {
                  return (
                    <Col key={index} className="col-lg-4 col-12">
                      <SimpleActivityEditorCard
                        campaign={campaign}
                        object={highlight}
                        callback={(data) => {
                          updateHighlight(index, data);
                        }}
                        onDelete={getDeleteHandler(index)}
                        organizationName={props.currentOrganization.name}
                        activitiesInHomepageFeedIsEnabled={activitiesInHomepageFeedIsEnabled(
                          props.features
                        )}
                        startDate={perfCoverageStartDate}
                        person={person}
                        // @ts-expect-error
                        omit={highlights.filter((x) => !!x.id).map((x) => x.id)}
                        disableUnsavedChangesPrompt={isDemoOrPreviewMode}
                        defaultVisibility={defaultVisibility}
                        isSubmitting={isSaveInProgress}
                      />
                    </Col>
                  );
                })}
              {highlights &&
                !reachedAccomplishmentsLimit(
                  // @ts-expect-error
                  highlights.length,
                  maximumAccomplishments
                ) && (
                  <Col className="col-lg-4 col-12">
                    <button
                      id="add-an-accomplishment-button"
                      className="d-inline-block dotted-line-button"
                      type="button"
                      onClick={addHighlight}
                    >
                      <Row
                        className="align-items-center justify-content-center"
                        style={{ height: '100%' }}
                      >
                        <Col>
                          <Row className="align-items-center justify-content-center">
                            <Col className="col-auto">
                              <div className="avatar avatar-sm mb-2">
                                <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                                  <i className="fe fe-plus"></i>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-center justify-content-center">
                            <Col className="col-auto">
                              <div className="text-muted">
                                <FormattedMessage
                                  id="app.views.performance.performance_step_assess_self.add_an_accomplishment"
                                  defaultMessage="Add an accomplishment"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </button>
                  </Col>
                )}
            </Row>
          </>
        ) : (
          <Loading
            message={formatMessage({
              id: 'app.views.performance.performance_step_assess_self.message.loading',
              defaultMessage: 'Loading...',
            })}
          />
        ))}
      {isInSelfReflectionEditingStep && (
        <>
          <div className="mb-4">
            <>
              <div>
                <FormattedMessage
                  id="app.views.performance.performance_step_assess_self.add_reflections_message"
                  defaultMessage="Add reflections to 3 of your most impactful activities below."
                />
              </div>
              <div className="text-muted small mb-4">
                <span className="fe fe-lock me-2"></span>
                <FormattedMessage
                  id="app.views.performance.performance_step_assess_self.only_manager_above_can_see_reflections"
                  defaultMessage="Only manager and above (and HR administrators) can see self-reflections."
                />
              </div>
            </>
          </div>
          <PersonProfileFeedback
            // @ts-expect-error
            campaign={campaign}
            relationshipType={RELATIONSHIP_TYPES.SELF}
            showFeedbackButtons={true}
            person={person}
            getFeedback={getFeedback}
            className="mb-4"
            hideConfettiModalsWhenClaimingContributions={true}
            hideFeedbackRequestPromptsWhenClaimingContributions={
              hideFeedbackRequestPromptsWhenClaimingContributions
            }
          />
        </>
      )}
      {isInCompleteObjectivesStep && (
        <>
          <div className="pb-4">
            <RichTextViewer model={objectivesMessageHtml} expanded={true} />
          </div>
          <PersonalObjectives
            isDemoOrPreviewMode={isDemoOrPreviewMode}
            showTeamNav={false}
            person={person}
            getObjectives={getObjectives}
            // @ts-expect-error
            defaultTimeFrame={selectedTimeFrameForObjectives}
            className="col"
            autoFocus={true}
          />
        </>
      )}
      {isInOpenResponsesStep && finalStepSubmitForm}
      {!isInCompleteObjectivesStep &&
        !isInCompleteResumeStep &&
        !isInOpenResponsesStep &&
        !shouldDisableContinue && (
          <Row className="align-items-center pt-5">
            <Col className="col-auto">{bottomButton}</Col>
            {maxPages > 1 && currentPageIndex !== 0 && (
              <Col>
                <div className="text-muted">
                  <FormattedMessage
                    id="app.views.performance.performance_step_assess_self.step_n_of_n"
                    defaultMessage="Step {current} of {max}"
                    values={{
                      current: currentPageIndex,
                      max: maxPages,
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
        )}
    </PerformancePage>
  );
};

interface Props {
  me: Me;
  features: Features;
  currentOrganization: Organization;
  currentPerfSurveyResponse: any;
  currentProxyPerson?: Person;
  setCurrentPerfSurveyResponse: (changes: any) => void;
  campaign: Campaign;
  setCampaign: (changes: any) => void;
  demoPeople: Person[];
}

const mapStateToProps = (state) => {
  const {
    me,
    features,
    currentOrganization,
    currentPerfSurveyResponse,
    currentProxyPerson,
    demoPeople,
  } = state;

  return {
    me,
    features,
    currentOrganization,
    currentPerfSurveyResponse,
    currentProxyPerson,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepAssessSelf)));
