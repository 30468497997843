import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import PerformanceWrittenResponse from './PerformanceWrittenResponse';
import { Person } from 'types';
import { WrittenResponse } from './utils';

interface Props {
  person: Person;
  questions: WrittenResponse[];
}

const PerformanceWrittenResponses: FC<Props> = (props: Props) => {
  return (
    <Row className="mb-4">
      <Col md="12">
        {props.questions.map((q, index) => (
          <PerformanceWrittenResponse
            person={props.person}
            question={q}
            key={index}
          />
        ))}
      </Col>
    </Row>
  );
};

export default PerformanceWrittenResponses;
