import React from 'react';
import { type IntlShape, FormattedMessage } from 'react-intl';
import {
  getFilteredPhaseOpenResponseQuestions,
  LIKERT_SCALE,
  replaceCampaignQuestionText,
} from 'utils/models/Performance';
import { getUnattributedPerson } from '../../../utils/models/Person';
import { toPersonWithSurvey } from 'utils/models/Campaign';
import {
  BasicPerson,
  Campaign,
  Organization,
  Person,
  SurveyResponse,
} from 'types';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';

export const getUniqueWordCloudsFromRelationships = (
  formatMessage,
  relationships,
  tagType
) => {
  if (!(relationships?.length > 0)) {
    return [];
  }

  return relationships.reduce(
    (acc, r) =>
      acc.concat(
        r[tagType]
          ? // ensure skills only mentioned once for a given
            // contributor
            r[tagType].map((tag, index) => ({
              wordObject: tag,
              contributedPerson: r.person?.id
                ? r.person
                : getUnattributedPerson(formatMessage, index, r.type),
            }))
          : []
      ),
    []
  );
};

export const getFilteredEvaluationQuestions = (
  targetPerson: Person,
  campaign?: Campaign,
  phaseType?: string,
  questionType?: string,
  surveyResponse?: SurveyResponse
) => {
  if (campaign && campaign.phases?.length > 0) {
    const phase = campaign.phases.find((p) => p.type === phaseType);
    if (phase) {
      return getFilteredPhaseOpenResponseQuestions({
        targetPerson: toPersonWithSurvey(targetPerson, surveyResponse),
        campaign,
        phase,
        type: questionType,
      });
    }
  }
  return [];
};

// This is for those that ask a Likert rating question
// in either upward feedback or peer reviews
export const getLikertRatingText = (value: number): JSX.Element => {
  let textValue: string | undefined = undefined;
  let color: string | undefined = undefined;

  if (value in LIKERT_SCALE) {
    textValue = LIKERT_SCALE[value].text;
    color = LIKERT_SCALE[value].color;
  }

  return (
    <span className="small">
      <FormattedMessage
        id="app.views.person.person_performance.recommend_to_others"
        defaultMessage="Recommend to others?"
      />{' '}
      <span className={'badge ' + color}>{textValue}</span>
    </span>
  );
};

const noResponseElement: JSX.Element = (
  <span className="fst-italic">
    <FormattedMessage
      id="app.views.person.person_performance.no_response"
      defaultMessage="No response"
    />
  </span>
);

export interface Question {
  name?: string;
  type?: string;
  label?: string;
  objects?: { value: string }[];
}

export interface WrittenResponse {
  anonymized: boolean;
  title: string;
  question: Question;
  responses: { person: BasicPerson; [key: string]: any }[];
}

export type ResponseElement = JSX.Element | string | undefined;

export interface FormattedResponse {
  question: Question;
  person: Person;
  title: string;
  response?: ResponseElement;
  anonymized: boolean;
}

// takes a person, question object, and responses dict (which could be a survey response
// or a relationship object) and formats the response for display
export const formatResponse = (
  formatMessage: IntlShape['formatMessage'],
  responderPerson: Person,
  targetPerson: Person,
  question: Question,
  responsesDict: { responses: { [key: string]: string | undefined } },
  campaign: Campaign,
  organization: Organization,
  anonymizedResponses?: string[]
): FormattedResponse => {
  const anonymizedNames = new Set(anonymizedResponses);
  let responseElement: ResponseElement = noResponseElement;
  let anonymized = false;

  // no name means this is a section/label
  if (responsesDict && question?.name) {
    const questionType = question?.type?.toUpperCase();
    if (anonymizedNames.has(question.name)) {
      responseElement = undefined;
      anonymized = true;
    } else if (questionType === INPUT_TYPES.LIKERT) {
      // send raw object to be rendered in a custom way
      if (
        responsesDict?.responses &&
        question.name in responsesDict.responses
      ) {
        responseElement = responsesDict.responses[question.name];
      } else {
        responseElement = responsesDict[question.name];
      }
    } else if (
      (questionType === INPUT_TYPES.SKILLS ||
        question.name === 'positive_skills' ||
        question.name === 'negative_skills') &&
      responsesDict[question.name]?.length > 0
    ) {
      // note: we display in the tags list later
      responseElement = responsesDict[question.name];
    } else if (
      typeof responsesDict[question.name] === 'number' ||
      responsesDict[question.name]?.length > 0
    ) {
      // for Relationship objects
      responseElement = responsesDict[question.name];
    } else if (
      // for SurveyResponse objects
      typeof responsesDict?.responses?.[question.name] !== 'undefined' // can't use ?.length > 0 here for switches where value is boolean
    ) {
      responseElement = responsesDict.responses[question.name];
    } else {
      // for other types of questions
      responseElement = responsesDict[question.name];
    }
  }

  // for single-question likert with no label,
  // use the first object's value (aka label) as the title instead
  const label =
    question?.type?.toUpperCase() === INPUT_TYPES.LIKERT &&
    !question.label &&
    question?.objects?.length === 1
      ? question.objects[0].value
      : question.label;

  return {
    question: question,
    person: responderPerson,
    title: replaceCampaignQuestionText(
      label,
      targetPerson?.given_name,
      campaign,
      organization,
      formatMessage
    ),
    response: responseElement,
    anonymized,
  };
};
