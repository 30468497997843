import * as consts from '../../consts/consts';

import {
  CAMPAIGN_STATUSES,
  getCampaignCoverageDurationMonthString,
  getPhaseByType,
  getRelationships,
} from '../../utils/models/Campaign';
import {
  CUSTOM_QUESTION_ANONYMOUS_PREFIX,
  CUSTOM_QUESTION_NONANONYMOUS_PREFIX,
  DEFAULT_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_CONTRIBUTION_SELF_REFLECTIONS,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT,
  PERFORMANCE_FEATURE_HIDE_TIMELINE_ACTIVITIES,
  PERFORMANCE_FEATURE_HIDE_TIME_AT_ORGANIZATION,
  PERFORMANCE_FEATURE_HIDE_TIME_IN_ROLE,
  PERFORMANCE_FEATURE_MANAGER_CONTRIBUTION_FEEDBACK,
  PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_PEER_CONTRIBUTION_FEEDBACK,
  PERFORMANCE_FEATURE_SHOW_CRITICAL_FEEDBACK_TO_MANAGERS,
  PERFORMANCE_FEATURE_SHOW_OBJECTIVES_TO_MANAGERS,
  PERFORMANCE_FEATURE_SHOW_RECOGNITION_TO_MANAGERS,
  PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_SELF,
  addCustomResponseValues,
  extractCustomResponses,
  getCampaignHasCalibrationPhase,
  getCampaignHasFeatureEnabled,
  getSelectedTimeFrameForObjectives,
  isEngagementSurveyOnlyCampaign,
  prepareOpenResponseQuestion,
  replaceCampaignQuestionText,
} from '../../utils/models/Performance';
import {
  Campaign,
  CampaignWithConfigs,
  Features,
  Feedback,
  Organization,
  Person,
  Relationship,
  SurveyResponse,
} from 'types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormattedResponse,
  WrittenResponse,
  formatResponse,
  getFilteredEvaluationQuestions,
} from './PersonPerformance/utils';
import { Link, useLocation } from 'react-router-dom';
import {
  PersonPerformanceContextConsumer,
  PersonPerformanceContextProvider,
} from './PersonPerformance/PersonPerformanceContext';
import PersonProfileIntroSummary, {
  PERSON_PROFILE_COMPONENT_BUSINESS_UNIT,
  PERSON_PROFILE_COMPONENT_DIRECT_REPORTS,
  PERSON_PROFILE_COMPONENT_JOINED,
  PERSON_PROFILE_COMPONENT_MANAGER,
  PERSON_PROFILE_COMPONENT_PREFERRED_FIRST_NAME,
} from './PersonProfileIntroSummary';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect, useSelector } from 'react-redux';
import {
  filterUniqueById,
  isEnabled,
  monthDiff,
  prepTagsForSubmit,
  yyyymmddToLocalDate,
} from '../../utils/util/util';
import {
  getDeclineReasonText,
  relationshipHasFeedback,
  relationshipIsCompleted,
} from '../../utils/models/Relationship';
import {
  getUnattributedPerson,
  peopleIdsAreEqual,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';

import Avatar from '../Widgets/People/Avatar';
import { CAMPAIGN_CONFIG_CREATED_WITH_DEFAULT_QUESTIONS } from '../Administration/CampaignFlow';
import CalloutsBox from './PersonPerformance/CalloutsBox';
import { CampaignSelectorRow } from './CampaignSelectorRow';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { EmptyFeedbackState } from './EmptyFeedbackState';
import FeedbackProviderListWidget from './PersonPerformance/FeedbackProviderListWidget';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import Loading from '../Widgets/Loading';
import ManagerRatingCard from './PersonPerformance/Trajectory/ManagerRatingCard';
import ManagerRatingDistributionChart from './PersonPerformance/Trajectory/ManagerRatingDistributionChart';
import ManagerRatingHistoryChart from './PersonPerformance/Trajectory/ManagerRatingHistoryChart';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import { Objective } from 'utils/models/Objective';
import { PageSection } from './components/PageSection';
import PerformanceFeedbackSection from './PersonPerformance/PerformanceFeedbackSection';
import { PerformanceHistoryData } from './PersonPerformance/Trajectory/HistoryChartUtils';
import PerformanceHistoryRow from './PersonPerformance/History';
import PerformanceNetworkResponse from './PersonPerformance/PerformanceNetworkResponse';
import PerformanceWrittenMultiResponses from './PersonPerformance/PerformanceWrittenMultiResponses';
import PerformanceWrittenResponses from './PersonPerformance/PerformanceWrittenResponses';
import PersonFeedbackList from './PersonFeedbackList';
import { PersonPerformanceAlertRow } from './PersonPerformanceAlertRow';
import PersonTimelineActivities from './PersonTimelineActivities';
import PersonalObjectives from './PersonalObjectives';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { atLeastOneContinuousFeedbackFeatureIsEnabled } from '../../utils/util/features';
import { buildDemoData } from './PersonPerformance/PreviewData';
import { getDateForTimeInRoleComputation } from '../../utils/util/time';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

const DEFAULT_MIN_POSITIVE_TAGS = 2;
const DEFAULT_MIN_NEGATIVE_TAGS = 1;

const networkQuestions = [
  {
    name: 'energizers',
    type: RELATIONSHIP_TYPES.ENERGIZED_BY,
  },
  {
    name: 'advisors',
    type: RELATIONSHIP_TYPES.ADVISED_BY,
  },
  {
    name: 'stakeholders',
    type: RELATIONSHIP_TYPES.HAS_AS_STAKEHOLDER,
  },
  {
    name: 'goldstars',
    type: RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
  },
  {
    name: 'headsups',
    type: RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT,
  },
  {
    name: 'formers',
    type: RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT,
  },
];

interface Props {
  person: Person;
  meId: number;
  currentProxyPerson?: Person;
  currentOrganization?: Organization;
  campaign?: CampaignWithConfigs;
  surveyResponse?: SurveyResponse;
  features?: Features;
  incomingRelationshipsWithFeedback?: Relationship[];
  outgoingRelationshipsWithFeedback?: Relationship[];
  showManagerOnlyPerformanceDetails: boolean;
  isInReviewFlow: boolean;
  isEditable?: boolean;
  showCampaignsSelector: boolean;
  showManagerResponses?: boolean;
  showHistory: boolean;
  isAdminable: boolean;
  actions: JSX.Element;
}

// PersonPerformance.propTypes = {
//   person: PropTypes.object.isRequired,
//   meId: PropTypes.number.isRequired,
//   currentProxyPerson: PropTypes.object,
//   currentOrganization: PropTypes.object.isRequired,
//   campaign: PropTypes.object,
//   surveyResponse: PropTypes.object,
//   incomingRelationshipsWithFeedback: PropTypes.arrayOf(PropTypes.object),
//   outgoingRelationshipsWithFeedback: PropTypes.arrayOf(PropTypes.object),
//   showManagerOnlyPerformanceDetails: PropTypes.bool,
//   isInReviewFlow: PropTypes.bool,
//   isEditable: PropTypes.bool,
//   showCampaignsSelector: PropTypes.bool,
//   showManagerResponses: PropTypes.bool,
//   showHistory: PropTypes.bool,
//   actions: PropTypes.object,
// };

const PersonPerformance: React.FC<Props> = (props) => {
  const propsShowManagerResponses = props.showManagerResponses ?? true;
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [campaign, setCampaign] = useState<CampaignWithConfigs | undefined>(
    props.isInReviewFlow ? props.campaign : undefined
  );
  const [campaigns, setCampaigns] = useState<Campaign[] | undefined>(undefined);
  const [performanceHistory, setPerformanceHistory] = useState<
    PerformanceHistoryData[] | null | undefined
  >(null);

  const [campaignIdToFetch, setCampaignIdToFetch] = useState(
    props.isInReviewFlow ? undefined : props.campaign?.id
  );

  const [surveyResponse, setSurveyResponse] = useState(props.surveyResponse);
  const [
    incomingRelationshipsWithFeedback,
    setIncomingRelationshipsWithFeedback,
  ] = useState<Relationship[] | undefined>(
    props.incomingRelationshipsWithFeedback ?? []
  );
  const [
    outgoingRelationshipsWithFeedback,
    setOutgoingRelationshipsWithFeedback,
  ] = useState<Relationship[] | undefined>(
    props.outgoingRelationshipsWithFeedback ?? []
  );
  const [isMounted, setIsMounted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [
    currentDirectReportFeedbackRelationship,
    setCurrentDirectReportFeedbackRelationship,
  ] = useState(undefined);
  const [showDirectReportFeedbackEditor, setShowDirectReportFeedbackEditor] =
    useState(false);
  const toggleDirectReportFeedbackEditor = useMemo(
    () => () =>
      setShowDirectReportFeedbackEditor(!showDirectReportFeedbackEditor),
    [showDirectReportFeedbackEditor]
  );
  const [
    hasLoadedRelationshipsInManagerView,
    setHasLoadedRelationshipsInManagerView,
  ] = useState(false);

  const { user } = useAuth0();
  const userSub = user?.sub;
  const ratingCommentLearnMoreRef = useRef<HTMLElement | undefined>();

  const displayDistributions = isEnabled(
    props.features,
    consts.FLAGS.PERFORMANCE_PROFILE_ENABLE_DISTRIBUTIONS
  );
  // @ts-expect-error
  const demoPeople = useSelector((state) => state.demoPeople);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState(null);
  const [feedbackList, setFeedbackList] = useState<Feedback[] | undefined>(
    undefined
  );
  const [demoObjectives, setDemoObjectives] = useState<Objective[] | undefined>(
    undefined
  );

  const objectivesAreEnabled = useMemo(
    () => props.features?.objectives?.enabled,
    [props.features]
  );

  const continuousFeedbackIsEnabled = useMemo(
    () => atLeastOneContinuousFeedbackFeatureIsEnabled(props.features),
    [props.features]
  );

  // mirrors conditions for is_eligible_for_reporting in the backend
  const isEligibleForReporting = useMemo(
    () =>
      surveyResponse &&
      (surveyResponse?.configs?.is_participating ?? true) &&
      ((surveyResponse?.configs?.is_participating_in_self_phase ?? true) ||
        (surveyResponse?.configs?.is_only_receiving_review ?? false) === false),
    [surveyResponse]
  );

  const hideTimelineActivities = useMemo(() => {
    return (
      campaign &&
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_TIMELINE_ACTIVITIES
      )
    );
  }, [campaign]);

  const [displayRatingWidgets, setDisplayRatingWidgets] = useState(false);

  const showObjectives = useMemo(() => {
    // we show objectives if option is enabled
    return (
      objectivesAreEnabled &&
      campaign &&
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_SHOW_OBJECTIVES_TO_MANAGERS
      )
    );
  }, [campaign, objectivesAreEnabled]);

  const selectedTimeFrameForObjectives =
    getSelectedTimeFrameForObjectives(campaign);

  const showFeedbackRecognitionAndNotes = useMemo(() => {
    // we show recognition & notes tab in ALL cases if continuous feedback is enabled
    return (
      continuousFeedbackIsEnabled &&
      campaign &&
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_SHOW_RECOGNITION_TO_MANAGERS
      )
    );
  }, [campaign, continuousFeedbackIsEnabled]);

  // We show critical/actionable feedback if this is enabled in the campaign AND
  // if recognition and notes are already enabled

  const showCriticalFeedback = useMemo(() => {
    return (
      showFeedbackRecognitionAndNotes &&
      campaign &&
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_SHOW_CRITICAL_FEEDBACK_TO_MANAGERS
      )
    );
  }, [campaign, showFeedbackRecognitionAndNotes]);

  const hasContributionFeedbackEnabled = useMemo(
    () =>
      campaign &&
      (getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_MANAGER_CONTRIBUTION_FEEDBACK
      ) ||
        getCampaignHasFeatureEnabled(
          campaign,
          PERFORMANCE_FEATURE_PEER_CONTRIBUTION_FEEDBACK
        )),
    [campaign]
  );

  const hideActivitiesWithoutFeedbackForFocalPerson = useMemo(
    () =>
      campaign &&
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_CONTRIBUTION_SELF_REFLECTIONS
      ) &&
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PEER_CONTRIBUTION_FEEDBACK
      ),
    [campaign]
  );

  const person = props.person;

  const hideRatingFromDirectReport = campaign
    ? getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT
      )
    : true;

  const displayRating =
    !hideRatingFromDirectReport || props.showManagerOnlyPerformanceDetails;

  const selectedCampaignData = useMemo(() => {
    if (!performanceHistory || performanceHistory.length === 0 || !campaign)
      return null;
    return performanceHistory.find((item) => item.campaign.id === campaign.id);
  }, [performanceHistory, campaign]);

  const hasRating = !!selectedCampaignData?.rating;

  const displayManagerRatingDistribution = displayRating && hasRating;

  useEffect(() => {
    if (!isMounted || !person) {
      return;
    }

    const params: {
      status: string;
      organization?: number;
      include_participation_for_person?: number;
      in_review_flow?: boolean;
    } = {
      status: CAMPAIGN_STATUSES.ACTIVE,
      organization: props.currentOrganization?.id,
      include_participation_for_person: person.id,
    };

    if (props.isInReviewFlow) {
      params.in_review_flow = true;
    }

    ConfirmAPI.getUrlWithCache(
      '/campaigns',
      'campaigns',
      userSub,
      props.currentProxyPerson,
      params,
      (data) => {
        if (isMounted) {
          if (data?.results) {
            const campaigns = data.results.filter(
              (c) => !isEngagementSurveyOnlyCampaign(c)
            );
            setCampaigns(campaigns);
            if (campaigns.length > 0) setCampaignIdToFetch(campaigns[0].id);
            setReceivedUserPerformanceData(false);
          }
        }
      },
      (message) => {
        console.error('Could not fetch campaigns: ' + message);
        // @ts-expect-error
        setCampaigns(null);
      }
    );
  }, [
    isMounted,
    person,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    props.isInReviewFlow,
    userSub,
  ]);

  const isDemoOrPreviewMode = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [campaign?.status]
  );

  useEffect(() => {
    if (!person || !campaign) {
      return;
    }
    if (isDemoOrPreviewMode) {
      setPerformanceHistory(undefined);
      const demoData = buildDemoData(
        // @ts-expect-error
        props.currentOrganization,
        campaign,
        person,
        demoPeople
      );

      setPerformanceHistory(demoData.history);
      setSurveyResponse(demoData.surveyResponse);
      setFeedbackList(demoData.feedback);
      setDemoObjectives(demoData.objectives);
    } else {
      setPerformanceHistory(undefined);
      const params: {
        organization_id?: number;
        manager_view: boolean;
        campaign_id: number;
        in_review_flow?: boolean;
      } = {
        organization_id: props.currentOrganization?.id,
        manager_view: props.showManagerOnlyPerformanceDetails,
        campaign_id: campaign.id,
      };

      if (props.isInReviewFlow) {
        params.in_review_flow = true;
      }

      ConfirmAPI.getObject(
        undefined,
        props.currentProxyPerson,
        'person-performance-history',
        person.id,
        (data) => {
          if (data?.data) {
            setPerformanceHistory(data?.data);
          }
        },
        (message) => {
          setErrorMessage(message);
        },
        props.currentProxyPerson
          ? { ...params, proxy: props.currentProxyPerson.email }
          : params
      );
    }
  }, [
    props.currentProxyPerson,
    props.currentOrganization,
    person,
    props.showManagerOnlyPerformanceDetails,
    props.isInReviewFlow,
    campaign,
    demoPeople,
    isDemoOrPreviewMode,
  ]);

  useEffect(() => {
    if (
      !isMounted ||
      !showFeedbackRecognitionAndNotes ||
      !person ||
      isDemoOrPreviewMode
    ) {
      return;
    }

    const params = {
      person: person.id,
      organization: props.currentOrganization?.id,
      proxy: props.currentProxyPerson
        ? props.currentProxyPerson.email
        : undefined,
      manager_view: true,
      campaign: campaign?.id,
    };

    ConfirmAPI.getUrlWithCache(
      '/feedback',
      // @ts-expect-error
      null,
      null, // this is too much data, don't cache it
      null,
      params,
      (data) => {
        if (isMounted) {
          if (data?.results) {
            setFeedbackList(data.results);
          }
        }
      },
      (message) => {
        setFeedbackErrorMessage(message);
      }
    );
  }, [
    isMounted,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    showFeedbackRecognitionAndNotes,
    person,
    campaign?.id,
    demoPeople,
    isDemoOrPreviewMode,
  ]);

  const [receivedUserPerformanceData, setReceivedUserPerformanceData] =
    useState(false);

  useEffect(() => {
    // in review flow, pass all data in from props
    if (props.isInReviewFlow) {
      if (props.campaign) {
        setCampaign(props.campaign);

        if (props.surveyResponse) {
          setSurveyResponse(props.surveyResponse);

          if (props.incomingRelationshipsWithFeedback) {
            setIncomingRelationshipsWithFeedback(
              props.incomingRelationshipsWithFeedback
            );
            setHasLoadedRelationshipsInManagerView(
              props.showManagerOnlyPerformanceDetails
            );
          }

          if (props.outgoingRelationshipsWithFeedback) {
            setOutgoingRelationshipsWithFeedback(
              props.outgoingRelationshipsWithFeedback ?? []
            );
          }
        }
      }
      setReceivedUserPerformanceData(true);
    } else {
      // not in review flow, so fetch all data from the server
      setReceivedUserPerformanceData(false);
      // @ts-expect-error
      setCampaign(null);
      setPerformanceHistory(null);
      // fetch this data from the server (and refetch if showManagerOnlyPerformanceDetails
      // is passed in and toggled; if not passed in, this is a view in perf)
      if (typeof props.showManagerOnlyPerformanceDetails !== 'undefined') {
        const params: {
          organization?: number;
          manager_view: boolean;
          campaign?: number;
        } = {
          organization: props.currentOrganization?.id,
          manager_view: props.showManagerOnlyPerformanceDetails,
        };

        // campaign is optional (but should only be checked if explicitly passed in)
        if (campaignIdToFetch) {
          params.campaign = campaignIdToFetch;
        }

        ConfirmAPI.getObject(
          // do not cache performance data given sensitivity
          undefined,
          undefined,
          ConfirmAPI.OBJECT_TYPES.PERSON_PERFORMANCE,
          props.person.id,
          (data) => {
            setReceivedUserPerformanceData(true);
            if (isMounted) {
              if (data) {
                setCampaign(data.campaign);
                setSurveyResponse(
                  data.campaign?.survey_responses?.length > 0
                    ? data.campaign.survey_responses[0]
                    : undefined
                );
                setIncomingRelationshipsWithFeedback(
                  data.campaign?.relationships
                );
                setOutgoingRelationshipsWithFeedback([]);
                setHasLoadedRelationshipsInManagerView(
                  props.showManagerOnlyPerformanceDetails
                );
              } else {
                // not found
                // @ts-expect-error
                setCampaign(null);
                // @ts-expect-error
                setSurveyResponse(null);
                // @ts-expect-error
                setIncomingRelationshipsWithFeedback(null);
                // @ts-expect-error
                setOutgoingRelationshipsWithFeedback(null);
              }
            }
          },
          (message) => {
            setErrorMessage(message);
          },
          props.currentProxyPerson
            ? {
                ...params,
                proxy: props.currentProxyPerson.email,
              }
            : params
        );
      }
    }
  }, [
    campaignIdToFetch,
    isMounted,
    props.campaign,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    props.incomingRelationshipsWithFeedback,
    props.outgoingRelationshipsWithFeedback,
    props.isInReviewFlow,
    props.person.id,
    props.showManagerOnlyPerformanceDetails,
    props.surveyResponse,
  ]);

  const personPreferredName = useMemo(() => person?.given_name, [person]);
  const personFullName = useMemo(() => person?.full_name, [person]);
  const isMe = useMemo(
    () => peopleIdsAreEqual(person?.id, props.meId),
    [person?.id, props.meId]
  );

  const endOfPerfCampaignCoveragePeriod = useMemo(
    () => (campaign ? new Date(campaign.coverage_end_date) : undefined),
    [campaign]
  );

  const selfWrittenResponses = useMemo(
    () =>
      getFilteredEvaluationQuestions(
        person,
        campaign,
        PHASE_TYPE_SELF,
        undefined,
        surveyResponse
      )?.map((q) =>
        formatResponse(
          formatMessage,
          person,
          person,
          q,
          // @ts-expect-error
          surveyResponse,
          campaign,
          props.currentOrganization,
          // @ts-expect-error
          surveyResponse?.anonymized_responses
        )
      ),
    [campaign, person, surveyResponse, props.currentOrganization, formatMessage]
  );

  const networkResponses = useMemo(
    () =>
      campaign && person
        ? networkQuestions.map((q) => {
            const relationships = getRelationships(campaign, q.type).filter(
              (r) => peopleObjectsAreEqual(person, r.from_person)
            );

            // gold stars and former direct reports go in
            // positive comments
            const peopleWithComments =
              q.type === RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO ||
              q.type === RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT
                ? relationships.map((r) => ({
                    ...r.to_person,
                    popoverContent:
                      r.positive_comments?.length > 0 ? (
                        <div className="text-dark mt-3">
                          {r.positive_comments}
                        </div>
                      ) : (
                        <div className="fst-italic mt-3">
                          <FormattedMessage
                            id="app.views.person.person_performance.no_comments_provided"
                            defaultMessage="No comments provided"
                          />
                        </div>
                      ),
                  }))
                : q.type === RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT
                ? relationships.map((r) => ({
                    ...r.to_person,
                    popoverContent:
                      r.negative_comments?.length > 0 ? (
                        <div className="text-dark mt-3">
                          {r.negative_comments}
                        </div>
                      ) : (
                        <div className="fst-italic mt-3">
                          <FormattedMessage
                            id="app.views.person.person_performance.no_comments_provided"
                            defaultMessage="No comments provided"
                          />
                        </div>
                      ),
                  }))
                : relationships.map((r) => r.to_person);

            return {
              person: person,
              title:
                // Some stakeholder questions will only make use of the first
                // argument (duration) but we pass both since some functions
                // require both
                consts
                  .PERFORMANCE_QUESTIONS(formatMessage)
                  [q.name](
                    getCampaignCoverageDurationMonthString(
                      campaign,
                      formatMessage
                    ),
                    props.currentOrganization?.name
                  ),
              people: peopleWithComments,
            };
          })
        : undefined,
    [campaign, person, props.currentOrganization, formatMessage]
  );

  const showTimeAtOrganization = useMemo(
    () =>
      campaign
        ? !getCampaignHasFeatureEnabled(
            campaign,
            PERFORMANCE_FEATURE_HIDE_TIME_AT_ORGANIZATION
          )
        : false,
    [campaign]
  );

  const showTimeInRole = useMemo(
    () =>
      campaign
        ? !getCampaignHasFeatureEnabled(
            campaign,
            PERFORMANCE_FEATURE_HIDE_TIME_IN_ROLE
          )
        : false,
    [campaign]
  );

  const monthsInRole = useMemo(
    () =>
      surveyResponse
        ? getDateForTimeInRoleComputation(surveyResponse?.configs) &&
          endOfPerfCampaignCoveragePeriod
          ? monthDiff(
              new Date(
                // @ts-expect-error
                getDateForTimeInRoleComputation(surveyResponse?.configs)
              ),
              endOfPerfCampaignCoveragePeriod
            )
          : undefined
        : undefined,
    [endOfPerfCampaignCoveragePeriod, surveyResponse]
  );

  const monthsAtOrganization = useMemo(
    () =>
      surveyResponse
        ? surveyResponse?.configs?.latest_hire_date &&
          endOfPerfCampaignCoveragePeriod
          ? monthDiff(
              new Date(surveyResponse?.configs?.latest_hire_date),
              endOfPerfCampaignCoveragePeriod
            )
          : undefined
        : undefined,
    [endOfPerfCampaignCoveragePeriod, surveyResponse]
  );

  const perfCoverageStartDate = useMemo(() => {
    if (campaign && campaign?.coverage_start_date) {
      return yyyymmddToLocalDate(campaign.coverage_start_date);
    }
    return null;
  }, [campaign]);

  const influenceRelationshipsWithFeedback = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback
            ?.filter(
              (r) =>
                (r.type === RELATIONSHIP_TYPES.ENERGIZED_BY ||
                  r.type === RELATIONSHIP_TYPES.ADVISED_BY) &&
                peopleObjectsAreEqual(person, r.to_person)
            )
            .map((r, i) => ({
              // Necessary now that people can see their own influence
              ...r,
              from_person:
                r.from_person ||
                getUnattributedPerson(formatMessage, i, r.type),
            }))
        : undefined,
    [incomingRelationshipsWithFeedback, person, formatMessage]
  );

  const goldStarRelationshipsWithFeedback = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback
            ?.filter(
              (r) =>
                r.type === RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO &&
                peopleObjectsAreEqual(person, r.to_person)
            )
            .map((r, i) => ({
              // Necessary now that people can see their own gold stars
              ...r,
              from_person:
                r.from_person ||
                getUnattributedPerson(formatMessage, i, r.type),
            }))
        : undefined,
    [incomingRelationshipsWithFeedback, person, formatMessage]
  );

  const showManagerAndAboveMetrics = useMemo(() => {
    // if not passed in, this is part of perf
    return (
      isDemoOrPreviewMode ||
      ((typeof props.showManagerOnlyPerformanceDetails === 'undefined' ||
        props.showManagerOnlyPerformanceDetails) &&
        hasLoadedRelationshipsInManagerView)
    );
  }, [
    isDemoOrPreviewMode,
    hasLoadedRelationshipsInManagerView,
    props.showManagerOnlyPerformanceDetails,
  ]);

  const headsupRelationshipsWithFeedback = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              r.type === RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT &&
              peopleObjectsAreEqual(person, r.to_person)
          )
        : undefined,
    [incomingRelationshipsWithFeedback, person]
  );

  const formerDirectReportRelationshipsWithFeedback = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              r.type === RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT &&
              peopleObjectsAreEqual(person, r.to_person)
          )
        : undefined,
    [incomingRelationshipsWithFeedback, person]
  );

  const acceptedPeerRelationshipsWithFeedback = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              !r.decline_reason &&
              // @ts-expect-error
              !r.dataset &&
              r.type === RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR
          )
        : undefined,
    [incomingRelationshipsWithFeedback]
  );

  const declinedOrUnresponsivePeerRelationshipsWithFeedback = useMemo(
    () =>
      isMe
        ? []
        : incomingRelationshipsWithFeedback?.filter(
            (r) =>
              (r.decline_reason ||
                // @ts-expect-error
                (r.dataset &&
                  // priority must be at least 5 as higher means the person
                  // was on a waitlist
                  // @ts-expect-error
                  r.priority <= 5 &&
                  (!acceptedPeerRelationshipsWithFeedback?.length ||
                    acceptedPeerRelationshipsWithFeedback.findIndex(
                      (r2) =>
                        peopleObjectsAreEqual(r.from_person, r2.from_person) &&
                        peopleObjectsAreEqual(r.to_person, r2.to_person)
                    ) === -1))) &&
              r.type === RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR
          ),
    [
      acceptedPeerRelationshipsWithFeedback,
      incomingRelationshipsWithFeedback,
      isMe,
    ]
  );

  const managerRelationshipsWithFeedback = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              // @ts-expect-error
              !r.dataset &&
              r.type === RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR &&
              peopleObjectsAreEqual(r.to_person, person)
          )
        : undefined,
    [incomingRelationshipsWithFeedback, person]
  );

  const managerRelationshipWithFeedback = managerRelationshipsWithFeedback?.[0];

  const otherManagerRelationshipsForReviewFlow = useMemo(() => {
    if (!props.isInReviewFlow) {
      return [];
    }
    return outgoingRelationshipsWithFeedback
      ? outgoingRelationshipsWithFeedback?.filter(
          (r) =>
            // @ts-expect-error
            !!r.dataset &&
            (r.type === RELATIONSHIP_TYPES.REPORTS_TO ||
              r.type === RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER) &&
            !peopleIdsAreEqual(r.to_person?.id, props.meId) &&
            peopleObjectsAreEqual(r.from_person, person)
        )
      : undefined;
  }, [
    outgoingRelationshipsWithFeedback,
    props.isInReviewFlow,
    person,
    props.meId,
  ]);

  const additionalManagersRelationships = useMemo(
    () =>
      props.isInReviewFlow
        ? otherManagerRelationshipsForReviewFlow
        : incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback.filter(
            (r) =>
              // @ts-expect-error
              !!r.dataset &&
              r.type === RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER &&
              peopleObjectsAreEqual(r.from_person, person)
          )
        : [],
    [
      incomingRelationshipsWithFeedback,
      otherManagerRelationshipsForReviewFlow,
      props.isInReviewFlow,
      person,
    ]
  );

  const additionalManagersRelationshipsWithFeedback = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              // @ts-expect-error
              !r.dataset &&
              (r.type ===
                RELATIONSHIP_TYPES.HAS_ADDITIONAL_MANAGER_DIRECT_REPORT_FEEDBACK_FOR ||
                (r.type === RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR &&
                  props.isInReviewFlow &&
                  !peopleIdsAreEqual(r.to_person?.id, props.meId))) &&
              peopleObjectsAreEqual(r.to_person, person)
          )
        : undefined,
    [
      incomingRelationshipsWithFeedback,
      person,
      props.isInReviewFlow,
      props.meId,
    ]
  );

  const directReportRelationshipsWithFeedback = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              // @ts-expect-error
              !r.dataset &&
              (r.type === RELATIONSHIP_TYPES.REPORTS_TO ||
                r.type === RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER) &&
              peopleObjectsAreEqual(r.to_person, person) &&
              relationshipHasFeedback(r)
          )
        : undefined,
    [incomingRelationshipsWithFeedback, person]
  );

  const assignedPeerRelationships: Relationship[] | undefined = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              // @ts-expect-error
              r.dataset &&
              r.type ===
                RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR &&
              peopleObjectsAreEqual(r.to_person, person)
          )
        : undefined,
    [incomingRelationshipsWithFeedback, person]
  );

  // for manager view, we want to ensure the optional
  // peers show in the top right (note that for non-manager view
  // the backend makes it look like these came from the dataset)
  const unassignedOptionalPeerRelationships = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              r.from_person?.id &&
              // @ts-expect-error
              !r.dataset &&
              r.type ===
                RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR &&
              peopleObjectsAreEqual(r.to_person, person) &&
              // @ts-expect-error
              assignedPeerRelationships
                .filter((r2) => r2.from_person?.id)
                .findIndex((r2) => r.from_person.id === r2.from_person.id) ===
                -1
          )
        : undefined,
    [assignedPeerRelationships, incomingRelationshipsWithFeedback, person]
  );

  const nonManagerPeerRelationships = useMemo(
    () =>
      [
        ...(acceptedPeerRelationshipsWithFeedback
          ? acceptedPeerRelationshipsWithFeedback
          : []),
        ...(unassignedOptionalPeerRelationships
          ? unassignedOptionalPeerRelationships
          : []),
      ].filter(
        // deduplicating
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      ),
    [acceptedPeerRelationshipsWithFeedback, unassignedOptionalPeerRelationships]
  );

  const nonManagerPeerRelationshipsWithFeedback = useMemo(
    () =>
      [
        ...(nonManagerPeerRelationships ? nonManagerPeerRelationships : []),
      ].filter((r) => campaign && relationshipIsCompleted(r, campaign)),
    [nonManagerPeerRelationships, campaign]
  );

  const assignedDirectReportRelationships = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              // @ts-expect-error
              r.dataset &&
              r.type === RELATIONSHIP_TYPES.REPORTS_TO &&
              peopleObjectsAreEqual(r.to_person, person)
          )
        : undefined,
    [incomingRelationshipsWithFeedback, person]
  );

  const assignedAdditionalDirectReportRelationships = useMemo(
    () =>
      incomingRelationshipsWithFeedback
        ? incomingRelationshipsWithFeedback?.filter(
            (r) =>
              // @ts-expect-error
              r.dataset &&
              r.type === RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER &&
              peopleObjectsAreEqual(r.to_person, person)
          )
        : undefined,
    [incomingRelationshipsWithFeedback, person]
  );

  const manager = useMemo(
    () =>
      managerRelationshipWithFeedback
        ? managerRelationshipWithFeedback.from_person
        : undefined,
    [managerRelationshipWithFeedback]
  );

  const managerFeedbackHasNegativeAndPositiveSkills = useMemo(() => {
    if (managerRelationshipWithFeedback) {
      return (
        !!managerRelationshipWithFeedback.positive_comments?.length &&
        !!managerRelationshipWithFeedback.negative_comments?.length
      );
    }
    return false;
  }, [managerRelationshipWithFeedback]);

  const defaultManagerPhaseOpenResponseQuestions = useMemo(
    () => DEFAULT_OPEN_RESPONSE_QUESTIONS,
    []
  );

  const managerPhaseEvaluationQuestions = useMemo(() => {
    let evaluationQuestions = getFilteredEvaluationQuestions(
      person,
      campaign,
      PHASE_TYPE_EVALUATION,
      undefined,
      surveyResponse
    );
    if (!evaluationQuestions?.length && campaign) {
      evaluationQuestions = campaign.configs?.[
        CAMPAIGN_CONFIG_CREATED_WITH_DEFAULT_QUESTIONS
      ]
        ? []
        : defaultManagerPhaseOpenResponseQuestions;
    }
    return evaluationQuestions;
  }, [
    campaign,
    surveyResponse,
    defaultManagerPhaseOpenResponseQuestions,
    person,
  ]);

  const upwardManagerEvaluationQuestions = useMemo(() => {
    let evaluationQuestions = getFilteredEvaluationQuestions(
      person,
      campaign,
      PHASE_TYPE_OTHERS,
      PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
      surveyResponse
    );

    if (!evaluationQuestions?.length && campaign) {
      evaluationQuestions = campaign.configs?.[
        CAMPAIGN_CONFIG_CREATED_WITH_DEFAULT_QUESTIONS
      ]
        ? []
        : defaultManagerPhaseOpenResponseQuestions;
    }
    return evaluationQuestions;
  }, [
    campaign,
    surveyResponse,
    defaultManagerPhaseOpenResponseQuestions,
    person,
  ]);

  const peerEvaluationQuestions = useMemo(() => {
    let evaluationQuestions = getFilteredEvaluationQuestions(
      person,
      campaign,
      PHASE_TYPE_OTHERS,
      undefined,
      surveyResponse
    );

    if (!evaluationQuestions?.length && campaign) {
      evaluationQuestions = campaign.configs?.[
        CAMPAIGN_CONFIG_CREATED_WITH_DEFAULT_QUESTIONS
      ]
        ? []
        : defaultManagerPhaseOpenResponseQuestions;
    }
    return evaluationQuestions;
  }, [
    campaign,
    surveyResponse,
    defaultManagerPhaseOpenResponseQuestions,
    person,
  ]);

  // Used when upward and peer free response questions are identical
  const nonManagerEvaluationQuestions = useMemo(() => {
    if (
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
      )
    ) {
      return getFilteredEvaluationQuestions(
        person,
        campaign,
        PHASE_TYPE_OTHERS,
        PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
        surveyResponse
      );
    }

    if (
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS
      )
    ) {
      return getFilteredEvaluationQuestions(
        person,
        campaign,
        PHASE_TYPE_OTHERS,
        // @ts-expect-error
        surveyResponse
      );
    }

    return defaultManagerPhaseOpenResponseQuestions;
  }, [
    campaign,
    surveyResponse,
    defaultManagerPhaseOpenResponseQuestions,
    person,
  ]);

  const managerWrittenResponses = useMemo(() => {
    if (managerRelationshipWithFeedback) {
      const evaluationQuestions = managerPhaseEvaluationQuestions;

      if (evaluationQuestions.length > 0) {
        const managerResponses = {
          ...managerRelationshipWithFeedback,
          ...managerRelationshipWithFeedback?.responses,
        };

        return evaluationQuestions?.map((q) =>
          formatResponse(
            formatMessage,
            // @ts-expect-error
            manager,
            person,
            q,
            managerResponses,
            campaign,
            props.currentOrganization
          )
        );
      }
    }
    return undefined;
  }, [
    campaign,
    manager,
    managerPhaseEvaluationQuestions,
    managerRelationshipWithFeedback,
    person,
    props.currentOrganization,
    formatMessage,
  ]);

  const additionalManagerWrittenResponses = useMemo(() => {
    if (
      additionalManagersRelationships &&
      additionalManagersRelationshipsWithFeedback
    ) {
      const evaluationQuestions = managerPhaseEvaluationQuestions;

      if (evaluationQuestions.length > 0) {
        return additionalManagersRelationships.map((additional_manager) => {
          const relationship = additionalManagersRelationshipsWithFeedback.find(
            (r) => r.from_person.id === additional_manager.to_person!.id
          );
          const managerResponses = {
            ...relationship,
            ...relationship?.responses,
          };

          return {
            relationship: relationship,
            manager: additional_manager.to_person!,
            responses: evaluationQuestions?.map((q) =>
              formatResponse(
                formatMessage,
                additional_manager.to_person!,
                person,
                q,
                // @ts-expect-error
                managerResponses,
                campaign,
                props.currentOrganization
              )
            ),
          };
        });
      }
    }
    return undefined;
  }, [
    campaign,
    managerPhaseEvaluationQuestions,
    additionalManagersRelationshipsWithFeedback,
    additionalManagersRelationships,
    person,
    props.currentOrganization,
    formatMessage,
  ]);

  const hasONAFeedback = useMemo(() => {
    return !!(
      influenceRelationshipsWithFeedback?.length ||
      goldStarRelationshipsWithFeedback?.length ||
      headsupRelationshipsWithFeedback?.length
    );
  }, [
    influenceRelationshipsWithFeedback,
    goldStarRelationshipsWithFeedback,
    headsupRelationshipsWithFeedback,
  ]);

  const upwardManagerWrittenResponses: WrittenResponse[] | undefined =
    useMemo(() => {
      if (directReportRelationshipsWithFeedback?.length) {
        const upwardManagerFeedbacks = directReportRelationshipsWithFeedback;

        const customEvaluationQuestions =
          upwardManagerEvaluationQuestions.filter(
            (q) =>
              q?.name?.startsWith(CUSTOM_QUESTION_NONANONYMOUS_PREFIX) ||
              q?.name?.startsWith(CUSTOM_QUESTION_ANONYMOUS_PREFIX) ||
              q?.name === 'rating'
          );

        if (customEvaluationQuestions.length > 0) {
          let customEvaluationResponses: FormattedResponse[] = [];
          for (let i = 0; i < upwardManagerFeedbacks.length; i++) {
            const upwardManagerFeedback = upwardManagerFeedbacks[i];
            const upwardManagerResponses = {
              ...upwardManagerFeedback,
              ...upwardManagerFeedback?.responses,
            };

            customEvaluationResponses = [
              ...customEvaluationResponses,
              ...(customEvaluationQuestions?.map((q) =>
                formatResponse(
                  formatMessage,
                  upwardManagerFeedback.from_person ||
                    getUnattributedPerson(
                      formatMessage,
                      i,
                      upwardManagerFeedback.type
                    ),
                  person,
                  q,
                  // @ts-expect-error
                  upwardManagerResponses,
                  campaign,
                  props.currentOrganization
                )
              ) || []),
            ];
          }

          const customEvaluationResponsesObj = {};
          for (const responseObj of customEvaluationResponses) {
            // @ts-expect-error
            if (!(responseObj.question?.name in customEvaluationResponsesObj)) {
              // @ts-expect-error
              customEvaluationResponsesObj[responseObj.question?.name] = {
                question: responseObj.question,
                title: responseObj.title,
                responses: [],
              };
            }
            customEvaluationResponsesObj[
              // @ts-expect-error
              responseObj.question?.name
            ].responses.push(responseObj);
          }

          return Object.values(customEvaluationResponsesObj);
        }
      }
      return undefined;
    }, [
      directReportRelationshipsWithFeedback,
      upwardManagerEvaluationQuestions,
      person,
      campaign,
      props.currentOrganization,
      formatMessage,
    ]);

  const peerWrittenResponses: WrittenResponse[] | undefined = useMemo(() => {
    if (nonManagerPeerRelationshipsWithFeedback?.length > 0) {
      const peerFeedbacks = nonManagerPeerRelationshipsWithFeedback;

      const customEvaluationQuestions = peerEvaluationQuestions.filter(
        (q) =>
          q?.name?.startsWith(CUSTOM_QUESTION_NONANONYMOUS_PREFIX) ||
          q?.name?.startsWith(CUSTOM_QUESTION_ANONYMOUS_PREFIX) ||
          q?.name === 'rating'
      );

      if (customEvaluationQuestions.length > 0) {
        let customEvaluationResponses: FormattedResponse[] = [];
        for (let i = 0; i < peerFeedbacks.length; i++) {
          const peerFeedback = peerFeedbacks[i];
          const peerFeedbackResponses = {
            ...peerFeedback,
            ...peerFeedback?.responses,
          };

          customEvaluationResponses = [
            ...customEvaluationResponses,
            ...(customEvaluationQuestions?.map((q) =>
              formatResponse(
                formatMessage,
                peerFeedback.from_person ||
                  getUnattributedPerson(formatMessage, i, peerFeedback.type),
                person,
                q,
                // @ts-expect-error
                peerFeedbackResponses,
                campaign,
                props.currentOrganization
              )
            ) ?? []),
          ];
        }

        const customEvaluationResponsesObj = {};
        for (const responseObj of customEvaluationResponses) {
          // @ts-expect-error
          if (!(responseObj.question?.name in customEvaluationResponsesObj)) {
            // @ts-expect-error
            customEvaluationResponsesObj[responseObj.question?.name] = {
              question: responseObj.question,
              title: responseObj.title,
              responses: [],
            };
          }
          customEvaluationResponsesObj[
            // @ts-expect-error
            responseObj.question?.name
          ].responses.push(responseObj);
        }
        return Object.values(customEvaluationResponsesObj);
      }
    }
    return undefined;
  }, [
    campaign,
    nonManagerPeerRelationshipsWithFeedback,
    peerEvaluationQuestions,
    person,
    props.currentOrganization,
    formatMessage,
  ]);

  const nonManagerWrittenResponse: WrittenResponse[] | undefined =
    useMemo(() => {
      // Assumes that upward feedback and peer feedback questions are identical
      const combinedResponses = {};

      if (upwardManagerWrittenResponses?.length) {
        for (const response of upwardManagerWrittenResponses) {
          combinedResponses[response.question.name!] = response;
        }
      }

      if (peerWrittenResponses?.length) {
        for (const response of peerWrittenResponses) {
          const questionName = response.question.name!;
          if (questionName in combinedResponses) {
            combinedResponses[questionName].responses = combinedResponses[
              questionName
            ].responses.concat(response.responses);
          } else {
            combinedResponses[questionName] = response;
          }
        }
      }

      return Object.values(combinedResponses);
    }, [upwardManagerWrittenResponses, peerWrittenResponses]);

  // Used for the "edit feedback" modal. We want to include all questions
  // here (and not filter out positive and negative skills, if both exist),
  // since we want everything to be editable
  const allManagerWrittenResponses = useMemo(() => {
    if (managerRelationshipWithFeedback) {
      const evaluationQuestions = managerPhaseEvaluationQuestions;

      if (evaluationQuestions.length > 0) {
        const managerResponses = {
          ...managerRelationshipWithFeedback,
          ...managerRelationshipWithFeedback?.responses,
        };

        return evaluationQuestions?.map((q) =>
          formatResponse(
            formatMessage,
            // @ts-expect-error
            manager,
            person,
            q,
            managerResponses,
            campaign,
            props.currentOrganization
          )
        );
      }
    }
    return undefined;
  }, [
    campaign,
    manager,
    managerPhaseEvaluationQuestions,
    managerRelationshipWithFeedback,
    person,
    props.currentOrganization,
    formatMessage,
  ]);

  const relationshipsWithFeedback = useMemo(
    () =>
      [
        // include manager feedback here if there is both
        // positive AND negative skills/comments
        ...((!(managerWrittenResponses?.length > 0) ||
          managerFeedbackHasNegativeAndPositiveSkills) &&
        managerRelationshipsWithFeedback
          ? managerRelationshipsWithFeedback
          : []),
        ...(additionalManagersRelationshipsWithFeedback
          ? additionalManagersRelationshipsWithFeedback
          : []),
        ...(acceptedPeerRelationshipsWithFeedback
          ? acceptedPeerRelationshipsWithFeedback
          : []),
        ...(directReportRelationshipsWithFeedback
          ? directReportRelationshipsWithFeedback
          : []),
      ].filter((r) => campaign && relationshipIsCompleted(r, campaign)),
    [
      acceptedPeerRelationshipsWithFeedback,
      directReportRelationshipsWithFeedback,
      managerFeedbackHasNegativeAndPositiveSkills,
      managerRelationshipsWithFeedback,
      additionalManagersRelationshipsWithFeedback,
      managerWrittenResponses?.length,
      campaign,
    ]
  );

  const nonManagerRelationshipsWithFeedback = useMemo(
    () =>
      [
        ...(acceptedPeerRelationshipsWithFeedback
          ? acceptedPeerRelationshipsWithFeedback
          : []),
        ...(directReportRelationshipsWithFeedback
          ? directReportRelationshipsWithFeedback
          : []),
      ].filter((r) => campaign && relationshipIsCompleted(r, campaign)),
    [
      acceptedPeerRelationshipsWithFeedback,
      directReportRelationshipsWithFeedback,
      campaign,
    ]
  );

  const nonManagerDirectReportRelationshipsWithFeedback = useMemo(
    () =>
      [
        ...(directReportRelationshipsWithFeedback
          ? directReportRelationshipsWithFeedback
          : []),
      ].filter((r) => campaign && relationshipIsCompleted(r, campaign)),
    [directReportRelationshipsWithFeedback, campaign]
  );

  const assignedRelationships = useMemo(
    () => [
      ...(managerRelationshipsWithFeedback
        ? managerRelationshipsWithFeedback
        : []),
      ...(assignedPeerRelationships
        ? assignedPeerRelationships.filter((r) => !r.decline_reason)
        : []),
      ...(unassignedOptionalPeerRelationships
        ? unassignedOptionalPeerRelationships
        : []),
      ...(assignedDirectReportRelationships
        ? assignedDirectReportRelationships
        : []),
    ],
    [
      assignedDirectReportRelationships,
      assignedPeerRelationships,
      managerRelationshipsWithFeedback,
      unassignedOptionalPeerRelationships,
    ]
  );

  const nonManagerAssignedRelationships = useMemo(
    () => [
      ...(assignedPeerRelationships
        ? assignedPeerRelationships.filter((r) => !r.decline_reason)
        : []),
      ...(unassignedOptionalPeerRelationships
        ? unassignedOptionalPeerRelationships
        : []),
      ...(assignedDirectReportRelationships
        ? assignedDirectReportRelationships
        : []),
      ...(assignedAdditionalDirectReportRelationships
        ? assignedAdditionalDirectReportRelationships
        : []),
    ],
    [
      assignedDirectReportRelationships,
      assignedAdditionalDirectReportRelationships,
      assignedPeerRelationships,
      unassignedOptionalPeerRelationships,
    ]
  );

  const feedbackGivers = useMemo(
    () =>
      assignedRelationships
        ? assignedRelationships.map(
            (r, index) =>
              r.from_person ||
              getUnattributedPerson(formatMessage, index, r.type)
          )
        : undefined,
    [assignedRelationships, formatMessage]
  );

  const nonManagerFeedbackGivers = useMemo(
    () =>
      nonManagerAssignedRelationships
        ? nonManagerAssignedRelationships.map(
            (r, index) =>
              r.from_person ||
              getUnattributedPerson(formatMessage, index, r.type)
          )
        : undefined,
    [nonManagerAssignedRelationships, formatMessage]
  );

  const nonManagerPeerFeedbackGivers = useMemo(
    () =>
      nonManagerPeerRelationshipsWithFeedback
        ? nonManagerPeerRelationshipsWithFeedback.map(
            (r, index) =>
              r.from_person ||
              getUnattributedPerson(formatMessage, index, r.type)
          )
        : undefined,
    [nonManagerPeerRelationshipsWithFeedback, formatMessage]
  );

  const directReportFeedbackGivers = useMemo(() => {
    const directReports = assignedDirectReportRelationships ?? [];
    const additionalDirectReports =
      assignedAdditionalDirectReportRelationships ?? [];
    const reports = [...directReports, ...additionalDirectReports];

    return reports.length > 0
      ? reports.map(
          (r, index) =>
            r.from_person || getUnattributedPerson(formatMessage, index, r.type)
        )
      : undefined;
  }, [
    assignedDirectReportRelationships,
    assignedAdditionalDirectReportRelationships,
    formatMessage,
  ]);

  const reportHasBeenReleased = useMemo(() => {
    return !!surveyResponse?.released_at;
  }, [surveyResponse]);

  const onSubmitDirectReportRelationship = useCallback(
    (data, error) => {
      if (data) {
        // update relationship in UI
        setIncomingRelationshipsWithFeedback(
          // @ts-expect-error
          incomingRelationshipsWithFeedback.map((r) =>
            r.id === data.id ? data : r
          )
        );
        toast.success(
          formatMessage({
            id: 'app.view.person_performance.comments_updated',
            defaultMessage: 'Comments updated!',
          })
        );
      } else {
        toast.error('Error: ' + error.toString());
      }
    },
    [incomingRelationshipsWithFeedback, formatMessage]
  );

  const showDeclinedAndUnresponsivePeers = useMemo(() => {
    // if not passed in, this is part of perf
    return (
      typeof props.showManagerOnlyPerformanceDetails === 'undefined' ||
      props.showManagerOnlyPerformanceDetails
    );
  }, [props.showManagerOnlyPerformanceDetails]);

  const editRelationship = useCallback((r) => {
    setCurrentDirectReportFeedbackRelationship(r);
    setShowDirectReportFeedbackEditor(true);
  }, []);

  const editedDirectReportFeedbackObject = useMemo(() => {
    const relationship = currentDirectReportFeedbackRelationship;

    if (!relationship) {
      return undefined;
    }

    const data = {
      // @ts-expect-error
      ...relationship,
      positive_skills: [
        // @ts-expect-error
        ...(relationship?.positive_skills
          ? // @ts-expect-error
            relationship.positive_skills.map((o) => ({
              ...o,
              _index: 'skills',
            }))
          : []),
      ],
      negative_skills: [
        // @ts-expect-error
        ...(relationship?.negative_skills
          ? // @ts-expect-error
            relationship.negative_skills.map((o) => ({
              ...o,
              _index: 'skills',
            }))
          : []),
      ],
    };

    // @ts-expect-error
    if (relationship.responses) {
      // @ts-expect-error
      addCustomResponseValues(relationship.responses, data);
    }

    return data;
  }, [currentDirectReportFeedbackRelationship]);

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        // @ts-expect-error
        campaign: campaign.id,
        type: object.type,
        from_person: object.from_person.id,
        to_person: object.to_person.id,
        positive_skills: prepTagsForSubmit(
          object?.positive_skills,
          props.currentOrganization?.id
        ),
        positive_comments: object?.positive_comments,
        negative_skills: prepTagsForSubmit(
          object?.negative_skills,
          props.currentOrganization?.id
        ),
        negative_comments: object.negative_comments,
        responses: extractCustomResponses(object),
        rating_comments: object.rating_comments,
      };
    },
    [campaign?.id, props.currentOrganization?.id]
  );

  const hasOthersPhase = useMemo(
    () => campaign && getPhaseByType(campaign, PHASE_TYPE_OTHERS),
    [campaign]
  );

  const onSelectHistoryCampaign = useCallback(
    // Select campaign from Trajectory module
    (id) => {
      // if it's already loaded in the UI, do nothing
      if (campaign?.id === id) {
        return;
      }

      // clear UI so loading indicator shows
      setCampaignIdToFetch(id);
      setReceivedUserPerformanceData(false);
    },
    [campaign?.id]
  );

  useEffect(() => {
    const pageConfigs = new URLSearchParams(location.search);

    // previous=true in queryString means default
    // to the 2nd item in the perf dropdown
    // NOTE: we check for campaigns?.length here
    // as we want to ensure that campaigns are set
    // before fetching a past campaign
    if (!!campaigns && campaigns.length > 1 && location.search) {
      if (pageConfigs?.get('previous') === 'true') {
        const campaignId = campaigns[1]?.id;
        if (campaignId) {
          setCampaignIdToFetch(campaignId);
          setReceivedUserPerformanceData(false);
        }
      }
    } else if (pageConfigs?.get('campaign_id')) {
      // providing a campaign_id in the query string
      // causes the dropdown to be set to that campaign
      // on page load
      // @ts-expect-error
      const campaignId = parseInt(pageConfigs?.get('campaign_id'));
      if (campaignId) {
        setCampaignIdToFetch(campaignId);
        setReceivedUserPerformanceData(false);
      }
    } else {
      // by default we find the latest campaign the user is participating to
      if (campaigns?.length) {
        const firstParticipatingCampaign = campaigns.find(
          // @ts-expect-error
          (x) => x.is_participating
        );
        if (firstParticipatingCampaign) {
          setCampaignIdToFetch(firstParticipatingCampaign.id);
        }
      }
    }
  }, [location, campaigns]);

  // for managers reviewing someone when nobody else is
  // doing the review, don't show what they've already written
  // in the reviewing UI if they go in to review this person again
  // unless there are reviews from others (aka peers) in there as well
  const showPerformanceFeedbackFromManagerAndOthers = useMemo(
    () =>
      (hasOthersPhase || !props.isInReviewFlow) &&
      !!feedbackGivers?.length &&
      !!relationshipsWithFeedback?.length,
    [
      feedbackGivers?.length,
      hasOthersPhase,
      props.isInReviewFlow,
      relationshipsWithFeedback?.length,
    ]
  );

  // If upward manager and peer reviews have different questions,
  // we separate them out into two sections
  const separatePeerAndDirectReportFeedback = useMemo(
    () =>
      hasOthersPhase &&
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
      ) &&
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS
      ) &&
      !!feedbackGivers?.length &&
      relationshipsWithFeedback?.length > 0,
    [
      feedbackGivers?.length,
      hasOthersPhase,
      campaign,
      relationshipsWithFeedback?.length,
    ]
  );

  const shouldNotBeRated = useMemo(
    () => surveyResponse?.configs?.is_rated === false,
    [surveyResponse?.configs?.is_rated]
  );

  const campaignHasCalibrationPhase = useMemo(
    () => campaign && getCampaignHasCalibrationPhase(campaign),
    [campaign]
  );

  const preparedManagerOpenResponseQuestions = useMemo(() => {
    if (!incomingRelationshipsWithFeedback?.length) {
      return [];
    }

    const fullQuestionsList = allManagerWrittenResponses
      ? allManagerWrittenResponses.map((wr, index) =>
          prepareOpenResponseQuestion(
            formatMessage,
            wr.question,
            [],
            true,
            personPreferredName,
            [],
            false,
            campaign,
            props.currentOrganization,
            false,
            index,
            isDemoOrPreviewMode
          )
        )
      : defaultManagerPhaseOpenResponseQuestions;

    // for companies that allow for rating comments, include ability to edit
    // private comments for rating at the end (to ensure these comments are
    // consistent with calibrations and/or any updates from conversation
    // with the person before the report is released)
    if (
      !getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      )
    ) {
      fullQuestionsList.push({
        name: 'rating_comments',
        required: true,
        type: INPUT_TYPES.TEXTAREA,
        maxLength: 1000,
        minRows: 3,
        maxRows: 15,
        label: (
          <span>
            {shouldNotBeRated ? (
              <FormattedMessage
                id="app.views.person.person_performance.rating_comments_label_no_rating"
                defaultMessage="What is your overall comment on {personPreferredName}'s performance?"
                values={{ personPreferredName: personPreferredName }}
              />
            ) : (
              <FormattedMessage
                id="app.views.person.person_performance.rating_comments_label"
                defaultMessage="Why was this rating given to {personPreferredName}?"
                values={{ personPreferredName: personPreferredName }}
              />
            )}
          </span>
        ),
        helperText: (
          <>
            <span>
              <FormattedMessage
                id="app.views.person.person_performance.not_visible_to_person"
                defaultMessage="These comments are not visible to {personPreferredName}."
                values={{ personPreferredName: personPreferredName }}
              />{' '}
              <span
                className="text-primary"
                role="button"
                // @ts-expect-error
                ref={ratingCommentLearnMoreRef}
              >
                <FormattedMessage
                  id="app.views.person.person_performance.learn_more"
                  defaultMessage="Learn more"
                />
              </span>
            </span>
            <UncontrolledPopover
              delay={50}
              trigger={'click'}
              placement="top"
              // @ts-expect-error
              target={ratingCommentLearnMoreRef}
            >
              {campaignHasCalibrationPhase ? (
                <FormattedMessage
                  id="app.views.person.person_performance.rating_comments_helpet_text_with_calibrations"
                  defaultMessage="These comments will be referenced as part of the calibration process which will review equity and fairness across manager groups."
                />
              ) : (
                <FormattedMessage
                  id="app.views.person.person_performance.rating_comments_helpet_text_without_calibrations"
                  defaultMessage="These comments will be referenced only by managers and your HR/People admins."
                />
              )}
            </UncontrolledPopover>
          </>
        ),
      });
    }

    return fullQuestionsList;
  }, [
    incomingRelationshipsWithFeedback?.length,
    allManagerWrittenResponses,
    defaultManagerPhaseOpenResponseQuestions,
    campaign,
    personPreferredName,
    props.currentOrganization,
    isDemoOrPreviewMode,
    shouldNotBeRated,
    campaignHasCalibrationPhase,
    formatMessage,
  ]);

  const minPositiveTagsRequired = useMemo(() => {
    // if not asking for positive skills, don't require
    const q = preparedManagerOpenResponseQuestions?.find(
      (q) => q.name === 'positive_skills'
    );

    if (!q) {
      return 0;
    }

    if (q.minLength) {
      return q.minLength;
    }

    return DEFAULT_MIN_POSITIVE_TAGS;
  }, [preparedManagerOpenResponseQuestions]);

  const minNegativeTagsRequired = useMemo(() => {
    // if not asking for negative skills, don't require
    const q = preparedManagerOpenResponseQuestions?.find(
      (q) => q.name === 'negative_skills'
    );

    if (!q) {
      return 0;
    }

    if (q.minLength) {
      return q.minLength;
    }

    return DEFAULT_MIN_NEGATIVE_TAGS;
  }, [preparedManagerOpenResponseQuestions]);

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (
        minPositiveTagsRequired > 0 &&
        !(obj.positive_skills?.length >= minPositiveTagsRequired)
      ) {
        errors['positive_skills'] = formatMessage(
          {
            id: 'app.view.person_performance.on_validate.positive_skills_required',
            defaultMessage:
              'At least {minPositiveTagsRequired, plural, one {one skill or behavior is required} other {{minPositiveTagsRequired} skills or behaviors are required}}.',
          },
          { minPositiveTagsRequired: minPositiveTagsRequired }
        );
      }

      if (
        minNegativeTagsRequired > 0 &&
        !(obj.negative_skills?.length >= minNegativeTagsRequired)
      ) {
        errors['negative_skills'] = formatMessage(
          {
            id: 'app.view.person_performance.on_validate.negative_skills_required',
            defaultMessage:
              'At least {minNegativeTagsRequired, plural, one {one skill or behavior is required} other {{minNegativeTagsRequired} skills or behaviors are required}}.',
          },
          { minNegativeTagsRequired: minNegativeTagsRequired }
        );
      }

      return errors;
    },
    [minPositiveTagsRequired, minNegativeTagsRequired, formatMessage]
  );

  const isLoading = useMemo(() => {
    if (receivedUserPerformanceData) {
      return false;
    }

    return (
      // == checks whether campaign is either null OR undefined
      // (both are needed to prevent flickering empty state), nothing that
      campaign == null || (performanceHistory == null && campaign !== null)
    );
  }, [campaign, performanceHistory, receivedUserPerformanceData]);

  const doneLoadingExistingCampaign = useMemo(
    () => !isLoading && campaign !== null,
    [isLoading, campaign]
  );

  if (!errorMessage && !campaign && campaigns && !campaigns.length && isMe) {
    return (
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.person.person_performance.no_feedback_published"
            defaultMessage="You do not have any feedback published yet."
          />
        </CardBody>
      </Card>
    );
  }

  const shouldShowCampaignSelector =
    props.showCampaignsSelector && !!campaigns?.length;

  const shouldShowHistoricalData = props.showHistory && !!person;
  return (
    <PersonPerformanceContextProvider
      // @ts-expect-error
      campaign={campaign}
      person={person}
      meId={props.meId}
      isInReviewFlow={props.isInReviewFlow}
      setDisplayRatingWidgets={setDisplayRatingWidgets}
      showManagerOnlyPerformanceDetails={
        props.showManagerOnlyPerformanceDetails
      }
      performanceHistoryData={performanceHistory ?? []}
    >
      <PersonPerformanceContextConsumer>
        {(context) => {
          return (
            <>
              {(shouldShowCampaignSelector || props.actions) && (
                <Row className="justify-content-between">
                  {shouldShowCampaignSelector && (
                    <Col className="col-auto">
                      <CampaignSelectorRow
                        campaigns={campaigns}
                        campaign={campaign}
                        campaignIdToFetch={campaignIdToFetch}
                        setCampaignIdToFetch={onSelectHistoryCampaign}
                        setReceivedUserPerformanceData={
                          setReceivedUserPerformanceData
                        }
                      />
                    </Col>
                  )}
                  {props.actions && (
                    <Col className="col-auto">{props.actions}</Col>
                  )}
                </Row>
              )}
              {!isLoading &&
                isMe &&
                !props.isInReviewFlow &&
                !reportHasBeenReleased && <EmptyFeedbackState />}
              {doneLoadingExistingCampaign && (
                <PersonPerformanceAlertRow
                  setSurveyResponse={setSurveyResponse}
                  surveyResponse={surveyResponse}
                  personPreferredName={personPreferredName}
                  campaign={campaign}
                  person={person}
                  editRelationship={editRelationship}
                  managerRelationshipsWithFeedback={
                    managerRelationshipsWithFeedback
                  }
                  meId={props.meId}
                  incomingRelationshipsWithFeedback={
                    incomingRelationshipsWithFeedback
                  }
                  isInReviewFlow={props.isInReviewFlow}
                  isMe={isMe}
                  // @ts-expect-error
                  isEligibleForReporting={isEligibleForReporting}
                  reportHasBeenReleased={reportHasBeenReleased}
                  personFullName={personFullName}
                  isAdminable={props.isAdminable}
                  hasONAFeedback={hasONAFeedback}
                />
              )}

              <PerformanceHistoryRow
                person={person}
                showManagerOnlyPerformanceDetails={
                  props.showManagerOnlyPerformanceDetails
                }
                shouldShowHistoricalData={shouldShowHistoricalData}
                // @ts-expect-error
                managerRelationshipsWithFeedback={
                  managerRelationshipsWithFeedback
                }
                manager={manager}
                showTimeAtOrganization={showTimeAtOrganization}
                campaign={campaign}
                monthsAtOrganization={monthsAtOrganization}
                monthsInRole={monthsInRole}
                role={surveyResponse?.configs?.title ?? person?.title}
                showTimeInRole={showTimeInRole}
                isInReviewFlow={props.isInReviewFlow}
                isEligibleForReporting={isEligibleForReporting}
                hasONAFeedback={hasONAFeedback}
                data={performanceHistory}
                displayDistributions={displayDistributions}
                isDemoOrPreviewMode={isDemoOrPreviewMode}
              />
              {isLoading && (
                <Loading
                  message={formatMessage({
                    id: 'app.views.person.person_performance.message.loading_cycle_data',
                    defaultMessage: 'Loading cycle data...',
                  })}
                />
              )}
              {doneLoadingExistingCampaign &&
                (isEligibleForReporting || hasONAFeedback) && (
                  <>
                    {displayRatingWidgets &&
                      propsShowManagerResponses &&
                      managerWrittenResponses?.length > 0 && (
                        <PageSection
                          id="perf-profile-manager-feedback"
                          title={formatMessage({
                            id: 'app.views.person.person_performance.title.manager_feedback',
                            defaultMessage: 'Manager feedback',
                          })}
                          titleIcon="user"
                          titleIconColor="text-success"
                          widths={[
                            ...(displayDistributions &&
                            context.displayCharts &&
                            (displayManagerRatingDistribution ||
                              context.managerRatingHistoricalData)
                              ? [
                                  ...(displayManagerRatingDistribution &&
                                  context.managerRatingHistoricalData
                                    ? [
                                        { xs: 12, lg: 6 },
                                        { xs: 12, lg: 6 },
                                      ]
                                    : [{ xs: 12, lg: 12 }]),
                                ]
                              : []),
                            { xs: 12, lg: 8 },
                            { xs: 12, lg: 4 },
                          ]}
                          rightWidget={
                            // @ts-expect-error
                            <FeedbackProviderListWidget people={[manager]} />
                          }
                        >
                          {context.displayCharts &&
                          displayDistributions &&
                          displayManagerRatingDistribution ? (
                            <ManagerRatingDistributionChart
                              person={person}
                              isInReviewFlow={props.isInReviewFlow}
                              hideRatingFromDirectReport={
                                hideRatingFromDirectReport
                              }
                            />
                          ) : null}
                          {context.displayCharts &&
                          displayDistributions &&
                          context.managerRatingHistoricalData ? (
                            <ManagerRatingHistoryChart
                              person={person}
                              showManagerOnlyPerformanceDetails={
                                props.showManagerOnlyPerformanceDetails
                              }
                              // @ts-expect-error
                              performanceData={performanceHistory}
                              // @ts-expect-error
                              organization={props.currentOrganization}
                            />
                          ) : null}
                          <PerformanceWrittenResponses
                            // @ts-expect-error
                            person={manager}
                            questions={managerWrittenResponses}
                          />
                          <div
                            className="position-sticky"
                            style={{ top: '24px', marginBottom: '48px' }}
                          >
                            <PersonProfileIntroSummary
                              title={formatMessage(
                                {
                                  id: 'app.views.person.person_performance.title.manager_this_cycle',
                                  defaultMessage: "{name}'s manager this cycle",
                                },
                                {
                                  name: props.person.given_name,
                                }
                              )}
                              fetchPersonDetails={true}
                              showPersonHeader={true}
                              isExternalUrl={true}
                              isMe={false}
                              // @ts-expect-error
                              person={manager}
                              showOperatingManualLink={false}
                              renderParts={(components) => {
                                return (
                                  <>
                                    <Col sm={6}>
                                      {
                                        components[
                                          PERSON_PROFILE_COMPONENT_PREFERRED_FIRST_NAME
                                        ]
                                      }
                                      {
                                        components[
                                          PERSON_PROFILE_COMPONENT_JOINED
                                        ]
                                      }
                                    </Col>
                                    <Col sm={6} className="mb-4">
                                      {
                                        components[
                                          PERSON_PROFILE_COMPONENT_MANAGER
                                        ]
                                      }
                                      {
                                        components[
                                          PERSON_PROFILE_COMPONENT_DIRECT_REPORTS
                                        ]
                                      }
                                      {
                                        components[
                                          PERSON_PROFILE_COMPONENT_BUSINESS_UNIT
                                        ]
                                      }
                                    </Col>
                                  </>
                                );
                              }}
                            />
                          </div>
                        </PageSection>
                      )}
                    {displayRatingWidgets &&
                      (propsShowManagerResponses || props.isInReviewFlow) &&
                      !!additionalManagerWrittenResponses?.length && (
                        <>
                          {additionalManagerWrittenResponses.map(
                            (additionalManagerResponses) => {
                              return (
                                <PageSection
                                  id={`perf-profile-additional-manager-feedback-${additionalManagerResponses.manager.id}`}
                                  key={`additional_mgr_${additionalManagerResponses.manager.id}`}
                                  title={
                                    props.isInReviewFlow
                                      ? formatMessage({
                                          id: 'app.views.person.person_performance.title.other_manager_feedback',
                                          defaultMessage:
                                            'Other manager feedback',
                                        })
                                      : formatMessage({
                                          id: 'app.views.person.person_performance.title.additional_manager_feedback',
                                          defaultMessage:
                                            'Additional manager feedback',
                                        })
                                  }
                                  titleIcon="user"
                                  titleIconColor="text-success"
                                  widths={[{ xs: 12 }, { xs: 12 }]}
                                  rightWidget={
                                    <FeedbackProviderListWidget
                                      people={[
                                        additionalManagerResponses.manager,
                                      ]}
                                    />
                                  }
                                >
                                  {displayRating && (
                                    <ManagerRatingCard
                                      person={props.person}
                                      isInReviewFlow={props.isInReviewFlow}
                                      hideRatingFromDirectReport={false}
                                      showManagerOnlyPerformanceDetails={
                                        props.showManagerOnlyPerformanceDetails
                                      }
                                      managerRelationshipsWithFeedback={
                                        additionalManagerResponses?.relationship
                                          ? [
                                              additionalManagerResponses.relationship,
                                            ]
                                          : []
                                      }
                                      manager={
                                        additionalManagerResponses.manager
                                      }
                                      isAdditionalManager={true}
                                    />
                                  )}
                                  <PerformanceWrittenResponses
                                    person={additionalManagerResponses.manager}
                                    questions={
                                      additionalManagerResponses.responses
                                    }
                                  />
                                </PageSection>
                              );
                            }
                          )}
                        </>
                      )}

                    {!isMe &&
                      showManagerAndAboveMetrics &&
                      !!formerDirectReportRelationshipsWithFeedback?.length && (
                        <Row>
                          <CalloutsBox
                            id={'formers-feedback'}
                            title={formatMessage({
                              id: 'app.views.person.person_performance.title.former_manager_comments',
                              defaultMessage: 'Former manager comments',
                            })}
                            titleType="Former manager comments"
                            helperText={formatMessage(
                              {
                                id: 'app.views.person.person_performance.title.former_manager_comments_helper_text',
                                defaultMessage:
                                  'Anyone who reported themselves as a previous manager to {personPreferredName} is shown here. Comments are optional.',
                              },
                              {
                                personPreferredName: personPreferredName,
                              }
                            )}
                            relationships={
                              formerDirectReportRelationshipsWithFeedback
                            }
                          />
                        </Row>
                      )}

                    {!!incomingRelationshipsWithFeedback?.length && (
                      <>
                        <ModalEditor
                          isOpen={showDirectReportFeedbackEditor}
                          toggle={toggleDirectReportFeedbackEditor}
                          // @ts-expect-error
                          submitButtonClassName={'btn btn-primary'}
                          title={formatMessage({
                            id: 'app.views.person.person_performance.title.edit_feedback',
                            defaultMessage: 'Edit feedback',
                          })}
                          object={editedDirectReportFeedbackObject}
                          method="POST"
                          url={
                            isDemoOrPreviewMode ? undefined : 'relationships'
                          }
                          inputs={preparedManagerOpenResponseQuestions}
                          callback={onSubmitDirectReportRelationship}
                          onValidate={onValidate}
                          transformObjectBeforeSubmit={
                            transformObjectBeforeSubmit
                          }
                        />
                        {separatePeerAndDirectReportFeedback &&
                          !!directReportFeedbackGivers?.length &&
                          !!nonManagerDirectReportRelationshipsWithFeedback?.length && (
                            <PageSection
                              id="perf-profile-feedback-from-direct-reports"
                              title={formatMessage({
                                id: 'app.views.person.person_performance.title.feedback_from_direct_reports',
                                defaultMessage: 'Feedback from direct reports',
                              })}
                              titleIcon="user"
                              titleIconColor="text-success"
                              widths={[{ md: 12 }, { md: 12 }, { md: 12 }]}
                              rightWidget={
                                <FeedbackProviderListWidget
                                  people={directReportFeedbackGivers}
                                />
                              }
                            >
                              {upwardManagerEvaluationQuestions.filter(
                                (q) =>
                                  q.name === 'positive_comments' ||
                                  q.name === 'negative_comments'
                              ).length > 0 && (
                                <Row>
                                  <PerformanceFeedbackSection
                                    title={formatMessage({
                                      id: 'app.views.person.person_performance.title.strengths',
                                      defaultMessage: 'Strengths',
                                    })}
                                    subtitle={replaceCampaignQuestionText(
                                      upwardManagerEvaluationQuestions.filter(
                                        (q) => q.name === 'positive_comments'
                                      )[0]?.label,
                                      personPreferredName,
                                      campaign,
                                      props.currentOrganization,
                                      formatMessage
                                    )}
                                    relationships={nonManagerDirectReportRelationshipsWithFeedback?.map(
                                      (r, index) => ({
                                        created_at: r.created_at,
                                        onEditRelationship:
                                          !reportHasBeenReleased &&
                                          peopleIdsAreEqual(
                                            props.meId,
                                            r.from_person?.id
                                          )
                                            ? () => editRelationship(r)
                                            : undefined,
                                        type: r.type,
                                        person: r.from_person
                                          ? r.from_person
                                          : getUnattributedPerson(
                                              formatMessage,
                                              index,
                                              r.type
                                            ),
                                        skills: r.positive_skills,
                                        comments: r.positive_comments,
                                      })
                                    )}
                                  />
                                  <PerformanceFeedbackSection
                                    title={formatMessage({
                                      id: 'app.views.person.person_performance.title.areas_of_growth',
                                      defaultMessage: 'Areas of growth',
                                    })}
                                    subtitle={replaceCampaignQuestionText(
                                      upwardManagerEvaluationQuestions.filter(
                                        (q) => q.name === 'negative_comments'
                                      )[0]?.label,
                                      personPreferredName,
                                      campaign,
                                      props.currentOrganization,
                                      formatMessage
                                    )}
                                    relationships={nonManagerDirectReportRelationshipsWithFeedback?.map(
                                      (r, index) => ({
                                        created_at: r.created_at,
                                        onEditRelationship:
                                          !reportHasBeenReleased &&
                                          peopleIdsAreEqual(
                                            props.meId,
                                            r.from_person?.id
                                          )
                                            ? () => editRelationship(r)
                                            : undefined,
                                        type: r.type,
                                        person: r.from_person
                                          ? r.from_person
                                          : getUnattributedPerson(
                                              formatMessage,
                                              index,
                                              r.type
                                            ),
                                        skills: r.negative_skills,
                                        comments: r.negative_comments,
                                      })
                                    )}
                                  />
                                </Row>
                              )}
                              <PerformanceWrittenMultiResponses
                                person={person}
                                questions={upwardManagerWrittenResponses}
                              />
                            </PageSection>
                          )}

                        {separatePeerAndDirectReportFeedback &&
                          !!nonManagerPeerFeedbackGivers?.length &&
                          !!nonManagerPeerRelationshipsWithFeedback?.length && (
                            <PageSection
                              id="perf-profile-feedback-from-peers"
                              title={formatMessage({
                                id: 'app.views.person.person_performance.title.feedback_from_peers',
                                defaultMessage: 'Feedback from peers',
                              })}
                              titleIcon="user"
                              titleIconColor="text-success"
                              widths={[{ md: 12 }, { md: 12 }, { md: 12 }]}
                              rightWidget={
                                <FeedbackProviderListWidget
                                  people={nonManagerPeerFeedbackGivers}
                                />
                              }
                            >
                              {peerEvaluationQuestions.filter(
                                (q) =>
                                  q.name === 'positive_comments' ||
                                  q.name === 'negative_comments'
                              ).length > 0 && (
                                <Row>
                                  <PerformanceFeedbackSection
                                    title={formatMessage({
                                      id: 'app.views.person.person_performance.title.strengths',
                                      defaultMessage: 'Strengths',
                                    })}
                                    subtitle={replaceCampaignQuestionText(
                                      peerEvaluationQuestions.filter(
                                        (q) => q.name === 'positive_comments'
                                      )[0]?.label,
                                      personPreferredName,
                                      campaign,
                                      props.currentOrganization,
                                      formatMessage
                                    )}
                                    relationships={nonManagerPeerRelationshipsWithFeedback?.map(
                                      (r, index) => ({
                                        created_at: r.created_at,
                                        onEditRelationship:
                                          !reportHasBeenReleased &&
                                          peopleIdsAreEqual(
                                            props.meId,
                                            r.from_person?.id
                                          )
                                            ? () => editRelationship(r)
                                            : undefined,
                                        type: r.type,
                                        person: r.from_person
                                          ? r.from_person
                                          : getUnattributedPerson(
                                              formatMessage,
                                              index,
                                              r.type
                                            ),
                                        skills: r.positive_skills,
                                        comments: r.positive_comments,
                                      })
                                    )}
                                  />
                                  <PerformanceFeedbackSection
                                    title={formatMessage({
                                      id: 'app.views.person.person_performance.title.areas_of_growth',
                                      defaultMessage: 'Areas of growth',
                                    })}
                                    subtitle={replaceCampaignQuestionText(
                                      peerEvaluationQuestions.filter(
                                        (q) => q.name === 'negative_comments'
                                      )[0]?.label,
                                      personPreferredName,
                                      campaign,
                                      props.currentOrganization,
                                      formatMessage
                                    )}
                                    relationships={nonManagerPeerRelationshipsWithFeedback?.map(
                                      (r, index) => ({
                                        created_at: r.created_at,
                                        onEditRelationship:
                                          !reportHasBeenReleased &&
                                          peopleIdsAreEqual(
                                            props.meId,
                                            r.from_person?.id
                                          )
                                            ? () => editRelationship(r)
                                            : undefined,
                                        type: r.type,
                                        person: r.from_person
                                          ? r.from_person
                                          : getUnattributedPerson(
                                              formatMessage,
                                              index,
                                              r.type
                                            ),
                                        skills: r.negative_skills,
                                        comments: r.negative_comments,
                                      })
                                    )}
                                  />
                                </Row>
                              )}
                              <PerformanceWrittenMultiResponses
                                person={person}
                                questions={peerWrittenResponses}
                              />
                            </PageSection>
                          )}

                        {!separatePeerAndDirectReportFeedback &&
                          showPerformanceFeedbackFromManagerAndOthers &&
                          hasOthersPhase &&
                          !!nonManagerFeedbackGivers?.length &&
                          !!nonManagerRelationshipsWithFeedback?.length && (
                            <>
                              <PageSection
                                id="perf-profile-feedback-from-others"
                                title={formatMessage({
                                  id: 'app.views.person.person_performance.title.feedback_from_others',
                                  defaultMessage: 'Feedback from others',
                                })}
                                titleIcon="user"
                                titleIconColor="text-success"
                                widths={[{ md: 12 }, { md: 12 }, { md: 12 }]}
                                rightWidget={
                                  <FeedbackProviderListWidget
                                    people={nonManagerFeedbackGivers}
                                  />
                                }
                              >
                                {nonManagerEvaluationQuestions.filter(
                                  (q) =>
                                    q.name === 'positive_comments' ||
                                    q.name === 'negative_comments'
                                ).length > 0 && (
                                  <Row>
                                    <PerformanceFeedbackSection
                                      title={formatMessage({
                                        id: 'app.views.person.person_performance.title.strengths',
                                        defaultMessage: 'Strengths',
                                      })}
                                      relationships={nonManagerRelationshipsWithFeedback?.map(
                                        (r, index) => ({
                                          created_at: r.created_at,
                                          onEditRelationship:
                                            !reportHasBeenReleased &&
                                            peopleIdsAreEqual(
                                              props.meId,
                                              r.from_person?.id
                                            )
                                              ? () => editRelationship(r)
                                              : undefined,
                                          type: r.type,
                                          person: r.from_person
                                            ? r.from_person
                                            : getUnattributedPerson(
                                                formatMessage,
                                                index,
                                                r.type
                                              ),
                                          skills: r.positive_skills,
                                          comments: r.positive_comments,
                                          rating: r.rating,
                                          rating_comments: r.rating_comments,
                                        })
                                      )}
                                    />
                                    <PerformanceFeedbackSection
                                      title={formatMessage({
                                        id: 'app.views.person.person_performance.title.areas_of_growth',
                                        defaultMessage: 'Areas of growth',
                                      })}
                                      relationships={nonManagerRelationshipsWithFeedback?.map(
                                        (r, index) => ({
                                          created_at: r.created_at,
                                          onEditRelationship:
                                            !reportHasBeenReleased &&
                                            peopleIdsAreEqual(
                                              props.meId,
                                              r.from_person?.id
                                            )
                                              ? () => editRelationship(r)
                                              : undefined,
                                          type: r.type,
                                          person: r.from_person
                                            ? r.from_person
                                            : getUnattributedPerson(
                                                formatMessage,
                                                index,
                                                r.type
                                              ),
                                          skills: r.negative_skills,
                                          comments: r.negative_comments,
                                        })
                                      )}
                                    />
                                  </Row>
                                )}
                                <PerformanceWrittenMultiResponses
                                  person={person}
                                  questions={nonManagerWrittenResponse}
                                />
                              </PageSection>
                            </>
                          )}
                      </>
                    )}
                    {selfWrittenResponses?.length > 0 && (
                      <PageSection
                        id="perf-profile-self-reflection"
                        title={formatMessage({
                          id: 'app.views.person.person_performance.title.self_reflection',
                          defaultMessage: 'Self-reflection',
                        })}
                        titleIcon="user"
                        titleIconColor="text-success"
                        widths={[{ md: 12 }, { md: 12 }, { md: 12 }]}
                        rightWidget={
                          <FeedbackProviderListWidget people={[person]} />
                        }
                      >
                        <PerformanceWrittenResponses
                          person={person}
                          questions={selfWrittenResponses}
                        />
                      </PageSection>
                    )}

                    {showObjectives && (
                      <>
                        <PageSection
                          id="perf-profile-objectives"
                          title={formatMessage({
                            id: 'app.views.person.person_performance.title.objectives',
                            defaultMessage: 'Objectives',
                          })}
                          titleIcon="user"
                          titleIconColor="text-success"
                          widths={[{ md: 12 }, { md: 12 }, { md: 12 }]}
                          rightWidget={
                            <FeedbackProviderListWidget people={[person]} />
                          }
                        >
                          <PersonalObjectives
                            demoObjectives={
                              isDemoOrPreviewMode ? demoObjectives : undefined
                            }
                            isDemoOrPreviewMode={isDemoOrPreviewMode}
                            showTeamNav={false}
                            person={person}
                            readOnly={true}
                            // @ts-expect-error
                            defaultTimeFrame={selectedTimeFrameForObjectives}
                            className="col"
                          />
                        </PageSection>
                      </>
                    )}

                    {!hideTimelineActivities && (
                      <>
                        <PageSection
                          id="perf-profile-impactful-activities"
                          title={formatMessage({
                            id: 'app.views.person.person_performance.title.impactful_activities',
                            defaultMessage: 'Impactful activities',
                          })}
                          titleIcon="activity"
                          titleIconColor="text-warning"
                          rightWidget={
                            <FeedbackProviderListWidget
                              // TODO: Riccardo: this was the original code from V1.
                              // The existing code couldn't work, so I restored the prev version
                              people={
                                hasContributionFeedbackEnabled
                                  ? [person, ...(feedbackGivers ?? [])]
                                  : [person]
                              }
                            />
                          }
                        >
                          <Card>
                            <CardBody>
                              <PersonTimelineActivities
                                aggregateCampaignActivities={true}
                                surveyResponse={surveyResponse}
                                activities={
                                  isDemoOrPreviewMode
                                    ? surveyResponse?.highlights
                                    : undefined
                                }
                                collapseActivitiesBeforeDate={
                                  perfCoverageStartDate
                                }
                                showAddToProfileButton={false}
                                person={person}
                                hideActivitiesWithoutFeedbackForFocalPerson={
                                  hideActivitiesWithoutFeedbackForFocalPerson
                                }
                                showFeedback={hasContributionFeedbackEnabled}
                                isUneditablePerfResumeMode={true}
                                showManagerOnlyPerformanceDetails={
                                  props.showManagerOnlyPerformanceDetails
                                }
                                isDemoOrPreviewMode={isDemoOrPreviewMode}
                              />
                            </CardBody>
                          </Card>
                        </PageSection>
                      </>
                    )}

                    {showFeedbackRecognitionAndNotes && (
                      <PageSection
                        id="perf-profile-feedback-and-recognition"
                        title={
                          showCriticalFeedback
                            ? formatMessage({
                                id: 'app.views.person.person_performance.title.feedback_and_recognition_with_critical',
                                defaultMessage:
                                  'Continuous feedback, recognition, and your notes',
                              })
                            : formatMessage({
                                id: 'app.views.person.person_performance.title.feedback_and_recognition',
                                defaultMessage: 'Recognition and your notes',
                              })
                        }
                        titleIcon="user"
                        rightWidget={
                          <FeedbackProviderListWidget
                            people={filterUniqueById(
                              feedbackList?.map((f) => f.author_person)
                            )}
                          />
                        }
                      >
                        <Card>
                          <CardBody>
                            <PersonFeedbackList
                              isPerformanceReview={true}
                              person={person}
                              feedbackList={feedbackList}
                              hideActionableFeedback={!showCriticalFeedback}
                              errorMessage={feedbackErrorMessage}
                              isAdminable={props.isAdminable}
                              showManagerOnlyPerformanceDetails={
                                props.showManagerOnlyPerformanceDetails
                              }
                              showSentFeedback={false}
                              isDemoOrPreviewMode={isDemoOrPreviewMode}
                            />
                          </CardBody>
                        </Card>
                      </PageSection>
                    )}
                    {showDeclinedAndUnresponsivePeers &&
                      !!declinedOrUnresponsivePeerRelationshipsWithFeedback?.length && (
                        <>
                          <div className="header mb-4">
                            <div className="header-body">
                              <div className="row">
                                <div className="col">
                                  <h2 className="header-title">
                                    <FormattedMessage
                                      id="app.views.person.person_performance.declined_or_unresponsive_peers"
                                      defaultMessage="Declined or unresponsive peers"
                                    />
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <div className="mb-4">
                                <FormattedMessage
                                  id="app.views.person.person_performance.assigned_but_did_not"
                                  defaultMessage="The following peers were assigned to review {personPreferredName} but did not."
                                  values={{
                                    personPreferredName: personPreferredName,
                                  }}
                                />
                              </div>
                              <div className="card">
                                <table className="table table-sm card-table">
                                  <thead>
                                    <tr>
                                      <th scope="col" style={{ width: '35%' }}>
                                        <FormattedMessage
                                          id="app.views.person.person_performance.team_member"
                                          defaultMessage="Team member"
                                        />
                                      </th>
                                      <th
                                        scope="col"
                                        className=""
                                        style={{ width: '65%' }}
                                      >
                                        <FormattedMessage
                                          id="app.views.person.person_performance.reason_given"
                                          defaultMessage="Reason given"
                                        />
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="fs-base">
                                    {declinedOrUnresponsivePeerRelationshipsWithFeedback.map(
                                      (r, index) => {
                                        const p = r.from_person;
                                        const declineReason =
                                          getDeclineReasonText(
                                            r,
                                            personPreferredName,
                                            formatMessage
                                          );

                                        return (
                                          <tr key={index}>
                                            <th scope="row">
                                              <div className="flex-nowrap row align-items-center">
                                                <div className="col-auto">
                                                  <Avatar
                                                    size="sm"
                                                    person={p}
                                                  />
                                                </div>
                                                <div className="col">
                                                  <div className="p-0 row">
                                                    <div className="ms-n3 col">
                                                      <h4 className="m-0 text-dark">
                                                        <Link
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          to={p.url}
                                                        >
                                                          {p.full_name}
                                                        </Link>
                                                      </h4>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </th>
                                            <td className="">
                                              {declineReason ? (
                                                declineReason
                                              ) : (
                                                <span className="text-muted">
                                                  <FormattedMessage
                                                    id="app.views.person.person_performance.did_not_complete_review"
                                                    defaultMessage="Did not complete review"
                                                  />
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    {isMe && (
                      <PageSection
                        id="perf-profile-person-network"
                        title={formatMessage(
                          {
                            id: 'app.views.person.person_performance.title.person_network',
                            defaultMessage: "{personPreferredName}'s network",
                          },
                          { personPreferredName }
                        )}
                        titleIcon="activity"
                        titleIconColor="text-warning"
                        rightWidget={
                          <FeedbackProviderListWidget people={[person]} />
                        }
                      >
                        <PerformanceNetworkResponse
                          questions={networkResponses}
                        />
                      </PageSection>
                    )}
                  </>
                )}
            </>
          );
        }}
      </PersonPerformanceContextConsumer>
    </PersonPerformanceContextProvider>
  );
};

const mapStateToProps = (state) => {
  const { me, currentProxyPerson, currentOrganization, features } = state;

  return {
    meId: me?.id,
    currentProxyPerson,
    currentOrganization,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(PersonPerformance));
