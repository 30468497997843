import React, { useContext } from 'react';

import { Organization } from '../../../../types';
import PerformanceHistoryChart from './HistoryChart';
import { PerformanceHistoryData } from './HistoryChartUtils';
import PersonPerformanceContext from '../PersonPerformanceContext';
import { useIntl } from 'react-intl';

interface ManagerRatingHistoryChartProps {
  person?: {
    id: number;
    given_name: string;
  };
  showManagerOnlyPerformanceDetails: boolean;
  performanceData?: PerformanceHistoryData[];
  organization: Organization;
}

const ManagerRatingHistoryChart: React.FC<ManagerRatingHistoryChartProps> = ({
  person,
  showManagerOnlyPerformanceDetails,
  performanceData,
  organization,
}) => {
  const { formatMessage } = useIntl();
  const ctx = useContext(PersonPerformanceContext);

  return (
    <PerformanceHistoryChart
      key={`chart_history_ratings_${person?.id}`}
      personId={person?.id}
      title={formatMessage(
        {
          id: 'app.views.person.person_performance.history.persons_manager_ratings',
          defaultMessage: 'Manager ratings over time',
        },
        {
          firstName: ctx.firstName,
        }
      )}
      firstName={ctx.firstName}
      data={ctx.managerRatingHistoricalData?.chartData ?? {}}
      data2={performanceData}
      organization={organization}
      options={ctx.managerRatingHistoricalData?.chartOptions ?? {}}
      individualAlerts={
        ctx.managerRatingHistoricalData?.dataValues.ratings.alerts
      }
      cycleDatapoints={ctx.managerRatingHistoricalData?.ratingValues.cycles}
      name={ctx.firstName}
      managerView={showManagerOnlyPerformanceDetails}
      chartType="rating"
    />
  );
};

export default ManagerRatingHistoryChart;
