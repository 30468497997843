import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useMemo } from 'react';

import Avatar from 'views/Widgets/People/Avatar';
import HideFromRecipientDescriptor from 'views/Widgets/Inputs/HideFromRecipientDescriptor';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import { Person } from 'types';
import RichTextViewer from 'views/Widgets/Inputs/RichTextViewer';
import { WrittenResponse } from './utils';
import { parseAndFormatQuestionResponse } from 'utils/models/Performance';

export interface Props {
  person: Person;
  question: WrittenResponse;
}

const PerformanceWrittenResponse: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const anonymizedResponseElement = (
    <span className="fst-italic">
      <FormattedMessage
        id="app.views.person.person_performance.question_anonymous_no_responses"
        defaultMessage="This question is confidential so responses are not shown."
      />
    </span>
  );

  const q = useMemo(() => props.question, [props.question]);

  if (q.question?.type?.toUpperCase() === INPUT_TYPES.SECTION) {
    return (
      <h3 className="header-title mb-3">
        <RichTextViewer model={q.question.label} expanded={true} />
      </h3>
    );
  }

  return (
    <Card>
      <CardBody>
        <h4 className="mb-3">
          <HideFromRecipientDescriptor {...q.question}>
            <RichTextViewer model={q.title} expanded={true} />
          </HideFromRecipientDescriptor>
        </h4>
        <Row className="flex-nowrap">
          <Col className="col-auto">
            <Avatar size="sm" person={props.person} />
          </Col>
          <Col className="ms-n3">
            <div>
              <div
                className="comment-body d-block px-3"
                style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
              >
                <Row>
                  <Col>
                    <div className="mb-0 py-1">
                      <div>
                        <div className="fr-view">
                          <div className="">
                            {q.anonymized
                              ? anonymizedResponseElement
                              : parseAndFormatQuestionResponse({
                                  q,
                                  locale,
                                  formatMessage,
                                })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PerformanceWrittenResponse;
