import React, { PropsWithChildren, useCallback, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

export interface CampaignReportsContextType {
  campaignId: number;
  hasEngagementSurvey: boolean;
  engagementSurveyListVersion: string;
  refreshEngagementSurveyListVersion: () => void;
}
const CampaignReportsContext =
  React.createContext<CampaignReportsContextType | null>(null);

type CampaignReportsProviderProps = PropsWithChildren<{
  campaignId: number;
  hasEngagementSurvey: boolean;
}>;

const CampaignReportsProvider = ({
  campaignId,
  hasEngagementSurvey,
  children,
}: CampaignReportsProviderProps) => {
  const [engagementSurveyListVersion, setEngagementSurveyListVersion] =
    useState(uuidv4());

  const refreshEngagementSurveyListVersion = useCallback(
    () => setEngagementSurveyListVersion(uuidv4()),
    []
  );

  const value: CampaignReportsContextType = React.useMemo(
    () => ({
      campaignId,
      engagementSurveyListVersion,
      hasEngagementSurvey,
      refreshEngagementSurveyListVersion,
    }),
    [
      campaignId,
      hasEngagementSurvey,
      engagementSurveyListVersion,
      refreshEngagementSurveyListVersion,
    ]
  );

  return (
    <CampaignReportsContext.Provider value={value}>
      {children}
    </CampaignReportsContext.Provider>
  );
};

const useCampaignReports = (): CampaignReportsContextType => {
  const context = React.useContext(CampaignReportsContext);
  if (!context) {
    throw new Error(
      'useCampaignReports must be used within a CampaignReportsProvider'
    );
  }
  return context;
};

export { CampaignReportsProvider, useCampaignReports };
