import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';

interface Props {
  isDemoMode: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any;
}

const IncludeExcludeFilterDescriptor: FC<Props> = (props: Props) => {
  const filterDescription = (filter) => {
    if (filter._index.startsWith('below_')) {
      return filter.description;
    }
    return filter.name;
  };

  function formatFilter(it) {
    return filterDescription(it);
  }

  const hasIncludeFilters =
    props.isDemoMode && (props?.filters?.include ?? []).length !== 0;

  const hasExcludeFilters =
    props.isDemoMode && (props?.filters?.exclude ?? []).length !== 0;

  if (!hasIncludeFilters && !hasExcludeFilters) {
    return null;
  }

  return (
    <>
      <br />{' '}
      <div
        data-testid="filter-description"
        className="badge bg-light mb-2 text-wrap"
      >
        {hasIncludeFilters && (
          <span>
            <FormattedMessage
              id="app.widgets.people.filters.include_exclude_filter_descriptor.this_question_will_only_show"
              defaultMessage="This question will only show for"
              description="Text for the include filter which is followed by a list"
            />{' '}
            <b>
              {props.filters.include.map(formatFilter).join(', ')}
              {'. '}
            </b>
          </span>
        )}
        <span> </span>
        {hasExcludeFilters && (
          <span>
            <FormattedMessage
              id="app.widgets.people.filters.include_exclude_filter_descriptor.this_question_will_not_show"
              defaultMessage="This question will not show for"
              description="Text for the exclude filter which is followed by a list"
            />{' '}
            <b>
              {props.filters.exclude.map(formatFilter).join(', ')}
              {'.'}
            </b>
          </span>
        )}
      </div>
    </>
  );
};

export default IncludeExcludeFilterDescriptor;
