import React, { FC, useCallback, useRef, useState } from 'react';
import { useEscape, useOutsideClick } from 'utils/util/hooks';

import AvatarGroup from 'views/Widgets/People/AvatarGroup';
import { BasicPerson } from 'types';
import { ICONS } from 'consts/consts';
import PeopleEditor from 'views/Widgets/Forms/PeopleEditor';
import SafeUncontrolledPopover from 'components/SafeUncontrolledPopover';
import { useIntl } from 'react-intl';

const DEFAULT_ON_UPDATE = () => {};

interface Props {
  selectedPeople: BasicPerson[];
  editTitle: string;
  readonly?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  onUpdate: (value: BasicPerson[]) => void;
}

const PeopleEditorOverlay: FC<Props> = ({
  selectedPeople,
  editTitle,
  readonly = false,
  size = 'xs',
  onUpdate = DEFAULT_ON_UPDATE,
}) => {
  const avatarsRef = useRef<HTMLButtonElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const toggle = useCallback(
    () => !readonly && setIsPopoverOpen(!isPopoverOpen),
    [readonly, isPopoverOpen]
  );

  const openPopover = useCallback(() => {
    if (!readonly) {
      setIsPopoverOpen(true);
    }
  }, [readonly]);

  const onClose = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);

  return (
    <>
      <span role="button" title={editTitle} ref={avatarsRef}>
        {selectedPeople.length > 0 ? (
          <AvatarGroup
            size={size}
            people={selectedPeople.map((p) => ({
              ...p,
              onClick: openPopover,
            }))}
            unlinked={!readonly}
          />
        ) : (
          !readonly && (
            <div
              className="btn btn-sm btn-rounded-circle btn-light mt-1"
              role="button"
              onClick={openPopover}
            >
              <i className={ICONS.ADD_PERSON} />
            </div>
          )
        )}
      </span>
      <PeersPopover
        target={avatarsRef}
        isOpen={isPopoverOpen}
        onUpdate={onUpdate}
        onClose={onClose}
        toggle={toggle}
        selectedPeople={selectedPeople}
        title={editTitle}
      />
    </>
  );
};

const PeersPopover = ({
  selectedPeople,
  title,
  toggle,
  onUpdate,
  onClose,
  target,
  isOpen,
}) => {
  const { formatMessage } = useIntl();
  const [people, setPeople] = useState(selectedPeople || []);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  useEscape(() => onClose());

  const handleInputChange = useCallback((value) => {
    setIsEditing(!!value);
  }, []);

  useOutsideClick(popoverRef, () => {
    if (!isEditing) {
      toggle();
    }
  });

  const handleCallback = useCallback(
    (value) => {
      setPeople(value);
      onUpdate(value);
    },
    [onUpdate]
  );

  const onBeforeChange = useCallback((_action, _target) => {
    return true;
  }, []);

  return (
    <SafeUncontrolledPopover
      target={target}
      delay={0}
      isOpen={isOpen}
      toggle={toggle}
    >
      <div
        ref={popoverRef}
        role="dialog"
        title={title}
        style={{ minWidth: '300px' }}
      >
        <div className="fw-bold">{title}</div>
        <PeopleEditor
          allowSelf={true}
          placeholder={formatMessage({
            id: 'app.views.administration.campaign_reports.people_editor_overlay.placeholder.enter_names',
            defaultMessage: 'Enter names',
          })}
          value={people}
          callback={handleCallback}
          onInputChange={handleInputChange}
          deliverEmptyInputChange={true}
          propagateOnClearInput={true}
          onBeforeChange={onBeforeChange}
        />
      </div>
    </SafeUncontrolledPopover>
  );
};

export default React.memo(PeopleEditorOverlay);
