import React, { FC, PropsWithChildren } from 'react';

interface Props {
  icon: string;
  iconColor?: string;
  rightWidget?: React.ReactNode;
}

export const PageSectionTitle: FC<PropsWithChildren<Props>> = ({
  icon,
  iconColor,
  children,
  rightWidget,
}) => {
  return (
    <h2 className="header-title d-flex justify-content-between">
      <div>
        <i className={`fe fe-${icon} me-2 ${iconColor}`} />
        {children}
      </div>
      {rightWidget}
    </h2>
  );
};
