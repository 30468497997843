import * as consts from '../../consts/consts';

import { Button, Col, Row } from 'reactstrap';
import { CAMPAIGN_STATUSES, getPhaseByType } from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_WELCOME_HEADER,
  PERFORMANCE_FEATURE_WELCOME_MEDIA,
  PERFORMANCE_FEATURE_WELCOME_MEDIA_CAPTION,
  PERFORMANCE_FEATURE_WELCOME_MESSAGE_HTML,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_OTHERS,
  getCampaignFeature,
  getCurrentPerformancePreviewPathPrefix,
  getDirectReportsEligibleForFeedback,
  getPerformancePhasesDisplayObjects,
  getStepNumber,
  isONAEnabled,
  perfCampaignCallback,
} from '../../utils/models/Performance';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { getPrettyDate, stripHtml } from '../../utils/util/util';
import { useHistory, useLocation, withRouter } from 'react-router';

import AngryPeopleImage from '../../assets/img/illustrations/people-angry.png';
import BackButtonLink from 'views/Widgets/Buttons/BackButtonLink';
import GroupResumeCreationImg from '../../assets/img/illustrations/group-resume-creation.png';
import HappyPeopleImage from '../../assets/img/illustrations/people-happy.png';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import Media from '../../components/media/Media';
import PerformancePage from './PerformancePage';
import PerformancePhases from './PerformancePhases';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import UnhappyPeopleImage from '../../assets/img/illustrations/people-unhappy.png';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import VideoEmbed from '../Widgets/VideoEmbed';
import { connect } from 'react-redux';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { toPersonIdToSurveyResponseLookup } from 'utils/models/SurveyResponse';
import { useCampaignToggles } from 'views/Administration/CampaignParticipants/hooks';

const PerformanceStepWelcome: FC<Props> = (props) => {
  const [isOnFirstPage, setIsOnFirstPage] = useState(true);
  const [selectedFeelingOption, setSelectedFeelingOption] = useState(
    // @ts-expect-error
    typeof props.currentPerfSurveyResponse?.responses?.feeling_start ===
      'undefined'
      ? -1
      : // @ts-expect-error
        props.currentPerfSurveyResponse?.responses?.feeling_start
  );

  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const feelingOptions = useMemo(
    () => [
      {
        id: 3,
        image: HappyPeopleImage,
        input: intl.formatMessage({
          id: 'app.views.performance.performance_step_welcome.input_3',
          defaultMessage: 'Good',
          description:
            'Response option to how are you feeling about doing this perf cycle',
        }),
        output: intl.formatMessage({
          id: 'app.views.performance.performance_step_welcome.output_3',
          defaultMessage:
            "We're glad to hear it, and we're here to help if you hit any snags.",
          description: 'Shows after user selects option for how they feel',
        }),
      },
      {
        id: 2,
        image: UnhappyPeopleImage,
        input: intl.formatMessage({
          id: 'app.views.performance.performance_step_welcome.input_2',
          defaultMessage: 'Okay',
          description:
            'Response option to how are you feeling about doing this perf cycle',
        }),
        output: intl.formatMessage({
          id: 'app.views.performance.performance_step_welcome.output_2',
          defaultMessage:
            "We understand. We'll do our best so it's a meaningful use of your time.",
          description: 'Shows after user selects option for how they feel',
        }),
      },
      {
        id: 1,
        image: AngryPeopleImage,
        input: intl.formatMessage({
          id: 'app.views.performance.performance_step_welcome.input_1',
          defaultMessage: "Don't ask",
          description:
            'Response option to how are you feeling about doing this perf cycle',
        }),
        output: intl.formatMessage({
          id: 'app.views.performance.performance_step_welcome.output_1',
          defaultMessage:
            "We've been there. We'll do our best to make it worthwhile.",
          description: 'Shows after user selects option for how they feel',
        }),
      },
    ],
    [intl]
  );

  const campaign = props.campaign;

  const {
    evaluationPhaseIsEnabled,
    engagementSurveyIsEnabled,
    npsIsEnabled,
    peer360sAreEnabled,
    upwardFeedbackisEnabled,
    // @ts-expect-error
  } = useCampaignToggles(campaign);

  const phaseOtherIsEnabled =
    peer360sAreEnabled ||
    upwardFeedbackisEnabled ||
    engagementSurveyIsEnabled ||
    npsIsEnabled;
  const phaseEvaluationIsEnabled = evaluationPhaseIsEnabled;

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const callback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(data);
        perfCampaignCallback(
          props.me,
          props.currentOrganization,
          campaign,
          props.currentPerfSurveyResponse,
          history,
          props.demoPeople,
          data,
          formatMessage
        );
      }
    },
    [
      history,
      props.currentOrganization,
      campaign,
      props.currentPerfSurveyResponse,
      props.demoPeople,
      props.me,
      propsSetCurrentPerfSurveyResponse,
      formatMessage,
    ]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        id: object.id,
        // @ts-expect-error
        campaign: campaign?.id,
        step: getStepNumber(
          props.me,
          props.currentOrganization,
          campaign,
          props.currentPerfSurveyResponse,
          props.demoPeople,
          location.pathname,
          formatMessage
        ),
        responses: {
          feeling_start: object.feeling_start,
        },
      };
    },
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.currentPerfSurveyResponse,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const object = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? // @ts-expect-error
          props.currentPerfSurveyResponse.id
        : undefined,
      feeling_start: selectedFeelingOption,
    }),
    [props.currentPerfSurveyResponse, selectedFeelingOption]
  );

  // hide submit button if option isn't selected
  const renderForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          {inputs}
          {object.feeling_start !== -1 && submitButton}
        </>
      );
    },
    [object.feeling_start]
  );

  const renderInputs = useCallback(
    (inputs) => {
      return (
        <>
          {inputs}
          <Row className="mt-4">
            <Col>
              {
                feelingOptions.find((o) => o.id === object.feeling_start)
                  ?.output
              }
            </Col>
          </Row>
        </>
      );
    },
    [feelingOptions, object.feeling_start]
  );

  const onChange = useCallback((o) => {
    setSelectedFeelingOption(o.feeling_start);
  }, []);

  const selfPhaseStartDate = useMemo(
    // @ts-expect-error
    () => new Date(campaign?.phases[0]?.start_date),
    // @ts-expect-error
    [campaign?.phases]
  );

  const perfIsOpen = useMemo(
    () =>
      // @ts-expect-error
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      selfPhaseStartDate <= new Date(),
    // @ts-expect-error
    [selfPhaseStartDate, campaign?.status]
  );

  const otherPhaseStartDate = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_OTHERS)?.start_date,
    [campaign]
  );

  const perfIsPhaseOthersOpen = useMemo(
    () =>
      // @ts-expect-error
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      (!!otherPhaseStartDate && new Date(otherPhaseStartDate) <= new Date()),
    // @ts-expect-error
    [otherPhaseStartDate, campaign?.status]
  );

  const evaluationPhaseStartDate = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_EVALUATION)?.start_date,
    [campaign]
  );

  const perfIsPhaseEvaluationOpen = useMemo(
    () =>
      // @ts-expect-error
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      (!!evaluationPhaseStartDate &&
        new Date(evaluationPhaseStartDate) <= new Date()),
    // @ts-expect-error
    [evaluationPhaseStartDate, campaign?.status]
  );

  const visiblePhaseCount = useMemo(
    // @ts-expect-error
    () => getPerformancePhasesDisplayObjects(campaign?.phases)?.length,
    // @ts-expect-error
    [campaign?.phases]
  );

  const customWelcomeMessageHeader = useMemo(
    () => getCampaignFeature(campaign, PERFORMANCE_FEATURE_WELCOME_HEADER),
    [campaign]
  );

  const customWelcomeMessageHtml = useMemo(
    () =>
      getCampaignFeature(campaign, PERFORMANCE_FEATURE_WELCOME_MESSAGE_HTML),
    [campaign]
  );

  const customWelcomeMedia = useMemo(
    () => getCampaignFeature(campaign, PERFORMANCE_FEATURE_WELCOME_MEDIA),
    [campaign]
  );

  const customWelcomeMediaCaption = useMemo(() => {
    const caption = getCampaignFeature(
      campaign,
      PERFORMANCE_FEATURE_WELCOME_MEDIA_CAPTION
    );

    // if caption is an empty string or all whitespace html, treat as undefined
    if (!stripHtml(caption)) {
      return undefined;
    }

    return caption;
  }, [campaign]);

  const hasDirectReports = useMemo(
    () =>
      campaign &&
      getDirectReportsEligibleForFeedback(
        props.me,
        campaign,
        // @ts-expect-error
        campaign?.relationships,
        // @ts-expect-error
        toPersonIdToSurveyResponseLookup(campaign?.survey_responses),
        props.demoPeople
      )?.length > 0,
    [campaign, props.demoPeople, props.me]
  );

  const isEligibleForSelfPhase = useMemo(() => {
    // we check for false explicitly as undefined should mean true (i.e. the dataset
    // doesnt explicitly limit the person from the self phase)
    if (
      // @ts-expect-error
      props.currentPerfSurveyResponse?.configs?.is_participating === false ||
      // @ts-expect-error
      props.currentPerfSurveyResponse?.configs
        ?.is_participating_in_self_phase === false
    ) {
      return false;
    }
    return true;
  }, [
    // @ts-expect-error
    props.currentPerfSurveyResponse?.configs?.is_participating,
    // @ts-expect-error
    props.currentPerfSurveyResponse?.configs?.is_participating_in_self_phase,
  ]);

  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();
  const backButtonLinkCallback = useCallback(() => setIsOnFirstPage(true), []);

  return (
    <PerformancePage
      campaign={campaign}
      title={
        customWelcomeMessageHeader
          ? customWelcomeMessageHeader
          : formatMessage(
              {
                id: 'app.views.performance.performance_step_welcome.welcome_to_orgs_campaign_generic',
                defaultMessage: "Welcome to {organization}'s {campaign}!",
              },
              {
                // @ts-expect-error
                organization: props.currentOrganization.name,
                // @ts-expect-error
                campaign: campaign.name,
              }
            )
      }
      // For card buttons, that's enough contrast so only include the
      // first page content in a card
      wrapBodyInCard={isOnFirstPage}
    >
      <>
        {!isOnFirstPage && (
          <>
            <BackButtonLink onClick={backButtonLinkCallback} />
            <span className="text-muted mx-3">
              <FormattedMessage
                id="app.views.performance.performance_step_welcome.dot_between_links"
                defaultMessage="∙"
              />
            </span>
          </>
        )}
        {!isOnFirstPage && (
          <span className="text-muted">
            <FormattedMessage
              id="app.views.performance.performance_step_welcome.step_2_of_2"
              defaultMessage="Step 2 of 2"
            />
          </span>
        )}
      </>
      {isOnFirstPage && (
        <Row>
          <Col className="order-2 order-md-1">
            <div className="mb-4">
              {customWelcomeMessageHtml && (
                <RichTextViewer
                  model={customWelcomeMessageHtml}
                  expanded={true}
                />
              )}
              {!customWelcomeMessageHtml && (
                <>
                  {/* @ts-expect-error */}
                  {campaign.phases?.length === 1 && (
                    <>
                      <FormattedMessage
                        id="app.views.performance.performance_step_welcome.welcome_single_phase"
                        defaultMessage="
                      This process is designed to provide visibility and
                      identify growth opportunities for everyone at {orgName} by reflecting on your
                      work and those around you.
                    "
                        // @ts-expect-error
                        values={{ orgName: props.currentOrganization.name }}
                      />
                    </>
                  )}
                  {/* @ts-expect-error */}
                  {campaign.phases?.length !== 1 && (
                    <>
                      <FormattedMessage
                        id="app.views.performance.performance_step_welcome.welcome_multiple_phases"
                        defaultMessage="
                      This process is designed to create clarity on performance
                      and growth for everyone at {orgName} by reflecting on your
                      work and those around you.
                    "
                        // @ts-expect-error
                        values={{ orgName: props.currentOrganization.name }}
                      />
                    </>
                  )}
                  {visiblePhaseCount > 1 && (
                    <>
                      {' '}
                      <FormattedMessage
                        id="app.views.performance.performance_step_welcome.divided"
                        defaultMessage="It's divided into {visiblePhaseCount} parts:"
                        values={{ visiblePhaseCount: visiblePhaseCount }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <PerformancePhases campaign={campaign} stepsComplete={0} />
            {isOnFirstPage && (
              <>
                {isEligibleForSelfPhase && (
                  <>
                    {!perfIsOpen && (
                      <span>
                        <Button color="primary" disabled={true}>
                          <FormattedMessage
                            id="app.views.performance.performance_step_welcome.continue"
                            defaultMessage="Continue"
                          />
                        </Button>
                        <span className="text-muted ms-3">
                          <FormattedMessage
                            id="app.views.performance.performance_step_welcome.phase_one_starts_on"
                            defaultMessage="Phase I starts on {date}."
                            values={{
                              date: getPrettyDate({
                                dateString: selfPhaseStartDate,
                                locale,
                              }),
                            }}
                          />
                        </span>
                      </span>
                    )}
                    {perfIsOpen && (
                      <Button
                        autoFocus
                        tabIndex={0}
                        color="primary"
                        onClick={() => setIsOnFirstPage(false)}
                      >
                        <FormattedMessage
                          id="app.views.performance.performance_step_welcome.continue"
                          defaultMessage="Continue"
                        />
                      </Button>
                    )}
                  </>
                )}
                {!isEligibleForSelfPhase && (
                  <>
                    {phaseOtherIsEnabled && (
                      <>
                        {!perfIsPhaseOthersOpen && (
                          <span>
                            <Button color="primary" disabled={true}>
                              <FormattedMessage
                                id="app.views.performance.performance_step_welcome.continue"
                                defaultMessage="Continue"
                              />
                            </Button>
                          </span>
                        )}
                        {perfIsPhaseOthersOpen && (
                          <Button
                            color="primary"
                            onClick={() => {
                              history.push(
                                previewPathPrefix +
                                  consts.PERFORMANCE_STEP_OTHERS_PHASE_WELCOME(
                                    formatMessage
                                  ).path
                              );
                            }}
                          >
                            <FormattedMessage
                              id="app.views.performance.performance_step_welcome.continue"
                              defaultMessage="Continue"
                            />
                          </Button>
                        )}
                      </>
                    )}
                    {!phaseOtherIsEnabled && phaseEvaluationIsEnabled && (
                      <>
                        {!hasDirectReports && (
                          <span className="text-muted">
                            <FormattedMessage
                              id="app.views.performance.performance_step_welcome.not_eligible"
                              defaultMessage="
                        You are not eligible to participate in this cycle. If
                        you believe this is a mistake, please contact your HR
                        representative to fix this.
                      "
                            />
                          </span>
                        )}
                        {hasDirectReports && (
                          <>
                            {!perfIsPhaseEvaluationOpen && (
                              <span>
                                <Button color="primary" disabled={true}>
                                  <FormattedMessage
                                    id="app.views.performance.performance_step_welcome.continue"
                                    defaultMessage="Continue"
                                  />
                                </Button>
                              </span>
                            )}
                            {perfIsPhaseEvaluationOpen && (
                              <Button
                                color="primary"
                                onClick={() => {
                                  history.push(
                                    previewPathPrefix +
                                      consts.PERFORMANCE_STEP_EVALUATION_PHASE_WELCOME(
                                        formatMessage
                                      ).path
                                  );
                                }}
                              >
                                <FormattedMessage
                                  id="app.views.performance.performance_step_welcome.continue"
                                  defaultMessage="Continue"
                                />
                              </Button>
                            )}
                            <span className="text-muted ms-3">
                              <FormattedMessage
                                id="app.views.performance.performance_step_welcome.eligible_manager_only"
                                defaultMessage="You are only eligible for the manager evaluation phase this cycle."
                              />
                            </span>
                          </>
                        )}
                      </>
                    )}
                    {!phaseOtherIsEnabled && !phaseEvaluationIsEnabled && (
                      <span>
                        <Button color="primary" disabled={true}>
                          <FormattedMessage
                            id="app.views.performance.performance_step_welcome.continue"
                            defaultMessage="Continue"
                          />
                        </Button>
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
          <Col className="col-12 col-md-6 order-1 order-md-2 pb-4 pb-md-0">
            {customWelcomeMedia && <Media link={customWelcomeMedia} />}
            {!customWelcomeMedia && isONAEnabled(campaign) && (
              <VideoEmbed
                videoId="852377241"
                description={
                  customWelcomeMediaCaption ?? (
                    <FormattedMessage
                      id="app.views.performance.performance_step_welcome.watch_to_learn_more"
                      defaultMessage="Watch to learn more about Confirm's research-backed methodology."
                    />
                  )
                }
              />
            )}
            {!customWelcomeMedia && !isONAEnabled(campaign) && (
              <img src={GroupResumeCreationImg} className="w-100 pe-5" />
            )}
            {!!customWelcomeMediaCaption &&
              (!!customWelcomeMedia || !isONAEnabled(campaign)) && (
                <div className="text-center text-muted mt-3">
                  <RichTextViewer
                    model={customWelcomeMediaCaption}
                    expanded={true}
                  />
                </div>
              )}
          </Col>
        </Row>
      )}
      {!isOnFirstPage && (
        <>
          <Row>
            <Col className="mb-4">
              {/* @ts-expect-error */}
              {campaign?.properties?.is_engagement_survey_only ? (
                <FormattedMessage
                  id="app.views.performance.performance_step_welcome.how_feeling.engagement_survey_only"
                  defaultMessage="Let's jump in. How are you feeling about doing this survey?"
                />
              ) : (
                <FormattedMessage
                  id="app.views.performance.performance_step_welcome.how_feeling"
                  defaultMessage="Let's jump in. How are you feeling about doing this cycle?"
                />
              )}
            </Col>
          </Row>
          <ValidatedForm
            method={object?.id ? 'PATCH' : 'POST'}
            url={
              // @ts-expect-error
              campaign?.status === CAMPAIGN_STATUSES.DEMO
                ? undefined
                : 'survey-responses'
            }
            buttonIsBlock={false}
            object={object}
            onChange={onChange}
            inputs={[
              {
                type: INPUT_TYPES.BUTTON_OPTIONS,
                name: 'feeling_start',
                allowCustom: true,
                className: '',
                buttonClassName: 'py-4 mb-0 align-items-center lift card',
                selectedButtonClassName:
                  'py-4 mb-0 align-items-center lift card border-primary bg-primary-soft',
                options: feelingOptions,
              },
            ]}
            callback={callback}
            renderInputs={renderInputs}
            renderForm={renderForm}
            transformObjectBeforeSubmit={transformObjectBeforeSubmit}
            submitText={formatMessage({
              id: 'app.views.performance.performance_step_welcome.submit_text.continue',
              defaultMessage: 'Continue',
            })}
          />
        </>
      )}
    </PerformancePage>
  );
};

const PerformanceStepWelcome_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = InferProps<typeof PerformanceStepWelcome_propTypes>;

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepWelcome)));
