import { useCallback, useMemo, useState } from 'react';

import ConfirmAPI from './ConfirmAPI';
import { ReduxState } from '../../types';
import { getFriendlyUserFacingErrorObjectAndMessage } from '../util/util';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useSelector } from 'react-redux';

export type ApiState = 'LOADING' | 'ERROR' | 'SUCCESS' | 'DISABLED';
export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type ApiHookParams<T> = {
  method: HttpMethod; // the http method to use
  url: string; // the url of the api call
  params?: object; // the parameters passet to the api call
  postProcess?: (data: any) => T; // this is to trasnsform the data from the api to the data we want to use
  callback?: (
    data: T | null,
    error: unknown,
    hardErrorMessage: unknown
  ) => void; // this is an alternative way to handle the data from the api if not using the hook elements
  disabled?: boolean; // this is to disable the api call
  clearOnChange?: boolean; // this is to clear the data on change
  clearOnError?: boolean; // this is to clear the data on error
};

export type ApiHookReturn<T> = {
  status: ApiState;
  error: unknown | undefined;
  data: T | undefined;
  clear: () => void;
};

export type ApiHookReturnWithDefault<T> = Omit<ApiHookReturn<T>, 'data'> & {
  data: T;
};

export const useProxyParameters = (): {
  proxy: string | undefined;
  organization_id: number | undefined;
  organization: number | undefined;
} => {
  const proxy = useSelector<ReduxState, string | undefined>(
    (state) => state?.currentProxyPerson?.email
  );

  const organization_id = useSelector<ReduxState, number | undefined>(
    (state) => state.currentOrganization?.id
  );

  const proxyParameters = useMemo(
    () => ({
      proxy,
      organization_id,
      organization: organization_id,
    }),
    [organization_id, proxy]
  );

  return proxyParameters;
};

const DEFAULT_PARAMS = {};
export const useConfirmApi = <T,>({
  method,
  url,
  params: propsParams = DEFAULT_PARAMS,
  postProcess,
  callback,
  disabled = false,
  clearOnChange = false,
  clearOnError = false,
}: ApiHookParams<T>): ApiHookReturn<T> => {
  const currentProxyPersonEmail = useSelector<ReduxState, string | undefined>(
    (state) => state?.currentProxyPerson?.email
  );

  const currentOrganizationId = useSelector<ReduxState, number | undefined>(
    (state) => state.currentOrganization?.id
  );

  const [status, setStatus] = useState<ApiState>(() => {
    return 'LOADING';
  });
  const [error, setError] = useState<unknown | undefined>(undefined);
  const [data, setData] = useState<T | undefined>(undefined);

  const params = useDeepCompareMemo(() => {
    return {
      ...propsParams,
      proxy: currentProxyPersonEmail,
      organization_id: currentOrganizationId,
    };
  }, [propsParams, currentProxyPersonEmail, currentOrganizationId]);

  const apiCallback = useCallback(
    (data: T, error: unknown, hardErrorMessage: unknown) => {
      if (error || hardErrorMessage) {
        const [errorObject] = getFriendlyUserFacingErrorObjectAndMessage(
          error,
          hardErrorMessage
        );
        if (callback) callback(null, error, hardErrorMessage);
        setError(errorObject);
        setStatus('ERROR');
        if (clearOnError) {
          setData(undefined);
        }
        return;
      }

      const postProcessedData = postProcess ? postProcess(data) : data;
      if (callback) callback(postProcessedData, error, hardErrorMessage);
      setData(postProcessedData);
      setStatus('SUCCESS');
      setError(undefined);
    },
    [postProcess, callback, clearOnError]
  );

  const clear = useCallback(() => {
    setData(undefined);
    setError(undefined);
    setStatus('LOADING');
  }, []);

  useDeepCompareEffect(() => {
    if (clearOnChange) {
      clear();
    }

    if (!disabled) {
      setStatus('LOADING');
      setError(undefined);
      ConfirmAPI.sendRequestToConfirm(method, url, params, apiCallback);
    }
  }, [method, url, clear, clearOnChange, apiCallback, disabled, params]);

  const result = useMemo(
    () => ({
      status: disabled ? 'DISABLED' : status,
      error,
      data,
      clear,
    }),
    [status, error, data, clear, disabled]
  );

  return result;
};

export const useConfirmApiWithDefault = <T,>({
  defaultValue,
  ...params
}: ApiHookParams<T> & { defaultValue: T }): ApiHookReturnWithDefault<T> => {
  const { data, status, error, clear }: ApiHookReturn<T> =
    useConfirmApi<T>(params);

  return { data: data ? data : defaultValue, status, error, clear };
};
