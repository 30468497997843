import * as consts from '../../../consts/consts';

import {
  CAMPAIGN_STATUSES,
  fetchCampaignData,
  getPhaseIndexByType,
  getPhaseIsOpen,
  hasPhase,
  isEngagementSurveyOnly,
} from '../../../utils/models/Campaign';
import { Card, CardBody, Col, NavLink, Row } from 'reactstrap';
import CardRowDashboard, { CARD_TYPES } from '../Dashboards/CardRowDashboard';
import {
  FILTER_FIELDS,
  createBreakdownData,
  extractPeopleFromSurveyResponses,
  getPersonField,
  getSkillsAndBehaviors,
  getStatsFromRelationship,
  processDistributionData,
  processPeople,
  sortByValue,
  sortChartLabels,
} from '../../../utils/models/Takeaways';
import { FormattedMessage, useIntl } from 'react-intl';
import NavigationButtons, {
  NextSectionLink,
  getNavSectionConfiguration,
} from './TakeawaysDashboard/NavigationButtons';
import {
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION,
  PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PHASE_TYPE_SELF,
  getCampaignFeature,
  getCampaignHasFeatureEnabled,
} from '../../../utils/models/Performance';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { myOptionFilter, numberWithCommas } from '../../../utils/util/util';
import {
  renderCompetenciesRows,
  renderConcernsRows,
  renderENpsRows,
  renderEngagementSurveyRows,
  renderImpactRows,
  renderInfluenceRows,
  renderReccommendationsRows,
  renderTalentMatrixRows,
} from './TakeawaysDashboard/config';

import DistributionStatsCard from './components/DistributionStatsCard';
import EmptyState from '../EmptyState';
import FancyTitleCard from './components/FancyTitleCard';
// @ts-expect-error FIXME
import { IntercomAPI } from '../../../vendor/react-intercom';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import MedalTableCard from '../Cards/MedalTableCard';
import { PERFORMANCE } from '../../../consts/consts';
import PropTypes from 'prop-types';
import SelectInput from '../Inputs/SelectInput';
import Sticky from '../Sticky';
import TalentMatricesCards from './TakeawaysDashboard/TalentMatricesCards';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import Welcome from './TakeawaysDashboard/Welcome';
import { connect } from 'react-redux';
import { expandDashboardData } from './TeamDashboard/helpers';
import { fetchTestData } from '../../../utils/util/testData';
import { getCurrentCampaignForKeyTakeaways } from './TakeawaysDashboard/common';
// N.B. get these files from Shared Drives/Engineering/Test Dataand copy into
// core/app/src/views/Performance/. Then, uncomment the import's below
// and comment out the const's.
import groupCreationImg from '../../../assets/img/illustrations/group-resume-creation.png';
import holding1Img from '../../../assets/img/illustrations/holding-1-card.png';
import holding2Img from '../../../assets/img/illustrations/holding-2-card.png';
import { isTalentMatricesEnabled } from 'utils/util/features';
import { loadOrRender } from '../../../utils/util/formatter';
import { parseDateFromUnicode } from '../../../utils/util/time';
import powerLawImg from '../../../assets/img/illustrations/power-law.png';
import { scrollTo } from '../../../utils/util/utiltsx';
import { usePageStatusIndicator } from 'components/BackgroundTaskModal/hooks';
import { withRouter } from 'react-router';

// booleans to hide elements that we'll explore post-MVP
const showLongestGoldStarsQuote = false;

const colorConfig = {
  influence: '#2c7be5',
  gold_star: '#f6c343',
  heads_up: '#e63757',
  tenure: '#95aac9',
};

const toggleSupport = () => {
  IntercomAPI('show');
};

const NextButton = (props) => {
  const [textDecoration, setTextDecoration] = useState('none');
  return (
    <NavLink
      onClick={() => scrollTo(props.target)}
      onMouseEnter={() => setTextDecoration('underline')}
      onMouseLeave={() => setTextDecoration('none')}
      role="button"
      style={{
        display: 'inline-block',
        textDecoration: textDecoration,
      }}
    >
      {props.children}
    </NavLink>
  );
};

const PerformanceTakeaways = (props) => {
  const { formatMessage, formatDate } = useIntl();
  const [data, setData] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const [activeFilters, setActiveFilters] = useState({});

  const isPrivateMode =
    new URLSearchParams(location.search).get('private') === 'true';

  const campaign = useMemo(() => {
    if (data?.campaign) {
      return data.campaign;
    } else {
      return undefined;
    }
  }, [data]);

  const shouldShowEmployeeNPS = useMemo(() => data?.employee_nps, [data]);

  const shouldShowEngagementSurveySummary = useMemo(
    () => data?.engagement_survey_summary?.length,
    [data]
  );

  const engagementSurveyVisibilityDate = data?.engagement_survey_visibility_date
    ? parseDateFromUnicode(
        data?.engagement_survey_visibility_date,
        'yyyy-MM-dd'
      )
    : null;

  const campaignHasEngagementSurveyEnabled = useMemo(
    () => getCampaignFeature(campaign, PERFORMANCE_FEATURE_ASSESS_ORGANIZATION),
    [campaign]
  );

  // No data means someone doesn't have permission, so we
  // just hide the entirety of the section
  const campaignHasEngagementSurvey = useMemo(
    () => data?.engagement_survey && campaignHasEngagementSurveyEnabled,
    [campaignHasEngagementSurveyEnabled, data]
  );

  const campaignHasENPSEnabled = useMemo(
    () =>
      getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED
      ),
    [campaign]
  );

  // we do not display the ENPs and engagement survey data
  // until the phase has ended, if that's the case, the server
  // will tell us when the section is going to be available
  const shouldShowEngagementSurveyPlaceholder =
    engagementSurveyVisibilityDate !== null;

  const ratingRequired = useMemo(
    () =>
      !getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      ),
    [campaign]
  );

  const [allPeople, filterOptions] = useMemo(() => {
    if (
      !data?.survey_responses ||
      !campaign?.coverage_end_date ||
      !data?.relationships
    ) {
      return [{}, {}];
    }
    // @ts-expect-error FIXME
    const [allPeople, filterOptions] = extractPeopleFromSurveyResponses(
      data?.survey_responses,
      campaign?.coverage_end_date,
      campaign
    );
    data?.relationships.forEach((r) => getStatsFromRelationship(r, allPeople));
    return [allPeople, filterOptions];
  }, [data?.survey_responses, data?.relationships, campaign]);

  const people = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(allPeople).filter(([, person]) => {
          for (const [filter, values] of Object.entries(activeFilters)) {
            const value = getPersonField(person, filter);
            // @ts-expect-error FIXME
            if (!values.find((x) => x.value === value)) {
              return false;
            }
          }
          return true;
        })
      ),
    [allPeople, activeFilters]
  );

  const [
    skillData,
    skillsFromSelfSkillOwners,
    skillsFromManagerSkillOwners,
    skillsFromOthersSkillOwners,
    haveSkillsData,
  ] = useMemo(() => {
    const [
      skillData,
      skillsFromSelfSkillOwners,
      skillsFromManagerSkillOwners,
      skillsFromOthersSkillOwners,
    ] = getSkillsAndBehaviors(people);

    let haveData = false;
    Object.keys(skillData).forEach((type) => {
      for (let i = 0; i < 2; i++) {
        for (let j = 0; j < 2; j++) {
          if (Object.keys(skillData[type][i][j]).length) {
            haveData = true;
          }
        }
      }
    });
    return [
      skillData,
      skillsFromSelfSkillOwners,
      skillsFromManagerSkillOwners,
      skillsFromOthersSkillOwners,
      haveData,
    ];
  }, [people]);

  const [
    breakdownData,
    goldstarLeaderboard,
    headsupLeaderboard,
    distributionData,
    superInfluencers,
    lowInfluencers,
    breakoutInfluencers,
  ] = useMemo(() => {
    if (!people) {
      return [{}, [], [], {}, [], []];
    }
    return [
      createBreakdownData(formatMessage, people, campaign),
      ...processPeople(people),
    ];
  }, [people, campaign, formatMessage]);

  const [loading, setLoading] = useState(true);
  const [loadingInitialBackgroundTask, setLoadingInitialBackgroundTask] =
    useState<boolean>(false);
  const [backgroundTaskKey, setBackgroundTaskKey] = useState(null);

  const fetchData = useCallback(
    (forceBackgroundRefresh) => {
      fetchCampaignData({
        getCurrentCampaignFunc: getCurrentCampaignForKeyTakeaways,
        organizationId: props.currentOrganization?.id,
        currentProxyPerson: props.currentProxyPerson,
        // campaign is optional (but should only be checked if explicitly passed in)
        campaignId: props.campaign ? props.campaign.id : undefined,
        forceBackgroundRefresh,
        setBackgroundTaskKey,
        setLoadingInitialBackgroundTask,
        callback: (data) => {
          if (data) {
            setData(expandDashboardData(data));
          } else {
            setData(null);
          }
          setLoading(false);
        },
        setErrorMessage,
      });
    },
    [props.campaign, props.currentOrganization?.id, props.currentProxyPerson]
  );

  const handleBackgroundTaskCompleted = useCallback(() => {
    fetchData(false);
  }, [fetchData]);

  const setPageStatusIndicator = props.setPageStatusIndicator;
  usePageStatusIndicator(
    backgroundTaskKey,
    handleBackgroundTaskCompleted,
    setPageStatusIndicator
  );

  useEffect(
    function loadTakeawaysData() {
      // For demo orgs, we use the anonymized Dailyapps data file
      if (props.currentOrganization.status === CAMPAIGN_STATUSES.DEMO) {
        fetchTestData(props.currentOrganization.name).then((d) => {
          setData(d);
          setLoading(false);
        });
      } else {
        // fetch people from the backend based on your permissions
        if (props.currentOrganization?.id) {
          setLoading(true);
          fetchData(true);
        }
      }
    },
    [fetchData, props.currentOrganization]
  );

  const onSelectFilter = useCallback(
    (values, filter) => {
      const newActiveFilters = Object.assign({}, activeFilters);
      if (!values) {
        delete newActiveFilters[filter];
      } else {
        newActiveFilters[filter] = values;
      }
      setActiveFilters(newActiveFilters);
    },
    [activeFilters, setActiveFilters]
  );

  const organizationName = props?.currentOrganization?.name;

  const getPowerLawLink = useCallback(
    (id) => {
      const targetId = 'power-law-link-' + id;
      return (
        <>
          <span className="text-primary" id={targetId}>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.power_law"
              defaultMessage="
          power law
        "
            />
          </span>
          <UncontrolledPopover
            placement="top"
            trigger="hover"
            target={targetId}
          >
            <div>
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.power_law.description"
                defaultMessage='
            A "Power Law" distribution is also known as a "long
            tail."
          '
              />
            </div>
            <img
              src={powerLawImg}
              alt={formatMessage({
                id: 'app.views.widgets.dashboards.takeaways_dashboard.images.power_law.alt',
                defaultMessage: 'power law distribution',
              })}
              className="mw-100 mt-5 mb-2"
              role="button"
              style={{
                position: 'relative',
                top: '-1rem',
              }}
            />
            <div>
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.power_law.long_description"
                defaultMessage='
            Contrary to popular belief, research shows that performance across
            people does not follow a bell curve (aka "normal
            distribution"). Instead, a small number of "hyper high
            performers" drive the majority of impact.'
              />{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.confirm.com/post/fixing-performance-reviews-for-good"
              >
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.power_law.learn_more"
                  defaultMessage="
              Learn more
            "
                />
              </a>
            </div>
          </UncontrolledPopover>
        </>
      );
    },
    [formatMessage]
  );

  const hasSelfPhase = useMemo(
    () => hasPhase(campaign, PHASE_TYPE_SELF),
    [campaign]
  );

  const engagementSurveyOnly =
    isEngagementSurveyOnly(campaign) || props.forceShowEngagementSurveyOnly;
  const shouldShowWelcomeContent = true;

  const shouldShowImpact = useMemo(() => {
    return !engagementSurveyOnly && hasSelfPhase;
  }, [engagementSurveyOnly, hasSelfPhase]);

  const shouldShowInfluence = useMemo(
    () =>
      campaign?.should_see_influence !== false &&
      !engagementSurveyOnly &&
      hasSelfPhase,
    [campaign?.should_see_influence, engagementSurveyOnly, hasSelfPhase]
  );

  const shouldShowTalentMatrix = useMemo(() => {
    return (
      !engagementSurveyOnly &&
      isTalentMatricesEnabled(props.features) &&
      !!data?.talent_matrices &&
      !!data?.talent_matrices?.length
    );
  }, [engagementSurveyOnly, data?.talent_matrices, props.features]);

  const shouldShowCompetencies = useMemo(() => {
    return !engagementSurveyOnly;
  }, [engagementSurveyOnly]);

  const shouldShowConcerns = useMemo(() => {
    return !engagementSurveyOnly && hasSelfPhase;
  }, [engagementSurveyOnly, hasSelfPhase]);

  const shouldShowRecommendations = useMemo(() => {
    return shouldShowImpact || shouldShowCompetencies || shouldShowConcerns;
  }, [shouldShowCompetencies, shouldShowConcerns, shouldShowImpact]);

  const rows = useMemo(
    () => [
      ...(shouldShowWelcomeContent
        ? [
            [
              {
                type: CARD_TYPES.CONTAINER,
                datakey: 'welcome_container',
              },
            ],
          ]
        : []),
      [
        {
          type: CARD_TYPES.CONTAINER,
          datakey: 'navigation_buttons',
        },
      ],
      ...(shouldShowImpact
        ? renderImpactRows({
            formatMessage,
            organizationName,
            ratingRequired,
            shouldShowInfluence,
            colorConfig,
            getPowerLawLink,
          })
        : []),
      ...(shouldShowInfluence
        ? renderInfluenceRows({
            formatMessage,
            organizationName,
            getPowerLawLink,
            colorConfig,
            //   I think we can remove this
            shouldShowInfluence,
          })
        : []),
      ...renderENpsRows({
        formatMessage,
        shouldShowEmployeeNPS,
        shouldShowEngagementSurveyPlaceholder,
        campaignHasENPSEnabled,
      }),
      ...renderEngagementSurveyRows({
        campaignHasEngagementSurvey,
        formatMessage,
        campaign,
        shouldShowEngagementSurveySummary,
        shouldShowEngagementSurveyPlaceholder,
        campaignHasEngagementSurveyEnabled,
        engagementSurveyData: data?.engagement_survey,
        engagementSurveySummaryData: data?.engagement_survey_summary,
      }),
      ...(shouldShowTalentMatrix ? renderTalentMatrixRows() : []),
      ...(shouldShowCompetencies
        ? renderCompetenciesRows({
            skillsFromSelfSkillOwners,
            skillsFromManagerSkillOwners,
            skillsFromOthersSkillOwners,
            formatMessage,
          })
        : []),
      ...(shouldShowConcerns
        ? renderConcernsRows({
            formatMessage,
            organizationName,
            ratingRequired,
            shouldShowInfluence,
            colorConfig,
          })
        : []),
      ...(shouldShowRecommendations
        ? renderReccommendationsRows({ formatMessage })
        : []),
      [
        {
          id: 'final-section-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.thats_all.title',
            defaultMessage: "That's all!",
          }),
          datakey: 'final_section',
        },
      ],
    ],
    [
      campaign,
      getPowerLawLink,
      organizationName,
      skillsFromSelfSkillOwners,
      skillsFromManagerSkillOwners,
      skillsFromOthersSkillOwners,
      ratingRequired,
      shouldShowInfluence,
      campaignHasEngagementSurvey,
      data?.engagement_survey,
      data?.engagement_survey_summary,
      formatMessage,
      shouldShowEmployeeNPS,
      shouldShowEngagementSurveySummary,
      shouldShowEngagementSurveyPlaceholder,
      shouldShowWelcomeContent,
      shouldShowImpact,
      shouldShowTalentMatrix,
      shouldShowCompetencies,
      shouldShowConcerns,
      shouldShowRecommendations,
      campaignHasEngagementSurveyEnabled,
      campaignHasENPSEnabled,
    ]
  );

  const distributionStats = useMemo(
    () => processDistributionData(distributionData),
    [distributionData]
  );

  const [
    numTotalEdges,
    numTotalGoldStarEdges,
    numTotalHeadsUpEdges,
    numInfluenceEdges,
    numTotalEnergizedEdges,
    numTotalHelpAndAdviceEdges,
  ] = useMemo(
    () => [
      distributionStats.gold_star.totalValue +
        distributionStats.heads_up.totalValue +
        distributionStats.influence.totalValue,
      distributionStats.gold_star.totalValue,
      distributionStats.heads_up.totalValue,
      distributionStats.influence.totalValue,
      distributionStats.energizes.totalValue,
      distributionStats.advises.totalValue,
    ],
    [distributionStats]
  );

  const [numParticipants, numTotalPeople] = useMemo(
    () => [
      Object.values(people).filter(
        (x) => getPersonField(x, 'is_participating') === true
      ).length,
      Object.values(people).length,
    ],
    [people]
  );

  const getHelpTooltip = useCallback((uniqueId, popoverContent) => {
    const id = 'help-tooltip-' + uniqueId;

    return (
      <>
        <i
          id={id}
          className={consts.ICONS.HELP + ' ms-2 text-primary position-relative'}
          style={{
            top: 2,
          }}
        />
        <UncontrolledPopover placement="top" trigger="hover" target={id}>
          {popoverContent}
        </UncontrolledPopover>
      </>
    );
  }, []);

  const getWhyTooltip = useCallback((uniqueId, popoverContent) => {
    const id = 'why-tooltip-' + uniqueId;

    return (
      <>
        {' '}
        <span className="text-primary" id={id}>
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaways_dashboard.why"
            defaultMessage="Why?"
          />
        </span>
        <UncontrolledPopover placement="top" trigger="hover" target={id}>
          {popoverContent}
        </UncontrolledPopover>
      </>
    );
  }, []);

  const shouldShowEmployeeNPSNavigationButton =
    shouldShowEmployeeNPS ||
    (shouldShowEngagementSurveyPlaceholder && campaignHasENPSEnabled);

  const shouldShowEngagementSurveyNavigationButton =
    campaignHasEngagementSurvey ||
    (shouldShowEngagementSurveyPlaceholder &&
      campaignHasEngagementSurveyEnabled &&
      !campaignHasENPSEnabled);

  const navSectionConfiguration = useMemo(
    () =>
      getNavSectionConfiguration(formatMessage, rows, {
        campaignHasEngagementSurvey: shouldShowEngagementSurveyNavigationButton,
        shouldShowEmployeeNPS: shouldShowEmployeeNPSNavigationButton,
      }),
    [
      rows,
      formatMessage,
      shouldShowEmployeeNPSNavigationButton,
      shouldShowEngagementSurveyNavigationButton,
    ]
  );

  const navigation_buttons = useMemo(() => {
    return (
      <NavigationButtons
        disabled={engagementSurveyOnly}
        configuration={navSectionConfiguration}
      />
    );
  }, [navSectionConfiguration, engagementSurveyOnly]);

  const gold_stars_and_influence_header = useMemo(
    () => (
      <>
        <Row
          className="justify-content-between py-4"
          id="gold-stars-and-influence-header"
        >
          <Col>
            <h2 className="header-title">
              <i className={consts.ICONS.GOLD_STAR + ' me-2'} />
              <i className={consts.ICONS.INFLUENCE + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.gold_stars_and_influence_header.columns.header.recognition"
                defaultMessage="All recognition"
              />{' '}
              {getHelpTooltip(
                'gold_stars_and_influence_header',
                <div className="mb-n3">
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.gold_stars_and_influence_header.columns.header.recognition.tooltip.heading"
                      defaultMessage="How do gold stars and influence differ?"
                    />
                  </h4>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.gold_stars_and_influence_header.columns.header.recognition.tooltip.description"
                    defaultMessage="
                      <p>Individual contributors tend to receive more gold stars, whereas managers tend to receive more influence.</p>
                      <p> If we combine both datasets together, we can see a more
                      holistic view of positive recognition. It is worth
                      comparing these results with manager assessments to
                      identify if there are places where the network is not
                      aligned with the manager's view.</p>
                    "
                    values={{ p: (msg) => <p>{msg}</p> }}
                  />
                </div>
              )}
            </h2>
          </Col>
          <Col className="col-auto">
            <NextSectionLink
              sectionId="gold-stars-and-influence-header"
              configuration={navSectionConfiguration}
            />
          </Col>
        </Row>
      </>
    ),
    [navSectionConfiguration, getHelpTooltip]
  );

  const cardsData = useMemo(() => {
    return {
      welcome_container: (
        <Welcome
          numParticipants={numParticipants}
          numTotalPeople={numTotalPeople}
          numTotalEdges={numTotalEdges}
          numTotalGoldStarEdges={numTotalGoldStarEdges}
          numTotalHeadsUpEdges={numTotalHeadsUpEdges}
          numInfluenceEdges={numInfluenceEdges}
          numTotalEnergizedEdges={numTotalEnergizedEdges}
          numTotalHelpAndAdviceEdges={numTotalHelpAndAdviceEdges}
          surveyOnly={engagementSurveyOnly}
        />
      ),
      navigation_buttons,
      gold_stars_header: (
        <Row className="justify-content-between py-4" id="impact">
          <Col>
            <h2 className="header-title">
              <i className={consts.ICONS.GOLD_STAR + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.impact.heading"
                defaultMessage="Impact"
              />{' '}
              {getHelpTooltip(
                'gold-stars',
                <div className="mb-n3">
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.impact.sub_heading"
                      defaultMessage="How is impact measured?"
                    />
                  </h4>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.impact.description"
                    defaultMessage='
                    <p>We use "gold stars" to represent someone
                    recognizing someone else for outstanding impact.</p>
                    <p>Specifically, we asked each person, "Choose up to 3
                    people who you believe made an outstanding impact at {organizationName}".</p>'
                    values={{
                      p: (chunks) => <p>{chunks}</p>,
                      organizationName,
                    }}
                  />
                </div>
              )}
            </h2>
          </Col>
          <Col className="col-auto">
            <NextSectionLink
              sectionId="gold-stars-header"
              configuration={navSectionConfiguration}
            />
          </Col>
        </Row>
      ),
      talent: {
        content: (
          <>
            <Row>
              <Col sm="12">
                {/* @ts-expect-error FIXME */}
                <DistributionStatsCard
                  description={formatMessage(
                    {
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.talent.content',
                      defaultMessage:
                        'Gold stars capture the most impactful work at {organizationName} that is seen by others.',
                    },
                    {
                      organizationName: organizationName,
                    }
                  )}
                  stats={{
                    average: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.talent.columns.header.average',
                        defaultMessage: 'Average',
                      }),
                      value: numberWithCommas(
                        distributionStats.gold_star.average,
                        2
                      ),
                    },
                    median: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.talent.columns.header.median',
                        defaultMessage: 'Median',
                      }),
                      value: numberWithCommas(
                        distributionStats.gold_star.median
                      ),
                    },
                    max: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.talent.columns.header.max',
                        defaultMessage: 'Max',
                      }),
                      value: numberWithCommas(distributionStats.gold_star.max),
                    },
                    total: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.talent.columns.header.total',
                        defaultMessage: 'Total',
                      }),
                      value: numberWithCommas(
                        distributionStats.gold_star.totalValue
                      ),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FancyTitleCard
                  icon={consts.ICONS.GOLD_STAR}
                  title={formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.talent.content.top_gold_star_recipients.heading',
                    defaultMessage: 'Top gold star recipients',
                  })}
                >
                  {showLongestGoldStarsQuote && (
                    <div className="mb-3">
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.talent.content.top_gold_star_recipients.heading.quote"
                        defaultMessage='"[longest quote for highest gold star recipient]"'
                      />
                    </div>
                  )}

                  {/* @ts-expect-error FIXME */}
                  <MedalTableCard data={goldstarLeaderboard.slice(0, 3)} />
                </FancyTitleCard>
              </Col>
            </Row>
          </>
        ),
        field: 'gold_star',
        xAxis: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.graphs.x_axis.gold_stars',
          defaultMessage: 'Gold stars',
        }),
        yAxis: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.graphs.y_axis.num_people',
          defaultMessage: 'Number of people',
        }),
        data: distributionData['gold_star'],
      },
      top_performers: {
        content: (
          <>
            <Card>
              <CardBody>
                <h3 className="border-bottom pb-3 mb-4">
                  <i className={consts.ICONS.RECOMMENDATION + ' me-2'} />
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.top_gold_star_recipients.heading"
                    defaultMessage="Recommended actions"
                  />
                </h3>
                <p>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.top_gold_star_recipients.pre_heading"
                    defaultMessage="These gold star recipients are among the people that {organizationName} can't afford to lose."
                    values={{ organizationName }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.top_gold_star_recipients.description"
                    defaultMessage="<bold>Recognize their impact</bold><break></break><a>Give public recognition</a> to these individuals to help them see that the company values their contributions."
                    values={{
                      bold: (msg) => <span className="fw-bold">{msg}</span>,
                      break: () => <br />,
                      a: (msg) => <a href="#create-feedback">{msg}</a>,
                    }}
                  />
                </p>
                <h4>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.career_goals.heading"
                    defaultMessage="Understand their career goals"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.career_goals.descrption"
                    defaultMessage="
              To retain your top performers, identify ways that the company can
              help them achieve their career goals. Have a development
              conversation, and help them <a>capture goals and aspirations</a>."
                    values={{
                      a: (msg) => (
                        <a
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            consts.MY_PROFILE(formatMessage).path +
                            consts.GOALS(formatMessage).path
                          }
                        >
                          {msg}
                        </a>
                      ),
                    }}
                  />
                </p>
                <h4>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.stretch_assignment.heading"
                    defaultMessage="Consider stretch assignments"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.stretch_assignment.description"
                    defaultMessage="Many top performers are <rockstarlink>rock stars or superstars</rockstarlink>. Identify challenges that will meet their level of mastery to let
                them find more moments of <flowstatelink>Flow</flowstatelink>."
                    values={{
                      rockstarlink: (msg) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={consts.SUPPORT_PORTAL.ROCK_STARS_VS_SUPERSTARS}
                        >
                          {msg}
                        </a>
                      ),
                      flowstatelink: (msg) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={consts.SUPPORT_PORTAL.FLOW_STATE}
                        >
                          {msg}
                        </a>
                      ),
                    }}
                  />
                </p>
                <h4>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.increase_resources.heading"
                    defaultMessage="Increase resources toward their initiatives"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.top_performers.content.increase_resources.description"
                    defaultMessage="
              A common reason for leaving fast-paced organizations is a lack of
              resources which can lead to burnout. Ensure they have what they
              need to continue being successful.
            "
                  />
                </p>
              </CardBody>
            </Card>
          </>
        ),
        leaderboard: goldstarLeaderboard,
      },
      breakdowns: breakdownData,
      influence_header: (
        <>
          <Row className="justify-content-between py-4" id="influence">
            <Col>
              <h2 className="header-title">
                <i className={consts.ICONS.INFLUENCE + ' me-2'} />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.influence_header.content.influence.heading"
                  defaultMessage="Influence"
                />{' '}
                {getHelpTooltip(
                  'influence',
                  <div className="mb-n3">
                    <h4>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.influence_header.content.influence.heading.tooltip.how_influence"
                        defaultMessage="How is influence measured?"
                      />
                    </h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.influence_header.content.influence.heading.tooltip.how_influence.description"
                      defaultMessage='
                      <p>Influence represents who is recognized as a provider of help, advice, and/or energy.</p>
                      <p>Specifically, we asked each person, "Who at {organizationName} motivated or energized you?" and "Who at {organizationName} did you go to for help and advice?</p>
                    '
                      values={{ organizationName, p: (msg) => <p>{msg}</p> }}
                    />
                  </div>
                )}
              </h2>
            </Col>
            <Col className="col-auto">
              <NextSectionLink
                sectionId="influence-header"
                configuration={navSectionConfiguration}
              />
            </Col>
          </Row>
        </>
      ),
      gold_stars_and_influence_header,
      influence: {
        content: (
          <>
            <Row>
              <Col>
                {/* @ts-expect-error FIXME */}
                <DistributionStatsCard
                  description={formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading',
                    defaultMessage:
                      'Influence captures who is gone to for help, advice, or energy.',
                  })}
                  stats={{
                    average: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.incluence.content.column.average',
                        defaultMessage: 'Average',
                      }),
                      value: numberWithCommas(
                        distributionStats.influence.average,
                        2
                      ),
                    },
                    median: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.incluence.content.column.median',
                        defaultMessage: 'Median',
                      }),
                      value: numberWithCommas(
                        distributionStats.influence.median
                      ),
                    },
                    max: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.incluence.content.column.max',
                        defaultMessage: 'Max',
                      }),
                      value: numberWithCommas(distributionStats.influence.max),
                    },
                    total: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.incluence.content.column.total',
                        defaultMessage: 'Total',
                      }),
                      value: numberWithCommas(
                        distributionStats.influence.totalValue
                      ),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FancyTitleCard
                  icon={consts.ICONS.RECOMMENDATION}
                  title={formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.reccomended_actions',
                    defaultMessage: 'Recommended actions',
                  })}
                >
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.give_influence_visibility"
                      defaultMessage="Give influencers visibility"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.give_influence_visibility.content"
                      defaultMessage='
              Given their impact on others, it is important that they know where
              the company would like to go. This could be done through
              conversations, document sharing, or creating a "task
              force" for superinfluencers.
            '
                    />
                  </p>
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.align_influencer_objectives"
                      defaultMessage="Align influencer objectives"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.keep_everyone_driving"
                      defaultMessage="
              To keep everyone driving in the same direction, work with
              influencers to <a>define individual objectives or OKRs</a> to help set them up for success."
                      values={{
                        a: (chunks) => (
                          <a
                            className="text-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              consts.MY_PROFILE(formatMessage).path +
                              consts.OBJECTIVES(formatMessage).path
                            }
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </p>
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.replicate_successful"
                      defaultMessage="Replicate successful onboarding practices"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.replicate_successful.content"
                      defaultMessage="
              Have conversations to identify how super influencers got the
              influence they have. Replicate hiring, onboarding, and management
              practices accordingly.
            "
                    />
                  </p>
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.measure_bias"
                      defaultMessage="Measure your bias"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.incluence.content.heading.measure_bias.description"
                      defaultMessage="Not all people are recognized equally for the same work. <a>Export your data</a> and attach demographics to identify disparities, then normalize for them."
                      values={{
                        a: (chunks) => (
                          <a
                            className="text-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={consts.TEAM_PERFORMANCE_DASHBOARD().path}
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </FancyTitleCard>
              </Col>
            </Row>
          </>
        ),
        field: 'influence',
        xAxis: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.graphs.x_axis.influece',
          defaultMessage: 'Influence',
        }),
        yAxis: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.graphs.y_axis.number_of_people',
          defaultMessage: 'Number of people',
        }),
        data: distributionData['influence'],
      },
      top_influencers: {
        content: (
          <>
            <p className="mb-4">
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.content.heading"
                defaultMessage="
              Influencers reach the largest possible share of the network with
              the fewest number of direct connections. Below are those who stand
              out.
            "
              />
            </p>
          </>
        ),
        breakout_influencers: breakoutInfluencers,
        super_influencers: superInfluencers,
        low_influencers: lowInfluencers ? lowInfluencers : [],
      },
      influence_vs_tenure_breakdown: (
        <CardBody>
          <h3 className="mt-3">
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.heading"
              defaultMessage="Up and comers"
            />
          </h3>
          <p>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.less_than_six_months"
              defaultMessage="< 6 months tenure"
            />
          </p>
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.influ"
              defaultMessage="
            [leaderboard of pics, names, depts, w/influence and tenure, highest
            influence first]
          "
            />
          </div>
          <h3 className="mt-3">
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.super_influencers"
              defaultMessage="Super-influencers"
            />
          </h3>
          <p>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.all_tenures"
              defaultMessage="All tenures"
            />
          </p>
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.leaderboard"
              defaultMessage="
            [leaderboard of pics, names, depts, w/influence and tenure, highest
            influence first]
          "
            />
          </div>
          <h3 className="mt-3">
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.below_potential_influence"
              defaultMessage="Below potential influence"
            />
          </h3>
          <p>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.two_plus_years"
              defaultMessage="2+ years tenure"
            />
          </p>
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure_breakdown.leaderboard_of_pics"
              defaultMessage="
            [leaderboard of pics, names, depts, w/influence and tenure, lowest
            tenure first]
          "
            />
          </div>
        </CardBody>
      ),
      influence_vs_tenure: (
        <CardBody>
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaways_dashboard.top_influencers.influence_vs_tenure.description"
            defaultMessage="
          Left third: Recommended Actions Replicate hiring, onboarding,
          management practices of super influencers Prioritize onboarding
          (quality & tracking) Super influencer task force Right two-thirds:
          [influence vs tenure graph that looks like what we have on our
          homepage at https://www.confirm.com/ inc. hover functionality with the
          following changes/additions: larger size dot for gold stars redder dot
          for headsups same color otherwise for dots]
        "
          />
        </CardBody>
      ),
      talent_matrix_header: (
        <>
          <Row className="justify-content-between py-4" id="talent-matrix">
            <Col>
              <h2 className="header-title">
                <i className={consts.ICONS.TALENT_MATRIX + ' me-2'} />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.talent_matrices.header"
                  defaultMessage="Talent matrices"
                />{' '}
                {getHelpTooltip(
                  'talent-matrix',
                  <div className="mb-n3">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.talent_matrices.header.tooltip.header"
                      defaultMessage="<title>What are talent matrices?</title><p>Talent matrices are visualizations that bucket your talent based on two different criteria. A 9-box mapping performance vs potential is the most common talent matrix.</p><p>Talent matrices are defined by the cycle administrator. A cycle can have as many talent matrices as they'd like, mapping responses to any questions asked during the cycle and/or ONA data against each other.</p> "
                      values={{
                        title: (msg) => <h4>{msg}</h4>,
                        p: (msg) => <p>{msg}</p>,
                      }}
                    />
                  </div>
                )}
              </h2>
            </Col>
            <Col className="col-auto">
              <NextSectionLink
                sectionId="talent-matrix-header"
                configuration={navSectionConfiguration}
              />
            </Col>
          </Row>
          {data?.talent_matrices && data.talent_matrices.length > 0 && (
            <TalentMatricesCards
              people={people}
              talentMatrices={data.talent_matrices}
            />
          )}
        </>
      ),
      skills_and_behaviors_header: (
        <>
          <Row className="justify-content-between py-4" id="competencies">
            <Col>
              <h2 className="header-title">
                <i className={consts.ICONS.SKILL + ' text-success me-2'} />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.skills_and_behaviors_header.header"
                  defaultMessage="
                Competencies"
                />{' '}
                {getHelpTooltip(
                  'skills',
                  <div className="mb-n3">
                    <h4>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.skills_and_behaviors_header.header.tootlip.header"
                        defaultMessage="What are competencies?"
                      />
                    </h4>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.skills_and_behaviors_header.header.tootlip.description.competencies"
                        defaultMessage="
                      Competencies are skills and behaviors that people have
                      mentioned explicitly during the cycle.
                    "
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.skills_and_behaviors_header.header.tootlip.description.skills"
                        defaultMessage="
                      Skills represent technical and critical skills that are
                      typically relevant to a specific role or job function.
                      Behaviors tend to be used across many job functions.
                    "
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.skills_and_behaviors_header.header.tootlip.description.actions"
                        defaultMessage="
                      Anyone can create new skills and behaviors. The Confirm
                      team will periodically curate them to fix misspellings,
                      create grammatical consistent, and ensure that skills and
                      behaviors are relevant and meaningful.
                    "
                      />
                    </p>
                  </div>
                )}
              </h2>
            </Col>
            <Col className="col-auto">
              <NextSectionLink
                sectionId="skills-and-behaviors-header"
                configuration={navSectionConfiguration}
              />
            </Col>
          </Row>
          {!haveSkillsData && (
            <Card>
              <CardBody>
                <EmptyState
                  title={formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.empty_state.title',
                    defaultMessage:
                      'No skills or behaviors were collected in this cycle.',
                  })}
                />
              </CardBody>
            </Card>
          )}
        </>
      ),
      // @ts-expect-error
      self_skills_and_behaviors: skillData.fromSelf,
      employee_nps_header: (
        <>
          <Row className="justify-content-between py-4" id="employee-nps">
            <Col>
              <h2 className="header-title">
                <i
                  className={
                    consts.ICONS.ENGAGEMENT_SURVEY + ' text-primary me-2'
                  }
                />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.employee_nps_header.header"
                  defaultMessage="eNPS"
                />{' '}
                {getHelpTooltip(
                  'enps',
                  <div className="mb-n3">
                    <h4>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.employee_nps_header.header.tooltip.header"
                        defaultMessage="What is eNPS?"
                      />
                    </h4>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.employee_nps_header.header.tooltip.description_part1"
                        defaultMessage="eNPS is a measure of employee engagement and loyalty. It is calculated by subtracting the
                          percentage of detractors, who respond with a score of 0-6, from the percentage of promoters, who respond
                          with a score of 9-10. Passives, who respond with a score of 7-8, are ignored.
                          The overall score can range from -100 to 100."
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.employee_nps_header.header.tooltip.description_part2"
                        defaultMessage="This score was calculated by asking employees 'How likely are you to recommend {organization} as a great place to work?'"
                        values={{
                          organization: organizationName,
                        }}
                      />
                    </p>
                  </div>
                )}
              </h2>
            </Col>
            <Col className="col-auto">
              <NextSectionLink
                sectionId="employee-nps-header"
                configuration={navSectionConfiguration}
              />
            </Col>
          </Row>
        </>
      ),
      employee_nps: data?.employee_nps ?? {},
      engagement_survey_header: (
        <>
          <Row className="justify-content-between py-4" id="competencies">
            <Col>
              <h2 className="header-title">
                <i
                  className={
                    consts.ICONS.ENGAGEMENT_SURVEY + ' text-secondary me-2'
                  }
                />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.engagement_survey_header.header.enagement_survey"
                  defaultMessage="
                Engagement survey"
                />{' '}
                {getHelpTooltip(
                  'engagement-survey',
                  <div className="mb-n3">
                    <h4>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.engagement_survey_header.header.enagement_survey.description.header"
                        defaultMessage="How are engagement survey results calculated?"
                      />
                    </h4>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.engagement_survey_header.header.enagement_survey.description.survey_presented"
                        defaultMessage="Engagement survey results are presented along pre-defined
                      groups, so that no individual responses are
                      identifiable."
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.takeaways_dashboard.engagement_survey_header.header.enagement_survey.description.sufficiend_volume"
                        defaultMessage='
                      If a certain group does not have a sufficient volume of
                      data, they are grouped into "Others". If the
                      "Others" group does not have sufficient volume, it will be omitted,
                      but all data will (by default) be included in the "All" group
                    '
                      />
                    </p>
                  </div>
                )}
              </h2>
            </Col>
            <Col className="col-auto">
              <NextSectionLink
                sectionId="engagement-survey-header"
                configuration={navSectionConfiguration}
              />
            </Col>
          </Row>
        </>
      ),
      engagegement_survey_placeholder:
        shouldShowEngagementSurveyPlaceholder &&
        campaignHasEngagementSurveyEnabled ? (
          <Card>
            <CardBody>
              <EmptyState
                title={formatMessage(
                  {
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.engagement_survey_placeholder.title_engagement_survey',
                    defaultMessage:
                      'Engagement survey results will appear here on {date}.',
                  },
                  {
                    date: formatDate(engagementSurveyVisibilityDate, {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric',
                    }),
                  }
                )}
                subtitle={formatMessage({
                  id: 'app.views.widgets.dashboards.takeaways_dashboard.engagement_survey_placeholder.subtitle',
                  defaultMessage: 'Come back then to see the results!',
                })}
              />
            </CardBody>
          </Card>
        ) : null,
      enps_placeholder:
        shouldShowEngagementSurveyPlaceholder && campaignHasENPSEnabled ? (
          <Card>
            <CardBody>
              <EmptyState
                title={formatMessage(
                  {
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.engagement_survey_placeholder.title_enps',
                    defaultMessage: 'eNPS results will appear here on {date}.',
                  },
                  {
                    date: formatDate(engagementSurveyVisibilityDate, {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric',
                    }),
                  }
                )}
                subtitle={formatMessage({
                  id: 'app.views.widgets.dashboards.takeaways_dashboard.engagement_survey_placeholder.subtitle',
                  defaultMessage: 'Come back then to see the results!',
                })}
              />
            </CardBody>
          </Card>
        ) : null,
      // @ts-expect-error
      manager_review_skills_and_behaviors: skillData.fromManager,
      // @ts-expect-error
      gold_star_heads_up_skills_and_behaviors: skillData.fromOthers,
      concerns_header: (
        <Row className="justify-content-between py-4" id="concerns">
          <Col>
            <h2 className="header-title">
              <i className={consts.ICONS.HEADS_UP + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.concerns_header.header"
                defaultMessage="Concerns"
              />{' '}
              {getHelpTooltip(
                'heads-ups',
                <div className="mb-n3">
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns_header.header.tooltip.header"
                      defaultMessage="How are concerns determined?"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns_header.header.tooltip.description"
                      defaultMessage='
                    We asked each person, "List anyone who needs additional
                    support or attention regarding their behaviors, actions, or
                    impact."
                  '
                    />
                  </p>
                </div>
              )}
            </h2>
          </Col>
          <Col className="col-auto">
            <NextSectionLink
              sectionId="concerns-header"
              configuration={navSectionConfiguration}
            />
          </Col>
        </Row>
      ),
      concerns: {
        content: (
          <>
            <Row>
              <Col>
                {/* @ts-expect-error FIXME */}
                <DistributionStatsCard
                  description={formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.concerns.header',
                    defaultMessage:
                      'These people could benefit from your attention or support.',
                  })}
                  stats={{
                    average: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.concerns.columns.average',
                        defaultMessage: 'Average',
                      }),
                      value: numberWithCommas(
                        distributionStats.heads_up.average,
                        2
                      ),
                    },
                    median: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.concerns.columns.median',
                        defaultMessage: 'Median',
                      }),
                      value: numberWithCommas(
                        distributionStats.heads_up.median
                      ),
                    },
                    max: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.concerns.columns.max',
                        defaultMessage: 'Max',
                      }),
                      value: numberWithCommas(distributionStats.heads_up.max),
                    },
                    total: {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.takeaways_dashboard.concerns.columns.total',
                        defaultMessage: 'Total',
                      }),
                      value: numberWithCommas(
                        distributionStats.heads_up.totalValue
                      ),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FancyTitleCard
                  icon={consts.ICONS.RECOMMENDATION}
                  title={formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.reccommended_actions',
                    defaultMessage: 'Recommended actions',
                  })}
                >
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.assess_the_situation"
                      defaultMessage="Assess the situation"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.review_the_final_performance"
                      defaultMessage="
              Review the <a>final performance report</a> of these people to ensure you have more complete picture of the
              situation."
                      values={{
                        a: (chunks) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              consts.MY_PROFILE(formatMessage).path +
                              consts.PERFORMANCE().path
                            }
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </p>
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.set_clear_objectives"
                      defaultMessage="Set clear objectives"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.ensure_expectations"
                      defaultMessage="To ensure that expectations are clear, work with these people to <a>define individual objectives or OKRs</a> to help set them up for success."
                      values={{
                        a: (chunks) => (
                          <a
                            className="text-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              consts.MY_PROFILE(formatMessage).path +
                              consts.OBJECTIVES(formatMessage).path
                            }
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </p>
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.give_constructive_feedback"
                      defaultMessage="Give constructive feedback"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.give_feedback"
                      defaultMessage="<a>Give feedback</a> to these individuals
                to help them see that the company values their contributions."
                      values={{
                        a: (chunks) => <a href="#create-feedback">{chunks}</a>,
                      }}
                    />
                  </p>
                  <h4>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.document_conversation"
                      defaultMessage="Document your conversations"
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.concerns.sub_heading.plan"
                      defaultMessage="
              If needed, create a <a>performance improvement plan</a> to share with the person to offer opportunity to improve and set
              clear expectations of possible future action."
                      values={{
                        a: (chunks) => (
                          <a
                            className="text-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              consts.SUPPORT_PORTAL
                                .PERFORMANCE_IMPROVEMENT_PLANS
                            }
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </FancyTitleCard>
              </Col>
            </Row>
          </>
        ),
        leaderboard: headsupLeaderboard,
      },
      whats_next_header: (
        <Row className="justify-content-between py-4" id="recommendations">
          <Col>
            <h2 className="header-title">
              <i className={consts.ICONS.RECOMMENDATION + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_header.final_reccommendations"
                defaultMessage="
              Final recommendations
            "
              />
            </h2>
          </Col>
          <Col className="col-auto">
            <NextButton target="TOP">
              <span className="text-muted">
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_header.final_reccommendations.return_to_top"
                  defaultMessage="Return to top: "
                />
              </span>{' '}
              <i className={'fe fe-rewind text-info'} />{' '}
              <span className="fw-bold text-dark">
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_header.final_reccommendations.overview"
                  defaultMessage="Overview"
                />
              </span>
            </NextButton>
          </Col>
        </Row>
      ),
      whats_next: (
        <>
          <Row>
            <Col className="col-sm-8 mb-6" md="12">
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.whats_next.heading"
                defaultMessage="
                  Before considering this performance cycle complete, there are still important actions to take. Also, continue striving for growth and improvement after completion with a few additional actions.
              "
              />
            </Col>
          </Row>
          <Row className="align-middle">
            <Col
              className="col-sm-2 offset-sm-1 mb-5 d-xs-none d-sm-flex d-lg-flex text-center align-items-center justify-content-end"
              lg={{ offset: 0, size: 3 }}
            >
              <img
                src={holding1Img}
                alt={formatMessage({
                  id: 'app.views.widgets.dashboards.takeaways_dashboard.images.person_holding_card.alt',
                  defaultMessage: 'person holding card with one',
                })}
                className="mw-100 h-51 mt-2 mb-2 mt-n5 final-steps--image"
                role="button"
              />
            </Col>
            <Col
              className="col-sm-7 offset-sm-1 pb-4 mb-5"
              md={{ offset: 0, size: 12 }}
              lg={{ offset: 1, size: 8 }}
            >
              <h3 className="border-bottom pb-3 mb-4 mt-3">
                <i className={consts.ICONS.RECOMMENDATION + ' me-2'} />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.immediate_next_steps.title"
                  defaultMessage="
                Immediate next steps
              "
                />
              </h3>
              <div>
                <p>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_immediate.columns.reccommended.actions.communicate_to_company"
                      defaultMessage="Communicate to the company."
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_immediate.columns.reccommended.actions.thanks_participants"
                    defaultMessage="
                  Thank participants, share quick insights, share how data will
                  be used.
                  "
                  />
                  {getWhyTooltip(
                    'immediate-comms',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.immediate_comms_description',
                      defaultMessage:
                        'To demonstrate that their effort was worth it, and because the data recorded is sensitive, so transparency around its use is important for psychological safety.',
                    })
                  )}
                </p>
                <p>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_immediate.columns.recommended.actions.engagement"
                      defaultMessage="
                    Engage with influencers, top performers, and people of
                    concern.
                  "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_immediate.columns.recommended.actions.engagement.description"
                    defaultMessage="
                  Executives taking time out of their day to acknowledge these
                  groups is a significant message that can go a long way with
                  acknowledging these people and giving their awareness of how
                  the organization recognizes them and that this matters to
                  senior company leadership.
                  "
                  />
                  {getWhyTooltip(
                    'immediate-engage',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.immediate_engage',
                      defaultMessage:
                        'These people are driving both the direction and culture of your business, as they are the people who the company has collectively identified as most top of mind / notable to them, so staying connected to these people is important to stay connected to how the business moves day-to-day.',
                    })
                  )}
                </p>
                <p>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_immediate.columns.reccommended.actions.schedule_next_cycle"
                      defaultMessage="Schedule your next cycle."
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_immediate.columns.reccommended.actions.schedule_next_cycle.description"
                    defaultMessage=" To
                  keep the habit, schedule when to do the next performance
                  review cycle, ideally once a quarter for network analysis
                  questions, and every 6 months for manager reviews and/or
                  calibrations.
                  "
                  />
                  {getWhyTooltip(
                    'immediate-schedule',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.immediate_schedule',
                      defaultMessage:
                        'While you are hiring new talent, it is important that you have a pulse on both these new hires and existing talent, as things change quickly.',
                    })
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-middle">
            <Col
              className="col-sm-2 offset-sm-1 my-5 d-xs-none d-sm-flex d-lg-flex text-center align-items-center justify-content-end"
              lg={{ offset: 0, size: 3 }}
            >
              <img
                src={holding2Img}
                alt={formatMessage({
                  id: 'app.views.widgets.dashboards.takeaways_dashboard.images.person_holding_card_2.alt',
                  defaultMessage: 'person holding card with two',
                })}
                className="mw-100 h-51 mt-2 mb-2 mt-n6  final-steps--image"
                role="button"
              />
            </Col>
            <Col
              className="col-sm-7 offset-sm-1 pb-4 mb-5"
              md={{ offset: 0, size: 12 }}
              lg={{ offset: 1, size: 8 }}
            >
              <h3 className="border-bottom pb-3 mb-4 mt-3">
                <i className={consts.ICONS.RECOMMENDATION + ' me-2'} />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_near_term.title"
                  defaultMessage="
                Follow-up actions
              "
                />
              </h3>
              <div>
                <p>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_near_term.heading.columns.reccommended.actions.establish_best_practices"
                      defaultMessage="
                    Establish a set of best practices for faster onboarding.
                  "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_near_term.heading.columns.reccommended.actions.establish_best_practices.description"
                    defaultMessage="
                    Learn from successful influencers' rapid network-building strategies and identify unique aspects of their experiences. Create programming (formal or informal) that promotes and replicates these effective practices.
                  "
                  />
                  {getWhyTooltip(
                    'followup-comms',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.followup_comms',
                      defaultMessage:
                        'For companies that grow rapidly, onboarding as a structured discipline that iterates quickly based on what is working is critical for team members to realize their full potential.',
                    })
                  )}
                </p>
                <p>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_near_term.heading.columns.reccommended.actions.take_actions_on_outliers"
                      defaultMessage="Take action on outliers."
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_near_term.heading.columns.reccommended.actions.take_actions_on_outliers.description"
                    defaultMessage="Ensure managers and executives have action plans for key influencers and top performers to retain valuable team members and address visible problem areas promptly."
                  />
                  {getWhyTooltip(
                    'followup-engage',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.followup_engage',
                      defaultMessage:
                        "Given limited time, we can't take action on everyone. That said, the top and bottom outliers are typically the ones with the greatest impact on your business.",
                    })
                  )}
                </p>
                <p>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_near_term.heading.columns.reccommended.actions.setup_trainings"
                      defaultMessage="Set up trainings."
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_near_term.heading.columns.reccommended.actions.setup_trainings.description"
                    defaultMessage=" Identify
                  areas of improvement that are shared across multiple people,
                  and use this information to create programming that will
                  support multiple people at the same time.
                  "
                  />
                  {getWhyTooltip(
                    'followup-bias',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.followup_bias_description',
                      defaultMessage:
                        "It's common for many people to share areas of improvements. Classes, trainings, mentorships, and other forms of programming can help many people without duplicating efforts.",
                    })
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-middle">
            <Col
              className="col-sm-2 offset-sm-1 my-5 d-xs-none d-sm-flex d-lg-flex text-center align-items-center justify-content-end"
              lg={{ offset: 0, size: 3 }}
            >
              <img
                src={groupCreationImg}
                alt={formatMessage({
                  id: 'app.views.widgets.dashboards.takeaways_dashboard.images.people_working_together.alt',
                  defaultMessage: 'people working together',
                })}
                className="mw-100 h-51 mt-2 mb-2 mt-n4 final-steps--image"
                role="button"
              />
            </Col>
            <Col
              className="col-sm-7 offset-sm-1 pb-4"
              md={{ offset: 0, size: 12 }}
              lg={{ offset: 1, size: 8 }}
            >
              <h3 className="border-bottom pb-3 mb-4 mt-3">
                <i className={consts.ICONS.RECOMMENDATION + ' me-2'} />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_long_term.title"
                  defaultMessage="
                Longer term actions
              "
                />
              </h3>
              <div>
                <p>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_long_term.column.cultivate_culture"
                      defaultMessage="
                    Cultivate a culture of recognition & transparency.
                  "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_long_term.column.encurage_activities"
                    defaultMessage="Encourage <profilelink>adding activities</profilelink> to Confirm profiles and <feedbacklink>providing recognition</feedbacklink> activities that warrant it."
                    values={{
                      profilelink: (msg) => (
                        <a
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={consts.MY_PROFILE(formatMessage).path}
                        >
                          {msg}
                        </a>
                      ),
                      feedbacklink: (msg) => (
                        <a href="#create-feedback">{msg}</a>
                      ),
                    }}
                  />
                  {getWhyTooltip(
                    'long-term-activity',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.long_term_activity',
                      defaultMessage:
                        "Capturing activities and recognition between cycles will give visibility to great work as it happens, index your company's skillsets, and make future perf cycles faster for participants to complete.",
                    })
                  )}
                </p>
                <p>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_long_term.column.analyze_cohorts"
                    defaultMessage="<bold>Analyze cohorts.</bold> Many people at {organizationName} are new.
                      Run cohort analyses to assess new cohort onboarding efficacy, identify lower performers early,
                      and make <feedbacklink>continuous feedback</feedbacklink> an organic
                      part of {organizationName} culture."
                    values={{
                      bold: (msg) => <span className="fw-bold">{msg}</span>,
                      feedbacklink: (msg) => (
                        <a href="#create-feedback">{msg}</a>
                      ),
                      organizationName,
                    }}
                  />
                  {getWhyTooltip(
                    'followup-cohort',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.followup_cohorts',
                      defaultMessage:
                        "Seeing changes over time between cycles can bring attention to aspects of your organization's culture or processes that may need revising or revisiting.",
                    })
                  )}
                </p>
                <p>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_long_term.column.encurage_changes"
                      defaultMessage="
                    Engage your organization in making change.
                  "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.whats_next_long_term.column.encurage_changes.descripton"
                    defaultMessage="
                  Identify areas of improvement that are shared across multiple
                  people, and use this information to create programming that
                  will support multiple people at the same time.
                  "
                  />
                  {getWhyTooltip(
                    'followup-bias',
                    formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.why_tooltip.followup_bias',
                      defaultMessage:
                        "It's common for many people to share areas of improvements. Classes, trainings, mentorships, and other forms of programming can help many people without duplicating efforts.",
                    })
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </>
      ),
      final_section: (
        <div className="pt-4">
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaways_dashboard.final_section"
            defaultMessage="<bold>That's all!</bold> Questions, comments, or concerns? <link>Let us know!</link>"
            values={{
              bold: (chunks) => <span className="fw-bold">{chunks}</span>,
              link: (chunks) => (
                <NavLink
                  className="text-primary"
                  style={{ display: 'inline' }}
                  role="button"
                  onClick={toggleSupport}
                >
                  {chunks}
                </NavLink>
              ),
            }}
          />
        </div>
      ),
    };
  }, [
    numParticipants,
    numTotalPeople,
    numTotalEdges,
    numTotalGoldStarEdges,
    numTotalHeadsUpEdges,
    numInfluenceEdges,
    numTotalEnergizedEdges,
    numTotalHelpAndAdviceEdges,
    engagementSurveyOnly,
    navigation_buttons,
    getHelpTooltip,
    organizationName,
    formatMessage,
    distributionStats.gold_star.average,
    distributionStats.gold_star.median,
    distributionStats.gold_star.max,
    distributionStats.gold_star.totalValue,
    distributionStats.influence.average,
    distributionStats.influence.median,
    distributionStats.influence.max,
    distributionStats.influence.totalValue,
    distributionStats.heads_up.average,
    distributionStats.heads_up.median,
    distributionStats.heads_up.max,
    distributionStats.heads_up.totalValue,
    goldstarLeaderboard,
    distributionData,
    breakdownData,
    gold_stars_and_influence_header,
    breakoutInfluencers,
    superInfluencers,
    lowInfluencers,
    haveSkillsData,
    // @ts-expect-error
    skillData.fromSelf,
    // @ts-expect-error
    skillData.fromManager,
    // @ts-expect-error
    skillData.fromOthers,
    // campaignHasEngagementSurvey,
    shouldShowEngagementSurveyPlaceholder,
    campaignHasEngagementSurveyEnabled,
    data?.employee_nps,
    data?.talent_matrices,
    formatDate,
    engagementSurveyVisibilityDate,
    campaignHasENPSEnabled,
    headsupLeaderboard,
    getWhyTooltip,
    navSectionConfiguration,
    people,
  ]);

  // NOTE: this order is important as it matches the Performance Admin, so
  // keeping it consistent for a better UX
  const filterList = useMemo(
    () =>
      Object.keys(FILTER_FIELDS(formatMessage)).filter(
        (f) => Object.keys(filterOptions[f] || {}).length > 0
      ),
    [filterOptions, formatMessage]
  );

  // Note: mb-0 with pt-3 below required to prevent visual glitch when scrolling for sticky filters
  const beforeContent = useMemo(
    () =>
      filterList?.length ? (
        <>
          {' '}
          <p className="mb-0 text-muted">
            <i
              className="fe fe-filter"
              style={{ position: 'relative', top: '1px' }}
            />{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.filter_report_by"
              defaultMessage="
            Filter this report by:
          "
            />
          </p>
          <Sticky
            position="top"
            unstuckClasses="pt-3 mb-4"
            stuckClasses="card card-body mb-0 p-3"
            unstuckStyles={{
              zIndex: 2,
            }}
            stuckStyles={{
              zIndex: 2,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Row className="mb-n3 ps-0 pe-3">
              {filterList.map((filter, index) => {
                const name = filter;
                const options = Object.entries(filterOptions[filter] || {})
                  .map(([k, v]) => ({
                    place_id: v,
                    object: v,
                    value: v,
                    label: k,
                    name: k,
                  }))
                  .sort(filter === 'rating' ? sortByValue : sortChartLabels);
                const selectText = FILTER_FIELDS(formatMessage)[filter] + '...';
                const loadOptions = (input, callback) => {
                  if (input) {
                    callback(myOptionFilter(options, input));
                  } else {
                    callback(options);
                  }
                };
                const onChange = (value) => onSelectFilter(value, name);
                return (
                  <Col
                    key={index}
                    className="mb-3 pe-0"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xxl="3"
                  >
                    <SelectInput
                      className="w-100"
                      clearable={true}
                      name={name}
                      loadOptions={loadOptions}
                      onChange={onChange}
                      placeholder={selectText}
                      defaultOptions={true}
                      multiple={true}
                    />
                  </Col>
                );
              })}
            </Row>
          </Sticky>
        </>
      ) : undefined,
    [filterList, filterOptions, onSelectFilter, formatMessage]
  );

  const loadOrRenderOutput = loadOrRender(undefined, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (loading) {
    return (
      <Loading
        message={formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.message.loading_cycle_data',
          defaultMessage: 'Loading cycle data...',
        })}
      />
    );
  }

  if (loadingInitialBackgroundTask) {
    return (
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaway_dashboard.loading_background_task"
            defaultMessage="
              Your data is now being generated. Come back here in a few minutes for the results.<br></br><br></br>Trying to
              complete your responses for the current cycle? <span>Go here instead.</span>"
            values={{
              br: () => <br />,
              span: (msg) => <Link to={PERFORMANCE().path}>{msg}</Link>,
            }}
          />
        </CardBody>
      </Card>
    );
  }

  if (
    props.campaign &&
    getPhaseIsOpen(
      props.campaign,
      getPhaseIndexByType(props.campaign, PHASE_TYPE_SELF)
    ) &&
    !data?.campaign?.is_admin
  ) {
    return (
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaways_dashboard.access_at_end_of_cycle"
            defaultMessage="
          The dashboards will be available on conclusion of this cycle.
        "
          />
        </CardBody>
      </Card>
    );
  }

  if (props.campaign && !data?.campaign) {
    return (
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaways_dashboard.no_access"
            defaultMessage="
          You do not have access to this feature. To get access, contact the
          administrator for Confirm at your organization.
        "
          />
        </CardBody>
      </Card>
    );
  }

  if (numTotalPeople === 0) {
    return (
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaway_dashboard.no_access_go_here_instead"
            defaultMessage="
          You do not have access to view team takeaway data. Trying to
          complete your responses for the current cycle? <span>Go here instead.</span>"
            values={{
              span: (msg) => <Link to={consts.PERFORMANCE().path}>{msg}</Link>,
            }}
          />
        </CardBody>
      </Card>
    );
  }

  const shouldShowBeforeContent = !engagementSurveyOnly;

  return (
    <>
      <CardRowDashboard
        rows={rows}
        beforeContent={shouldShowBeforeContent ? beforeContent : null}
        data={cardsData}
      />
      {isPrivateMode && (
        <style>{`div.ms-4 { filter: blur(5px); }
        table img { filter: blur(5px); }
        .flex-nowrap .avatar-img, .flex-nowrap .avatar-sm .avatar-title { filter: blur(5px); }
        .flex-nowrap .small.text-muted.mb-0 { filter: blur(4px); }
        .avatar, .fw-bold.small > .text-dark { filter: blur(10px); }
        h4 > a { filter: blur(5px); }`}</style>
      )}
    </>
  );
};

PerformanceTakeaways.defaultProps = {
  forceShowEngagementSurveyOnly: false,
};

PerformanceTakeaways.propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  features: PropTypes.object.isRequired,
  setPageStatusIndicator: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentOrganization, me, currentProxyPerson, features } = state;

  return {
    currentOrganization,
    me,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(
  withRouter(React.memo(PerformanceTakeaways))
);
