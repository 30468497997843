import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import { useIntl } from 'react-intl';

interface Props {
  required: boolean;
  innerRef?: Ref<HTMLTextAreaElement>;
  minRows: number;
  maxRows: number;
  role: string;
  style: object;
  name: string;
  placeholder: string;
  value: string;
  className: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onValidChange?: (message: string | null) => void;
  autoComplete: string;
  autoFocus: boolean;
  disabled: boolean;
  onKeyDown: KeyboardEventHandler<HTMLTextAreaElement>;
  maxLength: number;
}

const TextArea: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [autoFocusTriggered, setAutoFocusTriggered] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );

  const inputRef: Ref<HTMLTextAreaElement> = useMemo(
    () => props.innerRef ?? React.createRef(),
    [props.innerRef]
  );

  // Validation effect
  useEffect(() => {
    if (props.required) {
      const isValid =
        typeof props.value !== 'undefined' &&
        props.value !== null &&
        props.value.trim() !== '';
      setValidationMessage(
        isValid
          ? null
          : formatMessage({
              id: 'app.widgets.inputs.textarea.required',
              defaultMessage: 'This field is required.',
            })
      );
    } else {
      setValidationMessage(null);
    }
  }, [props.value, props.required, formatMessage]);

  // Propagate validation state
  useEffect(() => {
    if (props.onValidChange) {
      props.onValidChange(validationMessage);
    }
  }, [validationMessage, props.onValidChange, props]);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      if (props.onChange) {
        props.onChange(e);
      }
    },
    [props]
  );

  // since this component may be initially rendered as disabled but then soon enabled,
  // we need to manually control autofocus so it only triggers after the component
  // is enabled
  useEffect(() => {
    if (
      !autoFocusTriggered &&
      !props.disabled &&
      props.autoFocus &&
      // @ts-expect-error
      inputRef?.current
    ) {
      setAutoFocusTriggered(true);

      // @ts-expect-error
      inputRef.current.focus();
    }
  }, [autoFocusTriggered, props.disabled, props.autoFocus, inputRef]);

  return (
    <TextareaAutosize
      required={props.required}
      ref={inputRef}
      minRows={props.minRows}
      maxRows={props.maxRows}
      role={props.role}
      style={props.style}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      className={props.className}
      onChange={handleChange}
      autoComplete={props.autoComplete}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
      maxLength={props.maxLength}
    />
  );
};

export default React.memo(TextArea);
