import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import Avatar from 'views/Widgets/People/Avatar';
import { BasicPerson } from 'types';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import RichTextViewer from 'views/Widgets/Inputs/RichTextViewer';
import { WrittenResponse } from './utils';
import { parseAndFormatQuestionResponse } from 'utils/models/Performance';
import { useIntl } from 'react-intl';

export interface Props {
  question: WrittenResponse;
  person: BasicPerson;
  key: string;
}

export const PerformanceWrittenMultiResponse: FC<Props> = (props: Props) => {
  const { formatMessage, locale } = useIntl();
  const q = useMemo(() => props.question, [props.question]);

  if (q.question?.type?.toUpperCase() === INPUT_TYPES.SECTION) {
    return (
      <h3 className="header-title mb-3">
        <RichTextViewer model={q.question.label} expanded={true} />
      </h3>
    );
  }

  return (
    <Card key={props.key}>
      <CardBody>
        <h4 className="mb-3">
          <RichTextViewer model={q.title} expanded={true} />
        </h4>
        {q.responses.map((r, index) => (
          <Row key={index} className="flex-nowrap mt-4">
            <Col className="col-auto">
              <Avatar size="sm" person={r.person} />
            </Col>
            <Col className="ms-n3">
              <div>
                <div
                  className="comment-body d-block px-3"
                  style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                >
                  <Row>
                    <Col>
                      <div className="mb-0 py-1">
                        <div>
                          <div className="fr-view">
                            <p className="">
                              {parseAndFormatQuestionResponse({
                                q: r,
                                locale,
                                formatMessage,
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </CardBody>
    </Card>
  );
};
