import { CAMPAIGN_STATUSES, getPhaseByType } from '../../utils/models/Campaign';
import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_ASSESS_MANAGER,
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION,
  PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED,
  PERFORMANCE_FEATURE_HIDE_PULSE_ANONYMOUS_DESCRIPTION,
  PERFORMANCE_FEATURE_PEER_FEEDBACK,
  PHASE_TYPE_OTHERS,
  getCampaignFeature,
  getPerformanceFeatureEnabled,
  getStepNumber,
  perfCampaignCallback,
  updateLatestPerfStep,
} from '../../utils/models/Performance';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router';

import PeopleInputForm from '../../assets/img/illustrations/people-input-form.png';
import PerformancePage from './PerformancePage';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { getPrettyDate } from '../../utils/util/util';
import { setCurrentPerfSurveyResponse } from '../../actions';

const PerformanceStepOthersPhaseWelcome: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();

  const location = useLocation();
  const history = useHistory();

  const campaign = props.campaign;

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const propsDemoPeople = props.demoPeople;

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.currentPerfSurveyResponse,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.currentPerfSurveyResponse,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const callback = useCallback(
    (data) => {
      if (data) {
        const newSr = updateLatestPerfStep(
          props.currentPerfSurveyResponse,
          currentStepNumber
        );
        propsSetCurrentPerfSurveyResponse(newSr);
        perfCampaignCallback(
          props.me,
          props.currentOrganization,
          campaign,
          newSr,
          history,
          propsDemoPeople,
          data,
          formatMessage
        );
      }
    },
    [
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      campaign,
      history,
      propsDemoPeople,
      formatMessage,
    ]
  );

  const object = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? // @ts-expect-error
          props.currentPerfSurveyResponse.id
        : undefined,
      // @ts-expect-error
      campaign: campaign.id,
      step: currentStepNumber,
      responses: {},
    }),
    // @ts-expect-error
    [currentStepNumber, campaign.id, props.currentPerfSurveyResponse]
  );

  const othersPhaseEndDateString = getPhaseByType(
    campaign,
    PHASE_TYPE_OTHERS
  )?.end_date;

  const peerEnabled = getPerformanceFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_PEER_FEEDBACK
  );

  const managerEnabled = getPerformanceFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_ASSESS_MANAGER
  );
  const organizationEnabled =
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
    ) ||
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED
    );

  let introduction = '';

  if (peerEnabled && managerEnabled && organizationEnabled) {
    // @ts-expect-error
    introduction = (
      <FormattedMessage
        id="app.views.performance.phase_intro_all"
        defaultMessage="You'll give feedback about your peers, manager, and experience at {organization}."
        values={{
          // @ts-expect-error
          organization: props.currentOrganization?.name,
        }}
      />
    );
  } else if (peerEnabled && managerEnabled) {
    // @ts-expect-error
    introduction = (
      <FormattedMessage
        id="app.views.performance.phase_intro_peers_manager"
        defaultMessage="You'll give feedback about your peers and manager."
      />
    );
  } else if (peerEnabled && organizationEnabled) {
    // @ts-expect-error
    introduction = (
      <FormattedMessage
        id="app.views.performance.phase_intro_peers_organization"
        defaultMessage="You'll give feedback about your peers and experience at {organization}."
        values={{
          // @ts-expect-error
          organization: props.currentOrganization?.name,
        }}
      />
    );
  } else if (managerEnabled && organizationEnabled) {
    // @ts-expect-error
    introduction = (
      <FormattedMessage
        id="app.views.performance.phase_intro_manager_organization"
        defaultMessage="You'll give feedback about your manager and experience at {organization}."
        values={{
          // @ts-expect-error
          organization: props.currentOrganization?.name,
        }}
      />
    );
  } else if (peerEnabled) {
    // @ts-expect-error
    introduction = (
      <FormattedMessage
        id="app.views.performance.phase_intro_peers"
        defaultMessage="You'll give feedback about your peers."
      />
    );
  } else if (managerEnabled) {
    // @ts-expect-error
    introduction = (
      <FormattedMessage
        id="app.views.performance.phase_intro_manager"
        defaultMessage="You'll give feedback about your manager."
      />
    );
  } else if (organizationEnabled) {
    // @ts-expect-error
    introduction = (
      <FormattedMessage
        id="app.views.performance.phase_intro_organization"
        defaultMessage="You'll give feedback about your experience at {organization}."
        values={{
          // @ts-expect-error
          organization: props.currentOrganization?.name,
        }}
      />
    );
  } else {
    // @ts-expect-error
    introduction = (
      <FormattedMessage
        id="app.views.performance.phase_intro_fallback"
        defaultMessage="You'll give feedback."
        values={{
          // @ts-expect-error
          organization: props.currentOrganization?.name,
        }}
      />
    );
  }

  return (
    <PerformancePage
      campaign={campaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_others_phase_welcome.title.start_feedback_phase',
        defaultMessage: 'Start feedback phase',
      })}
    >
      <Row>
        <Col className="col-md-8 order-2 order-md-1">
          <div className="mb-4">{introduction}</div>
          {!getCampaignFeature(
            campaign,
            PERFORMANCE_FEATURE_HIDE_PULSE_ANONYMOUS_DESCRIPTION
          ) && (
            <div className="mb-4">
              <FormattedMessage
                id="app.views.performance.performance_step_others_phase_welcome.our_goal"
                defaultMessage="
              Our goal is to collect candid feedback we can take immediate
              action on to continue to make {organizationName}  a
              great place to work. Your responses will remain completely
              confidential, so please be open and honest."
                values={{
                  // @ts-expect-error
                  organizationName: props.currentOrganization?.name,
                }}
              />
            </div>
          )}
          <div className="mb-4">
            <FormattedMessage
              id="app.views.performance.performance_step_others_phase_welcome.you_have_time"
              defaultMessage="
            You will have until {date} to
            complete your responses. You can revise them any time until then."
              values={{
                date: getPrettyDate({
                  dateString: othersPhaseEndDateString,
                  locale,
                }),
              }}
            />
          </div>
          <ValidatedForm
            buttonClassName="mt-0"
            method={object.id ? 'PATCH' : 'POST'}
            url={
              // @ts-expect-error
              campaign.status === CAMPAIGN_STATUSES.DEMO
                ? undefined
                : 'survey-responses'
            }
            buttonIsBlock={false}
            object={object}
            inputs={[]}
            callback={callback}
            submitText={formatMessage({
              id: 'app.views.performance.performance_step_others_phase_welcome.submit_text.continue',
              defaultMessage: 'Continue',
            })}
          />
        </Col>
        <Col className="col-12 col-md-4 order-1 order-md-2 pb-4 pb-md-0">
          <img src={PeopleInputForm} className="w-100 pe-5" />
        </Col>
      </Row>
    </PerformancePage>
  );
};

const PerformanceStepOthersPhaseWelcome_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = InferProps<typeof PerformanceStepOthersPhaseWelcome_propTypes>;

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepOthersPhaseWelcome)));
