import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';

import AvatarGroup from 'views/Widgets/People/AvatarGroup';
import { Person } from 'types';
import React from 'react';

interface Props {
  questions?: { people: Person[]; title: string }[];
}

const PerformanceNetworkResponse: React.FC<Props> = (props) => {
  return (
    <>
      <Row className="mb-4">
        <Col>
          <Card>
            <CardBody>
              <ListGroup className="list-group-flush my-n3">
                {props?.questions &&
                  props.questions.map((q, index) => (
                    <ListGroupItem key={index}>
                      <Row className="flex-nowrap align-items-center">
                        <Col>{q.title}</Col>
                        {q.people?.length > 0 && (
                          <Col className="col-auto">
                            <AvatarGroup
                              size="xs"
                              isExternalUrl={true}
                              people={q.people}
                            />
                          </Col>
                        )}
                      </Row>
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PerformanceNetworkResponse;
