import {
  CAMPAIGN_STATUSES,
  getAdditionalManagersPeople,
  getBelievedAdditionalManagersPeople,
  getManagerPerson,
  getPeopleOnReceivingEnd,
  replaceRelationships,
} from '../../utils/models/Campaign';
import {
  INPUT_TYPES,
  getPeopleOrAllQuery,
} from '../Widgets/Inputs/ValidatedInputTypes';
import {
  PERFORMANCE_FEATURE_MANAGER_UNEDITABLE,
  getCampaignHasFeatureEnabled,
  getStepNumber,
  perfCampaignCallback,
  updateLatestPerfStep,
} from '../../utils/models/Performance';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router';

import PerformancePage from './PerformancePage';
import { PerformanceProgressCompletion } from 'types';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { parseDateFromUnicode } from '../../utils/util/time';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { useFeatures } from 'utils/util/features';
import { useIntl } from 'react-intl';

const PerformanceStepBasics: FC<Props> = (props) => {
  const { formatMessage, formatDate } = useIntl();
  const { ifEnabled, isEnabled } = useFeatures();
  const location = useLocation();
  const history = useHistory();

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;

  const propsDemoPeople = props.demoPeople;

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.currentPerfSurveyResponse,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.currentPerfSurveyResponse,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const completionsToAddForPage: PerformanceProgressCompletion[] = useMemo(
    () =>
      ifEnabled(
        'additional_managers',
        [PerformanceProgressCompletion.BELIEVED_ADDITIONAL_MANAGERS],
        []
      ),
    [ifEnabled]
  );

  const relationshipTypes = useMemo(
    () =>
      ifEnabled(
        'additional_managers',
        [
          RELATIONSHIP_TYPES.REPORTS_TO,
          RELATIONSHIP_TYPES.USED_TO_REPORT_TO,
          RELATIONSHIP_TYPES.BELIEVES_TO_REPORT_TO_ADDITIONAL_MANAGER,
        ],
        [RELATIONSHIP_TYPES.REPORTS_TO, RELATIONSHIP_TYPES.USED_TO_REPORT_TO]
      ),
    [ifEnabled]
  );

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const callback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(
          updateLatestPerfStep(
            props.currentPerfSurveyResponse,
            currentStepNumber
          )
        );
        // NOTE: we don't handle demo case here as we don't send request to server at all
        setCampaign(
          replaceRelationships(campaign, relationshipTypes, data.relationships)
        );
        perfCampaignCallback(
          props.me,
          props.currentOrganization,
          campaign,
          props.currentPerfSurveyResponse,
          history,
          propsDemoPeople,
          data,
          formatMessage
        );
      }
    },
    [
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      setCampaign,
      campaign,
      relationshipTypes,
      history,
      propsDemoPeople,
      formatMessage,
    ]
  );

  const me = props.me;
  const myManager = getManagerPerson(me, campaign, props.demoPeople, true);
  const currentCompletions = useMemo(
    () =>
      new Set<PerformanceProgressCompletion>(
        // @ts-expect-error
        props.currentPerfSurveyResponse?.configs?.completions ?? []
      ),
    // @ts-expect-error
    [props.currentPerfSurveyResponse?.configs?.completions]
  );

  const believedAdditionalManagers = useMemo(() => {
    if (!isEnabled('additional_managers')) {
      return [];
    }

    // Return the value set if the step has already been completed
    if (
      currentCompletions.has(
        PerformanceProgressCompletion.BELIEVED_ADDITIONAL_MANAGERS
      )
    ) {
      return getBelievedAdditionalManagersPeople(
        me,
        campaign,
        props.demoPeople
      );
    }
    // Otherwise, return the value that that hr has set
    return getAdditionalManagersPeople(me, campaign, props.demoPeople);
  }, [isEnabled, currentCompletions, props.demoPeople, me, campaign]);

  const object = useMemo(
    () => ({
      current_manager: myManager,
      believed_additional_managers: believedAdditionalManagers,
      past_managers: getPeopleOnReceivingEnd(
        campaign,
        RELATIONSHIP_TYPES.USED_TO_REPORT_TO,
        // @ts-expect-error
        me
      ),
    }),
    [me, myManager, campaign, believedAdditionalManagers]
  );

  const isDemoOrPreviewMode = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [campaign?.status]
  );

  const getDemoPerson = useCallback(
    // @ts-expect-error
    (id) => props.demoPeople.find((p) => p.id === id),
    [props.demoPeople]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      // Manager sometimes null (eg for CEO), handle that here
      let currentManager = [];

      if (object.current_manager) {
        const to_person = isDemoOrPreviewMode
          ? object.current_manager
          : object.current_manager.id;

        currentManager = [
          // @ts-expect-error
          {
            type: RELATIONSHIP_TYPES.REPORTS_TO,
            to_person,
          },
        ];
      }

      if (isDemoOrPreviewMode && currentManager.length > 0) {
        // @ts-expect-error
        currentManager[0].from_person = me;
      }

      const pastManagers = object.past_managers?.map((p) => ({
        type: RELATIONSHIP_TYPES.USED_TO_REPORT_TO,
        to_person: isDemoOrPreviewMode ? getDemoPerson(p.id) : p.id,
      }));

      const believedAdditionalManagers =
        object?.believed_additional_managers?.map((p) => ({
          type: RELATIONSHIP_TYPES.BELIEVES_TO_REPORT_TO_ADDITIONAL_MANAGER,
          to_person: isDemoOrPreviewMode ? getDemoPerson(p.id) : p.id,
        })) ?? [];

      return {
        // @ts-expect-error
        campaign: campaign.id,
        step: currentStepNumber,
        types: relationshipTypes,
        completions: completionsToAddForPage,
        relationships: [
          ...currentManager,
          ...believedAdditionalManagers,
          ...pastManagers,
        ],
      };
    },
    [
      isDemoOrPreviewMode,
      // @ts-expect-error
      campaign.id,
      currentStepNumber,
      relationshipTypes,
      me,
      getDemoPerson,
      completionsToAddForPage,
    ]
  );

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (myManager && !obj.current_manager) {
        errors['current_manager'] = 'This field is required.';
      }

      const invalidPeople = obj?.past_managers?.filter((p) => !p.id);

      if (invalidPeople?.length > 0) {
        if (invalidPeople.length === 1) {
          errors['past_managers'] =
            '"' + invalidPeople[0].email + '" was not found.';
        } else {
          errors['past_managers'] =
            'The following people were not found: "' +
            invalidPeople.map((p) => p.email).join('", "') +
            '"';
        }
      }

      return errors;
    },
    [myManager]
  );

  const managerIsUneditable = getCampaignHasFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_MANAGER_UNEDITABLE
  );

  const currentManagerQuestion = useMemo(() => {
    let label = formatMessage({
      id: 'app.views.performance.performance_step_basics.current_manager.label',
      defaultMessage: 'Who is your current manager?',
    });
    let disabled = false;
    let noManagerText;

    if (managerIsUneditable) {
      label = formatMessage({
        id: 'app.views.performance.performance_step_basics.this_is_your_manager.label',
        defaultMessage:
          'This is your manager for this cycle. If you have any questions, contact your HR/People admin.',
      });
      disabled = true;

      if (!myManager) {
        noManagerText = formatMessage({
          id: 'app.views.performance.performance_step_basics.no_manager.label',
          defaultMessage: 'No manager provided for this cycle',
        });
      }
    }

    const questionObj = {
      type: INPUT_TYPES.SELECT,
      name: 'current_manager',
      label: label,
      disabled: disabled,
      clearable: false,
      elasticsearchOptions: {
        index: 'people',
        url: 'get-people-by-name',
        getQuery: getPeopleOrAllQuery,
      },
    };

    if (noManagerText) {
      // @ts-expect-error
      questionObj.placeholder = 'No manager provided for this cycle';
    }

    return questionObj;
  }, [formatMessage, managerIsUneditable, myManager]);

  const currentAdditionalManagersQuestions = useMemo(() => {
    return ifEnabled(
      'additional_managers',
      [
        {
          type: INPUT_TYPES.PEOPLE_EDITOR,
          isDemoMode: isDemoOrPreviewMode,
          name: 'believed_additional_managers',
          label: formatMessage({
            id: 'app.views.performance.performance_step_basics.title.additional_managers',
            defaultMessage: 'Who are you current additional managers, if any?',
          }),
          helperText: formatMessage({
            id: 'app.views.performance.performance_step_basics.helper_text.additional_managers',
            defaultMessage:
              'In addition to your direct manager, any additional managers will also be providing performance feedback. For example, this can be a dotted line manager or a project lead you have indirectly reported to during the performance period.',
          }),
          autoFocus: true,
        },
      ],
      []
    );
  }, [formatMessage, ifEnabled, isDemoOrPreviewMode]);

  return (
    <PerformancePage
      campaign={campaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_basics.title.lets_start_with_the_basics',
        defaultMessage: "Let's start with the basics.",
      })}
    >
      <></>
      <ValidatedForm
        method="POST"
        url={isDemoOrPreviewMode ? undefined : 'relationships-list'}
        draftAutosaveEnabled={!isDemoOrPreviewMode}
        // @ts-expect-error
        uniqueFormKey={`campaign-${campaign.id}-self-reflection-basics`}
        callback={callback}
        buttonIsBlock={false}
        buttonClassName="mt-0"
        submitText={formatMessage({
          id: 'app.views.performance.performance_step_basics.submit_text.save_and_continue',
          defaultMessage: 'Save and continue',
        })}
        transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        object={object}
        onValidate={onValidate}
        inputs={[
          currentManagerQuestion,
          {
            type: INPUT_TYPES.PEOPLE_EDITOR,
            isDemoMode: isDemoOrPreviewMode,
            name: 'past_managers',
            label: formatMessage(
              {
                id: 'app.views.performance.performance_step_basics.title.list_any_other_managers_you_have_had_from_campaign_coverage_date',
                defaultMessage:
                  'List any other managers you have had from {coverageStartDate} to {coverageEndDate}.',
              },
              {
                coverageStartDate: formatDate(
                  parseDateFromUnicode(
                    // @ts-expect-error
                    campaign.coverage_start_date,
                    'yyyy-MM-dd'
                  ),
                  {
                    month: 'long',
                    year: 'numeric',
                  }
                ),
                coverageEndDate: formatDate(
                  parseDateFromUnicode(
                    // @ts-expect-error
                    campaign.coverage_end_date,
                    'yyyy-MM-dd'
                  ),
                  {
                    month: 'long',
                    year: 'numeric',
                  }
                ),
              }
            ),
            helperText: isEnabled('additional_managers')
              ? formatMessage({
                  id: 'app.views.performance.performance_step_basics.helper_text.this_context_is_shared_additional_managers',
                  defaultMessage:
                    ' This context is shared with your current direct manager, additional managers and HR/People admins. If your manager never changed, leave this blank.',
                })
              : formatMessage({
                  id: 'app.views.performance.performance_step_basics.helper_text.this_context_is_shared',
                  defaultMessage:
                    'This context is shared with your current manager and HR/People admins. If your manager never changed, leave this blank.',
                }),
            // even if the original manager question is enabled, we don't want
            // to default autofocus there because the vast majority of the
            // time the manager is expected, and we don't want to predispose
            // people to be confused to edit that field if it is fine.
            autoFocus: true,
          },
          ...currentAdditionalManagersQuestions,
        ]}
      />
    </PerformancePage>
  );
};

const PerformanceStepBasics_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = InferProps<typeof PerformanceStepBasics_propTypes>;

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepBasics)));
