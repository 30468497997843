import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import SimplePage from '../Layout/Pages/SimplePage';

const PageUnprovisionedUser: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <SimplePage
      title={formatMessage({
        id: 'app.error.auth.user_blocked.title',
        defaultMessage: "You don't have access to Confirm.",
      })}
      titleClassName="text-center"
    >
      <div className="text-center">
        <p>
          <FormattedMessage
            id="app.error.auth.user_blocked"
            defaultMessage="You don't have access to Confirm. Please reach out to your HR team to request access."
          />
        </p>
      </div>
    </SimplePage>
  );
};

export default React.memo(PageUnprovisionedUser);
