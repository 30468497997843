import { Col, Progress, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, type RouteComponentProps } from 'react-router-dom';
import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import {
  getCurrentPerformancePreviewPathPrefix,
  getPerformancePhases,
  getPerformancePhasesDisplayObjects,
  getPerformanceStepIsCompleted,
  getPhaseDateHasPassed,
  getPhaseDateHasStarted,
  getPhaseDisplayName,
  getPhaseIsComplete,
  isParticipatingInPhase,
} from '../../utils/models/Performance';
import { useHistory, useLocation, withRouter } from 'react-router';

import Avatar from '../Widgets/People/Avatar';
import { CAMPAIGN_STATUSES } from '../../utils/models/Campaign';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { getPrettyDate } from '../../utils/util/util';

const PerformanceNavLink = (props) => {
  const location = useLocation();

  if (props.to === location.pathname && location.search?.length === 0) {
    return <span className="fw-bold">{props.children}</span>;
  } else {
    return (
      <Link to={props.to} style={{ color: '#6E84A3' }}>
        {props.children}
      </Link>
    );
  }
};

const PerformanceNav: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  // @ts-expect-error
  const campaign = props.campaign;
  const location = useLocation();
  const history = useHistory();
  const [showCompletedPhasesList, setShowCompletedPhasesList] = useState([]);
  const [showProgressOnMobile, setShowProgressOnMobile] = useState(false);
  const toggleShowProgressOnMobile = useCallback(
    () => setShowProgressOnMobile(!showProgressOnMobile),
    [showProgressOnMobile]
  );
  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();

  const goToPhase = useCallback(
    (phaseIndex) => {
      // @ts-expect-error
      history.push(previewPathPrefix + props.phases[phaseIndex].steps[0].path);
    },
    [history, previewPathPrefix, props.phases]
  );

  const currentPhaseIndex = useMemo(
    () =>
      props.phases &&
      props.phases.findIndex(
        (s) =>
          // @ts-expect-error
          s?.steps &&
          // @ts-expect-error
          s.steps.findIndex(
            (step) => previewPathPrefix + step.path === location.pathname
          ) !== -1
      ),
    [location.pathname, previewPathPrefix, props.phases]
  );

  const currentStepIndex = useMemo(
    () =>
      // @ts-expect-error
      props.phases[currentPhaseIndex]?.steps &&
      // @ts-expect-error
      props.phases[currentPhaseIndex].steps.findIndex(
        (step) => previewPathPrefix + step.path === location.pathname
      ),
    [currentPhaseIndex, previewPathPrefix, location.pathname, props.phases]
  );

  const performancePhases = useMemo(
    () =>
      getPerformancePhases(
        props.me,
        props.currentOrganization,
        campaign,
        props.currentPerfSurveyResponse,
        props.demoPeople,
        formatMessage
      ),
    [
      props.currentOrganization,
      campaign,
      props.currentPerfSurveyResponse,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  /*
  const displayPhases = useMemo(
    () => getPerformancePhasesDisplayObjects(props.phases),
    [props.phases]
  );
  */

  const displayPhases = useMemo(() => {
    const phases = getPerformancePhasesDisplayObjects(props.phases)
      .map((p, i) => {
        const progress =
          performancePhases[i]?.steps?.length <= 1
            ? 100
            : (currentStepIndex / (performancePhases[i]?.steps?.length - 1)) *
              100;

        const isClosed =
          campaign.status !== CAMPAIGN_STATUSES.DEMO &&
          getPhaseDateHasPassed(p?.end_date);

        const isOpen =
          campaign.status === CAMPAIGN_STATUSES.DEMO ||
          (p?.start_date && getPhaseDateHasStarted(p?.start_date) && !isClosed);

        const isComplete = CAMPAIGN_STATUSES.DEMO
          ? true
          : currentPhaseIndex > i;

        const isInactive = isClosed || !isOpen;

        return {
          ...p,
          progress,
          isClosed,
          isOpen,
          isComplete,
          isInactive,
        };
      })
      .filter((p) =>
        isParticipatingInPhase(p?.type, props.currentPerfSurveyResponse)
      );
    phases.forEach((p, i) => {
      const isEarlierPhaseIncomplete =
        campaign?.status !== CAMPAIGN_STATUSES.DEMO &&
        i > 0 &&
        !getPhaseIsComplete(
          phases[i - 1]?.type,
          props.currentPerfSurveyResponse
        ) &&
        !phases[i - 1]?.isClosed;
      p.isEarlierPhaseIncomplete = isEarlierPhaseIncomplete;
    });
    return phases;
  }, [
    currentPhaseIndex,
    currentStepIndex,
    performancePhases,
    campaign.status,
    props.currentPerfSurveyResponse,
    props.phases,
  ]);

  return (
    <Row className="performance-nav pt-5 ps-3">
      <Col className="ps-0">
        <Row className="d-md-none" onClick={toggleShowProgressOnMobile}>
          <h6 className="header-pretitle text-primary mb-0">
            {showProgressOnMobile ? (
              <FormattedMessage
                id="app.views.performance.performance_page.hide_progress_steps"
                defaultMessage="Hide progress steps"
              />
            ) : (
              <FormattedMessage
                id="app.views.performance.performance_page.show_progress_steps"
                defaultMessage="Show progress steps"
              />
            )}
          </h6>
        </Row>
        <div className={(showProgressOnMobile ? '' : 'd-none ') + 'd-md-block'}>
          {displayPhases.map((phase, phaseIndex) => {
            return (
              <div key={phaseIndex} className="performance-nav-section">
                <h6
                  className="header-pretitle"
                  role={phase.isInactive ? undefined : 'button'}
                  onClick={
                    phase.isInactive ? undefined : () => goToPhase(phaseIndex)
                  }
                >
                  {getPhaseDisplayName(
                    campaign,
                    phase,
                    // @ts-expect-error
                    props.currentOrganization?.name
                  )}
                </h6>
                {phase.isEarlierPhaseIncomplete ? (
                  <div className="text-muted small mb-3 mt-n3">
                    <FormattedMessage
                      id="app.views.performance.performance_nav.complete_previous"
                      defaultMessage="
                    Complete previous section first

                  "
                    />
                  </div>
                ) : (
                  <>
                    {!phase.isOpen && !phase.isClosed && (
                      <div className="text-muted small mb-3 mt-n3">
                        <FormattedMessage
                          id="app.views.performance.performance_nav.starts"
                          defaultMessage="
                        Starts {date}"
                          values={{
                            date: getPrettyDate({
                              dateString: phase?.start_date,
                              locale,
                            }),
                          }}
                        />
                      </div>
                    )}
                    {phase.isClosed && (
                      <div className="text-muted small mb-3 mt-n3">
                        <FormattedMessage
                          id="app.views.performance.performance_nav.ended"
                          defaultMessage="
                        Ended {date}"
                          values={{
                            date: getPrettyDate({
                              dateString: phase?.end_date,
                              locale,
                            }),
                          }}
                        />
                      </div>
                    )}
                    {phase.isOpen &&
                      phase.isCompleted &&
                      currentPhaseIndex !== phaseIndex &&
                      // @ts-expect-error
                      showCompletedPhasesList.indexOf(phaseIndex) === -1 && (
                        <div
                          className="text-muted small mb-3 mt-n3"
                          role="button"
                          onClick={() =>
                            setShowCompletedPhasesList([
                              // @ts-expect-error
                              ...showCompletedPhasesList,
                              // @ts-expect-error
                              phaseIndex,
                            ])
                          }
                        >
                          <FormattedMessage
                            id="app.views.performance.performance_nav.show_completed"
                            defaultMessage="
                          Show completed
                          "
                          />
                          <i
                            className="fe fe-chevron-down ms-1"
                            style={{ fontSize: '0.5rem' }}
                          />
                        </div>
                      )}
                    {phase.isOpen &&
                      (CAMPAIGN_STATUSES.DEMO || !phase.isCompleted) &&
                      currentPhaseIndex === phaseIndex && (
                        <Progress
                          className="progress-sm w-75 mb-4"
                          color="success"
                          // advance by 1 for phase 2 to account for "all done" step
                          value={phase.progress}
                        />
                      )}
                    {phase.isOpen &&
                      (!phase.isCompleted ||
                        currentPhaseIndex === phaseIndex ||
                        // @ts-expect-error
                        showCompletedPhasesList.indexOf(phaseIndex) !== -1) && (
                        <ul className="checklist-nav">
                          {phase.steps?.map((step, stepIndex) => {
                            if (step.ignoreInNav) {
                              return <Fragment key={stepIndex} />;
                            }

                            const stepIsCompletedPerData =
                              getPerformanceStepIsCompleted(
                                step.path,
                                props.me,
                                props.currentOrganization,
                                campaign,
                                props.currentPerfSurveyResponse,
                                props.demoPeople,
                                formatMessage
                              )
                                ? true
                                : false;

                            const previousStepIsCompletedPerData =
                              stepIndex === 0
                                ? true
                                : getPerformanceStepIsCompleted(
                                    phase.steps[stepIndex - 1].path,
                                    props.me,
                                    props.currentOrganization,
                                    campaign,
                                    props.currentPerfSurveyResponse,
                                    props.demoPeople,
                                    formatMessage
                                  )
                                ? true
                                : false;

                            const stepIsCompleted =
                              (campaign.status === CAMPAIGN_STATUSES.DEMO &&
                                (currentPhaseIndex > phaseIndex ||
                                  (currentPhaseIndex === phaseIndex &&
                                    currentStepIndex > stepIndex))) ||
                              stepIsCompletedPerData;

                            const previousStepIsCompleted =
                              stepIndex === 0 ||
                              (campaign.status === CAMPAIGN_STATUSES.DEMO &&
                                (currentPhaseIndex > phaseIndex ||
                                  (currentPhaseIndex === phaseIndex &&
                                    currentStepIndex > stepIndex - 1))) ||
                              previousStepIsCompletedPerData;

                            const stepPath = previewPathPrefix + step.path;

                            return (
                              <li key={stepIndex} className="mb-2">
                                <i
                                  className={
                                    stepIsCompleted
                                      ? 'fe fe-check text-success'
                                      : ''
                                  }
                                />
                                {(stepIsCompleted ||
                                  previousStepIsCompleted) && (
                                  <PerformanceNavLink
                                    className="active"
                                    history={props.history}
                                    to={stepPath}
                                  >
                                    {step.name}
                                  </PerformanceNavLink>
                                )}
                                {!stepIsCompleted &&
                                  !previousStepIsCompleted && (
                                    <span className="text-muted">
                                      {step.name}
                                    </span>
                                  )}
                                {step.children && (
                                  <div className="ms-2 mt-2">
                                    {step.children.map((p, childIndex) => {
                                      const isCurrentChild =
                                        location.pathname === stepPath &&
                                        location.search ===
                                          '?index=' + childIndex;

                                      return isCurrentChild ? (
                                        <span
                                          key={childIndex}
                                          className="fw-bold"
                                        >
                                          <div className="mb-2">
                                            <Avatar size="xs" person={p} />
                                            <span className="ms-2">
                                              {p.full_name}
                                            </span>
                                          </div>
                                        </span>
                                      ) : (
                                        <Fragment key={childIndex}>
                                          {(stepIsCompleted ||
                                            previousStepIsCompleted) && (
                                            <Link
                                              to={
                                                stepPath +
                                                '?index=' +
                                                childIndex
                                              }
                                              style={{ color: '#6E84A3' }}
                                            >
                                              <div className="mb-2">
                                                <Avatar size="xs" person={p} />
                                                <span className="ms-2">
                                                  {p.full_name}
                                                </span>
                                              </div>
                                            </Link>
                                          )}
                                          {!stepIsCompleted &&
                                            !previousStepIsCompleted && (
                                              <div className="mb-2">
                                                <Avatar size="xs" person={p} />
                                                <span className="ms-2">
                                                  {p.full_name}
                                                </span>
                                              </div>
                                            )}
                                        </Fragment>
                                      );
                                    })}
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                  </>
                )}
              </div>
            );
          })}
          {campaign?.status === CAMPAIGN_STATUSES.DEMO && (
            <span className="badge rounded-pill bg-info-soft mb-4">
              <FormattedMessage
                id="app.views.performance.performance_nav.preview_mode"
                defaultMessage="
              Preview mode
            "
              />
            </span>
          )}
        </div>
      </Col>
    </Row>
  );
};

const PerformanceNav_propTypes = {
  phases: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = PropTypes.InferProps<typeof PerformanceNav_propTypes> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

export default connect(mapStateToProps)(withRouter(React.memo(PerformanceNav)));
