import * as consts from '../../../consts/consts';

import {
  CAMPAIGN_STATUSES,
  getPhaseByType,
  hasPhase,
} from '../../../utils/models/Campaign';
import {
  CampaignWithConfigs,
  OrganizationSettings,
  Person,
  PersonProfileDeltasMode,
  ReduxState,
} from '../../../types';
import {
  ChartCoordinates,
  DataValues,
  PerformanceHistoryData,
  RatingValues,
  TENURE_GAP_SIZE,
  chartAlertPointStyle,
  computeDataValues,
  computeLabels,
  computeRatingData,
  lineBaseStyle,
} from './Trajectory/HistoryChartUtils';
import {
  PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_SELF,
  getCampaignHasFeatureEnabledDefaultOn,
  getCampaignHasONA,
  getCampaignRatings,
  getPhaseDateHasPassed,
} from '../../../utils/models/Performance';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
} from 'react';

import { isEnabledWithDefault } from 'utils/util/util';
import { useFeatures } from 'utils/util/features';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export interface PersonPerformanceContextData {
  firstName: string;
  canDisplayOna: boolean;
  showGoldStarsAndInfluenceToRecipient: boolean;
  displayRatingWidgets: boolean;
  displayCharts: boolean;
  canDisplayDeltas: boolean;
  performanceHistoryData: PerformanceHistoryData[];
  selectedCampaignData?: PerformanceHistoryData;
  previousCampaignDataWithOna?: PerformanceHistoryData;
  previousCampaignDataWithRating?: PerformanceHistoryData;
  managerRatingHistoricalData?: ManagerRatingHistoricalData;
  networkRecognitionHistoricalData?: NetworkRecognitionHistoricalData;
}

interface PerformanceCampaign extends CampaignWithConfigs {
  is_admin: boolean;
  status: string;
}

// Represents the properties for a chart dataset
interface ChartDataset {
  data: Array<{ x: number; y: number }>;
  pointStyle?: (ctx: any) => any;
  showLine: boolean;
  borderColor?: string;
  borderDash?: number[];
  label?: string;
}

// Defines the options for a chart
interface ChartOptions {
  x: {
    label: string;
    tickMin: number;
    tickMax: number;
    tickSize: number;
  };
  y: {
    label: string;
    tickMax: number;
    tickSize?: number;
    maxTicksLimit: number;
  };
}

// Defines the properties for a manager rating chart
interface ManagerRatingHistoricalData {
  chartData: {
    datasets: ChartDataset[];
  };
  chartOptions: ChartOptions;
  dataValues: DataValues;
  ratingValues: RatingValues;
}

// Defines the properties for a network recognition chart
interface NetworkRecognitionHistoricalData {
  data: PerformanceHistoryData[];
  chartData: {
    datasets: ChartDataset[];
  };
  chartOptions: ChartOptions;
  chartOverlaps: {
    individual: { [index: number]: string[] };
    tenure: { [index: number]: string[] };
    baseline: { [index: number]: string[] };
  };
  dataValues: DataValues;
  ratingValues: RatingValues;
}

interface PersonPerformanceContextProviderProps {
  campaign: PerformanceCampaign;
  performanceHistoryData: PerformanceHistoryData[];
  person?: Person;
  meId?: number;
  isInReviewFlow: boolean;
  showManagerOnlyPerformanceDetails: boolean;
  setDisplayRatingWidgets?: (value: boolean) => void;
}

const PersonPerformanceContext = createContext<PersonPerformanceContextData>({
  firstName: '',
  canDisplayOna: false,
  showGoldStarsAndInfluenceToRecipient: false,
  displayRatingWidgets: false,
  displayCharts: false,
  canDisplayDeltas: false,
  performanceHistoryData: [],
  selectedCampaignData: undefined,
  previousCampaignDataWithOna: undefined,
  previousCampaignDataWithRating: undefined,
  managerRatingHistoricalData: undefined,
  networkRecognitionHistoricalData: undefined,
});

const getCanDisplayDeltas = (
  deltasMode: PersonProfileDeltasMode,
  isViewingSelf: boolean,
  showManagerOnlyPerformanceDetails: boolean
) => {
  switch (deltasMode) {
    case PersonProfileDeltasMode.EXCEPT_SELF:
      return !isViewingSelf && showManagerOnlyPerformanceDetails;
    case PersonProfileDeltasMode.ALL:
      return true;
    case PersonProfileDeltasMode.OFF:
    default:
      return false;
  }
};

const findPreviousCampaignWithRating = (
  data: PerformanceHistoryData[],
  currentCampaignIndex: number
): PerformanceHistoryData | undefined => {
  const campaignsBefore = data.slice(0, currentCampaignIndex).reverse();
  return campaignsBefore.find(
    (x) => x.rating?.value != null && x?.is_eligible_for_reporting
  );
};

const findPreviousCampaignWithOna = (
  data: PerformanceHistoryData[],
  currentCampaignIndex: number
): PerformanceHistoryData | undefined => {
  const campaignsBefore = data.slice(0, currentCampaignIndex).reverse();
  return campaignsBefore.find((x) => getCampaignHasONA(x.campaign));
};

export const PersonPerformanceContextProvider: FC<
  PropsWithChildren<PersonPerformanceContextProviderProps>
> = ({
  campaign,
  person,
  meId,
  isInReviewFlow,
  setDisplayRatingWidgets,
  showManagerOnlyPerformanceDetails,
  performanceHistoryData,
  children,
}) => {
  const { formatMessage } = useIntl();
  const currentProxyPerson = useSelector<ReduxState, Person | undefined>(
    (state) => state.currentProxyPerson
  );

  const orgSettings = useSelector<ReduxState, OrganizationSettings>(
    (state) => state.settings
  );

  const { features } = useFeatures();

  const showGoldStarsAndInfluenceToRecipient = useMemo(() => {
    return getCampaignHasFeatureEnabledDefaultOn(
      campaign,
      PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT
    );
  }, [campaign]);

  const isAdmin = useMemo(() => campaign?.is_admin, [campaign?.is_admin]);

  const viewerId = currentProxyPerson?.id ?? meId;

  const isViewingSelf = viewerId != null && viewerId === person?.id;

  const displayRatingWidgets = useMemo(
    () => hasPhase(campaign, PHASE_TYPE_EVALUATION),
    [campaign]
  );

  const displayCharts = isEnabledWithDefault(
    features,
    consts.FLAGS.PERFORMANCE_PROFILE_ENABLE_CHARTS,
    true
  );

  useEffect(() => {
    if (setDisplayRatingWidgets) {
      setDisplayRatingWidgets(displayRatingWidgets);
    }
  }, [displayRatingWidgets, setDisplayRatingWidgets]);

  const selfPhaseHasClosed = useMemo(
    () =>
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      getPhaseDateHasPassed(
        getPhaseByType(campaign, PHASE_TYPE_SELF)?.end_date
      ),
    [campaign]
  );

  const hasSelfPhase = useMemo(
    () => hasPhase(campaign, PHASE_TYPE_SELF),
    [campaign]
  );

  // Computation for manager rating chart
  const managerRatingHistoricalData = useMemo(() => {
    if (!performanceHistoryData || !person) return undefined;

    const data = performanceHistoryData;
    const firstName = person?.given_name ?? '';

    // Compute rating values, data values, and labels
    const ratingValues = computeRatingData(data);
    const dataValues = computeDataValues(data, ratingValues);
    const labels = computeLabels(data || []);

    // Don't render the chart if we have less than 2 data points
    if (dataValues.ratings.data.length < 2) return undefined;

    // Compute max rating
    let maxRating = 1;
    data.forEach((d) => {
      const ratingScale = getCampaignRatings(d.campaign);
      if (ratingScale) {
        if (ratingScale[0].value > maxRating) {
          maxRating = ratingScale[0].value;
        }
        if (ratingScale[ratingScale.length - 1].value > maxRating) {
          maxRating = ratingScale[ratingScale.length - 1].value;
        }
      }
    });

    // Adding 1 here to create vertical buffer in case
    // highest rating has an alert
    maxRating += 1;

    const managerRatingChartData = {
      datasets: [
        {
          showLine: true,
          pointStyle: (x) => chartAlertPointStyle(dataValues, 'ratings', x),
          ...lineBaseStyle(firstName, '#2c7be5'),
          ...dataValues['ratings'],
        },
      ],
    };

    const managerRatingChartOptions = {
      x: {
        label: formatMessage({
          id: 'app.components.campaign_insight.manager_rating_options.x.label.tenure',
          defaultMessage: 'Tenure (months)',
        }),
        tickMin: labels[0],
        tickMax: labels[1],
        tickSize: TENURE_GAP_SIZE,
      },
      y: {
        label: formatMessage({
          id: 'app.components.campaign_insight.manager_rating_options.x.label.rating',
          defaultMessage: 'Rating',
        }),
        // need space if the (!) icon shows in the top row so it doesn't get cut off
        // (this is fine since we don't display the text on the left)
        tickMax: maxRating + 1,
        tickSize: 1,
        maxTicksLimit: 5,
        callback: () => {
          // we don't want to displat Y labels for rating names
          return '';
        },
      },
    };

    return {
      firstName: firstName,
      chartData: managerRatingChartData,
      chartOptions: managerRatingChartOptions,
      dataValues: dataValues,
      ratingValues: ratingValues,
    };
  }, [performanceHistoryData, person, formatMessage]);

  // Computation for network recognition chart
  const networkRecognitionHistoricalData = useMemo(() => {
    if (!performanceHistoryData || !person) return undefined;

    const data = performanceHistoryData?.filter((x) =>
      getCampaignHasONA(x.campaign)
    );
    const firstName = person?.given_name ?? '';

    const ratingValues = computeRatingData(data);
    const dataValues = computeDataValues(data, ratingValues);
    const labels = computeLabels(data || []);

    let maxONA = Math.ceil(
      Math.max(
        1,
        ...(data?.map((e) => e.gold_stars.length + e.influence.length || 0) ||
          []),
        ...(data?.map(
          (e) => e?.benchmarks?.organization?.tenure?.positive_ona_avg || 0
        ) || []),
        ...(data?.map(
          (e) => e?.benchmarks?.organization?.all?.positive_ona_avg || 0
        ) || [])
      )
    );

    maxONA = 5 * Math.ceil(maxONA / 5);

    const networkChartOptions = {
      x: {
        label: formatMessage({
          id: 'app.components.campaign_insight.network_chart_options.x.label.tenure',
          defaultMessage: 'Tenure (months)',
        }),
        tickMin: labels[0],
        tickMax: labels[1],
        tickSize: TENURE_GAP_SIZE,
      },
      y: {
        label: formatMessage({
          id: 'app.components.campaign_insight.network_chart_options.x.label.recognition',
          defaultMessage: 'Recognition',
        }),
        tickMax: maxONA + 5,
        maxTicksLimit: 5,
      },
    };

    const testOneChartData = () => {
      if (data) {
        let slope = 0;
        let intercept = 0;
        let maxTenure = 0;
        const dataPts: ChartCoordinates[] = [];

        data?.forEach((d) => {
          const benchmark = d?.benchmarks?.organization?.cross_tenure;

          const overallBenchmark = benchmark?.overall;
          slope += overallBenchmark?.slope ?? 0;
          intercept += overallBenchmark?.intercept ?? 0;

          const bucketBenchmark = benchmark?.buckets || {};
          Object.values(bucketBenchmark)?.forEach(
            (b) => (maxTenure = Math.max(maxTenure, b?.next_point))
          );

          dataPts.push({
            x: d?.tenure_months,
            y: (d?.gold_stars?.length ?? 0) + (d?.influence?.length ?? 0),
          });
        });

        const avgIntercept = Number((intercept / data.length).toFixed(2));
        const avgSlope = Number((slope / data.length).toFixed(2));

        return {
          datasets: [
            {
              data: dataPts,
              pointStyle: (x) =>
                chartAlertPointStyle(dataValues, 'individual', x),
              showLine: true,
              ...lineBaseStyle(firstName, '#2c7be5'),
            },
            {
              label: formatMessage({
                id: 'app.components.campaign_insight.test_one_chart_data.tenure_avg',
                defaultMessage: 'Tenure avg.',
              }),
              data: [
                { x: 0, y: avgIntercept },
                {
                  x: labels[1],
                  y: avgIntercept + avgSlope * labels[1],
                },
              ],
              showLine: true,
              borderColor: '#f6c343',
              borderDash: [5, 5],
            },
          ],
        };
      }
    };

    const computeChartOverlaps = (dataValues: any) => {
      const individualOverlaps: { [index: number]: string[] } = {};
      const tenureOverlaps: { [index: number]: string[] } = {};
      const baselineOverlaps: { [index: number]: string[] } = {};

      dataValues.individual.data.forEach((_, i) => {
        if (dataValues.individual.data[i] === dataValues.tenure.data[i]) {
          (individualOverlaps[i] ||= []).push('tenure');
          (tenureOverlaps[i] ||= []).push('individual');
        }

        if (dataValues.individual.data[i] === dataValues.baseline.data[i]) {
          (individualOverlaps[i] ||= []).push('baseline');
          (baselineOverlaps[i] ||= []).push('individual');
        }

        if (dataValues.tenure.data[i] === dataValues.baseline.data[i]) {
          (baselineOverlaps[i] ||= []).push('tenure');
          (tenureOverlaps[i] ||= []).push('baseline');
        }
      });

      return {
        individual: individualOverlaps,
        tenure: tenureOverlaps,
        baseline: baselineOverlaps,
      };
    };

    const chartOverlaps = computeChartOverlaps(dataValues);
    const chartData = testOneChartData() ?? { datasets: [] }; // Ensure chartData is always defined

    return {
      firstName: firstName,
      data: data,
      chartData: chartData,
      chartOptions: networkChartOptions,
      chartOverlaps: chartOverlaps,
      dataValues: dataValues,
      ratingValues: ratingValues,
    };
  }, [performanceHistoryData, person, formatMessage]);

  // non-admin people managers should not be able to see ONA until the self phase is finished
  const canDisplayOna =
    (isInReviewFlow || selfPhaseHasClosed || isAdmin) && hasSelfPhase;

  const deltasMode =
    orgSettings?.performance_profile_deltas_mode ??
    PersonProfileDeltasMode.EXCEPT_SELF;

  const canDisplayDeltas: boolean = getCanDisplayDeltas(
    deltasMode,
    isViewingSelf,
    showManagerOnlyPerformanceDetails
  );

  const selectedCampaignIndex = useMemo(() => {
    if (!campaign) return -1;
    return performanceHistoryData.findIndex(
      (data) => data.campaign.id === campaign.id
    );
  }, [performanceHistoryData, campaign]);

  const previousCampaignDataWithOna = useMemo(() => {
    return findPreviousCampaignWithOna(
      performanceHistoryData,
      selectedCampaignIndex
    );
  }, [performanceHistoryData, selectedCampaignIndex]);

  const previousCampaignDataWithRating = useMemo(() => {
    return findPreviousCampaignWithRating(
      performanceHistoryData,
      selectedCampaignIndex
    );
  }, [performanceHistoryData, selectedCampaignIndex]);

  const contextValue: PersonPerformanceContextData = useMemo(
    () => ({
      firstName: person?.given_name ?? '',
      canDisplayOna,
      showGoldStarsAndInfluenceToRecipient,
      displayRatingWidgets,
      displayCharts,
      canDisplayDeltas,
      performanceHistoryData,
      selectedCampaignData: performanceHistoryData[selectedCampaignIndex],
      previousCampaignDataWithOna,
      previousCampaignDataWithRating,
      managerRatingHistoricalData: managerRatingHistoricalData,
      networkRecognitionHistoricalData: networkRecognitionHistoricalData,
    }),
    [
      person,
      canDisplayOna,
      showGoldStarsAndInfluenceToRecipient,
      displayRatingWidgets,
      displayCharts,
      canDisplayDeltas,
      performanceHistoryData,
      selectedCampaignIndex,
      previousCampaignDataWithOna,
      previousCampaignDataWithRating,
      managerRatingHistoricalData,
      networkRecognitionHistoricalData,
    ]
  );

  return (
    <PersonPerformanceContext.Provider value={contextValue}>
      {children}
    </PersonPerformanceContext.Provider>
  );
};

export const PersonPerformanceContextConsumer =
  PersonPerformanceContext.Consumer;

export default PersonPerformanceContext;
