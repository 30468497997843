import { Col, Row } from 'reactstrap';
import React, { FC, useCallback, useMemo } from 'react';

import ReactTagsInput from './ReactTagsInput';
import { ReduxState } from '../../../types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';

const DEFAULT_ON_CHANGE = () => {};

export type ApiFilterItem = {
  key: string;
  value: string;
  descriptor: {
    title?: string;
    full_name?: string;
  };
};

export type FilterItem = {
  name: string;
  icon?: string;
  object: ApiFilterItem;
  description?: string;
};

export type IncludeExcludeValue = {
  include: FilterItem[];
  exclude: FilterItem[];
};

type Props = {
  value: IncludeExcludeValue;
  onChange?: (filters: IncludeExcludeValue) => void;
  itemsAreEqual?: (tag1: FilterItem, tag2: FilterItem) => boolean;
  suggestions: FilterItem[];
  disabled?: boolean;
  includePlaceholder?: string;
  excludePlaceholder?: string;
  includeClassName?: string;
};

const DEFAULT_ITEMS_ARE_EQUAL = (tag1: FilterItem, tag2: FilterItem): boolean =>
  isEqual(tag1.object, tag2.object);

const GenericIncludeExcludeFilter: FC<Props> = ({
  disabled = false,
  includePlaceholder,
  excludePlaceholder,
  includeClassName = '',
  suggestions,
  value,
  itemsAreEqual = DEFAULT_ITEMS_ARE_EQUAL,
  onChange = DEFAULT_ON_CHANGE,
}) => {
  const { formatMessage } = useIntl();

  const handleIncludeCallback = useCallback(
    (items) => {
      onChange({ include: items, exclude: value.exclude });
    },
    [onChange, value.exclude]
  );

  const handleExcludeCallback = useCallback(
    (items) => {
      onChange({ include: value.include, exclude: items });
    },
    [onChange, value.include]
  );

  const includeFilterInput = useMemo(() => {
    const placeholder =
      includePlaceholder ??
      formatMessage({
        id: 'app.widgets.people.include_filter_placeholder',
        defaultMessage: 'Filter by name or anything else',
      });

    return (
      <Col className={includeClassName}>
        <ReactTagsInput
          readOnly={disabled}
          tagsAreEqual={itemsAreEqual}
          value={value.include}
          allowNew={false}
          suggestions={suggestions}
          placeholder={placeholder}
          callback={handleIncludeCallback}
          useTagCards={true}
          disableLengthCheck={true}
        />
      </Col>
    );
  }, [
    includePlaceholder,
    formatMessage,
    includeClassName,
    disabled,
    itemsAreEqual,
    value.include,
    suggestions,
    handleIncludeCallback,
  ]);

  const excludeFilterInput = useMemo(() => {
    const placeholder =
      excludePlaceholder ??
      formatMessage({
        id: 'app.widgets.people.include_filter_placeholder',
        defaultMessage: 'Filter by name or anything else',
      });

    return (
      <Col className={includeClassName}>
        <ReactTagsInput
          readOnly={disabled}
          tagsAreEqual={itemsAreEqual}
          value={value.exclude}
          allowNew={false}
          suggestions={suggestions}
          placeholder={placeholder}
          callback={handleExcludeCallback}
          useTagCards={true}
          disableLengthCheck={true}
        />
      </Col>
    );
  }, [
    excludePlaceholder,
    formatMessage,
    includeClassName,
    disabled,
    itemsAreEqual,
    value.exclude,
    suggestions,
    handleExcludeCallback,
  ]);

  return (
    <>
      <div>
        <Row className="align-items-center">
          <Col className="col-auto ps-3">
            <i className="fe fe-filter d-flex justify-content-center" />
          </Col>
          {includeFilterInput}
        </Row>
        <Row className="mt-3 align-items-center">
          <Col className="col-auto ps-3">
            <i className="fe fe-user-x d-flex justify-content-center" />
          </Col>
          {excludeFilterInput}
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState): object => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};
export default connect(mapStateToProps)(GenericIncludeExcludeFilter);
