import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import AvatarGroup from '../../Widgets/People/AvatarGroup';
import { Person } from 'types';

export interface Props {
  people?: Person[];
}

const FeedbackProviderListWidget: FC<Props> = ({ people }) => {
  if (!people || people.length === 0) return <></>;

  return (
    <div className="d-flex gap-2 align-items-baseline">
      <p className="text-muted fs-4">
        <FormattedMessage
          id="app.views.person.person_performance.subtitle.provided_by_person"
          defaultMessage="Provided by"
        />
      </p>
      <AvatarGroup
        people={people}
        isExternalUrl={true}
        size="sm"
        popoverPlacement={'auto'}
      />
    </div>
  );
};

export default FeedbackProviderListWidget;
