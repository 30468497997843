import { type IntlShape } from 'react-intl';
import { SUBMIT_ERROR } from 'consts/consts';

export const formatErrorMessageIfPresent = (errorMessageOrObject) => {
  if (errorMessageOrObject) {
    return formatErrorMessage(errorMessageOrObject);
  }
  return errorMessageOrObject;
};

export const isWhiteListedErrorMessage = (errorMessage: string) => {
  return (
    // AxiosError: Request aborted
    // => https://github.com/axios/axios/issues/4967
    // https://confirm.sentry.io/issues/4641461594/?project=1891157&query=&referrer=issue-stream&statsPeriod=30d&stream_index=4
    errorMessage.includes('AxiosError: Request aborted') ||
    // Invalid call to runtime.sendMessage(). Tab not found."
    // => https://stackoverflow.com/questions/78946123/chrome-scripting-executescript-fails-with-tab-not-found-error-after-creating-a
    // https://confirm.sentry.io/issues/6110736718/?project=1891157&referrer=issue-stream&statsPeriod=30d&stream_index=0
    errorMessage.includes(
      'Invalid call to runtime.sendMessage(). Tab not found.'
    )
  );
};

// Type of actions:
// We can expand this deciding for example to Auto-reload the page, or to show a modal.
type ErrorAction = 'DISPLAY_USER_ONLY' | 'DISPLAY_SENTRY_AND_USER';

type ErrorStrategy = {
  action: ErrorAction;
  errorMessage: string;
};

export const getErrorStrategyForAuthorizationMessage = (
  errorMessage: string,
  formatMessage: IntlShape['formatMessage']
): ErrorStrategy => {
  if (errorMessage.includes('Invalid authorization code')) {
    return {
      errorMessage: formatMessage({
        id: 'app.error.auth.invalid_auth_code',
        defaultMessage:
          'Invalid authorization code. Log out and log-in back again, or refresh this page.',
      }),
      action: 'DISPLAY_USER_ONLY',
    };
  } else if (
    errorMessage.includes("Timeout when executing 'fetch'") ||
    errorMessage === 'Timeout'
  ) {
    return {
      errorMessage: formatMessage({
        id: 'app.error.auth.timout',
        defaultMessage:
          'Timeout reached trying to authenticate your session. Check your connection and try again refreshing this page.',
      }),
      action: 'DISPLAY_USER_ONLY',
    };
  } else if (
    errorMessage.includes('User is not assigned to the client application.')
  ) {
    return {
      errorMessage: formatMessage({
        id: 'app.error.auth.user_not_assigned_to_client_app',
        defaultMessage:
          'User is not assigned to the client application. Log out and log-in back again, or refresh this page. If the problem persists, contact your IT team.',
      }),
      action: 'DISPLAY_USER_ONLY',
    };
  } else if (errorMessage.includes('user is blocked')) {
    return {
      errorMessage: formatMessage({
        id: 'app.error.auth.user_blocked',
        defaultMessage:
          "You don't have access to Confirm. Please reach out to your HR team to request access.",
      }),
      action: 'DISPLAY_USER_ONLY',
    };
  }

  return { errorMessage: `${errorMessage}`, action: 'DISPLAY_SENTRY_AND_USER' };
};

export const formatErrorMessage = (errorMessageOrObject) => {
  if (typeof errorMessageOrObject === 'string') {
    // if this is a whole webpage, rather than spitting that out
    // to the user as code, replace with a generic response
    if (errorMessageOrObject.startsWith('<!DOCTYPE html>')) {
      return SUBMIT_ERROR;
    }

    return errorMessageOrObject;
  }

  if (typeof errorMessageOrObject === 'object') {
    if (errorMessageOrObject?.data) {
      if (typeof errorMessageOrObject.data === 'string') {
        return errorMessageOrObject.data;
      } else if (typeof errorMessageOrObject.data === 'object') {
        if (typeof errorMessageOrObject.data.detail === 'string') {
          return errorMessageOrObject.data.detail;
        }
      }
    } else if (errorMessageOrObject?.detail) {
      return errorMessageOrObject.detail;
    } else if (errorMessageOrObject?.error) {
      return errorMessageOrObject.error;
    } else if (errorMessageOrObject?.statusText) {
      return errorMessageOrObject.statusText;
    }
  }

  console.error(
    'Unrecognized error message format: ' + JSON.stringify(errorMessageOrObject)
  );
  return JSON.stringify(errorMessageOrObject);
};
