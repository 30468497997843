import * as consts from 'consts/consts';

import {
  ASSESS_ORGANIZATION_EMPLOYEE_NPS_QUESTION,
  DEFAULT_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
  DEFAULT_AUTO_CALIBRATION_RULES,
  DEFAULT_OPEN_RESPONSE_QUESTIONS,
  DEFAULT_RATINGS,
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_STEP,
  PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_NAME,
  PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME,
  PERFORMANCE_FEATURE_RATING_SCALE,
  PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
  PHASE_TYPES_IN_ORDER,
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_INTRODUCTION_UI_ONLY,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_REPORTING,
  PHASE_TYPE_SELF,
  getCampaignFeature,
  getPhase,
  getWizardType,
} from '../../utils/models/Performance';
import { CampaignWithConfigs, Phase } from 'types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PropsListOptions,
  addUICampaignPhase,
  applyCampaignPhaseDates,
  preserveExistingAnswers,
  removeEnpsIfUnset,
  removeUICampaignPhase,
} from './CampaignFlow/common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCampaignPropsListOptions,
  getPropsList,
} from './CampaignFlow/PerformancePhasePropsList';
import {
  getFeatureForPhase,
  getPhaseStartDate,
  hasPhase,
} from '../../utils/models/Campaign';

import AccordionItem from '../Widgets/AccordionItem';
import { CampaignEditorPhaseProvider } from 'views/Administration/CampaignEditorPhaseContext';
import { CampaignFlowProvider } from './CampaignFlowContext';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import PropTypes from 'prop-types';
import SwitchInput from '../Widgets/Inputs/SwitchInput';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import ValidatedWizardForm from '../Widgets/Forms/ValidatedWizardForm';
import { connect } from 'react-redux';
import { getMMDDYYYYStringFromDate } from '../../utils/util/util';
import { getUserIsSuperAdmin } from '../../utils/models/User';
import { getWizardPhasesForCampaign } from '../../utils/models/Cycle';
import moment from 'moment';

// any campaign features that we will require all campaigns created to have
// WITHOUT the ability to edit them go here
// NOTE: besides the below, start_date and end_date are also required
const HIDDEN_DEFAULTS_SELF_PHASE = {
  type: PHASE_TYPE_SELF,
};

const HIDDEN_DEFAULTS_OTHERS_PHASE = {
  type: PHASE_TYPE_OTHERS,
  [PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS]:
    DEFAULT_OPEN_RESPONSE_QUESTIONS,
  [PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS]:
    DEFAULT_OPEN_RESPONSE_QUESTIONS,
  [PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS]:
    DEFAULT_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
  [PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_NAME]:
    ASSESS_ORGANIZATION_EMPLOYEE_NPS_QUESTION,
};

const HIDDEN_DEFAULTS_EVALUATION_PHASE = {
  type: PHASE_TYPE_EVALUATION,
  [PERFORMANCE_FEATURE_RATING_SCALE]: DEFAULT_RATINGS,
  [PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS]:
    DEFAULT_OPEN_RESPONSE_QUESTIONS,
};

const HIDDEN_DEFAULTS_CALIBRATION_PHASE = {
  type: PHASE_TYPE_CALIBRATION,
  auto_calibration_rules: DEFAULT_AUTO_CALIBRATION_RULES,
};

const HIDDEN_DEFAULTS_REPORTING_PHASE = {
  type: PHASE_TYPE_REPORTING,
};

const DEFAULT_PHASE_SELF = {
  ...HIDDEN_DEFAULTS_SELF_PHASE,
};

const DEFAULT_PHASE_OTHERS = {
  ...HIDDEN_DEFAULTS_OTHERS_PHASE,
};

const DEFAULT_PHASE_EVALUATION = {
  ...HIDDEN_DEFAULTS_EVALUATION_PHASE,
};

const DEFAULT_PHASE_CALIBRATION = {
  ...HIDDEN_DEFAULTS_CALIBRATION_PHASE,
};

const DEFAULT_PHASE_REPORTING = {
  ...HIDDEN_DEFAULTS_REPORTING_PHASE,
};

export const DEFAULT_PHASES = [
  DEFAULT_PHASE_SELF,
  DEFAULT_PHASE_OTHERS,
  DEFAULT_PHASE_EVALUATION,
  DEFAULT_PHASE_CALIBRATION,
  DEFAULT_PHASE_REPORTING,
];

export const PHASE_TYPE_TO_DEFAULT_PHASE = {
  [PHASE_TYPE_SELF]: DEFAULT_PHASE_SELF,
  [PHASE_TYPE_OTHERS]: DEFAULT_PHASE_OTHERS,
  [PHASE_TYPE_EVALUATION]: DEFAULT_PHASE_EVALUATION,
  [PHASE_TYPE_CALIBRATION]: DEFAULT_PHASE_CALIBRATION,
  [PHASE_TYPE_REPORTING]: DEFAULT_PHASE_REPORTING,
};

// DEFAULTS
// default enabled phases when creating a new campaign
export const DEFAULT_CAMPAIGN_PHASES_JSON = [DEFAULT_PHASE_SELF];

// a campaign that was created with the default open questions
// in the campaign. This flag instructs the code not to use the default
// questions if the open questions list for a phase is empty,
// because the client explicitly removed them
export const CAMPAIGN_CONFIG_CREATED_WITH_DEFAULT_QUESTIONS =
  'created_with_default_questions';

// keep tracks of a campaign that had it's peer feedback assignment done
export const CAMPAIGN_CONFIG_PEER_FEEDBACK_ASSIGNMENT_COMPLETE =
  'peers_feedback_assignment_complete';

// default disabled phases when creating new campaign
export const DEFAULT_CAMPAIGN_CONFIGS_JSON = {
  [CAMPAIGN_CONFIG_CREATED_WITH_DEFAULT_QUESTIONS]: true,
  ['hidden_phase_' + PHASE_TYPE_EVALUATION]: DEFAULT_PHASE_EVALUATION,
  ['hidden_phase_' + PHASE_TYPE_OTHERS]: DEFAULT_PHASE_OTHERS,
  ['hidden_phase_' + PHASE_TYPE_CALIBRATION]: DEFAULT_PHASE_CALIBRATION,
  ['hidden_phase_' + PHASE_TYPE_REPORTING]: DEFAULT_PHASE_REPORTING,
};

const getDefaultStartAndEndDates = () => {
  // get today's date as start date and one week from now as end date by default
  const today = new Date();
  const nextWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  );
  return {
    start_date: getMMDDYYYYStringFromDate(today),
    end_date: getMMDDYYYYStringFromDate(nextWeek),
  };
};

export const moveUnusedPhasesToConfigs = (
  campaign: CampaignWithConfigs
): CampaignWithConfigs => {
  const newPhases: Phase[] = [];

  const phasesForCampaign = getWizardPhasesForCampaign(campaign);
  phasesForCampaign.forEach((type) => {
    if (type === PHASE_TYPE_INTRODUCTION_UI_ONLY) {
      if (campaign.phases[0].type === PHASE_TYPE_INTRODUCTION_UI_ONLY) {
        newPhases.push(campaign.phases[0]);
      }
      return;
    }

    // try to find an enabled phase for the type, if it exists, use it.
    const existingPhase = campaign.phases.find((phase) => phase.type === type);
    if (existingPhase) {
      newPhases.push(existingPhase);
      // remove the hidden phase if it's there, we shouldn't have an hidden and enabled phase
      // at the same time
      delete campaign.configs[`hidden_phase_${type}`];
      return;
    }

    const disabledPhase = campaign.configs?.[`hidden_phase_${type}`];
    if (disabledPhase) {
      // re-enable if phase was auto-deactivated
      // and only if it has start/end dates (fix edge case where first
      // campaign ever created by an org has hidden phases with no dates
      // resulting in a failure if this code auto-activates them)
      if (
        disabledPhase.was_auto_deactivated &&
        disabledPhase.start_date &&
        disabledPhase.end_date
      ) {
        delete disabledPhase.was_auto_deactivated;
        newPhases.push(disabledPhase);
        delete campaign.configs[`hidden_phase_${type}`];
      }
      return;
    }

    console.warn(`failed to find disabled or existing phase ${type}`);
  });

  // move unused phases to configs
  campaign.phases.forEach((phase: Phase) => {
    if (
      !phasesForCampaign.includes(phase.type) &&
      phase.type !== PHASE_TYPE_INTRODUCTION_UI_ONLY
    ) {
      phase.was_auto_deactivated = true;
      campaign.configs[`hidden_phase_${phase.type}`] = phase;
    }
  });

  // if we don't have at least 1 enabled phase, search for the first
  // disabled phase with a compatible phase type and enable it
  if (newPhases.length === 0) {
    for (const type of phasesForCampaign) {
      const disabledPhase = campaign.configs?.[`hidden_phase_${type}`];
      if (disabledPhase) {
        delete disabledPhase.was_auto_deactivated;
        delete campaign.configs[`hidden_phase_${type}`];

        if (!disabledPhase.start_date || !disabledPhase.end_date) {
          const cycleStartDate = new Date(
            new Date(campaign.coverage_end_date).getTime() +
              consts.ONE_DAY_IN_MILLISECONDS
          );
          disabledPhase.start_date = getMMDDYYYYStringFromDate(
            new Date(cycleStartDate.getTime())
          );
          disabledPhase.end_date = getMMDDYYYYStringFromDate(
            new Date(
              cycleStartDate.getTime() +
                consts.ONE_WEEK_IN_MILLISECONDS -
                consts.ONE_DAY_IN_MILLISECONDS
            )
          );
        }

        newPhases.push(disabledPhase);
        break;
      }
    }
  }

  // if we still don't have at least 1 enabled phase, add the default phases
  if (newPhases.length === 0) {
    phasesForCampaign.forEach((phase) => {
      const defaultPhase = PHASE_TYPE_TO_DEFAULT_PHASE[phase];
      if (defaultPhase) {
        newPhases.push(defaultPhase);
      }
    });
    campaign.phases = newPhases;
    applyCampaignPhaseDates(campaign);
  } else {
    campaign.phases = newPhases;
  }
  return campaign;
};

const CollapsiblePhaseEditor = (props) => {
  // if phase is enabled, it should be in campaign.phases, else
  // it should be in campaign.configs['hidden_phase_[PHASE_TYPE_ID]'], e.g.
  // campaign.configs['hidden_phase_S']
  const propsCampaign = props.campaign;
  const propsType = props.type;
  const propsOnChange = props.onChange;
  const propsDisableToggle = props.disableToggle;

  const enabledPhase = useMemo(
    () => propsCampaign.phases?.find((phase) => phase.type === propsType),
    [propsCampaign.phases, propsType]
  );

  const hiddenPhase = useMemo(() => {
    // if phase is enabled, hidden phase should not exist (and vice versa)
    if (enabledPhase) {
      return null;
    }

    const foundHiddenPhase =
      propsCampaign.configs &&
      propsCampaign.configs['hidden_phase_' + propsType];

    if (!foundHiddenPhase) {
      // create a new hidden phase with default values
      const defaultPhase = DEFAULT_PHASES.find(
        (phase) => phase.type === propsType
      );
      return {
        ...defaultPhase,
        ...getDefaultStartAndEndDates(),
      };
    }

    return foundHiddenPhase;
  }, [enabledPhase, propsCampaign.configs, propsType]);

  const phase = useMemo(
    () => enabledPhase || hiddenPhase,
    [enabledPhase, hiddenPhase]
  );

  const toggleIsEnabled = useCallback(() => {
    if (hiddenPhase) {
      // enabled phase by removing hidden phase from campaign.configs and inserting
      // phase into campaign.phases in the right spot based on the ordering
      // SELF, OTHERS, EVALUATION, CALIBRATION, REPORTING
      const newPhases = [
        ...propsCampaign.phases.filter((p) => p.type !== propsType),
      ];
      // +1 because we assume that the phase INTRO is always first
      const hasIntroPhase = hasPhase(
        propsCampaign,
        PHASE_TYPE_INTRODUCTION_UI_ONLY
      );
      const phaseIndex =
        PHASE_TYPES_IN_ORDER.indexOf(propsType) + (hasIntroPhase ? 1 : 0);
      newPhases.splice(phaseIndex, 0, hiddenPhase);

      // update campaign in parent component
      propsOnChange({
        ...propsCampaign,
        phases: newPhases,
        configs: {
          ...propsCampaign.configs,
          ['hidden_phase_' + propsType]: undefined,
        },
      });
    } else {
      // disabled phase by removing it from campaign.phases
      // and inserting it into campaign.configs['hidden_phase_[PHASE_TYPE_ID]']
      const enabledPhase = propsCampaign.phases.find(
        (phase) => phase.type === propsType
      );

      // update campaign in parent component
      propsOnChange({
        ...propsCampaign,
        phases: propsCampaign.phases.filter((p) => p.type !== propsType),
        configs: {
          ...propsCampaign.configs,
          ['hidden_phase_' + propsType]: enabledPhase,
        },
      });
    }
  }, [hiddenPhase, propsCampaign, propsOnChange, propsType]);

  const onChangeFormValue = useCallback(
    (phase) => {
      if (hiddenPhase) {
        // update config value in campaign.configs['hidden_phase_[PHASE_TYPE_ID]']
        propsOnChange({
          ...propsCampaign,
          configs: {
            ...propsCampaign.configs,
            ['hidden_phase_' + propsType]: phase,
          },
        });
      } else {
        // update enabled phase in campaign.phases
        propsOnChange({
          ...propsCampaign,
          phases: propsCampaign.phases.map((p) =>
            p.type === phase.type ? phase : p
          ),
        });
      }
    },
    [hiddenPhase, propsCampaign, propsOnChange, propsType]
  );

  const labelHtml = useMemo(
    () => (
      <div className="py-2">
        <h2 className="mb-2">{props.title}</h2>
        <span className="text-muted">{props.helperText}</span>
      </div>
    ),
    [props.helperText, props.title]
  );

  const inputs = useMemo(
    () =>
      props.inputs?.map((i) => ({
        ...i,
        ...(i.type === INPUT_TYPES.QUESTIONS_EDITOR
          ? {
              campaign: props.campaign,
              excludeSpecialQuestions: props.excludeSpecialQuestions,
              questionDefaults: props.questionDefaults,
            }
          : {}),
        disabled: i.disabled || !enabledPhase,
        jsonPath: `${i.jsonRoot ?? '$.phases'}[?(@.type=='${propsType}')].${
          i.name
        }`,
      })),
    [
      enabledPhase,
      props.campaign,
      props.inputs,
      props.excludeSpecialQuestions,
      props.questionDefaults,
      propsType,
    ]
  );

  // provide defaults so, for example, if entire necessary feature,
  // e.g. rating scale, is deleted, we provide a default there instead.
  const phaseWithDefaults = useMemo(() => {
    const defaultPhase = DEFAULT_PHASES.find(
      (phase) => phase.type === propsType
    );
    return {
      ...defaultPhase,
      ...phase,
    };
  }, [phase, propsType]);

  const output = useMemo(
    () => (
      <CampaignEditorPhaseProvider
        phaseType={propsType}
        campaignId={propsCampaign.id}
      >
        <Row className="mt-4">
          <Col className="col-auto mt-4">
            <SwitchInput
              id={`phase-${propsType}-toggle`}
              disabled={propsDisableToggle}
              value={!!enabledPhase}
              onChange={toggleIsEnabled}
            />
          </Col>
          <Col>
            <AccordionItem label={labelHtml}>
              <ValidatedForm
                translationNamespace={`campaign_${propsCampaign.id}`}
                object={phaseWithDefaults}
                inputs={inputs}
                // to avoid warning we throw this
                callback={() => {
                  /* DO NOTHING */
                }}
                // NOTE: we use this instead of submitOnChange={true} because
                // the former mysteriously causes infinite loops when editing
                // anything (but not all the time, likely a race condition)
                onChange={onChangeFormValue}
                // we allow editing live even while something else is submitting
                // because we don't want to block the user from editing and
                // we will send all updates to the server anyway
                isSubmitting={false}
                hideSubmitButton={true}
                // since unsaved changes is handled in ValidatedWizardForm,
                // we should not trigger it here, else we will get conflicts like
                // "Warning: a history supports only one prompt at a time"
                // from React
                disableUnsavedChangesPrompt={true}
                doNotSendOrganization={true}
                organization={props.organization}
              />
            </AccordionItem>
          </Col>
        </Row>{' '}
      </CampaignEditorPhaseProvider>
    ),
    [
      enabledPhase,
      inputs,
      labelHtml,
      onChangeFormValue,
      phaseWithDefaults,
      props.organization,
      propsCampaign.id,
      propsDisableToggle,
      propsType,
      toggleIsEnabled,
    ]
  );

  return output;
};

const convertPeerFeedbackAssignmentToLocalTime = (campaign) => {
  const others = getPhase(campaign, 'O');
  if (others) {
    const utcTime = getCampaignFeature(
      campaign,
      PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME
    );
    if (utcTime) {
      const localTime = moment(utcTime).format('YYYY-MM-DDTHH:mm:ss.SSS');
      others[PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME] = localTime;
    }
  }
  return campaign;
};

const convertPeerFeedbackAssignmentToUTC = (campaign) => {
  const others = getPhase(campaign, 'O');
  if (others) {
    const localTime = getCampaignFeature(
      campaign,
      PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME
    );
    if (localTime) {
      const utcTime =
        moment(localTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
      others[PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME] = utcTime;
    }
  }
  return campaign;
};

const CampaignFlow = (props) => {
  const { formatMessage } = useIntl();
  const [campaignChanges, setCampaignChanges] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [autosaveStatus, setAutosaveStatus] = useState(null);

  const isSuperAdmin = useMemo(
    () => getUserIsSuperAdmin(props?.authUser),
    [props?.authUser]
  );

  const onChange = useCallback(
    (c) => {
      if (!hasUnsavedChanges) {
        setHasUnsavedChanges(true);
      }
      setCampaignChanges({ ...campaignChanges, ...c });
    },
    [campaignChanges, hasUnsavedChanges]
  );

  const transformObjectBeforeSubmit = useCallback((c) => {
    return convertPeerFeedbackAssignmentToUTC(removeUICampaignPhase(c));
  }, []);

  const campaign = useMemo(() => {
    return convertPeerFeedbackAssignmentToLocalTime(
      addUICampaignPhase({
        ...props.campaign,
        ...campaignChanges,
        updated_at: props.campaign.updated_at,
      })
    );
  }, [campaignChanges, props.campaign]);

  useEffect(() => {
    // when props.campaign changes, this is due to a save or new load,
    // so there are no unsaved changes
    setHasUnsavedChanges(false);
  }, [props.campaign]);

  const finalPhasePropsList = useMemo(() => {
    const wizardType = getWizardType(props.campaign);
    const options: PropsListOptions = getCampaignPropsListOptions(
      wizardType,
      isSuperAdmin,
      props.features,
      props.enableEmployeeNPS
    );

    let propsList = getPropsList(
      formatMessage,
      options,
      wizardType,
      props.features
    );
    preserveExistingAnswers(propsList, props.campaign);
    removeEnpsIfUnset(propsList, options.enableEmployeeNPS);

    const wizardPhases = getWizardPhasesForCampaign(props.campaign);
    propsList = propsList.filter((item) => wizardPhases.includes(item.type));

    return propsList;
  }, [
    formatMessage,
    props.campaign,
    props.features,
    props.enableEmployeeNPS,
    isSuperAdmin,
  ]);

  const output = useMemo(
    () => (
      <>
        <ValidatedWizardForm
          method={'PATCH'}
          url="/campaigns"
          object={campaign}
          setAutosaveStatus={setAutosaveStatus}
          // show nothing visually when saved, just toast
          autosaveSavedComponent={<></>}
          setHasUnsavedChanges={props.setHasUnsavedChanges}
          hasUnsavedChanges={hasUnsavedChanges}
          onGoBack={props.onGoBack}
          onSave={props.onSave}
          onSaveAndContinue={props.onSaveAndContinue}
          transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        >
          <Card>
            <CardBody>
              <p>
                <FormattedMessage
                  id="app.views.administration.campaign_flow.phases_selector"
                  defaultMessage="Select which phases to include in your cycle and customize them."
                />
              </p>
              {finalPhasePropsList ? (
                finalPhasePropsList.map((phaseObject, index) => (
                  <CollapsiblePhaseEditor
                    key={index}
                    campaign={campaign}
                    onChange={onChange}
                    organization={props.currentOrganization}
                    {...phaseObject}
                  />
                ))
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </ValidatedWizardForm>
        {autosaveStatus && (
          <div
            style={{
              position: 'fixed',
              bottom: '2.5rem',
              right: '2.5rem',
              zIndex: 1000,
            }}
          >
            {autosaveStatus}
          </div>
        )}
      </>
    ),
    [
      autosaveStatus,
      campaign,
      hasUnsavedChanges,
      onChange,
      props.onGoBack,
      props.onSave,
      props.onSaveAndContinue,
      props.setHasUnsavedChanges,
      finalPhasePropsList,
      props.currentOrganization,
      transformObjectBeforeSubmit,
    ]
  );

  const newLocal = getPhaseStartDate(props.campaign, 'S');

  return (
    <CampaignFlowProvider
      initialValue={{
        phaseSelfStartDate: newLocal,
        phaseSelfSetObjectivesLegacy: getFeatureForPhase(
          props.campaign,
          'S',
          PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_STEP
        ),
      }}
    >
      {output}
    </CampaignFlowProvider>
  );
};

CampaignFlow.propTypes = {
  campaign: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndContinue: PropTypes.func.isRequired,
  onGoBack: PropTypes.func,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  features: PropTypes.object.isRequired,
  setHasUnsavedChanges: PropTypes.func,
  enableEmployeeNPS: PropTypes.bool, // temporary for internal testing
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features, authUser } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
    authUser,
  };
};

export default connect(mapStateToProps)(React.memo(CampaignFlow));
