import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import { BasicPerson } from 'types';
import { PerformanceWrittenMultiResponse } from './PerformanceWrittenMultiResponse';
import { WrittenResponse } from './utils';

interface Props {
  person: BasicPerson;
  questions?: WrittenResponse[];
}

const PerformanceWrittenMultiResponses: FC<Props> = (props) => {
  return (
    <Row className="mb-4">
      <Col className="col-md-12">
        {props?.questions?.map((q, index) => (
          <PerformanceWrittenMultiResponse
            person={props.person}
            question={q}
            key={index.toString()}
          />
        ))}
      </Col>
    </Row>
  );
};

export default PerformanceWrittenMultiResponses;
