import {
  CampaignWithConfigs,
  Feedback,
  Organization,
  Person,
  RelationshipType,
  SurveyResponse,
} from '../../../types';

import { Objective } from 'utils/models/Objective';
import { PerformanceHistoryData } from './Trajectory/HistoryChartUtils';

export const buildDemoData = (
  org: Organization,
  campaign: CampaignWithConfigs,
  person: Person,
  demoPeople: Person[]
): {
  history: PerformanceHistoryData[];
  surveyResponse: SurveyResponse;
  feedback: Feedback[];
  objectives: Objective[];
} => {
  // ensure ids are unique (and unlikely to conflict with real data)
  let lastId = Number.MAX_SAFE_INTEGER;
  const generateFakeId = () => {
    lastId -= 1;
    return lastId;
  };

  const people = demoPeople.filter((p) => p.id !== person.id);
  const data = [
    {
      person: person,
      campaign: campaign,
      benchmarks: {
        organization: {
          all: {
            gold_stars: 2.0,
            influence: 6.0,
            heads_ups: 0.0,
            positive_ona_avg: 8.0,
          },
          tenure: {
            gold_stars: 1.0,
            influence: 5.0,
            heads_ups: 0.0,
            positive_ona_avg: 7.0,
            positive_ona_percentile: 45,
          },
          cross_tenure: {
            buckets: {
              '0-2': { next_point: 3, num_people: 68, average_ona: 1.5 },
              '3-5': { next_point: 6, num_people: 107, average_ona: 5.1 },
              '6-8': { next_point: 9, num_people: 70, average_ona: 7.4 },
              '9-11': {
                next_point: 12,
                num_people: 27,
                average_ona: 9.4,
              },
              '12-14': {
                next_point: 15,
                num_people: 82,
                average_ona: 10.0,
              },
              '15-17': {
                next_point: 18,
                num_people: 72,
                average_ona: 11.7,
              },
              '18-20': {
                next_point: 21,
                num_people: 101,
                average_ona: 10.9,
              },
              '21-23': {
                next_point: 24,
                num_people: 82,
                average_ona: 11.5,
              },
              '24-26': {
                next_point: 27,
                num_people: 59,
                average_ona: 12.0,
              },
              '27-29': {
                next_point: 30,
                num_people: 28,
                average_ona: 12.7,
              },
              '30-32': {
                next_point: 33,
                num_people: 30,
                average_ona: 14.4,
              },
              '33-35': {
                next_point: 36,
                num_people: 24,
                average_ona: 16.2,
              },
              '36-38': {
                next_point: 39,
                num_people: 35,
                average_ona: 15.3,
              },
              '39-41': {
                next_point: 42,
                num_people: 34,
                average_ona: 12.7,
              },
              '42-44': {
                next_point: 45,
                num_people: 25,
                average_ona: 15.0,
              },
              '45-47': {
                next_point: 48,
                num_people: 23,
                average_ona: 15.3,
              },
              '48-50': {
                next_point: 51,
                num_people: 13,
                average_ona: 12.0,
              },
              '51-53': {
                next_point: 54,
                num_people: 17,
                average_ona: 10.9,
              },
              '54-56': {
                next_point: 57,
                num_people: 8,
                average_ona: 15.6,
              },
              '57-59': {
                next_point: 60,
                num_people: 6,
                average_ona: 14.5,
              },
              '60-62': {
                next_point: 63,
                num_people: 5,
                average_ona: 13.0,
              },
              '63-65': {
                next_point: 66,
                num_people: 4,
                average_ona: 14.2,
              },
              '66-68': {
                next_point: 69,
                num_people: 3,
                average_ona: 12.3,
              },
              '69-71': {
                next_point: 72,
                num_people: 2,
                average_ona: 10.0,
              },
              '72-74': {
                next_point: 75,
                num_people: 4,
                average_ona: 12.0,
              },
              '75-77': {
                next_point: 78,
                num_people: 2,
                average_ona: 5.0,
              },
              '78-80': {
                next_point: 81,
                num_people: 2,
                average_ona: 15.5,
              },
              '81-83': {
                next_point: 84,
                num_people: 1,
                average_ona: 14.0,
              },
              '84-86': {
                next_point: 87,
                num_people: 1,
                average_ona: 28.0,
              },
              '87-89': {
                next_point: 90,
                num_people: 0,
                average_ona: 0.0,
              },
              '90-92': {
                next_point: 93,
                num_people: 0,
                average_ona: 0.0,
              },
              '93-95': {
                next_point: 96,
                num_people: 1,
                average_ona: 34.0,
              },
            },
            overall: { slope: 0.19, intercept: 6.21 },
          },
          ratings: {
            1: { max: 4, min: 2 },
            2: { max: 24, min: 4 },
            3: { max: 78, min: 24 },
            4: { max: 100, min: 78 },
          },
          ona: {
            heads_ups: [
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0,
              1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 3.0,
            ],
            influence: [
              0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 2.0,
              2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0,
              3.0, 3.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 5.0, 5.0, 5.0,
              5.0, 5.0, 5.0, 5.0, 5.0, 6.0, 6.0, 6.0, 6.0, 6.0, 6.0, 6.0, 7.0,
              7.0, 7.0, 7.0, 7.0, 7.0, 7.88, 8.0, 8.0, 8.0, 8.0, 8.68, 9.0, 9.0,
              9.0, 9.0, 10.0, 10.0, 10.0, 10.0, 10.0, 11.0, 11.0, 11.0, 11.0,
              12.0, 12.0, 12.0, 13.0, 13.0, 13.0, 14.0, 14.0, 15.0, 15.0, 16.0,
              16.0, 17.0, 17.0, 18.0, 19.0, 20.0, 21.0, 23.0, 24.56, 27.0, 29.0,
              36.28,
            ],
            gold_stars: [
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
              1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 2.0, 2.0,
              2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0,
              2.76, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0,
              4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 5.0, 5.0, 5.0, 5.0, 5.0,
              6.0, 6.0, 6.0, 7.0, 7.0, 8.0, 9.0, 11.0,
            ],
            positive_ona: [
              0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 2.0, 2.0, 2.0,
              2.0, 2.0, 2.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 4.0, 4.0, 4.0, 4.0,
              4.0, 4.0, 5.0, 5.0, 5.0, 5.0, 5.0, 5.0, 5.0, 6.0, 6.0, 6.0, 6.0,
              6.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.56, 8.0, 8.0, 8.0, 8.0, 8.0, 9.0,
              9.0, 9.0, 9.0, 9.0, 10.0, 10.0, 10.0, 10.0, 10.0, 11.0, 11.0,
              11.0, 11.0, 12.0, 12.0, 12.0, 12.0, 13.0, 13.0, 13.0, 14.0, 14.0,
              14.0, 15.0, 15.0, 15.0, 16.0, 16.0, 16.0, 17.0, 17.0, 18.0, 19.0,
              19.0, 19.0, 20.0, 21.0, 22.0, 22.0, 23.0, 25.0, 26.0, 28.0, 30.56,
              34.0, 37.0, 43.64,
            ],
          },
        },
      },
      level: 'Skilled',
      title: 'Senior InfoSec Administrator I',
      department: undefined,
      position_tenure: 1,
      tenure_months: 8,
      gold_stars: [
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T19:06:30.149848+00:00',
          type: RelationshipType.GIVES_GOLD_STAR_TO,
          positive_skills: [
            {
              name: 'initiative',
              type: 'E',
            },
            {
              name: 'efficiency',
              type: 'E',
            },
          ],
          positive_comments:
            'She enabled us to onboard hundreds of needed vendors & helped redesign our onboarding process.',
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          id: generateFakeId(),
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[0],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:44:35.856866+00:00',
          type: RelationshipType.GIVES_GOLD_STAR_TO,
          positive_skills: [
            {
              name: 'persistence',
              type: 'E',
            },
            {
              name: 'collaboration',
              type: 'E',
            },
            {
              name: 'team support',
              type: 'E',
            },
            {
              name: 'project management',
              type: 'E',
            },
            {
              name: 'teamwork',
              type: 'E',
            },
          ],
          positive_comments:
            'Beryl has been relentless and was a huge help to the team with the Polaris development and rollout',
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          id: generateFakeId(),
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[1],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:53:24.225803+00:00',
          type: RelationshipType.GIVES_GOLD_STAR_TO,
          positive_skills: [
            {
              name: 'patience',
              type: 'E',
            },
            {
              name: 'diligence',
              type: 'E',
            },
            {
              name: 'collaboration',
              type: 'E',
            },
            {
              name: 'customer focus',
              type: 'E',
            },
            {
              name: 'leadership',
              type: 'E',
            },
          ],
          positive_comments:
            'Patient, diligent and really embodies the spirit of "always be helpful" throughout ENT sales cycles.',
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: generateFakeId(),
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[2],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
      ],
      influence: [
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:58:12.290669+00:00',
          type: RelationshipType.ADVISED_BY,
          positive_skills: [
            {
              name: 'communication',
              type: 'E',
            },
            {
              name: 'empathy',
              type: 'E',
            },
            {
              name: 'teamwork',
              type: 'E',
            },
            {
              name: 'problem solving',
              type: 'E',
            },
          ],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: generateFakeId(),
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[0],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T19:01:02.312755+00:00',
          type: RelationshipType.ENERGIZED_BY,
          positive_skills: [
            {
              name: 'problem solving',
              type: 'E',
            },
            {
              name: 'leadership',
              type: 'E',
            },
            {
              name: 'collaboration',
              type: 'E',
            },
          ],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: generateFakeId(),
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[1],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T19:04:18.937620+00:00',
          type: RelationshipType.ADVISED_BY,
          positive_skills: [
            {
              name: 'flexibility',
              type: 'E',
            },
            {
              name: 'empathy',
              type: 'E',
            },
          ],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          id: generateFakeId(),
          salary_increase_comments: undefined,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[3],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T19:06:56.929823+00:00',
          type: RelationshipType.ENERGIZED_BY,
          positive_skills: [
            {
              name: 'negotiation',
              type: 'E',
            },
            {
              name: 'collaboration',
              type: 'E',
            },
            {
              name: 'planning',
              type: 'E',
            },
            {
              name: 'productivity',
              type: 'E',
            },
            {
              name: 'leadership',
              type: 'E',
            },
          ],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: generateFakeId(),
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: undefined,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[4],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:44:31.194168+00:00',
          type: RelationshipType.ENERGIZED_BY,
          positive_skills: [
            {
              name: 'sales acumen',
              type: 'E',
            },
            {
              name: 'business acumen',
              type: 'E',
            },
          ],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: generateFakeId(),
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[5],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:46:43.212917+00:00',
          type: RelationshipType.ADVISED_BY,
          positive_skills: [
            {
              name: 'innovation',
              type: 'E',
            },
            {
              name: 'collaboration',
              type: 'E',
            },
            {
              name: 'resilience',
              type: 'E',
            },
            {
              name: 'leadership',
              type: 'E',
            },
          ],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          id: generateFakeId(),
          salary_increase_comments: undefined,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[6],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
      ],
      heads_ups: [],
      heads_ups_display: 'full',
      rating: {
        rating: 4.0,
        negative_comments: 'nothing specific',
        created_at: '2023-07-10T19:28:05.496385+00:00',
        type: 'D',
        positive_skills: [
          { name: 'bias to action', type: 'E' },
          { name: 'collaboration', type: 'E' },
          { name: 'due diligence', type: 'E' },
          { name: 'effective communication', type: 'E' },
          { name: 'information security', type: 'E' },
          { name: 'organization', type: 'E' },
          { name: 'relentlessness', type: 'E' },
        ],
        positive_comments:
          'What Beryl brought into the risk and compliance program was order and consistency.  She drove how audit evidence is collected and how to collaborate with the different teams in a more efficient manner.  She was able to pick up the product and services quickly to drive the audits in a much smoother way\n\nAs part of the risk and compliance program, Beryl took on the vendor due diligence program and helped reshape it with the different teams from legal to procurement.  She collaborated with the different teams to build an easier VDD process\n\nOne unique aspect that Beryl took on was the infosec program management.  It was somewhat an open/empty role.  The program is high impact specifically for  the security team to organize and execute the projects.  This was not even part of her day to day responsibilities',
        recommend_for_promotion: true,
        final_rating: undefined,
        salary_increase_comments: undefined,
        id: generateFakeId(),
        rating_comments: 'She has gone above and beyond her responsibilities',
        to_person: person,
        final_rating_provided_by_person: undefined,
        priority: undefined,
        promotion_comments:
          'Beryl not only solved the problems that we needed her to solve, she worked on other problems to help the team and company scale and grow quickly',
        final_rating_comments: undefined,
        recommend_for_salary_increase: undefined,
        is_anonymous: false,
        organization: org,
        negative_skills: [
          { name: 'n/a (nothing or not applicable)', type: 'E' },
        ],
        campaign: campaign.id,
        from_person: people[0],
        responses: undefined,
        decline_reason: undefined,
        dataset: undefined,
        value: 4.0,
        benchmark: undefined,
      },
      ona_percentiles: {
        positive_ona: 69,
        gold_stars: 78,
        influence: 67,
      },
      is_eligible_for_reporting: true,
    },
  ];

  const activitiesList = [
    {
      id: generateFakeId(),
      visibility: 'E',
      name: 'Launched customer feedback loop for roadmap prioritization',
      contributions: [
        {
          id: generateFakeId(),
          created_at: '2024-09-30T20:39:57.727636Z',
          updated_at: '2024-09-30T20:39:57.924297Z',
          ignored_at: null,
          contributor_role: null,
          description:
            'Developed and launched a feedback process to capture insights from key customer segments, which informed our roadmap and increased feature usage by 20%. Collaborated with marketing and customer success to streamline feedback collection and prioritize actionable insights.',
          contributor_person: person,
          is_highlight: true,
          skills: [
            {
              name: 'customer insights',
              type: 'E',
            },
            {
              name: 'cross-functional collaboration',
              type: 'E',
            },
            {
              name: 'collaboration',
              type: 'E',
            },
            {
              name: 'roadmap prioritization',
              type: 'B',
            },
          ],
          contribution_comments: [],
          contribution_feedback: [],
          reactions: [],
        },
      ],
      contributions_count: 1,
      type: 'C',
      stage: 'L',
      reactions: [],
      created_at: '2024-09-30T20:39:57.662341+00:00',
      updated_at: '2024-09-30T20:39:57.662370+00:00',
      date_started: '2024-09-30T20:39:56.956900+00:00',
      date_completed: '2024-09-30T00:00:00+00:00',
      description: null,
    },
    {
      id: generateFakeId(),
      visibility: 'E',
      name: 'Improved product onboarding flow',
      contributions: [
        {
          id: generateFakeId(),
          created_at: '2024-09-30T20:39:57.444182Z',
          updated_at: '2024-09-30T20:39:57.636953Z',
          ignored_at: null,
          contributor_role: null,
          description:
            'Led a cross-functional team to revamp the onboarding experience, reducing setup time by 35% and increasing activation rates. Introduced in-app guides, simplified navigation, and addressed user pain points highlighted in customer feedback.',
          contributor_person: person,
          is_highlight: true,
          skills: [
            {
              name: 'product design',
              type: 'E',
            },
            {
              name: 'user experience',
              type: 'E',
            },
            {
              name: 'cross-functional leadership',
              type: 'E',
            },
          ],
          contribution_comments: [],
          contribution_feedback: [],
          reactions: [],
        },
      ],
      contributions_count: 1,
      type: 'C',
      stage: 'L',
      reactions: [],
      created_at: '2024-09-30T20:39:57.381995+00:00',
      updated_at: '2024-09-30T20:39:57.382024+00:00',
      date_started: '2024-09-30T20:39:56.873838+00:00',
      date_completed: '2024-09-30T00:00:00+00:00',
      description: null,
    },
    {
      id: generateFakeId(),
      visibility: 'E',
      name: 'Secured strategic customer partnership',
      contributions: [
        {
          id: generateFakeId(),
          created_at: '2024-09-30T20:39:57.146106Z',
          updated_at: '2024-09-30T20:39:57.357074Z',
          ignored_at: null,
          contributor_role: null,
          description:
            'Initiated and closed a pilot partnership with a key customer that aligned with our strategic goals, resulting in a potential $300k ARR. Led initial product demos, addressed customer-specific use cases, and coordinated follow-up meetings.',
          contributor_person: person,
          is_highlight: true,
          skills: [
            {
              name: 'strategic partnerships',
              type: 'E',
            },
            {
              name: 'customer engagement',
              type: 'E',
            },
            {
              name: 'product demonstrations',
              type: 'E',
            },
          ],
          contribution_comments: [],
          contribution_feedback: [],
          reactions: [],
        },
      ],
      contributions_count: 1,
      type: 'C',
      stage: 'L',
      reactions: [],
      created_at: '2024-09-30T20:39:57.053492+00:00',
      updated_at: '2024-09-30T20:39:57.053521+00:00',
      date_started: '2024-09-30T20:39:56.810262+00:00',
      date_completed: '2024-09-30T00:00:00+00:00',
      description: null,
    },
  ];

  const surveyResponse = {
    configs: {},
    responses: {
      accomplishment_impact:
        'In the last quarter, I led two major feature launches focused on onboarding and personalization, which contributed to a 15% increase in new user retention. I prioritized agile improvements, streamlined cross-team communication, and kept the team aligned with our customer-centric goals.',
      positive_comments:
        'Throughout the last quarter, I consistently demonstrated strong leadership and strategic insight, especially during the launch of the onboarding and personalization features.',
      negative_comments:
        'I focused on proactive decision-making and transparent communication, addressing challenges quickly to keep projects on track. I feel I’ve met expectations in autonomy and team support, though I’d like to improve my efficiency in handling multiple stakeholder needs.',
      self_inclusive_journey:
        'I created more inclusive team discussions and retrospectives to ensure all voices are heard. I also joined the diversity and inclusion working group to help support teammates with flexible hours and communication preferences.',
      self_manager_support:
        'Guidance on product metrics and a mentor in strategic product management would be helpful. I’d also appreciate your feedback on my one-on-ones to identify improvement areas.',
      positive_skills: [
        { name: 'strategic product analytics', type: 'E' },
        { name: 'leadership', type: 'E' },
        { name: 'long-term goal alignment', type: 'E' },
      ],
      negative_skills: [
        { name: 'being on time', type: 'E' },
        { name: 'handling difficult conversations', type: 'E' },
        { name: 'patience', type: 'E' },
      ],
    },
    highlights: activitiesList,
  };

  const feedbackList = [
    {
      id: generateFakeId(),
      created_at: '2024-08-30T16:37:34.032601Z',
      updated_at: '2024-08-30T16:37:37.744760Z',
      visibility: 'E',
      type: 'E',
      feedback_request: null,
      author_person: demoPeople[6],
      subject_people: [person],
      activities: [],
      skills: [
        {
          name: 'teamwork',
          type: 'B',
        },
      ],
      custom_responses: {},
      comments: 'Helping me navigate the Acme messaging. Helped a lot.',
      content_type: null,
      object_id: null,
    },
    {
      id: generateFakeId(),
      created_at: '2024-08-16T17:08:27.370507Z',
      updated_at: '2024-08-16T17:08:27.826936Z',
      visibility: 'E',
      type: 'E',
      feedback_request: null,
      author_person: demoPeople[7],
      subject_people: [person],
      activities: [],
      skills: [
        {
          name: 'customer care',
          type: 'E',
        },
        {
          name: 'excellence',
          type: 'B',
        },
        {
          name: 'grit',
          type: 'B',
        },
        {
          name: 'problem solving',
          type: 'B',
        },
      ],
      custom_responses: {},
      comments:
        "You've gone above and beyond in the past few weeks, working late because a client needed it, making SOC2 problems go away, making Acme problems go away, covering needs from the Philippines on their timezone, and doing everything with such accuracy and a helpful spirit. I'm super grateful for everything you've been doing!",
      content_type: null,
      object_id: null,
    },
    {
      id: generateFakeId(),
      created_at: '2024-08-16T16:44:10.971448Z',
      updated_at: '2024-08-16T16:44:12.336875Z',
      visibility: 'E',
      type: 'E',
      feedback_request: null,
      author_person: demoPeople[6],
      subject_people: [person],
      activities: [
        {
          visibility: 'E',
          type: 'C',
          name: 'General administration',
        },
      ],
      skills: [
        {
          name: 'banking',
          type: 'E',
        },
        {
          name: 'operations',
          type: 'B',
        },
        {
          name: 'payments',
          type: 'E',
        },
      ],
      custom_responses: {},
      comments: "Doing my job while I'm on vacation 🍹",
      content_type: null,
      object_id: null,
    },
    {
      id: generateFakeId(),
      created_at: '2024-08-02T16:36:16.743806Z',
      updated_at: '2024-08-02T16:36:19.496222Z',
      visibility: 'E',
      type: 'E',
      feedback_request: null,
      author_person: demoPeople[6],
      subject_people: [
        person,
        demoPeople[5],
        demoPeople[6],
        demoPeople[7],
        demoPeople[8],
        demoPeople[9],
      ],
      activities: [],
      skills: [
        {
          name: 'scrappiness',
          type: 'B',
        },
      ],
      custom_responses: {},
      comments:
        'Thank you for spinning up the PHL team so quickly! Having such a high quality resource to offload technical support will make a big difference in our ability to focus FTE resourcing on growth!&nbsp;',
      content_type: null,
      object_id: null,
    },
    {
      id: generateFakeId(),
      created_at: '2024-08-02T16:33:57.465974Z',
      updated_at: '2024-08-02T16:34:00.492609Z',
      visibility: 'E',
      type: 'E',
      feedback_request: null,
      author_person: demoPeople[6],
      subject_people: [person, demoPeople[5], demoPeople[6]],
      activities: [],
      skills: [
        {
          name: 'enabling us to scale',
          type: 'E',
        },
      ],
      custom_responses: {},
      comments:
        'I appreciate how you all helped get things started with the new Acme project team!',
      content_type: null,
      object_id: null,
    },
    {
      id: generateFakeId(),
      created_at: '2024-07-05T19:21:32.147897Z',
      updated_at: '2024-07-05T19:21:33.680331Z',
      visibility: 'E',
      type: 'E',
      feedback_request: null,
      author_person: demoPeople[5],
      subject_people: [person],
      activities: [
        {
          visibility: 'E',
          type: 'A',
          name: 'Acme demo',
        },
      ],
      skills: [
        {
          name: 'supportiveness',
          type: 'B',
        },
      ],
      custom_responses: {},
      comments:
        'Thank you for helping me with the UX changes demo. Your insights and support throughout the call helped us move the conversation further ahead, and I learned a lot from that call.&nbsp;',
      content_type: null,
      object_id: null,
    },
  ];

  const parentIds = [generateFakeId(), generateFakeId()];
  const objectives: Array<Objective> = [
    {
      matched: false,
      id: generateFakeId(),
      key: '1b6717bb-fb08-4ec4-ab86-04cdf29a9200',
      name: 'Drive product strategy alignment across cross-functional teams.',
      score: 90,
      status: 'T',
      score_denominator: null,
      weight: null,
      auto_calculation: false,
      collaborators: [person, people[8]],
      owner_person: person,
      coverage_start_date: '2024-10-01',
      coverage_end_date: '2024-12-31',
      related_to: [],
      // @ts-expect-error
      parent_id: null,
      children: [],
      parent_objective: null,
    },
    {
      matched: false,
      id: parentIds[0],
      key: '684d5e63-1afc-47a3-9e30-89f7f65e95f6',
      name: 'Increase product adoption among existing customers.',
      score: 55,
      status: 'R',
      score_denominator: null,
      weight: null,
      auto_calculation: false,
      collaborators: [person],
      owner_person: person,
      coverage_start_date: '2024-10-01',
      coverage_end_date: '2024-12-31',
      related_to: [],
      parent_id: null,
      children: [
        {
          matched: false,
          id: 65,
          key: '95b0302d-5557-4af7-a3c6-31cef94cecbb',
          name: 'Achieve a 15% increase in monthly active users by the end of the quarter.',
          score: 90,
          status: 'T',
          score_denominator: null,
          weight: null,
          auto_calculation: false,
          collaborators: [person, people[5]],
          owner_person: person,
          coverage_start_date: '2024-10-01',
          coverage_end_date: '2024-12-31',
          related_to: [],
          // @ts-expect-error
          parent_id: parentIds[0],
          children: [],
        },
        {
          matched: false,
          id: 66,
          key: 'c0b810e5-586b-4bdc-9eed-c1e2cdbe9ab1',
          name: 'Improve feature adoption rates by 25% for newly launched features.',
          score: 25,
          status: 'F',
          score_denominator: null,
          weight: null,
          auto_calculation: false,
          collaborators: [person],
          owner_person: person,
          coverage_start_date: '2024-10-01',
          coverage_end_date: '2024-12-31',
          related_to: [],
          // @ts-expect-error
          parent_id: parentIds[0],
          children: [],
        },
      ],
      parent_objective: null,
    },
    {
      matched: false,
      id: parentIds[1],
      key: '9931db02-dd7c-4e35-97ff-f7cbed97982a',
      name: 'Enhance customer satisfaction with core product features.',
      score: 85,
      status: 'T',
      score_denominator: null,
      weight: null,
      auto_calculation: false,
      collaborators: [person, people[5], people[6]],
      created_at: '2024-10-30T21:25:21.615716Z',
      updated_at: '2024-10-30T21:25:21.615760Z',
      owner_person: person,
      coverage_start_date: '2024-10-01',
      coverage_end_date: '2024-12-31',
      related_to: [],
      parent_id: null,
      children: [
        {
          matched: false,
          id: 67,
          key: 'fb28868c-e7b2-4666-ace7-1946cdb12ecf',
          name: 'Reduce feature-related support tickets by 20%',
          score: 75,
          status: 'R',
          score_denominator: null,
          weight: null,
          auto_calculation: false,
          collaborators: [person, people[5], people[6]],
          owner_person: person,
          coverage_start_date: '2024-10-01',
          coverage_end_date: '2024-12-31',
          related_to: [],
          // @ts-expect-error
          parent_id: parentIds[1],
          children: [],
        },
        {
          matched: false,
          id: 68,
          key: '872039a3-0ded-4d48-8157-f11a41dd2a91',
          name: 'Increase average customer satisfaction score from 4.0 to 4.5 by quarter-end.',
          score: 85,
          status: 'T',
          score_denominator: null,
          weight: null,
          auto_calculation: false,
          collaborators: [person, people[5], people[6]],
          owner_person: person,
          coverage_start_date: '2024-10-01',
          coverage_end_date: '2024-12-31',
          related_to: [],
          // @ts-expect-error
          parent_id: parentIds[1],
          children: [],
        },
      ],
      parent_objective: null,
    },
  ];

  return {
    history: data,
    surveyResponse: surveyResponse,
    feedback: feedbackList,
    objectives: objectives,
  };
};
