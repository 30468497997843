import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PHASE_TYPE_OTHERS,
  getPerformanceNextStep,
} from '../../utils/models/Performance';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getPhaseByType,
  getPhaseIndexByType,
  getPhaseIsOpen,
} from '../../utils/models/Campaign';

import Confetti from 'react-dom-confetti';
import PerformancePage from './PerformancePage';
import PerformancePhases from './PerformancePhases';
import { connect } from 'react-redux';
import { getPrettyDate } from '../../utils/util/util';
import holdingTwoCardImage from '../../assets/img/illustrations/holding-2-card.png';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';

const confettiConfig = {
  angle: '90',
  spread: '140',
  startVelocity: '56',
  elementCount: '200',
  dragFriction: '0.09',
  duration: '3440',
  stagger: 0,
  width: '10px',
  height: '14px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const confettiContainerStyle = {
  position: 'fixed',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  pointerEvents: 'none',
};

const PerformanceStepOthersPhaseComplete: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const [confettiShouldFire, setConfettiShouldFire] = useState(false);
  const history = useHistory();

  const campaign = props.campaign;

  const phaseOthers = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_OTHERS),
    [campaign]
  );

  const phaseEndDateString = useMemo(
    () => phaseOthers?.end_date,
    [phaseOthers?.end_date]
  );

  // fire confetti on page open
  useEffect(() => {
    setConfettiShouldFire(true);
  }, []);

  // reset confetti so it can be fired when clicking page
  useEffect(() => {
    if (confettiShouldFire) {
      setConfettiShouldFire(false);
    }
  }, [confettiShouldFire]);

  const onPageClick = useCallback(() => {
    // show confetti when modal opens
    setConfettiShouldFire(true);
  }, []);

  const nextPhaseIsOpen = useMemo(() => {
    return getPhaseIsOpen(
      campaign,
      getPhaseIndexByType(campaign, PHASE_TYPE_OTHERS) + 1
    );
  }, [campaign]);

  const goToNextPhase = useCallback(() => {
    const nextStep = getPerformanceNextStep(
      props.me,
      props.currentOrganization,
      campaign,
      props.currentPerfSurveyResponse,
      history.location.pathname,
      history.location.search,
      props.demoPeople,
      formatMessage
    );
    // @ts-expect-error
    history.push(nextStep);
  }, [
    history,
    props.currentOrganization,
    campaign,
    props.currentPerfSurveyResponse,
    props.demoPeople,
    props.me,
    formatMessage,
  ]);

  // if the next phase is open, go to it automatically
  // without showing the all done page - done in order to
  // increase follow through between phases
  useEffect(() => {
    if (nextPhaseIsOpen) {
      goToNextPhase();
    }
  }, [nextPhaseIsOpen, goToNextPhase]);

  return (
    <PerformancePage
      campaign={campaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_others_phase_complete.title',
        defaultMessage: 'Phase II is done!',
      })}
    >
      {/* @ts-expect-error */}
      <div style={confettiContainerStyle}>
        <Confetti
          className={'react-dom-confetti-overlay'}
          active={confettiShouldFire}
          // @ts-expect-error
          config={confettiConfig}
        />
      </div>
      <Row className="mb-4" onClick={onPageClick}>
        <div className="col-12 col-md-4">
          <img src={holdingTwoCardImage} className="w-75" />
        </div>
        <Col>
          <div className="mb-4">
            <FormattedMessage
              id="app.views.performance.performance_step_others_phase_complete.message"
              defaultMessage="
            You've completed this phase! You can make changes until {date} at 11:59pm your local time."
              values={{
                date: getPrettyDate({ dateString: phaseEndDateString, locale }),
              }}
            />
          </div>
          <PerformancePhases campaign={campaign} stepsComplete={2} />
        </Col>
      </Row>
    </PerformancePage>
  );
};

const PerformanceStepOthersPhaseComplete_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object),
};

type Props = InferProps<typeof PerformanceStepOthersPhaseComplete_propTypes>;

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepOthersPhaseComplete)));
