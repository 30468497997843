import React, { FC, useCallback, useState } from 'react';

import { Button } from 'reactstrap';
import EngagementSurveyReportEditCustomModalEditor from './EngagementSurveyReportEditCustomModalEditor';
import EngagementSurveyReportEditModalEditor from './EngagementSurveyReportEditModalEditor';
import { FormattedMessage } from 'react-intl';
import { useCampaignReports } from '../CampaignReportsContext';

interface EditModalProps {
  reportId: number;
  sliceType?: string;
  isOpen: boolean;
  onClosed: () => void;
  onCallback: () => void;
}

const EditModal: FC<EditModalProps> = ({
  reportId,
  sliceType,
  onClosed,
  onCallback,
  isOpen,
}) => {
  return (
    <>
      {sliceType !== 'CUSTOM' && (
        <EngagementSurveyReportEditModalEditor
          reportId={reportId}
          isOpen={isOpen}
          onClosed={onClosed}
          onCallback={onCallback}
        />
      )}
      {sliceType === 'CUSTOM' && (
        <EngagementSurveyReportEditCustomModalEditor
          reportId={reportId}
          isOpen={isOpen}
          onClosed={onClosed}
          onCallback={onCallback}
        />
      )}
    </>
  );
};

interface EditButtonProps {
  reportId: number;
  sliceType?: string;
}

const EditButton: FC<EditButtonProps> = ({ reportId, sliceType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refreshEngagementSurveyListVersion } = useCampaignReports();
  const handleOnClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleOnClosed = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOnCallback = useCallback(() => {
    refreshEngagementSurveyListVersion();
    handleOnClosed();
  }, [handleOnClosed, refreshEngagementSurveyListVersion]);

  return (
    <Button className="ms-3 btn btn-light btn-sm" onClick={handleOnClick}>
      <FormattedMessage
        id="app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.edit"
        defaultMessage="Edit"
      />
      <EditModal
        reportId={reportId}
        sliceType={sliceType}
        isOpen={isOpen}
        onClosed={handleOnClosed}
        onCallback={handleOnCallback}
      />
    </Button>
  );
};

const MemoizedEditButton = React.memo(EditButton);
const MemoizedEditModal = React.memo(EditModal);

export { MemoizedEditButton as EditButton, MemoizedEditModal as EditModal };
