import * as consts from 'consts/consts';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  getLikertRatingText,
  getUniqueWordCloudsFromRelationships,
} from './utils';

import Avatar from 'views/Widgets/People/Avatar';
import CardHeaderTitle from 'views/Widgets/Cards/CardHeaderTitle';
import { RELATIONSHIP_TYPES } from 'utils/models/RelationshipUtils';
import React from 'react';
import RelativeTime from 'views/Widgets/RelativeTime';
import RichTextViewer from 'views/Widgets/Inputs/RichTextViewer';
import TagsList from 'views/Widgets/TagsList';
import WordCloud from 'views/Widgets/WordCloud';
import { getAttributedOrUnattributedPersonHeading } from 'utils/models/Campaign';

interface Props {
  title: string;
  subtitle?: string;
  relationships: any[];
}

const PerformanceFeedbackSection: React.FC<Props> = (props: Props) => {
  const { formatMessage } = useIntl();
  const relationshipsWithComments = props.relationships?.filter(
    (r) => r.comments?.length > 0
  );

  return (
    <Col className="col-12 col-xl-6">
      <Card>
        <CardHeader>
          <CardHeaderTitle>
            {props.title}
            {props.subtitle && (
              <div className="mt-2 header-subtitle small">
                <RichTextViewer model={props.subtitle}></RichTextViewer>
              </div>
            )}
          </CardHeaderTitle>
        </CardHeader>
        <CardBody>
          <div className="mb-3">
            <WordCloud
              className="text-center"
              objects={getUniqueWordCloudsFromRelationships(
                formatMessage,
                props.relationships,
                'skills'
              )}
              pathPrefix={consts.SKILLS().path}
              isExternalUrl={true}
            />
          </div>
          {relationshipsWithComments?.length > 0 && (
            <ListGroup className="list-group-flush mt-n3">
              {relationshipsWithComments.map((r, index) => (
                <ListGroupItem key={index} className="border-0 pb-0">
                  <Row className="flex-nowrap">
                    <Col className="col-auto">
                      <Avatar
                        person={r.person}
                        size="sm"
                        isExternalUrl={true}
                        unattributedRelationshipType={r.type}
                      />
                      <br />
                      {r.person?.id &&
                        r.type ===
                          RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR && (
                          <div
                            className="text-muted small mx-n3 text-center mt-1"
                            style={{ fontSize: '.75rem' }}
                          >
                            <FormattedMessage
                              id="app.views.person.person_performance.manager"
                              defaultMessage="Manager"
                            />
                          </div>
                        )}
                      {r.person?.id &&
                        r.type ===
                          RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR && (
                          <div
                            className="text-muted small mx-n3 text-center mt-1"
                            style={{ fontSize: '.75rem' }}
                          >
                            <FormattedMessage
                              id="app.views.person.person_performance.peer"
                              defaultMessage="Peer"
                            />
                          </div>
                        )}
                      {r.person?.id &&
                        (r.type === RELATIONSHIP_TYPES.REPORTS_TO ||
                          r.type ===
                            RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER) && (
                          <div
                            className="text-muted small mx-n3 text-center mt-1"
                            style={{ fontSize: '.75rem' }}
                          >
                            <FormattedMessage
                              id="app.views.person.person_performance.report"
                              defaultMessage="Report"
                            />
                          </div>
                        )}
                    </Col>
                    <Col className="col ms-n3">
                      <div className="comment-body d-block px-3 py-2 small">
                        <Row>
                          <Col>
                            {getAttributedOrUnattributedPersonHeading(
                              formatMessage,
                              r.person,
                              index,
                              r.type,
                              false
                            )}
                            {!!r.created_at && (
                              <div className="small text-muted">
                                <RelativeTime
                                  unit="day"
                                  datetime={r.created_at}
                                />
                              </div>
                            )}
                            <div className="mb-0 py-1">{r.comments}</div>
                            {r?.skills?.length > 0 && (
                              <TagsList
                                skills={r.skills}
                                isExternalUrl={true}
                                truncated={true}
                              />
                            )}
                            {r.type !==
                              RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR &&
                              typeof r?.rating !== 'undefined' &&
                              r?.rating !== null && (
                                <div className="pt-0 text-muted">
                                  {getLikertRatingText(r?.rating)}
                                </div>
                              )}
                          </Col>
                          {r.onEditRelationship && (
                            <Col
                              className="col-auto ps-0 pe-2 mb-n1"
                              style={{ marginTop: 2 }}
                            >
                              <UncontrolledDropdown className="d-inline-block">
                                <DropdownToggle
                                  tag="button"
                                  className="more-dropdown-button btn btn-sm"
                                  role="button"
                                >
                                  <i className="fe fe-more-horizontal"></i>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <DropdownItem onClick={r.onEditRelationship}>
                                    <FormattedMessage
                                      id="app.views.person.person_performance.edit_feedback"
                                      defaultMessage="Edit feedback"
                                    />
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default PerformanceFeedbackSection;
