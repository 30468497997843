import { Card, CardBody, Col, Row } from 'reactstrap';
import {
  EngagementSurveyReportNewModalEditorButton,
  EngagementSurveyReportsTable,
} from './EngagementSurvey';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useMemo } from 'react';

import EmptyState from 'views/Widgets/EmptyState';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import Page from 'views/Layout/Pages/Page';
import ValidatedForm from 'views/Widgets/Forms/ValidatedForm';
import { useCampaignReports } from './CampaignReportsContext';

const EMPTY_CALLBACK = () => {
  /* DO NOTHING */
};

const CampaignReports: FC = () => {
  const object = useMemo(() => ({}), []);
  const { hasEngagementSurvey } = useCampaignReports();
  const { formatMessage } = useIntl();

  const sections = useMemo(
    () => [
      ...(hasEngagementSurvey
        ? [
            {
              id: 'engagement_survey_reports',
              title: (
                <FormattedMessage
                  id="app.views.administration.campaign_reports.engagement_survey_reports.label"
                  defaultMessage="Engagement survey reports"
                />
              ),
              helperText: (
                <FormattedMessage
                  id="app.views.administration.campaign_reports.engagement_survey_reports.helper_text"
                  defaultMessage="Create and manage engagement survey reports."
                />
              ),
              action: (
                <EngagementSurveyReportNewModalEditorButton
                  className="btn-sm ml-2"
                  color="light"
                />
              ),
              inputs: [
                {
                  type: INPUT_TYPES.CUSTOM_INPUT,
                  component: <EngagementSurveyReportsTable />,
                },
              ],
            },
          ]
        : []),
    ],
    [hasEngagementSurvey]
  );

  if (sections.length === 0) {
    return (
      <Page embedded={true} wrapBodyInCard={true}>
        <EmptyState
          title={formatMessage({
            id: 'app.views.administration.campaign_reports.campaign_reports.title.there_are_no_reports_available_for_this_cycle',
            defaultMessage: 'There are no reports available for this cycle.',
          })}
          subtitle={formatMessage({
            id: 'app.views.administration.campaign_reports.campaign_reports.subtitle.options_to_create_reports_would_appear_here_if_you_enabled_engagement_survey_etc',
            defaultMessage:
              'Options to create reports would appear here if you enabled engagement surveys for this cycle.',
          })}
        />
      </Page>
    );
  }

  return (
    <Page embedded={true}>
      <Card>
        <CardBody>
          <ValidatedForm
            object={object}
            inputs={sections.flatMap((section) => [
              {
                type: INPUT_TYPES.CUSTOM_INPUT,
                component: (
                  <>
                    <Row>
                      <Col>
                        <h2 className="mb-0">{section.title}</h2>
                      </Col>
                      {section.action && (
                        <Col className="text-end col-auto">
                          {section.action}
                        </Col>
                      )}
                    </Row>
                    {section.helperText && (
                      <Row>
                        <small className="form-text text-muted">
                          {section.helperText}
                        </small>
                      </Row>
                    )}
                  </>
                ),
              },
              ...section.inputs,
            ])}
            hideSubmitButton={true}
            callback={EMPTY_CALLBACK}
          />
        </CardBody>
      </Card>
    </Page>
  );
};

export default CampaignReports;
