import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import {
  CampaignReportESSliceType,
  CampaignReportSubType,
  CampaignReportType,
  processApiSliceValues,
} from './EngagementSurvey';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useState } from 'react';
import { useConfirmApi, useProxyParameters } from 'utils/api/ApiHooks';

import ConfirmAPI from 'utils/api/ConfirmAPI';
import { EditModal } from './EngagementSurveyReportsTableEdit';
import FilterablePeopleTable from 'views/Widgets/People/FilterablePeopleTable';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import Loading from 'views/Widgets/Loading';
import ModalEditorButton from 'views/Widgets/Modals/ModalEditorButton';
import { isEqual } from 'lodash';
import { renderValidationError } from 'utils/util/formatter';
import { useCampaignReports } from '../CampaignReportsContext';
import { v4 as uuidv4 } from 'uuid';

type SliceForTypeData = {
  campaign_id: number;
  slices: {
    id: string;
    name: string;
  }[];
};

type SliceValuesForTypeData = {
  campaign_id: number;
  slice_value: CampaignReportESSliceType;
  slice_id: string;
  values: {
    id: string;
    name: string;
    count: number;
  }[];
};

type Props = {
  className?: string;
  color?: string;
};

const EngagementSurveyReportNewModalEditorButton: FC<Props> = ({
  className = '',
  color = '',
}) => {
  const { formatMessage } = useIntl();
  const { campaignId, refreshEngagementSurveyListVersion } =
    useCampaignReports();
  const proxyParameters = useProxyParameters();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [createdReportId, setCreatedReportId] = useState<number | null>(null);
  const [createdReportSliceType, setCreatedReportSliceType] = useState<
    string | null
  >(null);

  const [object, setObject] = useState({
    slice_type: null,
    slice_id: null,
  });

  const { data: slicesForTypeData, error: errorSlicesForTypeData } =
    useConfirmApi<SliceForTypeData>({
      url: `/campaigns/${campaignId}/slices/${object.slice_type}`,
      method: 'GET',
      disabled: !object.slice_type,
      clearOnChange: true,
      clearOnError: true,
    });

  const {
    data: sliceValuesForTypeData,
    error: errorSliceValuesForTypeData,
    status: statusSliceValuesForTypeData,
  } = useConfirmApi<SliceValuesForTypeData>({
    url: `/campaigns/${campaignId}/slices/${object.slice_type}/${object.slice_id}`,
    method: 'GET',
    disabled: !object.slice_id || !object.slice_type,
    clearOnChange: true,
    clearOnError: true,
    postProcess: processApiSliceValues,
  });

  const handleInputsChange = useCallback(
    (newObject) => {
      if (isOpen) {
        if (isEqual(newObject, object)) {
          return;
        }
        if (newObject.slice_type !== object.slice_type) {
          setObject({
            ...newObject,
            slice_id:
              newObject.slice_type === 'CUSTOM' ? `custom_${uuidv4()}` : null,
          });
          return;
        }

        setObject(newObject);
      }
    },
    [isOpen, object]
  );

  const handleOnClosed = useCallback(() => {
    setObject({
      slice_type: null,
      slice_id: null,
    });
    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  const handleOnSuccessCallback = useCallback(
    (createdReport) => {
      setIsEditOpen(true);
      setCreatedReportId(createdReport.id);
      setCreatedReportSliceType(object.slice_type);
      refreshEngagementSurveyListVersion();
    },
    [object.slice_type, refreshEngagementSurveyListVersion]
  );

  const handleOnSubmit = useCallback(
    (submittedObject, onSubmit) => {
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        '/campaign-reports',
        {
          type: CampaignReportType.ENGAGEMENT_SURVEY,
          sub_type: CampaignReportSubType.USER_DEFINED,
          campaign: campaignId,
          config: submittedObject,
          ...proxyParameters,
        },
        (data, error: unknown, hardErrorMessage: unknown) => {
          onSubmit(data, error, hardErrorMessage);
        }
      );
    },
    [campaignId, proxyParameters]
  );

  const handleOnValidate = useCallback(() => {
    return {
      slice_id: errorSliceValuesForTypeData
        ? formatMessage({
            id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.select_valid_option',
            defaultMessage: 'Please select a valid option',
          })
        : undefined,
    };
  }, [errorSliceValuesForTypeData, formatMessage]);

  const renderNewForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          <Row>{inputs}</Row>
          <Row>
            {renderValidationError(errorSlicesForTypeData)}
            {renderValidationError(errorSliceValuesForTypeData)}
          </Row>
          <Row>
            <Col>
              <Button
                className="mt-4 d-block w-100"
                color="light"
                onClick={handleOnClosed}
              >
                <FormattedMessage
                  id="app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
            </Col>
            <Col>{submitButton}</Col>
          </Row>
        </>
      );
    },
    [errorSliceValuesForTypeData, errorSlicesForTypeData, handleOnClosed]
  );

  const handleOnEditClosed = useCallback(() => {
    setIsEditOpen(false);
    setCreatedReportId(null);
    setCreatedReportSliceType(null);
    refreshEngagementSurveyListVersion();
    handleOnClosed();
  }, [handleOnClosed, refreshEngagementSurveyListVersion]);

  return (
    <>
      <ModalEditorButton
        title={formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.title.create_new_report',
          defaultMessage: 'Create new report',
        })}
        buttonClassName={className}
        color={color}
        object={object}
        inputs={[
          {
            type: INPUT_TYPES.MULTIPLE_CHOICE,
            required: true,
            name: 'slice_type',
            label: formatMessage({
              id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_type.label',
              defaultMessage: 'Type',
            }),
            helperText: formatMessage({
              id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_type.helper_text',
              defaultMessage: 'Select the type of report you want to create',
            }),
            options: [
              {
                id: 'DEMOGRAPHIC',
                name: formatMessage({
                  id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_type.demographic.name',
                  defaultMessage: 'By demographic',
                }),
                helperText: formatMessage({
                  id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_type.demographic.helper_text',
                  defaultMessage:
                    'Create reports by department, cost center, location, or another.',
                }),
              },
              {
                id: 'LEADERS',
                name: formatMessage({
                  id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_type.leaders.name',
                  defaultMessage: 'By leader',
                }),
                helperText: formatMessage({
                  id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_type.leaders.helper_text',
                  defaultMessage:
                    'Create reports for each leader based on manager hierarchy.',
                }),
              },
              {
                id: 'CUSTOM',
                name: formatMessage({
                  id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_type.custom.name',
                  defaultMessage: 'Custom',
                }),
                helperText: formatMessage({
                  id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_type.custom.helper_text',
                  defaultMessage:
                    'Create any kind of custom report that includes anything you want.',
                }),
              },
            ],
          },
          ...(object.slice_type === 'DEMOGRAPHIC' && !!slicesForTypeData
            ? [
                {
                  type: INPUT_TYPES.DROPDOWN,
                  required: true,
                  name: 'slice_id',
                  label: formatMessage({
                    id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_id.demographic.dropdown.label',
                    defaultMessage: 'Vertical slice',
                  }),
                  helperText: formatMessage({
                    id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_id.demographic.dropdown.helper_text',
                    defaultMessage:
                      'Select the criteria to generate vertical sections of your report',
                  }),
                  objects: slicesForTypeData.slices,
                },
              ]
            : []),
          ...(object.slice_type === 'LEADERS' && !!slicesForTypeData
            ? [
                {
                  type: INPUT_TYPES.DROPDOWN,
                  required: true,
                  name: 'slice_id',
                  label: formatMessage({
                    id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_id.leaders.dropdown.label',
                    defaultMessage: 'Leaders section',
                  }),
                  helperText: formatMessage({
                    id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_id.leaders.dropdown.helper_text',
                    defaultMessage:
                      'Select the criteria to generate a hierarchical sections of your report.',
                  }),
                  objects: slicesForTypeData.slices,
                },
              ]
            : []),
          ...(object.slice_type === 'CUSTOM'
            ? [
                {
                  type: INPUT_TYPES.HIDDEN,
                  name: 'slice_id',
                  label: formatMessage({
                    id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.slice_id.custom.dropdown.label',
                    defaultMessage: 'Custom Repor',
                  }),
                  helperText: (
                    <Card>
                      <CardBody>
                        <FormattedMessage
                          id="app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.custom_description_placeholder"
                          defaultMessage="You will be able to create individual items, add filters and assign to viewers once this report is created."
                        />
                      </CardBody>
                    </Card>
                  ),
                },
              ]
            : []),
          ...(object.slice_type === 'DEMOGRAPHIC' && !!sliceValuesForTypeData
            ? [
                {
                  type: INPUT_TYPES.CUSTOM_INPUT,
                  component: (
                    // @ts-expect-error
                    <FilterablePeopleTable
                      rows={sliceValuesForTypeData.values}
                      columns={[
                        {
                          name: formatMessage({
                            id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.demographic.table.column.name.name',
                            defaultMessage: 'Slice',
                          }),
                          field: 'name',
                        },
                        {
                          name: formatMessage({
                            id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.demographic.table.column.count.name',
                            defaultMessage: '# of people',
                          }),
                          field: 'count',
                        },
                      ]}
                      hideFilters={true}
                      hideExportButton={true}
                    />
                  ),
                },
              ]
            : []),
          ...(object.slice_type === 'LEADERS' && !!sliceValuesForTypeData
            ? [
                {
                  type: INPUT_TYPES.CUSTOM_INPUT,
                  component: (
                    // @ts-expect-error
                    <FilterablePeopleTable
                      initialTableSize={10000}
                      rows={sliceValuesForTypeData.values}
                      columns={[
                        {
                          name: formatMessage({
                            id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.leaders.table.column.name.name',
                            defaultMessage: 'Leader',
                          }),
                          field: 'name',
                        },
                        {
                          name: formatMessage({
                            id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.leaders.table.column.count.name',
                            defaultMessage: '# of people',
                          }),
                          field: 'count',
                        },
                      ]}
                      hideFilters={true}
                      hideExportButton={true}
                    />
                  ),
                },
              ]
            : []),

          ...((object.slice_type === 'DEMOGRAPHIC' ||
            object.slice_type == 'LEADERS') &&
          statusSliceValuesForTypeData === 'LOADING'
            ? [
                {
                  type: INPUT_TYPES.CUSTOM_INPUT,
                  component: (
                    <Loading
                      message={formatMessage({
                        id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.message.fetching_dataset_slices',
                        defaultMessage: 'Fetching dataset slices...',
                      })}
                    />
                  ),
                },
              ]
            : []),
        ]}
        onChange={handleInputsChange}
        submitPromise={handleOnSubmit}
        callback={handleOnSuccessCallback}
        onValidate={handleOnValidate}
        renderForm={renderNewForm}
        submitText={formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_new_modal_editor_button.submitText.create_unpublished_report',
          defaultMessage: 'Create unpublished report',
        })}
        onClosed={handleOnClosed}
        isOpen={isOpen}
        toggle={toggle}
      />
      {createdReportId && createdReportSliceType && (
        <EditModal
          sliceType={createdReportSliceType}
          isOpen={isEditOpen}
          reportId={createdReportId}
          onClosed={handleOnEditClosed}
          onCallback={handleOnEditClosed}
        />
      )}
    </>
  );
};

export default EngagementSurveyReportNewModalEditorButton;
