import { IntlProvider, useIntl } from 'react-intl';
import {
  LOCALES_DISPLAY_LIST_FOR_SELECT,
  isValidLocale,
  loadLocale,
} from './messages';
import { isEmpty, isEqual } from 'lodash';

import { type AxiosInstance } from 'axios';
import ConfirmAPI from '../utils/api/ConfirmAPI';
import ElasticsearchAPI from 'utils/api/ElasticsearchAPI';
import React from 'react';
import { ReduxState } from '../types';
import app_en from './en.json';
import { getLoggedOutLocaleFromLocalStorage } from '../utils/models/User';
import { useLocalStorageState } from '../utils/util/hooks';
import { useSelector } from 'react-redux';

interface ConfirmIntlContextType {
  currentLocale: string;
  setCurrentLocale: (locale: string) => void;
  finalLocale: string;
  addRegisteredInterceptor: (interceptor: number) => void;
  addLanguageApiInterceptor: (api: AxiosInstance, locale: string) => number;
}

const ConfirmIntlContext = React.createContext<ConfirmIntlContextType | null>(
  null
);

const ConfirmIntlProvider = ({ children }) => {
  const [payload, setPayload] = React.useState(app_en);
  const [currentLocale, setCurrentLocale] = React.useState(
    getLoggedOutLocaleFromLocalStorage() || 'en'
  );

  const overrideLocaleParam = new URLSearchParams(window.location.search).get(
    'locale'
  );

  const [finalLocale, setFinalLocale] = React.useState(
    overrideLocaleParam || getLoggedOutLocaleFromLocalStorage() || 'en'
  );

  const [, setRegisteredInterceptors] = React.useState<number[]>([]);

  const addRegisteredInterceptor = React.useCallback((interceptor: number) => {
    console.info(`Registering interceptor with id ${interceptor}`);
    setRegisteredInterceptors((prev) => [...prev, interceptor]);
  }, []);

  const addLanguageApiInterceptor = React.useCallback(
    (api, locale: string): number => {
      const newInterceptor = api.interceptors.request.use(async (req) => {
        if (locale) {
          req.headers['Accept-Language'] = `${locale};q=0.9, en;q=0.8, *;q=0.5`;
        }
        return req;
      });
      console.info(
        `Added language interceptor for ${locale} with id ${newInterceptor}`
      );
      return newInterceptor;
    },
    []
  );

  const changeCurrentLocale = React.useCallback(
    (locale: string) => {
      setCurrentLocale(locale);
      // set the Accept-Language header for API requests
      setRegisteredInterceptors((prev: number[]) => {
        const newInterceptors = [ConfirmAPI, ElasticsearchAPI].flatMap(
          (api) => {
            // Add the new interceptor
            const newInterceptor = addLanguageApiInterceptor(api, locale);
            // Remove previous interceptors so the app does not have overrides
            prev.forEach((interceptor) => {
              console.info(`Ejecting interceptor with id ${interceptor}`);
              api.interceptors.request.eject(interceptor);
            });
            return [newInterceptor];
          }
        );
        return newInterceptors;
      });
    },
    [addLanguageApiInterceptor]
  );

  React.useEffect(() => {
    // update the html document's lang for screenreader accesibility, etc.
    document.documentElement.setAttribute('lang', finalLocale || 'en'); // Default to 'en' if no preference
  }, [finalLocale]);

  React.useEffect(() => {
    if (overrideLocaleParam && isValidLocale(overrideLocaleParam)) {
      setFinalLocale(overrideLocaleParam);
      changeCurrentLocale(overrideLocaleParam);
      loadLocale(overrideLocaleParam).then(setPayload);
    } else if (currentLocale && isValidLocale(currentLocale)) {
      setFinalLocale(currentLocale);
      loadLocale(currentLocale).then(setPayload);
    }
  }, [changeCurrentLocale, currentLocale, overrideLocaleParam]);
  return (
    <ConfirmIntlContext.Provider
      value={{
        currentLocale,
        setCurrentLocale: changeCurrentLocale,
        finalLocale,
        addRegisteredInterceptor,
        addLanguageApiInterceptor,
      }}
    >
      <IntlProvider locale={finalLocale} defaultLocale="en" messages={payload}>
        {children}
      </IntlProvider>
    </ConfirmIntlContext.Provider>
  );
};

const useConfirmIntl = (): ConfirmIntlContextType => {
  const context = React.useContext(ConfirmIntlContext);
  if (context == null) {
    throw new Error('useIntl must be used within a IntlProvider');
  }
  return context;
};

interface ApiLanguageSettings {
  enabled: boolean;
  current_locale: string;
  supported_locales: string[];
}
interface LanguageSettings {
  enabled: boolean;
  current_locale: string;
  supported_locales: SupportedLocaleOptions[];
}

interface SupportedLocaleOptions {
  id: string;
  name: string;
}
interface LanguageSettingsOptions {
  disable?: boolean;
}

const DEFAULT_LANGUAGE_SETTINGS = {
  current_locale: 'en',
  enabled: false,
  supported_locales: LOCALES_DISPLAY_LIST_FOR_SELECT.filter(
    (it) => it.id === 'en'
  ),
};

const useLanguageSettings = ({
  disable = false,
}: LanguageSettingsOptions = {}): LanguageSettings | undefined => {
  const currentProxyPersonEmail = useSelector<ReduxState, string | undefined>(
    (state) => state?.currentProxyPerson?.email
  );

  const currentOrganizationId = useSelector<ReduxState, number | undefined>(
    (state) => state.currentOrganization?.id
  );

  const [languageSettings, setLanguageSettings] = useLocalStorageState(
    'lauguage-settings',
    DEFAULT_LANGUAGE_SETTINGS
  );
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (disable) {
      return;
    }
    ConfirmAPI.sendRequestToConfirm(
      'GET',
      '/people/locales',
      {
        organization: currentOrganizationId,
        proxy: currentProxyPersonEmail,
      },
      (response: ApiLanguageSettings) => {
        if (response) {
          const supportedLocales = LOCALES_DISPLAY_LIST_FOR_SELECT.filter(
            (it) => response.supported_locales.includes(it.id)
          );
          const enabled = response.enabled ?? DEFAULT_LANGUAGE_SETTINGS.enabled;
          const current_locale =
            response.current_locale ?? DEFAULT_LANGUAGE_SETTINGS.current_locale;
          const newLanguageSettings = {
            current_locale,
            enabled,
            supported_locales: isEmpty(supportedLocales)
              ? DEFAULT_LANGUAGE_SETTINGS.supported_locales
              : supportedLocales,
          };
          if (!isEqual(languageSettings, newLanguageSettings)) {
            setLanguageSettings(newLanguageSettings);
          }
        }
      },
      null,
      null
    );
  }, [
    currentOrganizationId,
    currentProxyPersonEmail,
    formatMessage,
    languageSettings,
    setLanguageSettings,
    disable,
  ]);
  return languageSettings;
};

export { ConfirmIntlProvider, useConfirmIntl, useLanguageSettings };
