import React, { useCallback, useState } from 'react';

import { Button } from 'reactstrap';
import ConfirmAPI from 'utils/api/ConfirmAPI';
import ConfirmationDialogModal from 'views/Widgets/Modals/ConfirmationDialogModal';
import { getFriendlyErrorMessageIfErrorOccurred } from 'utils/util/util';
import { useCampaignReports } from '../CampaignReportsContext';
import { useIntl } from 'react-intl';
import { useProxyParameters } from 'utils/api/ApiHooks';

const DeleteButton = ({ reportId }) => {
  const { formatMessage } = useIntl();
  const { refreshEngagementSurveyListVersion } = useCampaignReports();
  const proxyParameters = useProxyParameters();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleOnClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleOnClosed = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOnDeleteCallback = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      `/campaign-reports/${reportId}`,
      proxyParameters,
      (data, error: unknown, hardErrorMessage: unknown) => {
        if (error || hardErrorMessage) {
          setErrorMessage(
            getFriendlyErrorMessageIfErrorOccurred(error, hardErrorMessage)
          );
          return;
        }
        refreshEngagementSurveyListVersion();
        handleOnClosed();
      }
    );
  }, [
    handleOnClosed,
    proxyParameters,
    refreshEngagementSurveyListVersion,
    reportId,
  ]);

  return (
    <>
      <Button className="ms-3 btn btn-light btn-sm" onClick={handleOnClick}>
        <i className="fe fe-trash-2" />
      </Button>
      <ConfirmationDialogModal
        isOpen={isOpen}
        onClosed={handleOnClosed}
        toggle={handleOnClosed}
        confirmCallback={handleOnDeleteCallback}
        title={formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.title.delete_report',
          defaultMessage: 'Delete report',
        })}
        description={formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.description.delete_report',
          defaultMessage: '"Are you sure that you want to delete this report?"',
        })}
        confirmText={formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_reports_table.confirm_text.delete_report',
          defaultMessage: 'Delete report',
        })}
        validationErrors={errorMessage}
      />
    </>
  );
};

export default React.memo(DeleteButton);
