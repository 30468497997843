import {
  ApiCampaignReport,
  ApiCampaignReportItem,
  CampaignReportItemStatus,
  formatCampaignReportDescriptor,
  formatPeopleListForSorting,
  toUpdateItemsApiPayload,
} from './EngagementSurvey';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useConfirmApi, useProxyParameters } from 'utils/api/ApiHooks';

import ConfirmAPI from 'utils/api/ConfirmAPI';
import FilterablePeopleTable from 'views/Widgets/People/FilterablePeopleTable';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import Loading from 'views/Widgets/Loading';
import ModalEditor from 'views/Widgets/Modals/ModalEditor';
import PeopleEditorOverlay from '../PeopleEditorOverlay';
import SwitchInput from 'views/Widgets/Inputs/SwitchInput';
import { renderValidationError } from 'utils/util/formatter';
import { useCampaignReports } from '../CampaignReportsContext';

interface Props {
  reportId: number;
  isOpen: boolean;
  onClosed: () => void;
  onCallback: () => void;
}

type ReportData = {
  description: ApiCampaignReport;
  items: ApiCampaignReportItem[];
};

const EngagementSurveyReportEditModalEditor: FC<Props> = ({
  reportId,
  isOpen,
  onClosed,
  onCallback,
}) => {
  const { formatMessage } = useIntl();
  const { campaignId } = useCampaignReports();
  const proxyParameters = useProxyParameters();

  const [object, setObject] = useState<ReportData | null>(null);

  const onSuccesfullRetrieve = useCallback(
    (
      data: ReportData | null,
      error: unknown,
      hardErrorMessage: unknown
    ): void => {
      if (!error && !hardErrorMessage) {
        setObject(data);
      }
    },
    []
  );

  const { error: errorForReportData } = useConfirmApi<ReportData>({
    url: `/campaigns/${campaignId}/reports/${reportId}`,
    method: 'GET',
    clearOnChange: true,
    clearOnError: true,
    disabled: !isOpen,
    callback: onSuccesfullRetrieve,
  });

  const handleInputsChange = useCallback((_newObject) => {
    // Do nothing
  }, []);

  const handleOnClosed = useCallback(() => {
    onClosed();
    setObject(null);
  }, [onClosed]);

  const handleOnValueItemChanged = useCallback(
    (id, reportId) => (value) => {
      setObject((object) => ({
        ...object!,
        items: object!.items.map((item) => {
          if (item.id === reportId) {
            return { ...item, [id]: value };
          }
          return item;
        }),
      }));
    },
    []
  );

  const handleOnSubmit = useCallback(
    (submittedObject, onSubmit) => {
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        `/campaigns/${campaignId}/reports/${reportId}/update`,
        toUpdateItemsApiPayload(submittedObject, proxyParameters),
        (data, error: unknown, hardErrorMessage: unknown) => {
          onSubmit(data, error, hardErrorMessage);
        }
      );
    },
    [campaignId, proxyParameters, reportId]
  );

  const renderForm = useCallback(
    (inputs, submitButton) => {
      if (!object) {
        return <Loading />;
      }
      const reportInfo = formatCampaignReportDescriptor(
        object!.description,
        formatMessage
      );
      return (
        <>
          <Row>
            <Col>
              <h4>{reportInfo.title}</h4>
              <small className="text-muted">{reportInfo.description}</small>
            </Col>
          </Row>

          <Row className="mt-4">{inputs}</Row>
          {errorForReportData && (
            <Row>{renderValidationError(errorForReportData)}</Row>
          )}
          <Row className="mt-4">
            <Col>
              <Button className="w-100" color="light" onClick={handleOnClosed}>
                <FormattedMessage
                  id="app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
            </Col>
            <Col>{submitButton}</Col>
          </Row>
        </>
      );
    },
    [errorForReportData, formatMessage, handleOnClosed, object]
  );

  const rows = useMemo(() => {
    return (
      object?.items.map((item) => ({
        // @ts-expect-error
        readOnly: !item.reach_minimum_threshold,
        id: item.id,
        slice: item.name,
        owners: (
          <PeopleEditorOverlay
            selectedPeople={item.owners}
            editTitle={'Edit people that will own the report'}
            onUpdate={handleOnValueItemChanged('owners', item.id)}
            readonly={false}
          />
        ),
        owners_sort: formatPeopleListForSorting('owners', 'email'),
        viewers: (
          <PeopleEditorOverlay
            selectedPeople={item.viewers}
            editTitle={'Edit people that will view the report'}
            onUpdate={handleOnValueItemChanged('viewers', item.id)}
            readonly={false}
          />
        ),
        viewers_sort: formatPeopleListForSorting('viewers', 'email'),
        count: item.count,
        // Do a dropdown for status
        status: (
          <div>
            <SwitchInput
              name="status"
              centered={true}
              value={item.status}
              checkedValue={CampaignReportItemStatus.PUBLISHED}
              uncheckedValue={CampaignReportItemStatus.UNPUBLISHED}
              onChange={handleOnValueItemChanged('status', item.id)}
            />
          </div>
        ),
        status_sort: item.status,
      })) || []
    );
  }, [handleOnValueItemChanged, object?.items]);

  const columns = useMemo(
    () => [
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.table.column.slice.name',
          defaultMessage: 'Slice',
        }),
        field: 'slice',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.table.column.count.name',
          defaultMessage: '# People',
        }),
        field: 'count',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.table.column.owners.name',
          defaultMessage: 'Owners',
        }),
        field: 'owners',
        sort: 'owners_sort',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.table.column.viewers.name',
          defaultMessage: 'Viewers',
        }),

        field: 'viewers',
        sort: 'viewers_sort',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.table.column.status.name',
          defaultMessage: 'Status',
        }),
        field: 'status',
        sort: 'status_sort',
      },
    ],
    [formatMessage]
  );

  const inputs = useMemo(
    () => [
      {
        type: INPUT_TYPES.CUSTOM_INPUT,
        component: (
          // @ts-expect-error
          <FilterablePeopleTable
            className="mb-0"
            rows={rows}
            columns={columns}
            hideFilters={true}
            hideExportButton={true}
          />
        ),
      },
    ],
    [columns, rows]
  );

  return (
    <ModalEditor
      title={formatMessage({
        id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.title.edit_report',
        defaultMessage: 'Edit report',
      })}
      isOpen={isOpen}
      toggle={handleOnClosed}
      object={object}
      inputs={inputs}
      onInputsChange={handleInputsChange}
      callback={onCallback}
      renderForm={renderForm}
      submitPromise={handleOnSubmit}
      submitText={formatMessage({
        id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.submitText.save',
        defaultMessage: 'Save',
      })}
      buttonClassName=""
      onClosed={handleOnClosed}
      className="mb-0"
    />
  );
};

export default React.memo(EngagementSurveyReportEditModalEditor);
