import {
  ApiResponseAvailableFilters,
  ApiResponseReport,
  CampaignReportItem,
  CampaignReportItemStatus,
  apiFilterItemToFilterItemTransformer,
  formatCampaignReportDescriptor,
  toCampaignReportCustomItem,
  toUpdateCustomItemsApiPayload,
} from './EngagementSurvey';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import GenericIncludeExcludeFilter, {
  FilterItem,
} from 'views/Widgets/Inputs/GenericIncludeExcludeFilter';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useConfirmApi, useProxyParameters } from 'utils/api/ApiHooks';

import ConfirmAPI from 'utils/api/ConfirmAPI';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import Loading from 'views/Widgets/Loading';
import ModalEditor from 'views/Widgets/Modals/ModalEditor';
import PeopleEditorOverlay from '../PeopleEditorOverlay';
import SwitchInput from 'views/Widgets/Inputs/SwitchInput';
import { renderValidationError } from 'utils/util/formatter';
import { toast } from 'react-toastify';
import { useCampaignReports } from '../CampaignReportsContext';

interface Props {
  reportId: number;
  isOpen: boolean;
  onClosed: () => void;
  onCallback: () => void;
}

const EngagementSurveyReportEditCustomModalEditor: FC<Props> = ({
  reportId,
  isOpen,
  onClosed,
  onCallback,
}) => {
  const { formatMessage } = useIntl();
  const { campaignId } = useCampaignReports();
  const proxyParameters = useProxyParameters();

  const [reportData, setReportData] = useState<ApiResponseReport | null>(null);
  const [campaignReportItem, setCampaignReportItem] =
    useState<CampaignReportItem | null>(null);
  const [filterSuggestions, setFilterSuggestions] = useState<FilterItem[]>([]);

  const onSuccesfullRetrieve = useCallback(
    (
      data: ApiResponseReport | null,
      error: unknown,
      hardErrorMessage: unknown
    ): void => {
      if (data && !error && !hardErrorMessage) {
        setReportData(data);
        setCampaignReportItem(toCampaignReportCustomItem(data, formatMessage));
      }
    },
    [formatMessage]
  );

  const onSuccesfullFiltersRetrieve = useCallback(
    (
      data: ApiResponseAvailableFilters | null,
      error: unknown,
      hardErrorMessage: unknown
    ): void => {
      if (data && !error && !hardErrorMessage) {
        setFilterSuggestions(
          data.available_filters.map(
            apiFilterItemToFilterItemTransformer(formatMessage)
          )
        );
      }
    },
    [formatMessage]
  );

  const { error: errorForReportData } = useConfirmApi<ApiResponseReport>({
    url: `/campaigns/${campaignId}/reports/${reportId}`,
    method: 'GET',
    clearOnChange: true,
    clearOnError: true,
    disabled: !isOpen,
    callback: onSuccesfullRetrieve,
  });

  const { error: errorForAvailableFilters } =
    useConfirmApi<ApiResponseAvailableFilters>({
      url: `/campaigns/${campaignId}/available-filters`,
      method: 'GET',
      clearOnChange: true,
      clearOnError: true,
      disabled: !isOpen,
      callback: onSuccesfullFiltersRetrieve,
    });

  useEffect(() => {
    if (errorForAvailableFilters) {
      toast.error('Error fetching available filters');
    }
  }, [errorForAvailableFilters]);

  const handleInputsChange = useCallback((_newObject) => {
    // Do nothing
  }, []);

  const handleOnChange = useCallback((newObject) => {
    setCampaignReportItem(newObject);
  }, []);

  const handleOnClosed = useCallback(() => {
    onClosed();
    setReportData(null);
  }, [onClosed]);

  const handleOnValueItemChanged = useCallback(
    (id: keyof CampaignReportItem) => (value: unknown) => {
      setCampaignReportItem((item: CampaignReportItem | null) => ({
        ...item!,
        [id]: value,
      }));
    },
    []
  );

  const handleOnSubmit = useCallback(
    (submittedObject, onSubmit) => {
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        `/campaigns/${campaignId}/reports/${reportId}/update`,
        toUpdateCustomItemsApiPayload(submittedObject, proxyParameters),
        (data, error: unknown, hardErrorMessage: unknown) => {
          onSubmit(data, error, hardErrorMessage);
        }
      );
    },
    [campaignId, proxyParameters, reportId]
  );

  const renderForm = useCallback(
    (inputs, submitButton) => {
      if (!reportData) {
        return <Loading />;
      }
      const reportInfo = formatCampaignReportDescriptor(
        reportData!.description,
        formatMessage
      );
      return (
        <>
          <Row>
            <Col>
              <h4>{reportInfo.title}</h4>
              <small className="text-muted">
                <FormattedMessage
                  id="app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.report_with_custom_sections_of_the_organization"
                  defaultMessage="Report with custom sections of the organization"
                />
              </small>
            </Col>
          </Row>

          <Row className="mt-4">{inputs}</Row>
          {errorForReportData && (
            <Row>{renderValidationError(errorForReportData)}</Row>
          )}
          <Row className="mt-4">
            <Col>
              <Button className="w-100" color="light" onClick={handleOnClosed}>
                <FormattedMessage
                  id="app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
            </Col>
            <Col>{submitButton}</Col>
          </Row>
        </>
      );
    },
    [errorForReportData, formatMessage, handleOnClosed, reportData]
  );

  const inputs = useMemo(() => {
    if (!campaignReportItem) {
      return [
        {
          type: INPUT_TYPES.CUSTOM_INPUT,
          component: (
            <Loading
              message={formatMessage({
                id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.message.fetching_report_configuration',
                defaultMessage: 'Fetching report configuration...',
              })}
            />
          ),
        },
      ];
    }
    return [
      {
        type: INPUT_TYPES.TEXT,
        autoFocus: false,
        name: 'name',
        label: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.name.label',
          defaultMessage: 'Report name',
        }),
        helperText: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.name.helper_text',
          defaultMessage: 'Name that will be displayed in the report.',
        }),
      },
      {
        type: INPUT_TYPES.CUSTOM_INPUT,
        name: 'filters',
        label: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.filters.label',
          defaultMessage: 'Filters',
        }),
        helperText: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.filters.helper_text',
          defaultMessage:
            'Filters that will be applied to the report. Leave empty to apply no filters and display the whole organization.',
        }),

        component: (
          <GenericIncludeExcludeFilter
            value={campaignReportItem.filters}
            suggestions={filterSuggestions}
            onChange={handleOnValueItemChanged('filters')}
          />
        ),
      },
      {
        type: INPUT_TYPES.CUSTOM_INPUT,
        name: 'owners',
        label: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.owners.label',
          defaultMessage: 'Owners',
        }),
        helperText: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.owners.helper_text',
          defaultMessage: 'People that will own the report.',
        }),
        component: (
          <PeopleEditorOverlay
            selectedPeople={campaignReportItem.owners}
            editTitle={formatMessage({
              id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.owners.editor.edit_title',
              defaultMessage: 'Edit the people that will own the report.',
            })}
            onUpdate={handleOnValueItemChanged('owners')}
            readonly={false}
          />
        ),
      },
      {
        type: INPUT_TYPES.CUSTOM_INPUT,
        name: 'viewers',
        label: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.viewers.label',
          defaultMessage: 'Viewers',
        }),
        helperText: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.viewers.helper_text',
          defaultMessage: 'People that will be able to view the report.',
        }),
        component: (
          <PeopleEditorOverlay
            selectedPeople={campaignReportItem.viewers}
            editTitle={formatMessage({
              id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.viewers.editor.edit_title',
              defaultMessage: 'Edit the people that will own the report.',
            })}
            onUpdate={handleOnValueItemChanged('viewers')}
            readonly={false}
          />
        ),
      },
      {
        type: INPUT_TYPES.CUSTOM_INPUT,
        name: 'status',
        label: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.status.label',
          defaultMessage: 'Published',
        }),
        helperText: formatMessage({
          id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_custom_modal_editor.form.status.helper_text',
          defaultMessage:
            'Status of the report. You will be able to publish the report once the cycle is finished.',
        }),
        component: (
          <div>
            <SwitchInput
              name="status"
              centered={true}
              value={campaignReportItem.status}
              checkedValue={CampaignReportItemStatus.PUBLISHED}
              uncheckedValue={CampaignReportItemStatus.UNPUBLISHED}
              onChange={handleOnValueItemChanged('status')}
            />
          </div>
        ),
      },
    ];
  }, [
    campaignReportItem,
    filterSuggestions,
    handleOnValueItemChanged,
    formatMessage,
  ]);

  return (
    <ModalEditor
      title={formatMessage({
        id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.title.edit_report',
        defaultMessage: 'Edit report',
      })}
      isOpen={isOpen}
      toggle={handleOnClosed}
      object={campaignReportItem}
      inputs={inputs}
      onInputsChange={handleInputsChange}
      onChange={handleOnChange}
      callback={onCallback}
      renderForm={renderForm}
      submitPromise={handleOnSubmit}
      submitText={formatMessage({
        id: 'app.views.administration.campaign_reports.engagement_survey.engagement_survey_report_edit_modal_editor.submitText.save',
        defaultMessage: 'Save',
      })}
      buttonClassName=""
      onClosed={handleOnClosed}
      className="mb-0"
    />
  );
};

export default React.memo(EngagementSurveyReportEditCustomModalEditor);
