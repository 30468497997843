import startCase from 'lodash/startCase';
import { FormattedList, FormattedMessage, type IntlShape } from 'react-intl';
import { BasicPerson } from 'types';
import React, { Fragment } from 'react';
import { getDescriptionForFieldName } from 'views/Widgets/People/Table/commons';
import {
  ApiFilterItem,
  FilterItem,
} from 'views/Widgets/Inputs/GenericIncludeExcludeFilter';
import { getIconForFieldName } from 'views/Widgets/People/Filters/common';
import { ICONS } from 'consts/consts';

export enum CampaignReportStatus {
  PUBLISHED = 'P',
  UNPUBLISHED = 'U',
  PARTIALLY_PUBLISHED = 'PARTIALLY_PUBLISHED',
}

export enum CampaignReportItemStatus {
  PUBLISHED = 'P',
  UNPUBLISHED = 'U',
}

export enum CampaignReportType {
  ENGAGEMENT_SURVEY = 'E',
}

export enum CampaignReportSubType {
  ES_ADMINS = 'E',
  USER_DEFINED = 'U',
}

export type CampaignReportESSliceType = 'DEMOGRAPHIC' | 'LEADERS' | 'CUSTOM';

export type CampaignReportConfigES = {
  slice_type?: CampaignReportESSliceType;
  slice_id?: string;
};

export type ApiCampaignReportExtraInfo = {
  custom_name?: string;
  filters?: {
    include: ApiFilterItem[];
    exclude: ApiFilterItem[];
  };
  status: CampaignReportStatus;
};

export type ApiCampaignReport = {
  id: number;
  type: CampaignReportType;
  sub_type: CampaignReportSubType;
  status: CampaignReportStatus;
  config: CampaignReportConfigES;
  extra_info: ApiCampaignReportExtraInfo;
  editable: boolean;
};

export type ApiCampaignReportItem = {
  id: number;
  key: string;
  name: string;
  owners: BasicPerson[];
  viewers: BasicPerson[];
  count: number;
  status: CampaignReportItemStatus;
  config: {
    filters?: {
      include: ApiFilterItem[];
      exclude: ApiFilterItem[];
    };
  };
};

export type CampaignReportItem = {
  name: string;
  viewers: BasicPerson[];
  owners: BasicPerson[];
  status: CampaignReportItemStatus;
  filters: {
    include: FilterItem[];
    exclude: FilterItem[];
  };
};

export type ApiResponseReport = {
  description: ApiCampaignReport;
  items: ApiCampaignReportItem[];
};

export type ApiResponseAvailableFilters = {
  campaign_id: number;
  available_filters: ApiFilterItem[];
};

export const getNameForFilter = (
  filter: ApiFilterItem,
  formatMessage: IntlShape['formatMessage']
): string => {
  if (filter.key === 'manager') {
    return formatMessage(
      {
        id: 'app.model.filters.manager',
        defaultMessage: "{name}'s direct reports",
      },
      { name: filter.descriptor?.full_name ?? filter.value }
    );
  } else if (filter.key === 'manager_or_above') {
    return formatMessage(
      {
        id: 'app.model.filters.manager_or_above',
        defaultMessage: "{name}'s full team",
      },
      { name: filter.descriptor?.full_name ?? filter.value }
    );
  }

  return filter.value;
};

export const getIconForFilterKey = (key: string) => {
  if (key === 'manager' || key === 'manager_or_above') {
    return ICONS.PEOPLE;
  }

  return getIconForFieldName(key);
};

const formatFilterForDescription = (
  filter: ApiFilterItem,
  formatMessage: IntlShape['formatMessage']
) => {
  if (filter.key === 'manager' || filter.key === 'manager_or_above') {
    return <b>{getNameForFilter(filter, formatMessage)}</b>;
  }
  const description = getDescriptionForFieldName(filter.key, formatMessage);
  return (
    <>
      <b>{filter.value}</b> <i>{'(' + description + ')'}</i>
    </>
  );
};

export const apiFilterItemToFilterItemTransformer =
  (formatMessage: IntlShape['formatMessage']) =>
  (filter: ApiFilterItem): FilterItem => {
    if (filter.key === 'manager' || filter.key === 'manager_or_above') {
      return {
        icon: ICONS.PEOPLE,
        name: getNameForFilter(filter, formatMessage),
        object: filter,
        description: filter.descriptor?.title ?? '',
      };
    }

    return {
      icon: getIconForFilterKey(filter.key),
      name: filter.value,
      object: filter,
      description: getDescriptionForFieldName(filter.key, formatMessage),
    };
  };

export const toUpdateCustomItemsApiPayload = (modelObject, proxyParameters) => {
  return {
    id: modelObject.id,
    name: modelObject.name,
    items: [
      {
        id: modelObject.id,
        name: modelObject.name,
        owners_ids: modelObject.owners.map((p) => p.id),
        viewers_ids: modelObject.viewers.map((p) => p.id),
        status: modelObject.status,
        config: {
          filters: {
            include: modelObject.filters.include.map((f) => f.object),
            exclude: modelObject.filters.exclude.map((f) => f.object),
          },
        },
      },
    ],
    ...proxyParameters,
  };
};

export const toCampaignReportCustomItem = (
  data: ApiResponseReport,
  formatMessage
): React.SetStateAction<CampaignReportItem | null> => {
  return {
    ...data.items[0],
    filters: {
      include: data.items[0]!.config!.filters!.include.map((f) => ({
        icon: getIconForFilterKey(f.key),
        name: getNameForFilter(f, formatMessage),
        object: f,
      })),
      exclude: data.items[0]!.config!.filters!.exclude.map((f) => ({
        icon: getIconForFilterKey(f.key),
        name: getNameForFilter(f, formatMessage),
        object: f,
      })),
    },
  };
};

const formatDemographicsSliceValue = (sliceValue: string) => {
  return startCase(sliceValue.replaceAll('_', ' '));
};

const formatLeadersSliceValue = (
  sliceValue: string,
  formatMessage: IntlShape['formatMessage']
) => {
  const [, level] = sliceValue.split('_');

  const message = formatMessage(
    {
      id: 'app.model.filters.level',
      defaultMessage: 'Level {level}',
    },
    { level }
  );

  if (level === '1') {
    return `${message} (${formatMessage({
      id: 'app.model.filters.level.top_level',
      defaultMessage: 'Top level',
    })})`;
  }

  return message;
};

export const formatCampaignReportDescriptor = (
  report: ApiCampaignReport,
  formatMessage: IntlShape['formatMessage']
): {
  title: string;
  description: string | JSX.Element | (string | JSX.Element)[];
} => {
  switch (report.sub_type) {
    case CampaignReportSubType.ES_ADMINS:
      return {
        title: formatMessage({
          id: 'app.views.administration.campaign_reports.sub_type.es_admins.title',
          defaultMessage: 'Admin report',
        }),
        description: formatMessage({
          id: 'app.views.administration.campaign_reports.sub_type.es_admins.description',
          defaultMessage:
            'Report about everyone in the organization available for engagement survey admins.',
        }),
      };
    case CampaignReportSubType.USER_DEFINED:
      switch (report.config.slice_type) {
        case 'DEMOGRAPHIC':
          return {
            title: formatMessage({
              id: 'app.views.administration.campaign_reports.sub_type.user_defined.demographic.title',
              defaultMessage: 'Demographic report',
            }),
            description: (
              <FormattedMessage
                id="app.views.administration.campaign_reports.sub_type.user_defined.demographic.description"
                defaultMessage="Vertical sections of the organizations sliced by <b>{value}</b>"
                values={{
                  value: formatDemographicsSliceValue(report.config.slice_id!),
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            ),
          };
        case 'LEADERS':
          return {
            title: formatMessage({
              id: 'app.views.administration.campaign_reports.sub_type.user_defined.leaders.title',
              defaultMessage: 'Leaders report',
            }),
            description: (
              <FormattedMessage
                id="app.views.administration.campaign_reports.sub_type.user_defined.leaders.description"
                defaultMessage="Reports for <b>{value}</b> employees"
                values={{
                  value: formatLeadersSliceValue(
                    report.config.slice_id!,
                    formatMessage
                  ),
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            ),
          };
        case 'CUSTOM': {
          const include = report.extra_info.filters?.include ?? [];
          const exclude = report.extra_info.filters?.exclude ?? [];
          return {
            title: formatMessage({
              id: 'app.views.administration.campaign_reports.sub_type.user_defined.custom.title',
              defaultMessage: 'Custom report',
            }),
            description: formatMessage(
              {
                id: 'app.views.administration.campaign_reports.sub_type.user_defined.custom.description',
                defaultMessage: '{name} - {filters}',
              },
              {
                name: report.extra_info.custom_name,
                filters: (
                  <>
                    {!include.length && !exclude.length && (
                      <b>
                        <FormattedMessage
                          id="app.views.administration.campaign_reports.engagement_survey.engagement_survey.everyone"
                          defaultMessage="Everyone"
                        />
                      </b>
                    )}
                    {include.length > 0 && exclude.length > 0 && (
                      <FormattedMessage
                        id="app.views.administration.campaign_reports.engagement_survey_reports.include_exclude"
                        defaultMessage="Everyone in {include} excluding {exclude}"
                        values={{
                          include: (
                            <FormattedList
                              value={include.map((f, i) => (
                                <Fragment key={i}>
                                  {formatFilterForDescription(f, formatMessage)}
                                </Fragment>
                              ))}
                            />
                          ),
                          exclude: (
                            <FormattedList
                              value={exclude.map((f, i) => (
                                <Fragment key={i}>
                                  {formatFilterForDescription(f, formatMessage)}
                                </Fragment>
                              ))}
                            />
                          ),
                        }}
                      />
                    )}
                    {include.length > 0 && !exclude.length && (
                      <FormattedList
                        value={include.map((f, i) => (
                          <Fragment key={i}>
                            {formatFilterForDescription(f, formatMessage)}
                          </Fragment>
                        ))}
                      />
                    )}
                    {!include.length && exclude.length > 0 && (
                      <FormattedMessage
                        id="app.views.administration.campaign_reports.engagement_survey_reports.exclude_only"
                        defaultMessage="Everyone excluding {exclude}"
                        values={{
                          exclude: (
                            <FormattedList
                              value={exclude.map((f, i) => (
                                <Fragment key={i}>
                                  {formatFilterForDescription(f, formatMessage)}
                                </Fragment>
                              ))}
                            />
                          ),
                        }}
                      />
                    )}
                  </>
                ),
              }
            ),
          };
        }
      }
      throw new Error('Unknown combination provided');
  }
};

export const formatCampaignReportStatus = (
  report: ApiCampaignReport,
  formatMessage: IntlShape['formatMessage']
): string => {
  switch (report.extra_info.status) {
    case CampaignReportStatus.PUBLISHED:
      return formatMessage({
        id: 'app.views.administration.campaign_reports.engagement_survey_reports.published',
        defaultMessage: 'Published',
      });
    case CampaignReportStatus.UNPUBLISHED:
      return formatMessage({
        id: 'app.views.administration.campaign_reports.engagement_survey_reports.unpublished',
        defaultMessage: 'Unpublished',
      });
    case CampaignReportStatus.PARTIALLY_PUBLISHED:
      return formatMessage({
        id: 'app.views.administration.campaign_reports.engagement_survey_reports.partially_published',
        defaultMessage: 'Partially Published',
      });
  }
};

export const processApiSliceValues = (data: any) => {
  return {
    ...data,
    values: data.values.map((it) => ({
      ...it,
      readOnly: !it.reach_minimum_threshold,
    })),
  };
};

export const toUpdateItemsApiPayload = (modelObject, proxyParameters) => {
  return {
    items: modelObject.items.map((item) => ({
      id: item.id,
      owners_ids: item.owners.map((p) => p.id),
      viewers_ids: item.viewers.map((p) => p.id),
      status: item.status,
    })),
    ...proxyParameters,
  };
};

export const formatPeopleListForSorting =
  (fieldName, propertyName) => (aItem, bItem) => {
    const a = aItem[fieldName];
    const b = bItem[fieldName];
    const diffInLength = a.length - b.length;
    if (diffInLength !== 0) return diffInLength;
    if (a.length === 0) return 0;
    return a[0][propertyName].localeCompare(b[0][propertyName]);
  };
