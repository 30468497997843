import * as consts from '../../consts/consts';

import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
  UncontrolledAccordion,
} from 'reactstrap';
import {
  AgendaItem,
  ExtendedPerson,
  Features,
  Me,
  Organization,
  OrganizationSettings,
  Person,
  ReduxState,
} from 'types';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useEventsSerializer,
  useStaleTabDetector,
} from '../../utils/util/hooks';

import { AGENDA_ITEM_VISIBILITY_ALL_PARTICIPANTS } from '../../utils/models/AgendaItem';
import AgendaItemList from '../OneOnOnes/AgendaItemList';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import Loading from '../Widgets/Loading';
import PersonCard from '../Widgets/Cards/PersonCard';
import PersonFeedbackList from './PersonFeedbackList';
import PersonGoals from './PersonGoals';
import PersonalObjectives from './PersonalObjectives';
import { Prompt } from 'react-router-dom';
import PulseCheckSummary from '../PulseChecks/PulseCheckSummary';
import { atLeastOneContinuousFeedbackFeatureIsEnabled } from '../../utils/util/features';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { omit } from 'lodash';
import { peopleIdsAreEqual } from '../../utils/models/Person';
import { renderErrorOrCallback } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';
import { useCollaborationToken } from '../../utils/util/collab';
import { useWebSocket } from '../../utils/util/websocket';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_AGENDA_ITEM_FIELDS = {
  visibility: AGENDA_ITEM_VISIBILITY_ALL_PARTICIPANTS.id,
  // for sorting on creation, we need to populate these fields
  sort_key: null,
  completed_at: null,
};

const DEFAULT_AGENDA_ITEM_TEMPLATES = [
  {
    id: 1,
    name: 'Weekly 1:1 meeting agenda (separate items for each topic)',
    value: [
      '🥅 How are you progressing towards your goals?&nbsp;<br>',
      '👍 What was achieved since last meeting?&nbsp;<br>',
      '3️⃣ What are your top three goals for next week?&nbsp;<br>',
      '🛑 Roadblocks &amp; Concerns?<br>',
      '🎁 Additional Feedback or things on your mind?<br>',
    ],
    category: 'Weekly 1:1s',
  },
  {
    id: 2,
    name: 'Weekly 1:1 meeting agenda (one big item)',
    value:
      '🥅 How are you progressing towards your goals?&nbsp;<br><br><br>👍 What was achieved since last meeting?&nbsp;<br><br><br>3️⃣ What are your top three goals for next week?&nbsp;<br><br><br>🛑 Roadblocks &amp; Concerns?<br><br><br>🎁 Additional Feedback or things on your mind?<br><br>',
    category: 'Weekly 1:1s',
  },
  {
    id: 3,
    name: 'First 1:1 meeting agenda (separate items for each topic)',
    value: [
      '👋 A little bit about you<br><ul><li>What do you like to do outside of work?&nbsp;</li><li>What motivates you the most?&nbsp;</li><li>What kind of projects are you most excited to work on?&nbsp;</li></ul>',
      '📖 About the role<br><ul><li>What are your expectations for this role?&nbsp;</li><li>What do you need from your manager?&nbsp;</li><li>How do you prefer to receive recognition – publicly or privately?&nbsp;</li><li>In what way (Slack, email, in person, etc.) do you prefer to receive feedback?&nbsp;</li><li>What makes 1:1s the most valuable for you?&nbsp;</li></ul>',
      '💼 Career Goals<br><ul><li>What are your short, medium, and long-term career goals?&nbsp;</li><li>What does success look like in the next 30, 60, 90 days?&nbsp;</li></ul>',
      '🙂 How can I help?',
    ],
    category: 'First 1:1s',
  },
  {
    id: 4,
    name: 'First 1:1 meeting agenda (one big item)',
    value:
      '👋 A little bit about you<br><ul><li>What do you like to do outside of work?&nbsp;</li><li>What motivates you the most?&nbsp;</li><li>What kind of projects are you most excited to work on?&nbsp;</li></ul><br>📖 About the role<br><ul><li>What are your expectations for this role?&nbsp;</li><li>What do you need from your manager?&nbsp;</li><li>How do you prefer to receive recognition – publicly or privately?&nbsp;</li><li>In what way (Slack, email, in person, etc.) do you prefer to receive feedback?&nbsp;</li><li>What makes 1:1s the most valuable for you?&nbsp;</li></ul><br>💼 Career Goals<br><ul><li>What are your short, medium, and long-term career goals?&nbsp;</li><li>What does success look like in the next 30, 60, 90 days?&nbsp;</li></ul><br>🙂 How can I help?<br><br>',
    category: 'First 1:1s',
  },
];

interface Props {
  me: Me;
  person: ExtendedPerson;
  currentOrganization: Organization;
  originalOrganization: Organization;
  currentProxyPerson: Person;
  features: Features;
  settings: OrganizationSettings;
}

const PersonOneOnOnes: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [agendaItems, setAgendaItems] = useState<
    AgendaItem[] | undefined | null
  >(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [focalPersonIsMe, setFocalPersonIsMe] = useState(false);

  const [feedbackList, setFeedbackList] = useState(undefined);
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState(undefined);
  const [myFeedbackList, setMyFeedbackList] = useState(undefined);
  const [myFeedbackErrorMessage, setMyFeedbackErrorMessage] =
    useState(undefined);
  const [aspirations, setAspirations] = useState(undefined);
  const [myAspirations, setMyAspirations] = useState(undefined);
  const [pulseChecks, setPulseChecks] = useState(undefined);

  const setAgendaItemsWithFilter = useCallback(
    (agendaItems) => {
      setAgendaItems(
        agendaItems.filter(
          (agendaItem) =>
            agendaItem.visibility === 'A' ||
            agendaItem.owner_person === props.me.id ||
            agendaItem.owner_person.id === props.me.id
        )
      );
    },
    [setAgendaItems, props.me]
  );

  const onSubmit = (agendaItem, onSuccess, onError) => {
    if (agendaItem.operation === 'DELETE') {
      ConfirmAPI.sendRequestToConfirm(
        'DELETE',
        // use key instead of id in case id does not exist
        '/agenda-items/' + agendaItem.key,
        null,
        renderErrorOrCallback(onSuccess, onError)
      );
    } else {
      // send POST whether new or existing as we use key for uniqueness
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        '/agenda-items',
        transformObjectBeforeSubmit(agendaItem),
        renderErrorOrCallback(onSuccess, onError)
      );
    }
  };

  const onSuccess = (successItem) => {
    setAgendaItems((agendaItems) =>
      // @ts-expect-error
      agendaItems.map((agendaItem) =>
        agendaItem.key === successItem.key
          ? omit(agendaItem, 'status')
          : agendaItem
      )
    );
  };

  const onError = (message, errorItem) => {
    if ((errorItem?.retryAttempt ?? 0) > 2) {
      setAgendaItems((agendaItems) =>
        // @ts-expect-error
        agendaItems.map((agendaItem) =>
          agendaItem.key === errorItem.key
            ? { ...agendaItem, status: 'UPDATING' }
            : agendaItem
        )
      );
    }
    console.warn('Could not save agenda item, retrying...:', errorItem.key);
  };

  const [addToBufferQueue, hasPendingChanges] = useEventsSerializer(
    onSubmit,
    onSuccess,
    onError
  );

  const { user } = useAuth0();
  const userSub = user?.sub;

  const toggleSidebarFocalPerson = useCallback(() => {
    setFocalPersonIsMe(!focalPersonIsMe);
  }, [focalPersonIsMe]);

  // on load, append ?with={your person id} to the url so that it can be
  // shared with the other person for easy access and redirect to the right
  // place (which is done in OneOnOnesDirectory.js)
  useEffect(() => {
    if (props.me?.id) {
      const url = new URL(window.location.href);
      // @ts-expect-error
      url.searchParams.set('with', props.me?.id);
      window.history.replaceState({}, '', url);
    }
  }, [props.me?.id]);

  // if agendaItemsAddOrUpdateQueue or agendaItemsDeleteQueue are non-empty,
  // then we are in the middle of a save and should not allow the user to
  // navigate away from the page unless they want to lose information
  const leavePromptMessage = consts.UNSAVED_CHANGES_PROMPT;
  const onUnload = useCallback(
    (e) => {
      if (hasPendingChanges) {
        e.preventDefault();
        e.returnValue = leavePromptMessage;
        return leavePromptMessage;
      }
    },
    [hasPendingChanges, leavePromptMessage]
  );
  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [hasPendingChanges, onUnload]);

  // since we are saving as we go, there is potential data loss if the user
  // has a stale tab and starts editing it by accident. this will overwrite
  // the data in the database with potentially old data in the stale tab.
  // to prevent this, we will reload the agenda if the user navigates away
  // for more than 30 seconds
  useStaleTabDetector(() => {
    setAgendaItems(undefined);
    setInitialPopulationRequestInFlight(false);
    // @ts-expect-error
  }, []);

  const replacePersonIdsWithPersonObjects = useCallback(
    (agendaItem) => {
      return {
        ...agendaItem,
        owner_person: peopleIdsAreEqual(agendaItem.owner_person, props.me?.id)
          ? props.me
          : props.person,
        participant_person: peopleIdsAreEqual(
          agendaItem.participant_person,
          props.me?.id
        )
          ? props.me
          : props.person,
      };
    },
    [props.me, props.person]
  );

  const transformObjectBeforeSubmit = useCallback(
    (agendaItem) => {
      return {
        ...agendaItem,
        owner_person: agendaItem.owner_person?.id,
        participant_person: agendaItem.participant_person?.id,
        // add organization if no id set (meaning this is a create)
        organization: agendaItem.organization?.id
          ? undefined
          : props.currentOrganization?.id,

        // remove id field (in favor of key field) to avoid any possible
        // data loss / conflicts / collisions
        id: undefined,
      };
    },
    [props.currentOrganization?.id]
  );

  // this handles all of the updates from the durable object
  const onWebSocketMessage = useCallback(
    (event) => {
      const message = JSON.parse(event.data);

      if (!agendaItems) {
        // if agendaItems is undefined or null, then we are still loading the
        // agenda so we don't want to do anything with the message
        return;
      }

      // someone else manually updated one or more agenda items
      if (message.type === 'update') {
        // resolve conflicts in favor of incoming data
        let agendaItemsCopy = agendaItems.map((ai) => {
          const x = message.data.find((t) => ai.key === t.key);
          return x || ai;
        });

        // also add any new items
        message.data.forEach((t) => {
          if (!agendaItemsCopy.find((ai) => ai.key === t.key)) {
            // when we add items created by remote users, we don't
            // want to switch focus to them, so we set the id to -1
            // see AgendaItemInput.js:190 to see the logic for this
            agendaItemsCopy.push({ id: -1, ...t });
          }
        });

        // ensure that we have person objects instead of ids
        agendaItemsCopy = agendaItemsCopy.map((x) => {
          if (
            typeof x.owner_person !== 'object' &&
            typeof x.participant_person !== 'object'
          ) {
            return replacePersonIdsWithPersonObjects(x);
          } else {
            return x;
          }
        });

        setAgendaItemsWithFilter(agendaItemsCopy); // update UI
      } else if (message.type === 'delete') {
        // someone else deleted one or more items
        const newAgendaItems = agendaItems.filter((ai) => {
          return !message.data.find((t) => ai.key === t.key);
        });

        setAgendaItems(newAgendaItems); // update UI
      } else if (message.type === 'connect') {
        // someone else connected to the collaborative agenda
        //
        // syncing local items with the items of the one who just
        // connected resulted in apparent corruption. instead, stick
        // to the philosophy of only updating local items in response
        // to someone typing on the other end, and do not make any
        // local item changes when someone else connects, even if their
        // version of items are different or they have items that we
        // do not
      } else if (message.type === 'input') {
        // resolve conflicts in favor of incoming data
        let agendaItemsCopy = agendaItems.map((ai) => {
          const x = message.data.find((t) => ai.key === t.key);
          return x ? { ...ai, description: x.description } : ai;
        });

        // ensure that we have person objects instead of ids
        agendaItemsCopy = agendaItemsCopy.map((x) => {
          if (
            // @ts-expect-error
            typeof x.owner_person !== 'object' &&
            // @ts-expect-error
            typeof x.participant_person !== 'object'
          ) {
            return replacePersonIdsWithPersonObjects(x);
          } else {
            return x;
          }
        });

        setAgendaItemsWithFilter(agendaItemsCopy); // update UI
      }
    },
    [agendaItems, replacePersonIdsWithPersonObjects, setAgendaItemsWithFilter]
  );

  const participants = useMemo(
    () => [props.me?.id, props.person?.id],
    [props.me?.id, props.person?.id]
  );
  const collaborationToken = useCollaborationToken(
    'agenda',
    participants,
    // @ts-expect-error
    props.currentOrganization?.id,
    props.originalOrganization?.id,
    props.currentProxyPerson
  );

  const [sendWebSocket] = useWebSocket(collaborationToken, onWebSocketMessage);

  const [
    initialPopulationRequestInFlight,
    setInitialPopulationRequestInFlight,
  ] = useState(false);

  // initial fetch
  useEffect(() => {
    if (agendaItems || initialPopulationRequestInFlight) {
      return;
    }

    if (props.person?.id && props.currentOrganization?.id) {
      setInitialPopulationRequestInFlight(true);
      ConfirmAPI.getUrlWithCache(
        '/agenda-items',
        // Note: we do NOT cache here because if the cached version loads fast
        // but the server's version loads slowly, and the user edits the cached
        // version before the server's version loads, then the user's edits
        // will be lost. We want to avoid this situation.
        // @ts-expect-error
        null, //'agenda-items-' + props.person?.id,
        null, //userSub,
        props.currentProxyPerson,
        {
          person: props.person?.id,
          organization: props.currentOrganization?.id,
        },
        (data) => {
          if (data?.results) {
            setAgendaItems(data.results.map(replacePersonIdsWithPersonObjects));
            sendWebSocket({ type: 'connect', data: data.results });
          }
        },
        (message) => {
          console.error('Could not fetch agenda items: ' + message);
          setErrorMessage(message);
          setAgendaItems(null);
        }
      );
    }
  }, [
    props.me,
    props.person,
    agendaItems,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    userSub,
    replacePersonIdsWithPersonObjects,
    sendWebSocket,
    initialPopulationRequestInFlight,
  ]);

  const showPulseCheck = useMemo(
    () => props.features?.pulse_checks?.enabled,
    [props.features?.pulse_checks?.enabled]
  );

  const showObjectives = useMemo(
    () => props.features?.objectives?.enabled,
    [props.features?.objectives?.enabled]
  );

  const showContinuousFeedbackAndRecognition = useMemo(
    () => atLeastOneContinuousFeedbackFeatureIsEnabled(props.features),
    [props.features]
  );

  useEffect(() => {
    if (!showContinuousFeedbackAndRecognition || !props.person?.id) {
      return;
    }

    const params = {
      person: props.person.id,
      organization: props.currentOrganization?.id,
      proxy: props.currentProxyPerson
        ? props.currentProxyPerson.email
        : undefined,
      // default to true and trust the backend to deny if not allowed
      manager_view: true,
      // only show most recent result
      limit: 1,
      received_only: true,
    };

    ConfirmAPI.getUrlWithCache(
      '/feedback',
      // @ts-expect-error
      null,
      'feedback-first-' + props.person?.id,
      null,
      params,
      (data) => {
        if (data?.results) {
          setFeedbackList(data.results);
        }
      },
      (message) => {
        setFeedbackErrorMessage(message);
      }
    );
  }, [
    props.currentOrganization?.id,
    props.currentProxyPerson,
    showContinuousFeedbackAndRecognition,
    props.person?.id,
  ]);

  useEffect(() => {
    if (!showContinuousFeedbackAndRecognition || !props.me?.id) {
      return;
    }

    const params = {
      person: props.me.id,
      organization: props.currentOrganization?.id,
      proxy: props.currentProxyPerson
        ? props.currentProxyPerson.email
        : undefined,
      // only show most recent result
      limit: 1,
      received_only: true,
    };

    ConfirmAPI.getUrlWithCache(
      '/feedback',
      // @ts-expect-error
      null,
      'feedback-first-' + props.me?.id,
      null,
      params,
      (data) => {
        if (data?.results) {
          setMyFeedbackList(data.results);
        }
      },
      (message) => {
        setMyFeedbackErrorMessage(message);
      }
    );
  }, [
    props.currentOrganization?.id,
    props.currentProxyPerson,
    showContinuousFeedbackAndRecognition,
    props.me?.id,
  ]);

  const showGoals = useMemo(
    () => props.features?.goals?.enabled,
    [props.features?.goals?.enabled]
  );

  const incompleteAgendaItems = useMemo(() => {
    // sort from oldest to newest so order persists when refreshing page
    // as new items show at the bottom
    return (
      agendaItems
        ?.filter((t) => !t.completed_at)
        // @ts-expect-error
        ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)) ?? []
    );
  }, [agendaItems]);

  const completeAgendaItems = useMemo(() => {
    return agendaItems?.filter((t) => t.completed_at);
    // note: sorting will get done when grouped in grouping component below
  }, [agendaItems]);

  const strToNewAgendaItem = useCallback(
    (description) => ({
      ...DEFAULT_AGENDA_ITEM_FIELDS,
      key: uuidv4(),
      owner_person: props.me,
      participant_person: props.person,
      description,
      // default to right now to ensure new items are sortable
      // and the latest item shows at the bottom
      created_at: new Date().toISOString(),
    }),
    [props.me, props.person]
  );

  // when agenda items are changed by this user locally, we send the changes
  // both to the server and to the websocket
  const processLocalChanges = useCallback(
    (updateType, changes) => {
      if (updateType === 'add') {
        addToBufferQueue(changes);
        sendWebSocket({ type: 'update', data: changes });
        setAgendaItems((agendaItems) => {
          if (!agendaItems) return agendaItems;
          return [...agendaItems, ...changes];
        });
      } else if (updateType === 'update') {
        const t = changes[0];
        addToBufferQueue(t);
        sendWebSocket({ type: 'update', data: [t] });
        setAgendaItems((agendaItems) => {
          if (!agendaItems) return agendaItems;
          return agendaItems.map((ai) => (ai.key === t.key ? t : ai));
        });
      } else if (updateType === 'delete') {
        const t = changes[0];
        addToBufferQueue({ ...t, operation: 'DELETE' });
        sendWebSocket({ type: 'delete', data: [t] });
        setAgendaItems((agendaItems) => {
          if (!agendaItems) return agendaItems;
          return agendaItems.filter((ai) => ai.key !== t.key);
        });
      }
    },
    [addToBufferQueue, sendWebSocket]
  );

  const onAgendaItemAdded = useCallback(
    (val) => {
      const itemsToAdd = val && Array.isArray(val) ? val : [val];
      processLocalChanges('add', itemsToAdd.map(strToNewAgendaItem));
    },
    [strToNewAgendaItem, processLocalChanges]
  );

  const onInput = useCallback(
    (t) => {
      sendWebSocket({
        type: 'input',
        data: [{ key: t.key, description: t.description }],
      });
    },
    [sendWebSocket]
  );

  const onAgendaItemChanged = useCallback(
    (t) => {
      processLocalChanges('update', [t]);
    },
    [processLocalChanges]
  );

  const onAgendaItemDeleted = useCallback(
    (t) => {
      processLocalChanges('delete', [t]);
    },
    [processLocalChanges]
  );

  const orgSpecificAgendaItemTemplates = props?.settings?.agenda_item_templates;

  const agendaItemTemplates = useMemo(() => {
    return orgSpecificAgendaItemTemplates ?? DEFAULT_AGENDA_ITEM_TEMPLATES;
  }, [orgSpecificAgendaItemTemplates]);

  const loadOrRenderOutput = loadOrRender(agendaItems, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (typeof agendaItems === 'undefined') {
    return <Loading />;
  }

  // TODO: consider: toggle top right defaulted on to show items only visible
  // to you that you can disable for screen sharing

  const pageDisplay = (
    <Row>
      <Col className="col-12 col-md-8">
        <Row className="pb-4" data-testid="agenda-items-active">
          <Col>
            <h3 className="mb-3">
              <FormattedMessage
                id="app.views.person.person_one_on_ones.section_title.agenda"
                defaultMessage="Agenda"
              />
            </h3>
            <AgendaItemList
              allowReordering={true}
              disabled={!!props.currentProxyPerson}
              agendaItems={incompleteAgendaItems}
              callback={(ai) => onAgendaItemChanged(ai)}
              addNewCallback={(val) => onAgendaItemAdded(val)}
              deleteCallback={(ai) => onAgendaItemDeleted(ai)}
              inputCallback={(val) => onInput(val)}
              // @ts-expect-error
              agendaItemTemplates={agendaItemTemplates}
            />
          </Col>
        </Row>
        {!!completeAgendaItems?.length && (
          <Row data-testid="agenda-items-completed">
            <Col>
              <h3 className="mb-3 text-muted">
                <FormattedMessage
                  id="app.views.person.person_one_on_ones.section_title.history"
                  defaultMessage="History"
                />
              </h3>
              <div>
                <AgendaItemList
                  allowReordering={false}
                  disabled={!!props.currentProxyPerson}
                  agendaItems={completeAgendaItems}
                  groupByDate={true}
                  callback={(ai) => onAgendaItemChanged(ai)}
                  deleteCallback={(ai) => onAgendaItemDeleted(ai)}
                  inputCallback={(val) => onInput(val)}
                  // @ts-expect-error
                  agendaItemTemplates={agendaItemTemplates}
                />
              </div>
            </Col>
          </Row>
        )}
      </Col>
      <Col className="col-12 col-md-4 one-on-ones-sidebar">
        <div role="button" onClick={toggleSidebarFocalPerson}>
          <ul className="nav nav-tabs mb-4">
            <li className="nav-item">
              <span
                style={{ wordBreak: 'normal' }}
                className={
                  'nav-link py-0 text-center' + (!focalPersonIsMe && ' active')
                }
              >
                <div className="mb-3 pe-3">
                  <PersonCard
                    size="xxs"
                    person={{
                      ...props.person,
                      full_name: props.person.given_name,
                    }}
                    showDescription={false}
                    bodyOnly={true}
                  />
                </div>
              </span>
            </li>
            <li className="nav-item">
              <span
                style={{ wordBreak: 'normal' }}
                className={
                  'nav-link py-0 text-center' + (focalPersonIsMe && ' active')
                }
              >
                <div className="mb-3 pe-3">
                  <PersonCard
                    size="xxs"
                    person={{
                      ...props.me,
                      full_name: 'Me',
                    }}
                    showDescription={false}
                    bodyOnly={true}
                  />
                </div>
              </span>
            </li>
          </ul>
        </div>
        {!focalPersonIsMe && (
          <UncontrolledAccordion
            stayOpen={true}
            defaultOpen={['their-checkins', 'their-recognition']}
          >
            {/* we check undefined and > 0 separately because we want to load it when
                undefined as the component itself fetches the pulse checks, but if 0
                are loaded, we don't want to show the sidebar at all */}
            {showPulseCheck &&
              (typeof pulseChecks === 'undefined' ||
                // @ts-expect-error
                pulseChecks?.length > 0) && (
                <>
                  {/* Note: this will only show if the person has a pulse check and
        the chain of command / admin permissions allows the current user to see it */}
                  <AccordionItem>
                    <AccordionHeader targetId="their-checkins">
                      <CardHeaderTitle>
                        <FormattedMessage
                          id="app.views.person.person_one_on_ones.accordion_title.checkins"
                          defaultMessage="Check-ins"
                        />
                      </CardHeaderTitle>
                    </AccordionHeader>
                    <AccordionBody accordionId="their-checkins">
                      <PulseCheckSummary
                        isMe={false}
                        bodyOnly={true}
                        person={props.person}
                        // @ts-expect-error
                        setPulseChecks={setPulseChecks}
                      />
                    </AccordionBody>
                  </AccordionItem>
                </>
              )}
            {/* hide if error thrown as this is secondary content */}
            {!feedbackErrorMessage && showContinuousFeedbackAndRecognition && (
              <AccordionItem>
                <AccordionHeader targetId="their-recognition">
                  <CardHeaderTitle>
                    <FormattedMessage
                      id="app.views.person.person_one_on_ones.accordion_title.recognition"
                      defaultMessage="Recent recognition"
                    />
                  </CardHeaderTitle>
                </AccordionHeader>
                <AccordionBody accordionId="their-recognition">
                  <PersonFeedbackList
                    bodyOnly={true}
                    showAvatars={false}
                    hideFilters={true}
                    showManagerOnlyPerformanceDetails={true}
                    person={props.person}
                    feedbackList={feedbackList}
                    errorMessage={feedbackErrorMessage}
                    showSentFeedback={false}
                  />
                </AccordionBody>
              </AccordionItem>
            )}
            {showObjectives && (
              <>
                <AccordionItem>
                  <AccordionHeader targetId="their-objectives">
                    <CardHeaderTitle>
                      <FormattedMessage
                        id="app.views.person.person_one_on_ones.accordion_title.objectives"
                        defaultMessage="Objectives"
                      />
                    </CardHeaderTitle>
                  </AccordionHeader>
                  <AccordionBody accordionId="their-objectives">
                    <PersonalObjectives
                      showTeamNav={false}
                      person={props.person}
                      isReadOnly={true}
                      hideDatePicker={true}
                      hideScoresAndWeight={true}
                      hideRelatedObjectives={true}
                      showMiniEmptyStateCallToAction={true}
                    />
                  </AccordionBody>
                </AccordionItem>
              </>
            )}
            {showGoals && (
              <AccordionItem>
                <AccordionHeader targetId="their-aspirations">
                  <CardHeaderTitle>
                    <FormattedMessage
                      id="app.views.person.person_one_on_ones.accordion_title.aspirations"
                      defaultMessage="Aspirations"
                    />
                  </CardHeaderTitle>
                </AccordionHeader>
                <AccordionBody accordionId="their-aspirations">
                  <PersonGoals
                    inSidebar={true}
                    readOnly={true}
                    person={props.person}
                    aspirations={aspirations}
                    setAspirations={setAspirations}
                  />
                </AccordionBody>
              </AccordionItem>
            )}
          </UncontrolledAccordion>
        )}
        {focalPersonIsMe && (
          <UncontrolledAccordion
            stayOpen={true}
            defaultOpen={['my-checkins', 'my-recognition']}
          >
            {showPulseCheck && (
              <>
                <AccordionItem>
                  <AccordionHeader targetId="my-checkins">
                    <CardHeaderTitle>
                      <FormattedMessage
                        id="app.views.person.person_one_on_ones.accordion_title.my_checkins"
                        defaultMessage="Check-ins"
                      />
                    </CardHeaderTitle>
                  </AccordionHeader>
                  <AccordionBody accordionId="my-checkins">
                    <PulseCheckSummary
                      isMe={true}
                      bodyOnly={true}
                      person={props.me}
                    />
                  </AccordionBody>
                </AccordionItem>
              </>
            )}
            {showContinuousFeedbackAndRecognition && (
              <AccordionItem>
                <AccordionHeader targetId="my-recognition">
                  <CardHeaderTitle>
                    <FormattedMessage
                      id="app.views.person.person_one_on_ones.accordion_title.my_recognition"
                      defaultMessage="Recent recognition"
                    />
                  </CardHeaderTitle>
                </AccordionHeader>
                <AccordionBody accordionId="my-recognition">
                  <PersonFeedbackList
                    bodyOnly={true}
                    showAvatars={false}
                    hideFilters={true}
                    person={props.me}
                    showManagerOnlyPerformanceDetails={false}
                    feedbackList={myFeedbackList}
                    errorMessage={myFeedbackErrorMessage}
                    showSentFeedback={false}
                  />
                </AccordionBody>
              </AccordionItem>
            )}
            {showObjectives && (
              <>
                <AccordionItem>
                  <AccordionHeader targetId="my-objectives">
                    <CardHeaderTitle>
                      <FormattedMessage
                        id="app.views.person.person_one_on_ones.accordion_title.my_objectives"
                        defaultMessage="Objectives"
                      />
                    </CardHeaderTitle>
                  </AccordionHeader>
                  <AccordionBody accordionId="my-objectives">
                    <PersonalObjectives
                      showTeamNav={false}
                      person={props.me}
                      isReadOnly={true}
                      hideDatePicker={true}
                      hideScoresAndWeight={true}
                      hideRelatedObjectives={true}
                      showMiniEmptyStateCallToAction={true}
                    />
                  </AccordionBody>
                </AccordionItem>
              </>
            )}
            {showGoals && (
              <AccordionItem>
                <AccordionHeader targetId="their-aspirations">
                  <CardHeaderTitle>
                    <FormattedMessage
                      id="app.views.person.person_one_on_ones.accordion_title.my_aspirations"
                      defaultMessage="Aspirations"
                    />
                  </CardHeaderTitle>
                </AccordionHeader>
                <AccordionBody accordionId="their-aspirations">
                  <PersonGoals
                    inSidebar={true}
                    readOnly={true}
                    person={props.me}
                    aspirations={myAspirations}
                    setAspirations={setMyAspirations}
                  />
                </AccordionBody>
              </AccordionItem>
            )}
          </UncontrolledAccordion>
        )}
        <Row className="pt-4">
          <Col>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={consts.SUPPORT_PORTAL.ONE_ON_ONES}
            >
              <FormattedMessage
                id="app.views.person.person_one_on_ones.learn_more"
                defaultMessage="
              Learn more about 1:1s
              "
              />
              <i className="fe fe-external-link ps-2" />
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
  // TODO: consider: toggle top right defaulted on to show items only visible
  // to you that you can disable for screen sharing
  return (
    <>
      <Prompt
        when={hasPendingChanges}
        message={formatMessage({
          id: 'app.views.person.person_one_on_ones.message.you_have_unsaved_changes',
          defaultMessage:
            'You have unsaved changes, are you sure you want to leave?',
        })}
      />
      {hasPendingChanges && (
        <span hidden>
          <FormattedMessage
            id="app.views.person.person_one_on_ones.saving"
            defaultMessage="Saving...."
          />
        </span>
      )}
      {pageDisplay}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const {
    me,
    currentOrganization,
    originalOrganization,
    currentProxyPerson,
    features,
    settings,
  } = state;

  return {
    me: me,
    currentOrganization: currentOrganization,
    originalOrganization: originalOrganization,
    currentProxyPerson: currentProxyPerson,
    features,
    settings,
  };
};

export default connect(mapStateToProps)(React.memo(PersonOneOnOnes));
