import React, { useContext } from 'react';

import PerformanceHistoryChart from './HistoryChart';
import { PerformanceHistoryData } from './HistoryChartUtils';
import PersonPerformanceContext from '../PersonPerformanceContext';
import { useIntl } from 'react-intl';

interface NetworkRecognitionHistoryChartProps {
  person?: {
    id: number;
    given_name: string;
  };
  showManagerOnlyPerformanceDetails: boolean;
  performanceData?: PerformanceHistoryData[];
}

const NetworkRecognitionHistoryChart: React.FC<
  NetworkRecognitionHistoryChartProps
> = ({ person, showManagerOnlyPerformanceDetails }) => {
  const { formatMessage } = useIntl();
  const ctx = useContext(PersonPerformanceContext);

  return (
    <PerformanceHistoryChart
      key={`chart_history_network_${person?.id}`}
      personId={person?.id}
      title={formatMessage(
        {
          id: 'app.views.person.person_performance.history.persons_network_recognition',
          defaultMessage: 'Network recognition over time',
        },
        {
          firstName: ctx.firstName,
        }
      )}
      firstName={ctx.firstName}
      data={ctx.networkRecognitionHistoricalData?.chartData ?? {}}
      options={ctx.networkRecognitionHistoricalData?.chartOptions ?? {}}
      individualAlerts={
        ctx.networkRecognitionHistoricalData?.dataValues.individual.alerts ?? []
      }
      cycleDatapoints={ctx.networkRecognitionHistoricalData?.data}
      overlaps={ctx.networkRecognitionHistoricalData?.chartOverlaps}
      name={ctx.firstName}
      includeAverage={true}
      managerView={showManagerOnlyPerformanceDetails}
      chartType="recognition"
    />
  );
};

export default NetworkRecognitionHistoryChart;
