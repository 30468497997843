import { useEffect, useState } from 'react';

import ConfirmAPI from '../api/ConfirmAPI';
import { Person } from '../../types';
import { toast } from 'react-toastify';

/**
 * This hook is used to obtain a collaboration token that
 * authenticates the websocket connection to the Cloudflare
 * worker.
 *
 * This token should be sent to the websocket as a query
 * parameter called "token".
 *
 * This hook handles periodically refreshing the token when it
 * expires so that the calling code doesn't have to.
 *
 * @param type - the type of collaboration, e.g. "agenda"
 * @param participants - the participants in the collaborative editing session
 * @param organizationId - the organization id
 * @param currentProxyPerson - the current proxy person
 *
 * @returns collaborationToken
 */
export const useCollaborationToken = (
  type: string,
  participants: number[],
  organizationId: number,
  originalOrganizationId: number,
  currentProxyPerson?: Person
) => {
  const [collaborationToken, setCollaborationToken] = useState<string>();

  useEffect(() => {
    if (!collaborationToken) {
      // if orgs are different, we are proxying internally
      if (originalOrganizationId !== organizationId) {
        setCollaborationToken(() => '');
        return;
      }

      if (participants?.length) {
        ConfirmAPI.getUrlWithCache(
          '/get-collaboration-token',
          // @ts-expect-error
          null,
          null,
          currentProxyPerson,
          {
            type,
            participants,
            organization_id: organizationId,
          },
          (data) => {
            if (data?.collaboration_token) {
              setCollaborationToken(data.collaboration_token);

              const now = new Date().getTime() / 1000;
              const ttl_in_seconds = data.expiration - now;
              setTimeout(() => {
                setCollaborationToken(undefined);
              }, ttl_in_seconds * 1000);
            }
          },
          (error) => {
            if (error?.status === 400 && Array.isArray(error?.data)) {
              toast.warn(error.data.join(', '));
              return;
            }
            console.warn(
              `useCollaborationToken: cannot get token: ${JSON.stringify(
                error
              )}`
            );
          }
        );
      }
    }
  }, [
    collaborationToken,
    currentProxyPerson,
    organizationId,
    originalOrganizationId,
    participants,
    type,
  ]);

  return collaborationToken;
};
